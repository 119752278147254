import { combineReducers } from "redux";

import { empresasComPGREmitidoReducer } from "../ExportacaoEmpresas/reducers";
import { empresasAtivasReducer } from "../RelatorioEmpresas/reducers";
import relatoriosHomeReducer from "../Relatorios/_/reducers";

export default combineReducers({
  empresasComPGREmitido: empresasComPGREmitidoReducer,
  empresasAtivas: empresasAtivasReducer,
  relatoriosHome: relatoriosHomeReducer,
});
