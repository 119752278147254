import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { onUnauthenticated } from "../../../../auth/SetupInterceptors";
import Button from "../../../../components/Buttons/_/components/button";
import IconSESI from "../../../../components/IconsSESI";
import Assistant from "../../../../components/VirtualAssistant";
import viviSorrindoEsquerda from "../../../../constants/images/vivi-sorrindo-esquerda.svg";
import { isEmpty } from "../../../../util/arrayUtil";
import { getCompanyAnswers } from "../actions";
import Perguntas from "./Perguntas";

const Questionario = (props) => {
  const [hasAnswers, setHasAnswers] = useState(false);

  useEffect(() => {
    if (isEmpty(props.companySelected)) {
      onUnauthenticated();
    }
  });

  useEffect(() => {
    getCompanyAnswers(props.dispatch);
    loadFirstTalk();
  }, []);

  useEffect(() => {
    setHasAnswers(true);
  }, [JSON.stringify(props.respostasAutodeclaracao)]);

  const loadFirstTalk = () => {
    if (props.respostasAutodeclaracao.length !== 0) {
      return;
    }

    let currentTalk = {
      assistant: viviSorrindoEsquerda,
      viewMode: "default",
      buttons: null,
      text: [],
    };

    currentTalk.text = props.assistantTalks[1].descricao;
    setTalk(currentTalk);
  };

  const changeTalk = (currentTalkIndex) => {
    getAssistantTalk(currentTalkIndex);
  };

  const [talk, setTalk] = useState([]);

  const getAssistantTalk = (index) => {
    const newTalk = props.assistantTalks[index];
    const currentTalk = {
      assistant: viviSorrindoEsquerda,
      viewMode: "default",
      buttons: null,
      text: [],
    };

    newTalk.descricao.forEach((talk) => {
      currentTalk.text.push(talk);
    });

    setTalk(currentTalk);
  };

  return (
    <div className="questionario-wrapper">
      <Row gutter={24}>
        <Col span={6}>
          <Assistant talk={talk} />
        </Col>
        <Col span={18}>
          <Row gutter={24}>
            <Col span={24}>
              <div className="header-form">
                <Link to="/autodeclaracao">
                  <Button
                    shape="circle"
                    ghost
                    text={<IconSESI type="arrow-left" />}
                  />
                </Link>
                <h2>Autodeclaração</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Perguntas continue={hasAnswers} changeTalk={changeTalk} />
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    companySelected: state.caracterizacao.companySelected,
    respostasAutodeclaracao: state.autodeclaracao.respostas
      ? state.autodeclaracao.respostas
      : [],
    assistantTalks: state.gestorDN.virtualAssistant.autoStatement,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({
      getCompanyAnswers,
    }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Questionario);
