import React, { useState, useEffect } from "react";
import { Card } from "antd";
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";

import { Link } from "react-router-dom";
import IconSESI from "../../../../../../../components/IconsSESI";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    console.log(payload, label);
    return (
      <div className="custom-tooltip">
        <p className="label">
          <strong>{`${payload[0].name}: `}</strong>
          {payload[0].value}
        </p>
      </div>
    );
  }

  return null;
};
const RelatorioDiarioCard = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    parseData(props.data);
  }, [JSON.stringify(props.data)]);

  const parseData = (data) => {
    let parsedData = [
      {
        name: "Gerar PGR",
        value: 0,
        color: "var(--marguerite)",
      },
      {
        name: "Plano de Ação",
        value: 0,
        color: "var(--comet)",
      },
      {
        name: "Classificação de Riscos",
        value: 0,
        color: "var(--melrose)",
      },
      {
        name: "Em Preenchimento",
        value: 0,
        color: "var(--red)",
      },
      {
        name: "Questionário Autodeclaração",
        value: 0,
        color: "var(--green)",
      },
    ];
    if (data.length) {
      parsedData.map((status) => {
        data.map((company) => {
          if (status.name === company.status) {
            status.value++;
          }
        });
      });
      setData(parsedData);
      return parsedData;
    }
  };

  let isEmpty = true;
  if (data.length) isEmpty = false;

  return (
    <Link to={"/relatorio-diario"}>
      <Card bordered={false} hoverable={true} className="relatorio-status">
        <div className="header">
          <h3>Relatório por status</h3>
          <IconSESI type="transfer-right" />
        </div>
        <div className="content">
          {isEmpty ? (
            <span style={{ marginTop: "1rem" }}>
              <br />{" "}
              <h4>
                Ainda não há informações
                <br /> sobre o dia de hoje
              </h4>
            </span>
          ) : (
            <PieChart width={288} height={150}>
              <Tooltip content={<CustomTooltip />} />
              <Legend
                verticalAlign="middle"
                layout="vertical"
                align="right"
                iconType="square"
              />
              <Pie
                cx={90}
                cy={70}
                innerRadius={35}
                outerRadius={60}
                data={data}
              >
                {data.map((entry, index) => (
                  <Cell fill={entry.color} stroke={entry.color} />
                ))}
              </Pie>
            </PieChart>
          )}
        </div>
      </Card>
    </Link>
  );
};

export default RelatorioDiarioCard;
