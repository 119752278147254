import {
  ALTERAR_FATOR_DE_RISCO,
  ALTERAR_MAO_POR_GRUPO,
  LIMPAR_FATOR_DE_RISCO_SELECIONADO,
  LISTAR_FATORES_DE_RISCO,
  LISTAR_MAOS,
  LISTAR_MAOS_POR_GRUPO,
  OBTER_FATOR_DE_RISCO,
  OBTER_MAO,
  OBTER_EPC,
  SALVAR_FATOR_DE_RISCO,
  SALVAR_MAO_POR_GRUPO,
  LIMPAR_MAO_SELECIONADO,
  LISTAR_EPCS_POR_GRUPO,
  LISTAR_EPIS,
  LISTAR_EPIS_POR_GRUPO,
  OBTER_EPI,
  SALVAR_EPI_POR_GRUPO,
  ALTERAR_EPI_POR_GRUPO,
  LIMPAR_EPI_SELECIONADO,
  LISTAR_EPCS,
  CRIAR_EPC,
  EDITAR_EPC,
  OBTER_EPC_DA_LISTA,
  LIMPAR_EPC_SELECIONADO,
  DELETAR_EPC,
  EDITAR_EPI,
  OBTER_EPI_DA_LISTA,
  LIMPAR_SELECAO_EPI,
  DELETAR_EPI,
  EDITAR_MAO,
  OBTER_MAO_DA_LISTA,
  LIMPAR_SELECAO_MAO,
  DELETAR_MAO,
} from "../actions/types";
import {
  ADICIONAR_CNAE_FALTANTE,
  DELETAR_CNAE_FALTANTE,
  LISTAR_CNAES,
  LISTAR_CNAES_FALTANTES,
  LISTAR_CNAES_NAO_AUTOMATIZADOS,
} from "../components/CNAEs/actions/types";
import {
  GET_ACTION_PLAN_TALKS,
  GET_AUTOSTATEMENT_TALKS,
  GET_BEGINNING_TALKS,
  GET_CATEGORIZATION_TALKS,
  GET_MONITORING_TALKS,
  GET_RISKS_TALKS,
  PATCH_ACTION_PLAN_TALKS,
  PATCH_BEGINNING_TALK,
  PATCH_CATEGORIZATION_TALK,
  PATCH_MONITORING_TALK,
  PATCH_RISKS_TALKS,
  PATCH_AUTOSTATEMENT_TALKS,
} from "../components/AssistenteVirtual/actions/types";

export const gestorDNReducer = (
  state = {
    fatoresDeRiscoPorGrupo: [],
    fatorDeRiscoSelecionado: {},
    cnaesAutomatizados: [],
    cnaesNaoAutomatizados: [],
    cnaesFaltantes: [],
    virtualAssistant: {
      beginningTalks: [],
      categorizationTalks: [],
      monitoringTalks: {},
    },
  },
  action
) => {
  switch (action.type) {
    case LISTAR_FATORES_DE_RISCO:
      return {
        ...state,
        fatoresDeRiscoPorGrupo: action.payload,
      };
    case SALVAR_FATOR_DE_RISCO:
      return {
        fatoresDeRiscoPorGrupo: [],
      };
    case OBTER_FATOR_DE_RISCO:
      return {
        ...state,
        fatorDeRiscoSelecionado: action.payload,
      };
    case LIMPAR_FATOR_DE_RISCO_SELECIONADO:
      return {
        ...state,
        fatorDeRiscoSelecionado: action.payload,
      };
    case ALTERAR_FATOR_DE_RISCO:
      return {
        fatorDeRiscoSelecionado: {},
        fatoresDeRiscoPorGrupo: [],
      };
    case LISTAR_MAOS:
      return {
        ...state,
        listaMAOs: action.payload,
      };
    case LISTAR_MAOS_POR_GRUPO:
      return {
        ...state,
        listaMAOsPorGrupo: action.payload,
      };
    case LISTAR_EPCS_POR_GRUPO:
      return {
        ...state,
        listaEPCsPorGrupo: action.payload,
      };
    case OBTER_MAO:
      return {
        ...state,
        relacaoMAOFatorDeRiscoSelecionada: action.payload,
      };
    case OBTER_EPC:
      return {
        ...state,
        relacaoEPCFatorDeRiscoSelecionada: action.payload,
      };
    case SALVAR_MAO_POR_GRUPO:
      return {
        listaMAOsPorGrupo: [],
      };
    case ALTERAR_MAO_POR_GRUPO:
      return {
        listaMAOsPorGrupo: [],
      };
    case LIMPAR_MAO_SELECIONADO:
      return {
        ...state,
        relacaoMAOFatorDeRiscoSelecionada: action.payload,
      };

    case LISTAR_EPCS:
      return {
        ...state,
        listaEPCs: action.payload,
      };
    case LISTAR_EPIS:
      return {
        ...state,
        listaEPIs: action.payload,
      };
    case LISTAR_EPIS_POR_GRUPO:
      return {
        ...state,
        listaEPIsPorGrupo: action.payload,
      };
    case OBTER_EPI:
      return {
        ...state,
        relacaoEPIFatorDeRiscoSelecionada: action.payload,
      };
    case SALVAR_EPI_POR_GRUPO:
      return {
        listaEPIsPorGrupo: [],
      };
    case ALTERAR_EPI_POR_GRUPO:
      return {
        listaEPIsPorGrupo: [],
      };
    case LIMPAR_EPI_SELECIONADO:
      return {
        ...state,
        relacaoEPIFatorDeRiscoSelecionada: action.payload,
      };
    case EDITAR_EPC:
      return {
        ...state,
        listaEPCs: action.payload,
      };
    case OBTER_EPC_DA_LISTA:
      return {
        ...state,
        epcSelecionado: action.payload,
      };
    case LIMPAR_EPC_SELECIONADO:
      return {
        ...state,
        epcSelecionado: action.payload,
      };
    case DELETAR_EPC:
      return {
        ...state,
        listaEPCs: action.payload,
      };
    case EDITAR_EPI:
      return {
        ...state,
        listaEPIs: action.payload,
      };
    case OBTER_EPI_DA_LISTA:
      return {
        ...state,
        epiSelecionado: action.payload,
      };
    case LIMPAR_SELECAO_EPI:
      return {
        ...state,
        epiSelecionado: action.payload,
      };
    case DELETAR_EPI:
      return {
        ...state,
        listaEPIs: action.payload,
      };
    case EDITAR_MAO:
      return {
        ...state,
        listaMAOs: action.payload,
      };
    case OBTER_MAO_DA_LISTA:
      return {
        ...state,
        maoSelecionado: action.payload,
      };
    case LIMPAR_SELECAO_MAO:
      return {
        ...state,
        maoSelecionado: action.payload,
      };
    case DELETAR_MAO:
      return {
        ...state,
        listaMAOs: action.payload,
      };
    case LISTAR_CNAES:
      return {
        ...state,
        cnaesAutomatizados: action.payload,
      };
    case LISTAR_CNAES_NAO_AUTOMATIZADOS:
      return {
        ...state,
        cnaesNaoAutomatizados: action.payload,
      };
    case LISTAR_CNAES_FALTANTES:
      return {
        ...state,
        cnaesFaltantes: action.payload,
      };
    case ADICIONAR_CNAE_FALTANTE:
      return {
        ...state,
        cnaesFaltantes: [...state.cnaesFaltantes, action.payload],
      };
    case DELETAR_CNAE_FALTANTE:
      return {
        ...state,
        cnaesFaltantes: state.cnaesFaltantes.filter(
          (cnae) => cnae.id !== action.payload
        ),
      };
    case GET_BEGINNING_TALKS:
      return {
        ...state,
        virtualAssistant: {
          beginningTalks: action.payload,
        },
      };
    case PATCH_BEGINNING_TALK: {
      const uneditedTalks = state.virtualAssistant.beginningTalks.filter(
        (talk) => talk.id !== action.payload.id
      );
      return {
        ...state,
        virtualAssistant: {
          ...state.virtualAssistant,
          beginningTalks: [...uneditedTalks, { ...action.payload }],
        },
      };
    }
    case GET_CATEGORIZATION_TALKS:
      return {
        ...state,
        virtualAssistant: {
          ...{ beginningTalks: state.virtualAssistant.beginningTalks },
          ...{ categorizationTalks: action.payload },
        },
      };
    case PATCH_CATEGORIZATION_TALK: {
      const uneditedTalks = state.virtualAssistant.categorizationTalks.filter(
        (talk) => talk.id !== action.payload.id
      );
      return {
        ...state,
        virtualAssistant: {
          ...state.virtualAssistant,
          categorizationTalks: [...uneditedTalks, { ...action.payload }],
        },
      };
    }
    case GET_RISKS_TALKS:
      return {
        ...state,
        virtualAssistant: {
          ...state.virtualAssistant,
          ...{
            monitoringTalks: {
              risksTalks: action.payload,
            },
          },
        },
      };
    case PATCH_RISKS_TALKS: {
      const uneditedTalks =
        state.virtualAssistant.monitoringTalks.risksTalks.filter(
          (talk) => talk.id !== action.payload.id
        );
      return {
        ...state,
        virtualAssistant: {
          ...state.virtualAssistant,
          monitoringTalks: {
            ...state.virtualAssistant.monitoringTalks,
            risksTalks: [...uneditedTalks, { ...action.payload }],
          },
        },
      };
    }
    case GET_ACTION_PLAN_TALKS:
      return {
        ...state,
        virtualAssistant: {
          ...state.virtualAssistant,
          ...{
            monitoringTalks: {
              ...state.virtualAssistant.monitoringTalks,
              ...{ actionPlanTalks: action.payload },
            },
          },
        },
      };
    case PATCH_ACTION_PLAN_TALKS: {
      const uneditedTalks =
        state.virtualAssistant.monitoringTalks.actionPlanTalks.filter(
          (talk) => talk.id !== action.payload.id
        );
      return {
        ...state,
        virtualAssistant: {
          ...state.virtualAssistant,
          monitoringTalks: {
            ...state.virtualAssistant.monitoringTalks,
            actionPlanTalks: [...uneditedTalks, { ...action.payload }],
          },
        },
      };
    }
    case GET_MONITORING_TALKS:
      return {
        ...state,
        virtualAssistant: {
          ...state.virtualAssistant,
          ...{
            monitoringTalks: {
              ...state.virtualAssistant.monitoringTalks,
              ...{ monitoringTalks: action.payload },
            },
          },
        },
      };
    case PATCH_MONITORING_TALK: {
      const uneditedTalks =
        state.virtualAssistant.monitoringTalks.monitoringTalks.filter(
          (talk) => talk.id !== action.payload.id
        );
      return {
        ...state,
        virtualAssistant: {
          ...state.virtualAssistant,
          monitoringTalks: {
            ...state.virtualAssistant.monitoringTalks,
            monitoringTalks: [...uneditedTalks, { ...action.payload }],
          },
        },
      };
    }
    case GET_AUTOSTATEMENT_TALKS:
      return {
        ...state,
        virtualAssistant: {
          ...state.virtualAssistant,
          ...{ autoStatement: action.payload },
        },
      };
    case PATCH_AUTOSTATEMENT_TALKS: {
      const uneditedTalks = state.virtualAssistant.autoStatement.filter(
        (talk) => talk.id !== action.payload.id
      );
      return {
        ...state,
        virtualAssistant: {
          ...state.virtualAssistant,
          autoStatement: [...uneditedTalks, { ...action.payload }],
        },
      };
    }
    default:
      return state;
  }
};
