import { LIST_QUESTIONS, ANSWER_QUESTION, GET_ANSWERS } from "../actions/types";

export const selfDeclarationReducer = (state = {}, action) => {
  switch (action.type) {
    case LIST_QUESTIONS:
      return { ...state, autodeclaracao: action.payload };
    case ANSWER_QUESTION:
      return { ...state, responses: action.payload };
    case GET_ANSWERS:
      return { ...state, respostas: action.payload };
    default:
      return state;
  }
};
