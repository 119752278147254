import React, { useState } from "react";
import CustomMenu from "./../../components/CustomMenu";
import { Row, Col, Tooltip } from "antd";
import { Link } from "react-router-dom";
import "./styles.scss";
import logo from "./../../constants/images/logo.svg";

const CustomSider = (props) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = (collapsedArg) => {
    if (collapsedArg) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  };

  let customLogo;
  props.customizacoes != null && props.customizacoes.logo != null
    ? (customLogo = `${process.env.REACT_APP_BACKEND_URL}${props.customizacoes.logo.url}`)
    : (customLogo = logo);

  let customName;
  props.customizacoes != null && props.customizacoes.nomeDeExibicao
    ? (customName = props.customizacoes.nomeDeExibicao)
    : (customName = "");

  let inicio = "/";
  if (props.perfil === "ADMINISTRADOR") inicio = "/admin";
  if (!props.authenticated) return null;

  return (
    <div className={collapsed ? "sider-collapsed" : "sider"}>
      <Row className="header-side" align="middle">
        <Col span={3} className="logo" id="logo">
          <Tooltip
            title="Início"
            placement="right"
            overlayClassName="tooltip-inicio"
          >
            <Link to={inicio}>
              <img alt="Logo do SESI" src={customLogo} />
              <div className={collapsed ? "word-wrap" : "customName-wrapper"}>
                <h6>{customName}</h6>
              </div>
            </Link>
          </Tooltip>
        </Col>
        <Col className="user">
          <h5>{props.username}</h5>
          <h6>{props.profile}</h6>
        </Col>
      </Row>
      <CustomMenu toggleCollapse={toggleCollapse} />
    </div>
  );
};

export default CustomSider;
