import { Col, Row, Tabs } from "antd";
import React, { useState, useEffect } from "react";
import IconSESI from "../../../../../../../components/IconsSESI";
import Button from "../../../../../../../components/Buttons/_/components/button";
import ProcessForm from "../components/ProcessForm";
import ProcessesList from "../components/ProcessesList";
import "../../styles.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchCargos,
  fetchFuncionarios,
} from "../../../CaracterizacaoFuncionarios/_/actions";
import { fetchSetores } from "../../../CaracterizacaoSetores/_/actions";
import { fetchProcessos } from "../../../CaracterizacaoProcessosProdutivos/_/actions";
import {
  getInventarioDeRisco,
  getInventarioDeRiscoByProcessIds,
} from "../../../../../../InventarioRiscos/_/actions";
import { Link } from "react-router-dom";

const CaracterizacaoRealizadorProcessos = (props) => {
  const [currentTab] = useState("list");

  const { TabPane } = Tabs;

  useEffect(() => {
    if (props.setores.length < 1) {
      fetchFuncionarios(props.dispatch);
      fetchSetores(props.dispatch);
      fetchCargos(props.dispatch);
      fetchProcessos(props.dispatch);
      getInventarioDeRisco(props.dispatch);
    }
    // if (props.stepsEmpresa.REALIZADOR_DO_PROCESSO) {
    //   props.onChangeTalk("talk3", 4);
    // } else {
    //   props.onChangeTalk("talk1", 4);
    // }
  });

  useEffect(() => {
    if (props.stepsEmpresa.REALIZADOR_DO_PROCESSO) {
      props.onChangeTalk("talk3", 1);
    } else {
      props.onChangeTalk("talk1", 1);
    }
  }, [props.stepsEmpresa.REALIZADOR_DO_PROCESSO]);

  // useEffect(() => {
  //   getInventarioDeRisco(props.dispatch);
  // }, [JSON.stringify(props.getInventarioDeRisco)]);

  return (
    <Row gutter={32} className="definir-operador">
      <Col lg={24} md={24}>
        <Tabs activeKey={currentTab}>
          <TabPane tab="" key="list">
            <div className="container realizador-processos-wrapper">
              <Row
                gutter={16}
                type="flex"
                justify="space-around"
                align="middle"
              >
                <Col span={18}>
                  <div className="header-form">
                    <Button
                      shape="circle"
                      ghost
                      onClick={() => props.nextStep(props.current - 1)}
                      text={<IconSESI type="arrow-left" />}
                    />
                    <h2>Trabalhador x Processo</h2>
                  </div>
                </Col>
                <Col span={6}>
                  <Link to={"/acompanhamento"}>
                    <Button
                      type="primary"
                      text="CONTINUAR"
                      className="next-step"
                      block
                    />
                  </Link>
                </Col>
              </Row>
              <div className="content">
                <ProcessesList
                  funcionarios={props.funcionarios}
                  setores={props.setores}
                  cargos={props.cargos}
                  processosProdutivos={props.processosProdutivos}
                  inventarioDeRisco={props.inventarioDeRisco}
                  perfil={props.perfil}
                  getInventarioDeRiscoByProcessIds={
                    getInventarioDeRiscoByProcessIds
                  }
                  completed={props.stepsEmpresa.REALIZADOR_DO_PROCESSO}
                  dispatch={props.dispatch}
                />
              </div>
            </div>
          </TabPane>

          <TabPane tab="" key="form">
            <ProcessForm />
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    funcionarios: state.caracterizacao.funcionarios.funcionarios,
    setores: state.caracterizacao.setores.setores,
    cargos: state.caracterizacao.funcionarios.cargos,
    processosProdutivos:
      state.caracterizacao.processosProdutivos.processosProdutivos,
    inventarioDeRisco: state.caracterizacao.inventarioDeRisco,
    perfil: state.user.userInfo.perfil,
    stepsEmpresa: state.caracterizacao.stepsEmpresa.stepsEmpresa,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        fetchFuncionarios,
        fetchCargos,
        fetchSetores,
        fetchProcessos,
        getInventarioDeRisco,
        getInventarioDeRiscoByProcessIds,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaracterizacaoRealizadorProcessos);
