import React from "react";
import { Form, Row, Col, Input } from "antd";

const SearchBar = (props) => {
  const { filterList, name } = props;
  return (
    <Form>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item>
            <Input placeholder={`Pesquisar ${name}`} onChange={filterList} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchBar;
