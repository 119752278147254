import React, { Component } from "react";
import CompanySelectResponsavel from "./_/components/CompanySelectResponsavel";
import { connect } from "react-redux";
import "./styles.scss";

class SelecionarEmpresa extends Component {
  render() {
    return <CompanySelectResponsavel {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    customizacoes: state.user.userInfo ? state.user.userInfo.customizacoes : {},
    perfil: state.user.userInfo.perfil,
    companySelected: state.caracterizacao
      ? state.caracterizacao.companySelected
      : {},
    dashboard: state.dashboard,
    talkInicio: state.gestorDN.virtualAssistant.beginningTalks,
  };
};

export default connect(mapStateToProps, {})(SelecionarEmpresa);
