export const validateCpf = (cpfNumber) => {
  cpfNumber = cpfNumber.replace(/\D/g, "");
  if (typeof cpfNumber !== "string") return false;

  cpfNumber = cpfNumber.replace(/[\s.-]*/gim, "");
  if (
    !cpfNumber ||
    cpfNumber.length != 11 ||
    cpfNumber == "00000000000" ||
    cpfNumber == "11111111111" ||
    cpfNumber == "22222222222" ||
    cpfNumber == "33333333333" ||
    cpfNumber == "44444444444" ||
    cpfNumber == "55555555555" ||
    cpfNumber == "66666666666" ||
    cpfNumber == "77777777777" ||
    cpfNumber == "88888888888" ||
    cpfNumber == "99999999999"
  ) {
    return false;
  }
  var soma = 0;
  var resto;
  for (var i = 1; i <= 9; i++)
    soma = soma + parseInt(cpfNumber.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpfNumber.substring(9, 10))) return false;
  soma = 0;
  for (var i = 1; i <= 10; i++)
    soma = soma + parseInt(cpfNumber.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpfNumber.substring(10, 11))) return false;
  return true;
};

export const validateCNPJ = (cnpj) => {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj == "") return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999"
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
};

export const validateCAEPF = (value) => {
  const caepf = String(value).replace(/[^0-9]*/g, "");
  if (caepf.length !== 14) {
    return false;
  }
  const caepfBase = caepf.slice(0, 12);
  const digitosReverse = [...caepfBase]
    .reverse()
    .reduce((previousValue, currentValue) => {
      previousValue.push(Number(currentValue));
      return previousValue;
    }, []);
  const calcModuleDV = (array, nModuleInit = 9, nModuleMin = 2) => {
    let nModule = 9;
    let result = 0;
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      result += element * nModule;
      nModule > nModuleMin ? (nModule = nModule - 1) : (nModule = nModuleInit);
    }
    result = result % 11;
    if (result === 10) {
      result = 0;
    }
    return result;
  };
  const firstDigitDV = calcModuleDV(digitosReverse);
  const lastDigitDV = calcModuleDV([firstDigitDV].concat(digitosReverse));
  let resultDV = firstDigitDV * 10 + lastDigitDV + 12;
  if (resultDV > 99) {
    resultDV = resultDV - 100;
  }
  let resultDVString = "";
  if (resultDV == 0) {
    resultDVString = "00";
  } else if (resultDV > 0 && resultDV < 10) {
    resultDVString = `0${resultDV}`;
  } else {
    resultDVString = String(resultDV);
  }
  const validCAEPF = `${caepfBase}${resultDVString}`;
  return caepf === validCAEPF ? true : false;
};

export const validateCNO = (value) => {
  const cno = String(value).replace(/[^\d]+/g, "");

  if (cno.length != 12) {
    return false;
  }

  const cnoValues = [...cno].map((v) => Number(v));
  const numericalWeights = [7, 4, 1, 8, 5, 2, 1, 6, 3, 7, 4];
  let sumResult = 0;

  for (let i = 1; i < 12; i++) {
    const fator = numericalWeights[i - 1];
    const valor = cnoValues[i - 1];
    sumResult += fator * valor;
  }

  const sumLength = sumResult.toString().length - 2;

  const tens = sumResult.toString().substring(sumLength);

  const algTens = tens.toString().substring(0, 1);

  const numericalUnit = sumResult - (sumResult / 10) * 10;

  sumResult = parseInt(algTens) + numericalUnit;

  const lastDigitCNO = Number(cno.substring(11));
  const digitFound = 10 - sumResult;

  return lastDigitCNO === digitFound ? true : false;
};

export const removePeriods = (value) => {
  return value.replace(/[.-]/g, "");
};

export const parseResponsavel = (responsaveis) => {
  return responsaveis.map((responsavel) => {
    return {
      cpfResp: responsavel.cpfResp.replace(/\D/g, ""),
      ideOC: responsavel.ideOC,
      nrOC: responsavel.nrOC,
      ufOC: responsavel.ufOC,
      dscOC: responsavel.dscOc,
    };
  });
};

export const formatHarmfulAgents = (agentesNocivos) => {
  let harmfulAgentsFormatted = agentesNocivos.map((agente) => {
    return {
      idFatorDeRisco: agente.id,
      codigoTabela24: agente.valor,
      descricao: agente.nome,
    };
  });

  harmfulAgentsFormatted.push({
    codigoTabela24: "09.01.001",
    idFatorDeRisco: 0,
    descricao:
      "Ausência de agente nocivo ou de atividades previstas no Anexo IV do Decreto 3.048/1999",
  });

  return harmfulAgentsFormatted;
};
