import { Button, Form, Input, Tabs } from "antd";
import * as FileSaver from "file-saver";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SheetJs from "sheetjs-style";
import IconSESI from "../../../../../../../components/IconsSESI";
import {
  changeTags,
  deleteMissingCNAE,
  downloadCnaesExcel,
  getCurrentCNAEs,
  getMissingCNAEs,
  getNotAutomatedCNAEs,
  saveMissingCNAE,
} from "../actions";
import CNAECard from "../components/CNAECard";
import SearchBar from "../components/SearchBar";
import "../styles.scss";

const { TabPane } = Tabs;

const CNAEsAutomatizadosContainer = (props) => {
  const [CNAEsAtuais, setCNAEsAtuais] = useState([]);
  const [CNAESNaoAutomatizados, setCNAESNaoAutomatizados] = useState([]);
  const [CNAESFaltantes, setCNAESFaltantes] = useState([]);
  const [currentTab, setCurrentTab] = useState("1");
  const [showAdd, setShowAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onLoadScreen = async () => {
    let currentCnaes = [];
    let noAutomatedCnaes = [];
    let missingCnaes = [];

    Promise.allSettled([
      (currentCnaes = await getCurrentCNAEs(props.dispatch)),
      (missingCnaes = await getMissingCNAEs(props.dispatch)),
      (noAutomatedCnaes = await getNotAutomatedCNAEs(props.dispatch)),
    ]);

    setCNAEsAtuais(currentCnaes);
    setCNAESNaoAutomatizados(noAutomatedCnaes);
    setCNAESFaltantes(missingCnaes);
  };

  useEffect(() => {
    onLoadScreen();
  }, []);

  const filterList = (event) => {
    const value = event.target.value;
    const cnaes =
      currentTab === "1"
        ? props.CNAEsAutomatizados
        : currentTab === "2"
        ? props.CNAESNaoAutomatizados
        : null;
    if (value.length > 2) {
      let filteredCNAEs = cnaes.filter((cnae) => {
        return (
          cnae.descricao.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
          cnae.id.toLowerCase().indexOf(value.toLowerCase()) !== -1
        );
      });
      if (currentTab === "1") setCNAEsAtuais(filteredCNAEs);
      if (currentTab === "2") setCNAESNaoAutomatizados(filteredCNAEs);
    } else {
      if (currentTab === "1") setCNAEsAtuais(cnaes);
      if (currentTab === "2") setCNAESNaoAutomatizados(cnaes);
    }
  };

  const filterLots = (lots) => {
    const cnaes =
      currentTab === "1"
        ? props.CNAEsAutomatizados
        : currentTab === "2"
        ? props.CNAESNaoAutomatizados
        : null;

    if (lots.length >= 1) {
      let filteredCNAEs = cnaes.filter((cnae) => {
        return lots.includes(cnae.lote);
      });
      if (currentTab === "1") setCNAEsAtuais(filteredCNAEs);
      if (currentTab === "2") setCNAESNaoAutomatizados(filteredCNAEs);
    } else {
      if (currentTab === "1") setCNAEsAtuais(cnaes);
      if (currentTab === "2") setCNAESNaoAutomatizados(cnaes);
    }
  };

  const addMissingCNAE = () => {
    form.validateFields().then((values) => {
      saveMissingCNAE(props.dispatch, values, setShowAdd);
    });
  };

  const getCurrentLots = () => {
    if (props.CNAEsAutomatizados?.length > 0) {
      switch (currentTab) {
        case "1":
          return [
            ...new Set(props.CNAEsAutomatizados.map((cnae) => cnae.lote)),
          ];
      }
    }
  };

  const onChangeTab = (tabKey) => {
    setCurrentTab(tabKey);
  };

  const handleExportToXLS = () => {
    const fileType =
      "application/vdn.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    const fileExtension = ".xlsx";

    const excelData = [];

    let date = new Date().toLocaleDateString();

    CNAESNaoAutomatizados.forEach((cnae) => {
      excelData.push({
        "Código do CNAE": cnae.id,
        "Nome do CNAE": cnae.descricao,
      });
    });

    const tableParsed = SheetJs.utils.json_to_sheet(excelData);
    const tableConfig = { Sheets: { data: tableParsed }, SheetNames: ["data"] };
    const excelBuffer = SheetJs.write(tableConfig, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `${date} – CNAEs não automatizados` + fileExtension);

    if (data) {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <h1>CNAEs</h1>
      <SearchBar
        name="código ou nome"
        filterList={filterList}
        lots={getCurrentLots()}
        filterLots={filterLots}
      />
      <div className="actions">
        <Button ghost className="add" onClick={() => setShowAdd(true)}>
          <IconSESI type="add-circle" theme="filled" />
          Adicionar CNAE Faltante
        </Button>
        {currentTab !== "3" && (
          <div className="export-button">
            <Button
              type="button"
              loading={loading}
              onClick={() => {
                setLoading(true);
                if (currentTab === "1") {
                  downloadCnaesExcel().finally(() => setLoading(false));
                } else {
                  handleExportToXLS();
                  setLoading(false);
                }
              }}
            >
              Exportar para excel
            </Button>
          </div>
        )}
      </div>
      {showAdd ? (
        <Form
          form={form}
          onFinish={addMissingCNAE}
          className="add-cnae-form"
          layout="inline"
        >
          <Form.Item
            label={"Código do CNAE"}
            name="codigo"
            rules={[
              {
                required: true,
                message: "Informe o código do CNAE!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={"Nome do CNAE"}
            name="nome"
            rules={[
              {
                required: true,
                message: "Informe o nome do CNAE!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={"Lote pertencente"}
            name="lote"
            rules={[
              {
                required: true,
                message: "Informe o lote do CNAE!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button block type="primary" text="Salvar" htmlType="submit">
              Salvar
            </Button>
          </Form.Item>
        </Form>
      ) : null}

      <Tabs className="cnaes-tabs" defaultActiveKey="1" onChange={onChangeTab}>
        <TabPane tab="Atuais" key="1">
          <CNAECard
            cnaes={CNAEsAtuais}
            showProcess={true}
            changeTags={changeTags}
            dispatch={props.dispatch}
          />
        </TabPane>
        <TabPane tab="CNAEs não automatizados" key="2">
          <CNAECard
            cnaes={CNAESNaoAutomatizados}
            showProcess={false}
            changeTags={changeTags}
            dispatch={props.dispatch}
            open={"disabled"}
          />
        </TabPane>
        <TabPane tab="Faltantes" key="3">
          <CNAECard
            cnaes={CNAESFaltantes}
            showProcess={false}
            changeTags={changeTags}
            dispatch={props.dispatch}
            open={"disabled"}
            deleteMissingCNAE={deleteMissingCNAE}
            setShowAdd={setShowAdd}
          />
        </TabPane>
      </Tabs>
      <span>{`Total de CNAEs: ${
        currentTab === "1"
          ? CNAEsAtuais.length
          : currentTab === "2"
          ? CNAESNaoAutomatizados.length
          : CNAESFaltantes.length
      }`}</span>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    CNAEsAutomatizados: state.gestorDN.cnaesAutomatizados,
    CNAESNaoAutomatizados: state.gestorDN.cnaesNaoAutomatizados,
    CNAESFaltantes: state.gestorDN.cnaesFaltantes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        getCurrentCNAEs,
        getNotAutomatedCNAEs,
        changeTags,
        saveMissingCNAE,
        deleteMissingCNAE,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CNAEsAutomatizadosContainer);
