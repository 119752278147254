import { Modal } from "antd";

const { confirm } = Modal;

const OnConfirm = (dispatch, remove, id, msg, className) => {
  console.log(dispatch);
  confirm({
    content: msg,
    className,
    centered: true,
    okText: "SIM",
    cancelText: "NÃO",
    onOk: () => {
      remove(dispatch, id);
    },
    onCancel: () => {
      Modal.destroyAll();
    },
  });
};

export const OnConfirmMedico = (callback, msg, className) => {
  confirm({
    content: msg,
    className,
    centered: true,
    okText: "SIM",
    cancelText: "NÃO",
    onOk() {
      callback();
    },
    onCancel() {
      Modal.destroyAll();
    },
  });
};

export const GenericOnConfirm = (msg, onOk, onCancel, className) => {
  confirm({
    content: msg,
    className,
    centered: true,
    okText: "SIM",
    cancelText: "NÃO",
    onOk() {
      onOk();
    },
    onCancel() {
      onCancel();
      Modal.destroyAll();
    },
  });
};

export default OnConfirm;
