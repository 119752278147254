export const assistantTalksNotEditable = () => {
  return {
    riskDescription,
    selfDeclarationRisk,
    acceptNotification,
  };
};
const riskDescription =
  "A partir das suas respostas, tudo indica que sua empresa não precisa de um PGR, pois, de acordo com suas respostas, não identificamos exposição dos trabalhadores a fatores de riscos químico, físico ou biológico.";
const selfDeclarationRisk =
  "Se ainda assim, você quiser elaborar o PGR da sua empresa, basta clicar em 'Ir para o PGR', para fazer o levantamento para identificação e avaliação dos fatores de riscos ergonômicos e mecânicos.";
const acceptNotification =
  "Ao concluir meu PGR, desejo que o SESI entre em contato comigo para me apresentar os próximos passos que precisam ser dados para manter minha empresa atualizada e atender a outros requisitos legais de segurança e saúde no trabalho.";
