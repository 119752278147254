import { ArrowDownOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import VirtualAssistant from "../../../../../../../../components/VirtualAssistant/_/components/VirtualAssistant";
import {
  companySelectionTalks,
  talksAcompanhamento,
  talksAutodeclaracao,
  talksEmpresa,
  talksInventariodeRiscos,
  talksPlanoDeAcao,
  talksProcessos,
  talksRealizadorProcesso,
  talksSetores,
  talksTrabalhadores,
} from "../../../../../../../../constants/aroldoTalks";
import {
  patchActionPlanTalk,
  patchAutoStatementTalk,
  patchMonitoringTalk,
  patchRisksTalk,
} from "../../actions";
import {
  ACOMPANHAMENTO,
  AUTODECLARACAO,
  CARACTERIZACAO,
  INICIO,
} from "../../constants";

const { TextArea } = Input;

const Content = (props) => {
  const [form] = Form.useForm();
  const [currentTalk, setCurrentTalk] = useState({});

  const parseTalks = (talk) => {
    let talkCopy = [...talk.descricao];
    if (
      props.unchangeableParagraphs &&
      talk.descricao.join().indexOf("NÃO EDITÁVEL") === -1
    ) {
      let unchangeableParagraphs = props.unchangeableParagraphs.map((index) =>
        parseInt(index)
      );
      unchangeableParagraphs.map((paragraph) => {
        talkCopy[
          paragraph
        ] = `[NÃO EDITÁVEL]${talkCopy[paragraph]}[NÃO EDITÁVEL]`;
      });
    }
    return form.setFieldsValue({
      changeable: talkCopy.join("\n\n"),
    });
  };

  const getTalkObject = (category, currentTalk) => {
    if (Object.keys(currentTalk).length > 0) {
      let talks;
      switch (category) {
        case INICIO:
          talks = companySelectionTalks(currentTalk.descricao);
          return talks[currentTalk.id];
        case CARACTERIZACAO:
          if (currentTalk.subcategoria === "Empresa")
            talks = talksEmpresa(currentTalk.descricao);
          if (currentTalk.subcategoria === "Setores")
            talks = talksSetores(currentTalk.descricao);
          if (currentTalk.subcategoria === "Trabalhadores")
            talks = talksTrabalhadores(currentTalk.descricao);
          if (currentTalk.subcategoria === "Processos produtivos")
            talks = talksProcessos(currentTalk.descricao);
          if (currentTalk.subcategoria === "Trabalhador x Processo")
            talks = talksRealizadorProcesso(currentTalk.descricao);
          return talks[currentTalk.id];
        case ACOMPANHAMENTO:
          if (currentTalk.subcategoria === "Gerar PGR")
            talks = talksAcompanhamento(
              currentTalk.descricao,
              currentTalk.naoEditavel
            );
          if (currentTalk.subcategoria === "Inventário de riscos")
            talks = talksInventariodeRiscos(
              currentTalk.descricao,
              currentTalk.naoEditavel
            );
          if (currentTalk.subcategoria === "Plano de ação")
            talks = talksPlanoDeAcao(currentTalk.descricao);
          return talks[currentTalk.id];
        case AUTODECLARACAO:
          talks = talksAutodeclaracao(
            currentTalk.descricao,
            currentTalk.naoEditavel
          );
          return talks[currentTalk.id];
      }
    }
  };

  useEffect(() => {
    if (props.currentTalk && Object.keys(props.currentTalk).length) {
      parseTalks(props.currentTalk);
      setCurrentTalk(props.currentTalk);
    }
  }, [JSON.stringify(props.currentTalk)]);

  const handleSubmit = () => {
    const {
      dispatch,
      currentTalk,
      category,
      patchBeginningTalk,
      patchCaracterizationTalk,
    } = props;
    form
      .validateFields()
      .then((values) => {
        switch (category) {
          case INICIO:
            patchBeginningTalk(
              dispatch,
              currentTalk.id,
              values.changeable.split("\n\n")
            );
            break;
          case CARACTERIZACAO:
            patchCaracterizationTalk(
              dispatch,
              currentTalk.id,
              values.changeable.split("\n\n")
            );
            break;
          case ACOMPANHAMENTO:
            let paragraphs = values.changeable.split("\n\n");
            let unchangeableParagraphs = paragraphs.reduce((a, e, i) => {
              if (e.indexOf("[NÃO EDITÁVEL]") !== -1) a.push(i);
              return a;
            }, []);
            let talkWithoutAddedText = values.changeable.replaceAll(
              "[NÃO EDITÁVEL]",
              ""
            );
            if (currentTalk.subcategoria === "Gerar PGR") {
              patchMonitoringTalk(
                dispatch,
                currentTalk.id,
                talkWithoutAddedText.split("\n\n"),
                unchangeableParagraphs
              );
            } else if (currentTalk.subcategoria === "Inventário de riscos") {
              patchRisksTalk(
                dispatch,
                currentTalk.id,
                talkWithoutAddedText.split("\n\n"),
                unchangeableParagraphs
              );
            } else if (currentTalk.subcategoria === "Plano de ação") {
              patchActionPlanTalk(
                dispatch,
                currentTalk.id,
                talkWithoutAddedText.split("\n\n"),
                unchangeableParagraphs
              );
            }
            break;
          case AUTODECLARACAO:
            //these names are terrible but it's too late and i'm too tired to come up with something else or do it more effectively
            let paragraphs2 = values.changeable.split("\n\n");
            let unchangeableParagraphs2 = paragraphs2.reduce((a, e, i) => {
              if (e.indexOf("[NÃO EDITÁVEL]") !== -1) a.push(i);
              return a;
            }, []);
            let talkWithoutAddedText2 = values.changeable.replaceAll(
              "[NÃO EDITÁVEL]",
              ""
            );
            patchAutoStatementTalk(
              dispatch,
              currentTalk.id,
              talkWithoutAddedText2.split("\n\n"),
              unchangeableParagraphs2
            );
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Form form={form} onFinish={handleSubmit} layout="inline">
      <div
        className={`content-wrapper 
        ${
          Object.keys(currentTalk).length > 0
            ? getTalkObject(props.category, currentTalk).viewMode
            : ""
        }`}
      >
        <div className="change-wrapper">
          <div className="button-wrapper">
            <Form.Item name="changeable">
              <TextArea />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" block>
                Salvar
              </Button>
            </Form.Item>
          </div>
          {Object.keys(currentTalk).length > 0 ? (
            getTalkObject(props.category, currentTalk).viewMode !==
            "default" ? (
              <ArrowDownOutlined />
            ) : (
              <ArrowRightOutlined />
            )
          ) : null}
        </div>

        <VirtualAssistant
          perfil={props.perfil}
          talk={
            Object.keys(currentTalk).length > 0
              ? getTalkObject(props.category, currentTalk)
              : {}
          }
          customizacoes={props.customizacoes}
          companySelected={props.companySelected}
          path="caracterizacao"
        />
      </div>
    </Form>
  );
};

export default Content;
