import React, { useEffect } from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import Assistant from "../../components/VirtualAssistant";
import { talksAutodeclaracao } from "../../constants/aroldoTalks";
import { isEmpty } from "../../util/arrayUtil";
import { connect } from "react-redux";
import { onUnauthenticated } from "../../auth/SetupInterceptors";

import "./styles.scss";

const Autodeclaracao = (props) => {
  useEffect(() => {
    if (isEmpty(props.companySelected)) {
      onUnauthenticated();
    }
  });

  return (
    <div className="autodeclaracao-wrapper">
      <Assistant
        talk={
          talksAutodeclaracao(
            props.autodeclaracaoTalks[0].descricao,
            props.autodeclaracaoTalks[0].naoEditavel
          )[props.autodeclaracaoTalks[0].id]
        }
      />
      <div className="inicio-autodeclaracao">
        <Link to="/questionario-autodeclaracao">
          <Button>AVANÇAR</Button>
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    companySelected: state.caracterizacao.companySelected,
    autodeclaracaoTalks: state.gestorDN.virtualAssistant.autoStatement,
  };
};

export default connect(mapStateToProps, {})(Autodeclaracao);
