import {
  LISTAR_SETORES,
  LISTAR_SETORES_ERRO,
  FETCH_SETORES_CATALOGO,
  FETCH_SETOR,
  LISTAR_SETORES_ATUALIZAR_CATALOGO,
  REMOVE_SETOR,
  EDIT_SETOR_CLIENTE
} from "../actions/types";

export const caracterizacaoSetoresReducer = (
  state = {
    setores: [],
    erro: null,
    setoresCatalogo: [],
    setoresIndisponiveis: [],
    setorSelecionado: null
  },
  action
) => {
  switch (action.type) {
    case LISTAR_SETORES:
      return {
        ...state,
        setores: action.payload
      };
    case LISTAR_SETORES_ATUALIZAR_CATALOGO:
      return {
        ...state,
        setores: action.payload.setores,
        setoresIndisponiveis: [
          ...state.setoresIndisponiveis,
          action.payload.novoSetor
        ]
      };
    case LISTAR_SETORES_ERRO:
      return {
        ...state,
        erro: action.erro
      };
    case FETCH_SETORES_CATALOGO:
      return {
        ...state,
        setoresCatalogo: action.payload.disponiveis,
        setoresIndisponiveis: action.payload.indisponiveis
      };
    case FETCH_SETOR:
      return {
        ...state,
        setorSelecionado: action.payload
      };
    case EDIT_SETOR_CLIENTE:
      var setoresIndisponiveis = state.setoresIndisponiveis;
      const setoresWithoutEditedItem = state.setores.filter(
        setor => setor.id !== action.payload.id
      );
      const setorEditado = state.setores.find(
        setor => setor.id === action.payload.id
      );
      const newSetor = action.payload.setor;
      setorEditado.cargos = [...setorEditado.cargos, ...newSetor.cargos];
      setorEditado.cargos = setorEditado.cargos.filter(
        cargo =>
          action.payload.cargosRemovidos.find(
            cargoRemovido => cargoRemovido.descricao === cargo
          ) === undefined
      );
      if (setorEditado.nome !== newSetor.nome) {
        setoresIndisponiveis = setoresIndisponiveis.filter(
          setor => setor !== newSetor.nome
        );
        setoresIndisponiveis = [...setoresIndisponiveis, setorEditado.nome];
      }
      setorEditado.nome = newSetor.nome;
      return {
        ...state,
        setores: [...setoresWithoutEditedItem, setorEditado],
        setoresIndisponiveis
      };
    case REMOVE_SETOR:
      const setor = state.setores.find(setor => setor.id === action.payload);
      return {
        ...state,
        setores: [...state.setores].filter(
          setorExistente => setorExistente.id !== action.payload
        ),
        setoresIndisponiveis: [...state.setoresIndisponiveis].filter(
          setorExistente => {
           return setorExistente !== setor.nome
          }
        )
      };
    default:
      return state;
  }
};
