import { Col, Row, Tabs } from "antd";
import React, { useState, useEffect } from "react";
import IconSESI from "../../components/IconsSESI";
import Button from "../../components/Buttons/_/components/button";
import "./styles.scss";
import ActionForm from "./_/components/ActionForm";
import { Link } from "react-router-dom";
import {
  getPlanoDeAcao,
  fetchAction,
  deleteAction,
  rejectSuggestedAction,
  finishActionPlanForRisk,
} from "./_/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { addAction, editAction } from "./_/actions";
import ActionPlan from "./_/components/ActionPlan";
import Assistant from "../../components/VirtualAssistant";
import { talksPlanoAcao, talksPlanoDeAcao } from "../../constants/aroldoTalks";
import { isEmpty } from "../../util/arrayUtil";
import { onUnauthenticated } from "../../auth/SetupInterceptors";

const PlanoDeAcao = (props) => {
  const [currentTab, setCurrentTab] = useState("list");
  const [acceptParam, setAcceptParam] = useState({
    id: null,
    description: "",
    fromCatalogue: null,
  });
  const [, setLoading] = useState(false);
  const [mode, setMode] = useState("new");
  const [fatorDeRiscoId, setFatorDeRiscoId] = useState(0);
  const [selectedAction, setSelectedAction] = useState({});
  const [talk, setTalk] = useState({});

  const { TabPane } = Tabs;

  const handleSwitchTab = (tab, param = {}, loading, fatorDeRiscoId) => {
    setCurrentTab(tab);
    setAcceptParam(param);
    if (loading) setLoading(loading);
    if (fatorDeRiscoId) setFatorDeRiscoId(fatorDeRiscoId);
  };

  const configEdit = (id) => {
    fetchAction(props.dispatch, id);
    handleSwitchTab("form");
    setMode("edit");
  };

  useEffect(() => {
    if (isEmpty(props.companySelected)) {
      onUnauthenticated();
    }
    getPlanoDeAcao(props.dispatch);
  }, [props.acoesPlanejadas]);

  useEffect(() => {
    if (isEmpty(props.companySelected)) {
      onUnauthenticated();
    }
    setSelectedAction(props.acaoSelecionada);
  }, [props.acaoSelecionada]);

  useEffect(() => {
    if (props.talksPlanoDeAcao) {
      let talks = talksPlanoDeAcao(props.talksPlanoDeAcao[0].descricao);
      setTalk(talks[props.talksPlanoDeAcao[0].id]);
    }
  }, [JSON.stringify(props.talksPlanoDeAcao)]);

  return (
    <Row gutter={32}>
      <Col lg={32} md={24}>
        <Assistant talk={talk} />
        <div className="container plano-de-acao-wrapper">
          <Row gutter={32} type="flex" justify="space-around" align="middle">
            <Col lg={32} md={24}>
              <div className="header-form">
                <Link
                  to={
                    currentTab === "list" ? "/acompanhamento" : "/plano-de-acao"
                  }
                >
                  <Button
                    shape="circle"
                    ghost
                    text={<IconSESI type="arrow-left" />}
                    onClick={() => {
                      setCurrentTab("list");
                      setMode("new");
                    }}
                  />
                </Link>
                <h2>Plano de Ação</h2>
              </div>
            </Col>
          </Row>

          <Row gutter={32} type="flex" justify="space-around" align="middle">
            <Col lg={30} md={24}>
              <div className="content">
                <Tabs activeKey={currentTab}>
                  <TabPane tab="" key="list">
                    <ActionPlan
                      handleSwitchTab={handleSwitchTab}
                      planoDeAcao={props.planoDeAcao.planoDeAcao}
                      acoesPlanejadas={props.planoDeAcao.acoesPlanejadas}
                      configEdit={configEdit}
                      deleteAction={deleteAction}
                      dispatch={props.dispatch}
                      // handleTalk={handleTalk}
                      rejectSuggestedAction={rejectSuggestedAction}
                      finishActionPlanForRisk={finishActionPlanForRisk}
                    />
                    <span>
                      Total de {props.planoDeAcao.planoDeAcao.length}{" "}
                      {props.planoDeAcao.planoDeAcao.length > 1
                        ? "riscos"
                        : "risco"}
                      .
                    </span>
                  </TabPane>

                  <TabPane tab="" key="form">
                    <ActionForm
                      handleSwitchTab={handleSwitchTab}
                      acceptParam={acceptParam}
                      addAction={addAction}
                      mode={mode}
                      fatorDeRiscoId={fatorDeRiscoId}
                      loading={setLoading}
                      dispatch={props.dispatch}
                      selectedAction={selectedAction}
                      editAction={editAction}
                      // handleTalk={handleTalk}
                      responsavelEmpresa={props.responsavelEmpresa}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    planoDeAcao: state.planoDeAcao,
    acoesPlanejadas: state.planoDeAcao.acoesPlanejadas
      ? state.planoDeAcao.acoesPlanejadas
      : [],
    acaoSelecionada: state.planoDeAcao.acaoSelecionada
      ? state.planoDeAcao.acaoSelecionada
      : {},
    responsavelEmpresa: state.caracterizacao.companySelected.nomeResponsavel,
    companySelected: state.caracterizacao.companySelected,
    talksPlanoDeAcao:
      state.gestorDN.virtualAssistant.monitoringTalks.actionPlanTalks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        getPlanoDeAcao,
        fetchAction,
        deleteAction,
        finishActionPlanForRisk,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanoDeAcao);
