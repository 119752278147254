import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Popover,
  Row,
  Select,
  Table,
  Tooltip,
} from "antd";
import localeValues from "antd/lib/locale/pt_BR";
import * as FileSaver from "file-saver";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  FaDownload,
  FaEdit,
  FaFilter,
  FaPlus,
  FaRegTrashAlt,
} from "react-icons/fa";
import SheetJs from "sheetjs-style";
import { openAssistantModal } from "../../../../../components/AssistantModalHeader";
import { HeaderWithModal } from "../../../../../components/HeaderWithModal";
import { eSocialExclusionEventConfirmation } from "../../../../../components/modals";
import {
  consultEsocialEvent,
  createAndSendS3000,
  generateEsocialXml,
  getAssistantTalks,
  listar2210,
  listar2220,
  listar2240,
  sendESocialEvent,
} from "../../actions/index";
import { openCertificate_A3_Modal } from "../../certificate";
import { disableEventAction, formatEventType } from "../../constants";

const { RangePicker } = DatePicker;
const { Option } = Select;

const FollowEvents = (props) => {
  const {
    setActiveTab,
    activeTab,
    setFormMode,
    duplicarEvento,
    funcionariosEsocial,
    certificadosCadastrados,
  } = props;
  const [assistantTalk, setAssistantTalk] = useState([]);
  const [tableData, setTableData] = useState();
  const [popoverSelected, setPopoverSelected] = useState("");
  const [filters, setFilters] = useState({
    eventType: "",
    employee: "",
    status: "",
    date: [],
  });
  const [filteredData, setFilteredData] = useState();

  const getDigitalSignatureAssistantTalks = async () => {
    const talks = await getAssistantTalks(
      "acompanhar-evento",
      "Acompanhar Eventos"
    );
    setAssistantTalk(talks.descricao);
  };

  const onLoadScreen = async () => {
    let s2210Events = [];
    let s2240Events = [];
    let s2220Events = [];
    let formattedEvents = [];

    Promise.allSettled([
      (s2210Events = await listar2210(props.dispatch)),
      (s2240Events = await listar2240(props.dispatch)),
      (s2220Events = await listar2220(props.dispatch)),
    ]);

    let allEvents = [s2210Events, s2220Events, s2240Events].flat();

    allEvents.forEach((event) => {
      let date = event.dataEnvio
        ? moment(event.dataEnvio).format("DD/MM/YYYY")
        : "-";

      formattedEvents.push({
        id: event.id,
        key: event.idEvento,
        eventType: event.tipoEvento,
        dateASO: date,
        sendDate: moment(event.dataEnvio),
        status: event.enviado,
        signature: event.assinado,
        worker: event.nomeTrab,
        workerCPF: event.cpfTrab,
        actions: event,
        statusProgresso: event,
        protocolo: event.protocolo,
        receiptNumber: event.nrRecibo || "-",
        esocialError: event.orientacao || "-",
      });
    });

    if (
      !filters.date ||
      filters.date.length !== 0 ||
      filters.employee !== "" ||
      filters.eventType !== "" ||
      filters.status !== ""
    ) {
      filterTable(undefined, undefined, formattedEvents);
    } else {
      setFilteredData(formattedEvents);
    }

    setTableData(formattedEvents);
  };

  useEffect(() => {
    onLoadScreen();
    getDigitalSignatureAssistantTalks();
  }, [activeTab]);

  //Filtros
  const filterTable = (type, value, allEvents) => {
    let currentFilters = { ...filters };
    let filteredResult = allEvents ? [...allEvents] : [...tableData];

    if (type || value) currentFilters[type] = value;

    if (currentFilters.eventType) {
      filteredResult = filteredResult.filter(
        (item) => item.eventType === currentFilters.eventType
      );
    }

    if (currentFilters.employee) {
      filteredResult = filteredResult.filter(
        (item) => item.workerCPF === currentFilters.employee
      );
    }

    if (currentFilters.status) {
      filteredResult = filteredResult.filter(
        (item) => item.statusProgresso.statusProgresso === currentFilters.status
      );
    }

    if (currentFilters.date && currentFilters.date.length === 2) {
      filteredResult = filteredResult.filter(
        (item) =>
          item.sendDate >= moment(currentFilters.date[0]) &&
          item.sendDate <= moment(currentFilters.date[1])
      );
    }

    setFilteredData(filteredResult);
    setFilters(currentFilters);
  };

  const disabledDate = (current) => {
    return current.valueOf() > Date.now();
  };

  //Enviar evento
  const sendEvent = async (eventType, id) => {
    if (certificadosCadastrados.length === 0) {
      openCertificate_A3_Modal(eventType, id, onLoadScreen);
    } else {
      let formData = new FormData();

      formData.append("tipoDoEvento", formatEventType(eventType));
      formData.append("idEvento", id);

      await sendESocialEvent(formatEventType(eventType), formData).then(
        (response) => {
          if (response) onLoadScreen();
        }
      );
    }
  };

  //Consultar evento
  const handleEventQuery = async (eventType, protocol) => {
    let formData = new FormData();

    formData.append("tipoDoEvento", formatEventType(eventType));
    formData.append("protocolo", protocol);

    await consultEsocialEvent(formatEventType(eventType), formData);

    onLoadScreen();
  };

  //Baixar XML
  const handleDownloadXml = async (eventType, id) => {
    const path = await generateEsocialXml(formatEventType(eventType), id);
    let url = `${process.env.REACT_APP_BACKEND_URL}${path}`;

    var downloadFile = document.createElement("a");

    downloadFile.href = url;
    downloadFile.click();
  };

  //Editar e retificar
  const manipulateEvent = async (event, formMode) => {
    let generateNewEvent = { ...event, enviado: false, nrRecibo: "" };
    setFormMode(formMode);

    switch (event.tipoEvento) {
      case "S-2210":
        duplicarEvento(formMode === "rectification" ? event : generateNewEvent);
        setPopoverSelected(null);
        setActiveTab("6");
        break;
      case "S-2240":
        duplicarEvento(formMode === "rectification" ? event : generateNewEvent);
        setPopoverSelected(null);
        setActiveTab("7");
        break;
      case "S-2220":
        duplicarEvento(formMode === "rectification" ? event : generateNewEvent);
        setPopoverSelected(null);
        setActiveTab("3");
        break;
    }
    onLoadScreen();
  };

  //Evento de exclusão
  const handleDelete = async (event) => {
    let newS3000 = {
      nrInsc: event.nrInsc,
      tpEvento: event.tipoEvento,
      nrRecEvt: event.nrRecibo,
      cpfTrab: event.cpfTrab,
      indApuracao: "MENSAL",
      perApur: "2023-02",
    };

    await createAndSendS3000(newS3000).then(() => {
      onLoadScreen();
    });
  };

  const handleExportToXLS = () => {
    const fileType =
      "application/vdn.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    const fileExtension = ".xlsx";

    const excelData = [];

    let date = new Date().toLocaleDateString();

    tableData.forEach((event) => {
      excelData.push({
        "TIPO DE EVENTO": event.eventType,
        FUNCIONÁRIO: event.worker,
        "DATA DE ENVIO": event.dateASO,
        "STATUS DO EVENTO":
          event.statusProgresso === "INCONSISTENCIA"
            ? "ERRO ESOCIAL"
            : event.statusProgresso,
        "NÚMERO DO RECIBO": event.receiptNumber,
        "ERRO ESOCIAL": event.esocialError,
      });
    });

    const tableParsed = SheetJs.utils.json_to_sheet(excelData);
    const tableConfig = { Sheets: { data: tableParsed }, SheetNames: ["data"] };
    const excelBuffer = SheetJs.write(tableConfig, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      `${date} – Acompanhamento de Eventos eSocial` + fileExtension
    );
  };

  const columns = [
    {
      title: "TIPO DO EVENTO",
      dataIndex: "eventType",
      key: "eventType",
      align: "center",
    },
    {
      title: "FUNCIONÁRIO",
      dataIndex: "worker",
      key: "worker",
      align: "center",
    },
    {
      title: "DATA DO ENVIO",
      dataIndex: "dateASO",
      key: "dateASO",
      align: "center",
    },
    {
      title: "NÚMERO DO RECIBO",
      dataIndex: "receiptNumber",
      key: "receiptNumber",
      align: "center",
    },
    {
      title: "STATUS DO EVENTO",
      dataIndex: "statusProgresso",
      key: "statusProgresso",
      align: "center",
      render: (event) => {
        const formatStatus = {
          AGUARDANDO_ENVIO: "Aguardando envio",
          AGUARDANDO_PROCESSAMENTO: "Aguardando processamento",
          RECEBIDO: "Recebido",
          ENVIADO: "Enviado",
          INCONSISTENCIA: "Erro eSocial!",
          RETIFICADO: "Retificado",
          EXCLUIDO: "Excluído",
        };

        return event.statusProgresso === "INCONSISTENCIA" ? (
          <Tooltip title={<p>{event.orientacao}</p>}>
            <span>{formatStatus[event.statusProgresso]}</span>
          </Tooltip>
        ) : (
          <span>{formatStatus[event.statusProgresso]}</span>
        );
      },
    },
    {
      title: "AÇÕES",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (event) => {
        return (
          <React.Fragment>
            {event.statusProgresso === "AGUARDANDO_ENVIO" ||
            event.statusProgresso === "INCONSISTENCIA" ? (
              <Button
                onClick={() => sendEvent(event.tipoEvento, event.id)}
                className="send-event-button"
              >
                Enviar
              </Button>
            ) : event.statusProgresso === "AGUARDANDO_PROCESSAMENTO" ? (
              <Button
                onClick={() =>
                  handleEventQuery(event.tipoEvento, event.protocolo)
                }
                className="send-button"
              >
                Processar
              </Button>
            ) : (
              <Button
                onClick={() => {
                  manipulateEvent(event, "rectification");
                }}
                disabled={disableEventAction(
                  "rectification",
                  event.statusProgresso
                )}
              >
                Retificar
              </Button>
            )}
            <Button
              className="download-button"
              onClick={() => handleDownloadXml(event.tipoEvento, event.id)}
            >
              <FaDownload />
            </Button>
            <Popover
              placement="bottomRight"
              visible={popoverSelected === `${event.tipoEvento}-${event.id}`}
              onVisibleChange={(visible) => {
                setPopoverSelected(
                  visible ? `${event.tipoEvento}-${event.id}` : ""
                );
              }}
              content={
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 14 }}
                >
                  {/* editar */}
                  <Button
                    style={{
                      display: "flex",
                      gap: 4,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      manipulateEvent(event, "rectification");
                    }}
                    disabled={disableEventAction("edit", event.statusProgresso)}
                  >
                    <FaEdit /> Editar
                  </Button>
                  {/* gerar novo */}
                  <Button
                    style={{
                      display: "flex",
                      gap: 4,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      manipulateEvent(event, "increment");
                    }}
                  >
                    <FaPlus /> Gerar novo
                  </Button>
                  {/* Evento de exclusão */}
                  <Button
                    style={{
                      display: "flex",
                      gap: 4,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      eSocialExclusionEventConfirmation(() =>
                        handleDelete(event)
                      );
                    }}
                    disabled={disableEventAction(
                      "exclusionEvent",
                      event.statusProgresso
                    )}
                  >
                    <FaRegTrashAlt />
                    Enviar evento de exclusão
                  </Button>
                </div>
              }
              trigger="click"
            >
              <Button>
                <FaPlus />
              </Button>
            </Popover>
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Col lg={24} className="esocial-dashboard">
        <HeaderWithModal
          pageTitle="Acompanhe seus eventos"
          helpText="Clique aqui e saiba mais!"
          assistant={"vivi"}
          openModal={() => openAssistantModal(assistantTalk, "vivi")}
          prevButton={() => {
            props.setActiveTab("1");
            setFilters({
              eventType: "",
              employee: "",
              status: "",
              date: [],
            });
          }}
        />

        <div className="content">
          <Row className="filter-row">
            <FaFilter />
            <h3>Filtros</h3>
            <Select
              placeholder="Filtrar evento"
              allowClear
              value={
                filters.eventType !== "" ? filters.eventType : "Filtrar evento"
              }
              onSelect={(value) => filterTable("eventType", value, undefined)}
              onClear={() => filterTable("eventType", "", undefined)}
            >
              <Option value="S-2210">S-2210</Option>
              <Option value="S-2220">S-2220</Option>
              <Option value="S-2240">S-2240</Option>
            </Select>
            <Select
              placeholder="Filtrar por funcionário"
              allowClear
              value={
                filters.employee !== ""
                  ? filters.employee
                  : "Filtrar por funcionário"
              }
              onSelect={(value) => filterTable("employee", value, undefined)}
              onClear={() => filterTable("employee", "", undefined)}
            >
              {funcionariosEsocial.map((funcionario) => {
                return (
                  <Option key={funcionario.id} value={funcionario.cpf}>
                    {funcionario.nome}
                  </Option>
                );
              })}
            </Select>
            <Select
              placeholder="Filtrar por status"
              allowClear
              value={
                filters.status !== "" ? filters.status : "Filtrar por status"
              }
              onSelect={(value) => filterTable("status", value, undefined)}
              onClear={() => filterTable("status", "", undefined)}
            >
              <Option value="AGUARDANDO_ENVIO">Aguardando envio</Option>
              <Option value="AGUARDANDO_PROCESSAMENTO">
                Aguardando processamento
              </Option>
              <Option value="RECEBIDO">Recebido</Option>
              <Option value="RETIFICADO">Retificado</Option>
              <Option value="EXCLUIDO">Excluído</Option>
              <Option value="INCONSISTENCIA">Erro eSocial</Option>
            </Select>
            <ConfigProvider locale={localeValues}>
              <RangePicker
                disabledDate={disabledDate}
                format="DD/MM/YYYY"
                suffixIcon={false}
                bordered={false}
                value={filters.date?.length !== 2 ? [] : filters.date}
                onChange={(value) => filterTable("date", value, undefined)}
                allowEmpty={true}
                onClear={() => {
                  filterTable("date", [], undefined);
                }}
              />
            </ConfigProvider>
          </Row>
          <div className="export-button">
            <Button type="button" onClick={handleExportToXLS}>
              Exportar para excel
            </Button>
          </div>
          <div className="esocial-table-container">
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-dark" : "table-row-martinique"
              }
              dataSource={filteredData}
              columns={columns}
              pagination={true}
              bordered
              locale={{ emptyText: "Nenhum registro encontrado." }}
            />
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default FollowEvents;
