import React, { Component } from "react";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import CompanyForm from "../components/CompanyForm";
import { stepsTalks } from "../../../../../../../constants/aroldoTalks";
import Button from "../../../../../../../components/Buttons/_/components/button";
import { Link } from "react-router-dom";
import IconSESI from "../../../../../../../components/IconsSESI";
import { saveEmpresa } from "../actions";
import { isEmpty } from "../../../../../../../util/arrayUtil";
import { onUnauthenticated } from "../../../../../../../auth/SetupInterceptors";
class CaracterizacaoEmpresa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companySelected: {},
      viewOnly: this.props.stepsEmpresa.EMPRESA,
      // talk: this.props.stepsEmpresa.EMPRESA
      //   ? stepsTalks.talksFinishedStep[1]
      //   : stepsTalks.talksEmpresa[1],
    };
  }

  componentDidMount() {
    if (isEmpty(this.props.companySelected)) {
      onUnauthenticated();
    }
    !this.props.stepsEmpresa.EMPRESA
      ? this.props.onChangeTalk("talk1", 0)
      : this.props.onChangeTalk("talk2", 0);
  }

  componentDidUpdate(prevProps) {
    const { stepsEmpresa } = this.props;
    if (stepsEmpresa.EMPRESA !== prevProps.stepsEmpresa.EMPRESA) {
      this.setState({
        viewOnly: this.props.stepsEmpresa.EMPRESA,
        completed: this.props.stepsEmpresa.EMPRESA,
      });
      !this.props.stepsEmpresa.EMPRESA
        ? this.props.onChangeTalk("talk1", 0)
        : this.props.onChangeTalk("talk2", 0);
    }
  }

  render() {
    return (
      <div className="caracterizacao-empresa-wrapper">
        <Row gutter={24}>
          <Col lg={24} md={24}>
            <div className="caracterizacao-empresa">
              <Row
                gutter={16}
                type="flex"
                justify="space-around"
                align="middle"
              >
                <Col span={18}>
                  <div className="header-form">
                    <Link to="/selecaoempresa">
                      <Button
                        shape="circle"
                        ghost
                        text={<IconSESI type="arrow-left" />}
                      />
                    </Link>
                    <h2>Empresa</h2>
                  </div>
                </Col>
                <Col span={6}>
                  <Button
                    onClick={() => this.props.nextStep(this.props.current + 1)}
                    type="primary"
                    text="PRÓXIMO PASSO"
                    className="next-step"
                    block
                  />
                </Col>
              </Row>
              <div className="content">
                {!isEmpty(this.props.companySelected) && (
                  <CompanyForm
                    companySelected={this.props.companySelected}
                    saveEmpresa={this.props.saveEmpresa}
                    completed={this.props.stepsEmpresa.EMPRESA}
                    perfil={this.props.perfil}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const customizacoes = state.user.userInfo
    ? state.user.userInfo.customizacoes
    : {};
  return {
    page: state.buttons.pageStepsEmpresa,
    perfil: state.user.userInfo.perfil,
    companySelected: state.caracterizacao.companySelected,
    customizacoes,
    stepsEmpresa: state.caracterizacao.stepsEmpresa.stepsEmpresa,
  };
};

export default connect(mapStateToProps, {
  saveEmpresa,
})(CaracterizacaoEmpresa);
