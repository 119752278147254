import { Button, Checkbox, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  postEPI,
  getEPIFromList,
  editEPI,
  emptyEPISelecionado,
} from "../../actions";
import "../../../styles.scss";

const CadastroEPIForm = (props) => {
  const [form] = Form.useForm();
  const [mode, setMode] = useState("new");
  const [epiSelecionado, setEpiSelecionado] = useState({});
  const [active, setActive] = useState(false);
  const [epiDefault, setEpiDefault] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (epiSelecionado) setMode("edit");
    if (props.epiSelecionado) setEpiSelecionado(props.epiSelecionado);
    setEdit(props.epiSelecionado);
  }, [JSON.stringify(props.epiSelecionado)]);

  const setEdit = (epiSelecionado) => {
    if (mode == "edit" || props.epiSelecionado) {
      form.setFieldsValue({
        epiCode: epiSelecionado.id,
        epiName: epiSelecionado.nome,
        epiActive: epiSelecionado.ativo,
        epiDefault: epiSelecionado.padrao,
      });
      setActive(epiSelecionado.ativo);
      setEpiDefault(epiSelecionado.padrao);
    }
  };

  const handleEdit = () => {
    setLoading(true);
    form.validateFields().then((values) => {
      let newEPI = {
        id: values.epiCode,
        nome: values.epiName,
        ativo: active,
        padrao: epiDefault,
      };
      editEPI(values.epiCode, newEPI, props.dispatch, setLoading);
      form.resetFields();
    });
  };

  const handleNew = () => {
    setLoading(true);
    form.validateFields().then((values) => {
      let newEPI = {
        id: values.epiCode,
        nome: values.epiName,
        ativo: active,
        padrao: epiDefault,
      };
      postEPI(newEPI, props.dispatch, setLoading);
      form.resetFields();
    });
  };

  const checkboxOnChange = (e) => {
    if (e.target.id === "epiActive") {
      setActive(e.target.checked);
    } else {
      setEpiDefault(e.target.checked);
    }
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={mode === "new" ? () => handleNew() : () => handleEdit()}
      >
        <Form.Item label="Código do EPI" name="epiCode">
          <Input type="number" />
        </Form.Item>
        <Form.Item label="EPI" name="epiName">
          <Input />
        </Form.Item>
        <Form.Item label="Ativo" name="epiActive">
          <Checkbox checked={active} onChange={checkboxOnChange} />
        </Form.Item>
        <Form.Item label="Padrão para químicos" name="epiDefault">
          <Checkbox checked={epiDefault} onChange={checkboxOnChange} />
        </Form.Item>

        <div className="epi-button-div">
          {!epiSelecionado.nome ? (
            <Button loading={loading} onClick={() => handleNew()}>
              Salvar
            </Button>
          ) : (
            ""
          )}
          {epiSelecionado.nome ? (
            <React.Fragment>
              <Button loading={loading} onClick={() => handleEdit()}>
                Salvar
              </Button>
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    epiSelecionado: state.gestorDN.epiSelecionado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...dispatch,
    ...bindActionCreators({
      getEPIFromList,
      emptyEPISelecionado,
    }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CadastroEPIForm);
