import { LISTAR_EMPRESAS_COM_PGR_EMITIDO } from "../actions/types";

export const empresasComPGREmitidoReducer = (
  state = {
    empresasComPGREmitido: [],
  },
  action
) => {
  switch (action.type) {
    case LISTAR_EMPRESAS_COM_PGR_EMITIDO:
      return {
        ...state,
        empresasComPGREmitido: action.payload,
      };
    default:
      return state;
  }
};
