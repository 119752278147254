import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SideBar from "../SideBar";
import Content from "../Content";
import { bindActionCreators } from "redux";
import { patchBeginningTalk } from "../../actions";
import { INICIO } from "../../constants";

const InicioAssistenteVirtual = (props) => {
  const [currentTalk, setCurrentTalk] = useState({});

  useEffect(() => {
    setCurrentTalk(props.talksInicio[0]);
  }, []);

  const changeTalk = (id) => {
    setCurrentTalk(props.talksInicio[id - 1]);
  };

  return (
    <div className="admin-assistente-virtual-wrapper">
      <div className="admin-assistente-virtual-lateral">
        <SideBar
          content={props.talksInicio}
          changeTalk={changeTalk}
          category={INICIO}
        />
      </div>
      <div className="admin-assistente-virtual-falas">
        <Content
          currentTalk={currentTalk}
          dispatch={props.dispatch}
          patchBeginningTalk={patchBeginningTalk}
          category={INICIO}
          changeTalk={changeTalk}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    talksInicio: state.gestorDN.virtualAssistant.beginningTalks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ patchBeginningTalk }, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InicioAssistenteVirtual);
