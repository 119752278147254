import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import promise from "redux-promise";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native
import * as serviceWorker from "./serviceWorker";
import reducers from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import Routes from "./components/Routes";
import Zendesk from "./zendexConfig"
const zendeskKey = process.env.REACT_APP_ZENDESK_KEY

const persistConfig = {
  key: "rootPersist",
  storage,
  blacklist: []
};

// const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
const createStoreWithMiddleware = applyMiddleware(thunk, promise)(createStore);
const persistedReducer = persistReducer(persistConfig, reducers);
export const store = createStoreWithMiddleware(
  persistedReducer,
  composeWithDevTools()
);

const persistor = persistStore(store);

const Root = () => <Routes />;

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Zendesk defer zendeskKey={zendeskKey} />
      <Root />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.register();
