import axios from "axios";
import {
  switchClienteUrl,
  switchTecnicoUrl,
  switchGetStepsCliente,
  switchStatusFluxo,
} from "../../../../constants/index";
import showModal from "../../../../components/modals";
import {
  TOGGLE_STEP_STATUS_CLIENTE,
  TOGGLE_STEP_STATUS_TECNICO,
  GET_STATUS_STEPS_TECNICO,
  TOGGLE_STATUS_FLUXO,
} from "./types";

export const toggleStepStatusCliente = (etapa, accept) => {
  let accepted;
  if (accept) {
    accepted = 1;
  } else {
    accepted = 0;
  }
  return (dispatch) => {
    axios
      .patch(switchClienteUrl(etapa, accepted))
      .then((response) => {
        dispatch({
          type: TOGGLE_STEP_STATUS_CLIENTE,
          payload: response.data.etapasConcluidas,
        });
      })
      .catch((error) => {
        showModal(error.response.data.message);
      });
  };
};

export const toggleStepStatusTecnico = (etapa, accept) => {
  if (accept) {
    accept = 0;
  } else {
    accept = 1;
  }
  let etapaUrl;
  if (etapa === "produtosQuimicos") {
    etapaUrl = "produtos-quimicos";
  } else if (etapa === "fatoresDeRisco") {
    etapaUrl = "fatores-de-risco";
  } else if (etapa === "espacoFisico") {
    etapaUrl = "espaco-fisico";
  } else if (etapa === "planoDeAcao") {
    etapaUrl = "plano-de-acao";
  } else {
    etapaUrl = etapa;
  }
  return (dispatch) => {
    axios
      .patch(`${switchTecnicoUrl}/${etapaUrl}/${accept}`)
      .then(() => {
        dispatch({
          type: TOGGLE_STEP_STATUS_TECNICO,
          payload: { etapa: etapa, concluida: accept },
        });
      })
      .catch((error) => {
        showModal(error.response.data.message);
      });
  };
};

export const getStepStatusTecnico = () => {
  return (dispatch) => {
    axios.get(switchTecnicoUrl).then((response) => {
      dispatch({
        type: GET_STATUS_STEPS_TECNICO,
        payload: response.data,
      });
    });
  };
};

export const getStepsCliente = () => {
  return (dispatch) => {
    axios.get(switchGetStepsCliente).then((response) => {
      dispatch({
        type: TOGGLE_STEP_STATUS_CLIENTE,
        payload: response.data.etapasConcluidas,
      });
    });
  };
};

export const toggleStatusFluxo = (statusFluxo) => {
  return (dispatch) => {
    axios
      .patch(switchStatusFluxo, statusFluxo)
      .then(() => {
        dispatch({
          type: TOGGLE_STATUS_FLUXO,
          payload: { statusFluxo },
        });
      })
      .catch((error) => console.log(error));
  };
};
