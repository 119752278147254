import React from "react";
import "./styles.scss";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

const Sidebar = ({ activeItem, setMenuItems }) => {
  const handleChangeActiveSubItem = (item) => {
    setMenuItems((oldValue) =>
      oldValue.map((old) => ({
        ...old,
        active:
          old.active && old.options.some((option) => option.id === item.id),
        options: old.options.map((option) => ({
          ...option,
          active: option.id === item.id,
          subOptions: option.subOptions
            ? option.subOptions.map((subOption) => ({
                ...subOption,
                active: subOption.id === 1,
              }))
            : [],
        })),
      }))
    );
  };

  const handleChangeSubOption = (item) => {
    setMenuItems((oldValue) =>
      oldValue.map((old) => ({
        ...old,
        active: old.active,
        options: old.options.map((option) => ({
          ...option,
          active: option.active,
          subOptions: option.subOptions.map((subOption) => ({
            ...subOption,
            active: subOption.id === item.id,
          })),
        })),
      }))
    );
  };

  return (
    <div className="sidebar-menu-items">
      <h3>Telas</h3>
      {activeItem.options &&
        activeItem.options.map((item) => (
          <div
            className={
              item.active ? "active sidebar-item" : "default sidebar-item"
            }
            key={item.id}
          >
            <div
              className="item-box"
              onClick={() => handleChangeActiveSubItem(item)}
            >
              <p>{item.name}</p>

              {item.subOptions && item.subOptions.length !== 0 && (
                <>{item.active ? <CaretUpOutlined /> : <CaretDownOutlined />}</>
              )}
            </div>

            {item.active && item.subOptions && item.subOptions.length !== 0 && (
              <div className="sub-options">
                {item.subOptions.map((option) => {
                  return (
                    <p
                      className={option.active ? "active" : "default"}
                      key={option.id}
                      onClick={() => handleChangeSubOption(option)}
                    >
                      {option.name}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default Sidebar;
