import { Button, Col, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { openAssistantModal } from "../../../../components/AssistantModalHeader";
import { HeaderWithModal } from "../../../../components/HeaderWithModal/index";
import { getAssistantTalks } from "../actions/index";


const { Option } = Select;

const ESocialEventSelector = (props) => {
  const [eventTab, setEventTab] = useState();
  const [disableButtons, setDisableButtons] = useState(true);
  const [assistantTalk, setAssistantTalk] = useState([]);

  useEffect(() => {
    getDigitalSignatureAssistantTalks()
  }, [])

  const redirectToEvent = (event) => {
    switch (event) {
      case "S-2220":
        setEventTab("3");
        break;
      case "S-2240":
        setEventTab("7");
        break;
      case "S-2210":
        setEventTab("6");
        break;
      default:
        setEventTab("2");
    }
  };

  const getDigitalSignatureAssistantTalks = async () => {
    const talks = await getAssistantTalks('acompanhar-evento', 'Gerar Eventos')
    setAssistantTalk(talks.descricao)
  }

  return (
    <React.Fragment>
      <HeaderWithModal
        pageTitle="Selecione o tipo de evento"
        helpText={"Clique aqui e saiba mais!"}
        assistant={"vivi"}
        openModal={() =>
          openAssistantModal(assistantTalk, "vivi")
        }
        prevButton={() => props.setActiveTab("1")}
      />
      <Col lg={24} className="esocial-dashboard">
        <div className="companyContent">
          <Form>
            <div className="section">
              <Form.Item
                label="Evento::"
                tooltip={
                  "Escolha agora qual evento deseja gerar e clique no botão PRÓXIMO PASSO"
                }
              >
                <Select
                  showArrow={false}
                  allowClear
                  onSelect={(value) => {
                    redirectToEvent(value);
                    setDisableButtons(false);
                  }}
                  onClear={() => {
                    setEventTab("2");
                    setDisableButtons(true);
                  }}
                >
                  <Option key="S-2210" value="S-2210">
                    S-2210 - Comunicação de Acidente de trabalho
                  </Option>
                  <Option key="S-2220" value="S-2220">
                    S-2220 - Monitoramento da Saúde do Trabalhador
                  </Option>
                  <Option key="S-2240" value="S-2240">
                    S-2240 - Condições Ambientais do Trabalho - Agentes Nocivos
                  </Option>
                </Select>
              </Form.Item>
            </div>
            <div className="event-buttons">
              <Button
                onClick={() => {
                  props.setActiveTab(eventTab);
                }}
                disabled={disableButtons}
              >
                PRÓXIMO PASSO
              </Button>
            </div>
          </Form>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default ESocialEventSelector;
