import axios from 'axios';
import { store } from '../index';
import { LOGOUT } from '../shared/LayoutApp/_/actions/types';
import openUniqueNotification from '../components/uniqueNotification';
import { history } from "../components/Routes";
 
export const setup401HandlerInterceptor = () => {
  axios.interceptors.response.use(response => response,
  error => {
    let isAuthenticated = !store.getState().user.hasOwnProperty("authenticated") ? false : store.getState().user.authenticated
    let onClose = () => {
      localStorage.removeItem("persist:rootPersist");
      store.dispatch({
        type: LOGOUT
      });
      history.push('/');
    }
    if(error.response && error.response.status === 401 && isAuthenticated) {
      openUniqueNotification(
        "Sua sessão expirou!",
        "Clique aqui para realizar o login novamente",
        "warn",
        1,
        0,
        onClose,
        onClose
      );  
    }
    return Promise.reject(error);
  }
)};