import React, { useEffect, useState } from "react";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { showRemoveSuggestedProcessModal } from "../../../../components/modals";

const SuggestedAction = (props) => {
  const [action] = useState(props.planoDeAcao);
  const [sugestedActionState, setSuggestedActionState] = useState([]);
  // const [acceptedActions, setAcceptedActions] = useState(
  //   props.planoDeAcao.acoesPlanejadas
  // );

  useEffect(() => {
    setSuggestedActionState(removeAcceptedAction());
  }, [JSON.stringify(props.planoDeAcao.acoesPlanejadas)]);

  useEffect(() => {
    const acoesAtuais = props.planoDeAcao.acoesPlanejadas.map(
      (acaoPlanejada) => acaoPlanejada.descricao
    );

    setSuggestedActionState(
      props.planoDeAcao.acoesSugeridas.filter(
        (acaoSugerida) => !acoesAtuais.includes(acaoSugerida.descricao)
      )
    );
  }, [JSON.stringify(props.planoDeAcao.acoesSugeridas)]);

  const showModal = (id) => {
    showRemoveSuggestedProcessModal(() => removeSuggestedAction(id));
  };

  const removeSuggestedAction = (id) => {
    setSuggestedActionState(
      sugestedActionState.filter((suggestedAction) => suggestedAction.id !== id)
    );
    props.rejectSuggestedAction(props.dispatch, id);
  };

  const removeAcceptedAction = () => {
    const acoesPlanejadas = action.acoesPlanejadas.map(
      (acao) => acao.descricao
    );
    const acoesSugeridas = action.acoesSugeridas;

    return acoesSugeridas.filter(
      (action) => !acoesPlanejadas.includes(action.descricao)
    );
  };

  // const acceptSuggestedAction = (id) => {
  //   setAcceptedActions(
  //     ...sugestedActionState.filter(
  //       (suggestedAction) => suggestedAction.id === id
  //     )
  //   );
  // };

  return action.acoesSugeridas.length > 0 ? (
    <div className="suggested-actions">
      <h3>Ações sugeridas</h3>
      {sugestedActionState.map((suggestedAction) => (
        <div className="action-list">
          <div className="action-item">
            <div className="action-name">
              <div className="suggested-action-description action-description">
                <h3>{suggestedAction.descricao}</h3>
              </div>

              <div className="suggested-action-buttons">
                <Button
                  className="accept-button"
                  type="accept"
                  onClick={() => {
                    props.handleSwitchTab(
                      "form",
                      {
                        id: suggestedAction.id,
                        description: suggestedAction.descricao,
                      },
                      null,
                      suggestedAction.idFatorDeRisco
                    );
                    props.handleTalk(5);
                  }}
                >
                  <CheckOutlined />
                  <span>Aceitar</span>
                </Button>
                <Button
                  className="reject-button"
                  type="danger"
                  onClick={() => showModal(suggestedAction.id)}
                >
                  <CloseOutlined />
                  <span>Recusar</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  ) : (
    ""
  );
};

export default SuggestedAction;
