import React, { Component, useState } from "react";
import { Card } from "antd";
import { Link } from "react-router-dom";
import IconSESI from "../../../../../../../components/IconsSESI";

const EmitirSMDCard = (props) => {
  return (
    <Link to={"/relatorio-smd"}>
      <Card bordered={false} className="smallest-card">
        <div className="header">
          <div>
            <h3>Emitir SMD</h3>
          </div>
          <IconSESI type="transfer-right" />
        </div>
      </Card>
    </Link>
  );
};

export default EmitirSMDCard;
