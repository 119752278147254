import { Col, Row, Button, Modal } from "antd";

import React, { useState, useEffect } from "react";
import IconSESI from "../../../../components/IconsSESI";

import Assistant from "../../../../components/VirtualAssistant";
import { getAssistantTalks } from "../actions";

import viviSorrindoDireita from "../../../../constants/images/vivi-sorrindo-direita.svg";

const baseUrl = process.env.REACT_APP_BASE_URL;

const ESocialBanner = (props) => {
  const [assistantTalk, setAssistantTalk] = useState({});

  const getDigitalSignatureAssistantTalks = async () => {
    const talks = await getAssistantTalks("Nao premium", "Nao premium");

    setAssistantTalk({
      viewMode: "horizontal",
      assistant: viviSorrindoDireita,
      buttons: null,
      text: talks.descricao,
    });
  };

  useEffect(() => {
    getDigitalSignatureAssistantTalks();
  }, []);

  return (
    <div className="esocial-banner-wrapper">
      <Row gutter={24}>
        <Col span={24}>
          {assistantTalk.text &&
            <Assistant talk={assistantTalk} />
          }
        </Col>
      </Row>

      <div className="action">
        <Row className="become-premium-parent-row">
          <div>
            <div className="become-premim-div">
              <Row gutter={24}>
                <Col span={4} className="premium-icon-col">
                  <IconSESI type="premium" fill={"var(--alto)"} />
                </Col>
                <Col span={10} className="premium-text-col">
                  Conheça todas as vantagens de ser um membro SESI Facilita
                  Premium, clicando no botão ao lado
                </Col>
                <Col span={10} className="premium-button-col">
                  <a href={`${baseUrl}/web/loja/conversao`} target={"_blank"}>
                    <Button>SEJA PREMIUM</Button>
                  </a>
                </Col>
              </Row>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     dispatch,
//     ...bindActionCreators({
//       listQuestions,
//     }),
//   };
// };

export default ESocialBanner;
