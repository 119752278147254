import axios from "axios";
import {
  LISTAR_FUNCIONARIOS,
  LISTAR_FUNCIONARIOS_ERRO,
  CRIAR_FUNCIONARIO,
  CRIAR_FUNCIONARIO_ERRO,
  ATIVAR_DESATIVAR_FUNCIONARIO,
  EDITAR_FUNCIONARIO,
  OBTER_FUNCIONARIO,
  LISTAR_CARGOS,
} from "./types";
import { funcionariosUrl, cargosUrl } from "../../../../../../../constants";
import { setupInterceptors } from "../../../../../../../auth/SetupInterceptors";
import openNotification from "../../../../../../../components/notifications";
import { erroMessage } from "../../../../../../../constants/errorMessage";
import {
  CPF_JA_CADASTRADO,
  NUMERO_MAXIMO,
  CPF_INVALIDO,
} from "../../../../../../../constants/errorMessagesTypes";
setupInterceptors();

const tipoEnum = Object.freeze({
  1: "TRABALHADOR",
  2: "MENOR_APRENDIZ",
  3: "ESTAGIARIO",
  4: "OUTRO",
});

export const fetchFuncionarios = (dispatch) => {
  axios
    .get(funcionariosUrl)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch({
        type: LISTAR_FUNCIONARIOS,
        payload: res.data,
      });
    })
    .catch((erro) => {
      dispatch({
        type: LISTAR_FUNCIONARIOS_ERRO,
        payload: erro,
      });
    });
};

export const fetchCargos = (dispatch) => {
  axios.get(cargosUrl).then((res) => {
    if (res.error) {
      throw res.error;
    }
    dispatch({
      type: LISTAR_CARGOS,
      payload: res.data,
    });
  });
};

export const postFuncionario = (
  dispatch,
  values,
  switchTab,
  resetFields,
  loading
) => {
  loading(true);
  axios
    .post(`${funcionariosUrl}`, retrieveFuncionarioData(values, "new"))
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: CRIAR_FUNCIONARIO,
          payload: response.data,
        });
        loading(false);
        switchTab();
        resetFields();
        openNotification("Trabalhador adicionado com sucesso!", "", "success");
        if (response.data.podeSerAlterado === 0) {
          openNotification(
            "CPF cadastrado como colaborador do SESI.",
            "Os dados do trabalhador foram salvos, porém o nome não pode ser alterado",
            "warning"
          );
        }
      }
    })
    .catch((erro) => {
      loading(false);
      if (erro.response) {
        let errorMessage = erro.response.data.message;
        if (errorMessage.indexOf(CPF_JA_CADASTRADO) !== -1) {
          openNotification(erroMessage(CPF_JA_CADASTRADO), "", "error");
        } else if (errorMessage.indexOf(NUMERO_MAXIMO) !== -1) {
          openNotification(erroMessage(NUMERO_MAXIMO), "", "error");
        } else if (errorMessage.indexOf(CPF_INVALIDO) !== -1) {
          openNotification(erroMessage(CPF_INVALIDO), "", "error");
        }
      } else {
        openNotification(
          "Erro ao adicionar trabalhador!",
          "Tente novamente",
          "error"
        );
      }
      console.log(erro);
      dispatch({
        type: CRIAR_FUNCIONARIO_ERRO,
        payload: erro,
      });
    });
};

export const editarFuncionario = (
  dispatch,
  values,
  id,
  switchTab,
  resetFields,
  loading
) => {
  loading(true);
  axios
    .patch(
      `${funcionariosUrl}/${id}`,
      retrieveFuncionarioData(values, "edit"),
      {
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
      }
    )
    .then((res) => {
      console.log(res);
      values.tipo = tipoEnum[values.tipo];
      dispatch({
        type: EDITAR_FUNCIONARIO,
        payload: values,
      });
      axios
        .get(funcionariosUrl)
        .then((res) => {
          dispatch({
            type: LISTAR_FUNCIONARIOS,
            payload: res.data,
          });
          loading(false);
          switchTab();
          resetFields();
          openNotification(
            "Trabalhador editado com sucesso!",
            "Salvo",
            "success"
          );
        })
        .catch((erro) => {
          dispatch({
            type: LISTAR_FUNCIONARIOS_ERRO,
            payload: erro,
          });
        });
    })
    .catch((erro) => {
      loading(false);
      if (erro.response) {
        if (erro.response.data.status === 500) {
          let errorMessage = erro.response.data.message;
          if (errorMessage.indexOf(CPF_JA_CADASTRADO) !== -1) {
            openNotification(erroMessage(CPF_JA_CADASTRADO), "", "error");
          } else if (errorMessage.indexOf(NUMERO_MAXIMO) !== -1) {
            openNotification(erroMessage(NUMERO_MAXIMO), "", "error");
          } else {
            openNotification(erroMessage(errorMessage), "", "error");
          }
        } else {
          openNotification(
            "Erro ao editar trabalhador! ",
            "Tente novamente",
            "error"
          );
        }
      }
    });
};

export const obterFuncionario = (id) => {
  return (dispatch) => {
    axios.get(`${funcionariosUrl}/${id}`).then((res) => {
      dispatch({
        type: OBTER_FUNCIONARIO,
        payload: res.data,
      });
    });
  };
};

export const ativarDesativarFuncionario = (funcionario, int, situacao) => {
  if (funcionario.ativo === 0) {
    funcionario.ativo = 1;
  } else {
    funcionario.ativo = 0;
  }
  return (dispatch) => {
    axios
      .patch(`${funcionariosUrl}/${funcionario.id}/${int}/${situacao}`)
      .then(() => {
        dispatch({
          type: ATIVAR_DESATIVAR_FUNCIONARIO,
          payload: funcionario,
        });
      })
      .catch((erro) => {
        if (erro.response) {
          if (erro.response.data.status === 500) {
            let errorMessage = erro.response.data.message;
            if (errorMessage.indexOf(CPF_JA_CADASTRADO) !== -1) {
              openNotification(erroMessage(CPF_JA_CADASTRADO), "", "error");
            } else if (errorMessage.indexOf(NUMERO_MAXIMO) !== -1) {
              openNotification(erroMessage(NUMERO_MAXIMO), "", "error");
            } else {
              openNotification(erroMessage(errorMessage), "", "error");
            }
          } else {
            openNotification(
              "Erro ao editar trabalhador!",
              "Tente novamente",
              "error"
            );
          }
        }
      });
  };
};

function retrieveFuncionarioData(values, mode) {
  const funcionarioTipo = {
    TRABALHADOR: 1,
    MENOR_APRENDIZ: 2,
    ESTAGIARIO: 3,
    OUTRO: 4,
  };
  const funcionario = {
    nome: values.nome,
    cpf: values.cpf.replace(/-|\./g, ""),
    rg: values.rg,
    orgaoEmissor: values.orgaoEmissor,
    sexo: values.sexo,
    tipo: funcionarioTipo[values.tipo],
    tipoEspecifico: values.tipoEspecifico,
    situacao: 1,
    nascimento: values.nascimento.split("/").reverse().join("-"),
    idCargo: values.tipo !== 4 ? values.idCargo : "",
    ativo: 1,
    dataDeAdmissao: values.admissao
      ? values.admissao.split("/").reverse().join("-")
      : null,
    dataDeDesligamento: "",
    matricula: values.matricula,
  };
  if (mode === "edit" && values.situacao !== "1") {
    console.log(values);
    funcionario.ativo = 0;
    funcionario.situacao = parseInt(values.situacao);
    funcionario.dataDeDesligamento = values.desligamento
      .split("/")
      .reverse()
      .join("-");
  }
  return funcionario;
}
