import {
  SIGNIN_FAILURE,
  AUTH_USER,
  USER_COMPANIES,
  LOGOUT,
} from "../actions/types";

export default function (state = { drSelecionada: null }, action) {
  switch (action.type) {
    case SIGNIN_FAILURE:
      return {
        ...state,
        authenticated: false,
      };
    case AUTH_USER:
      return {
        ...state,
        authenticated: true,
        userInfo: action.payload,
      };
    case USER_COMPANIES:
      return {
        ...state,
        companies: action.payload.map((company) => company),
      };
    case LOGOUT:
      return {
        ...state,
        authenticated: false,
        userInfo: null,
        companies: null,
      };
    default:
      return state;
  }
}
