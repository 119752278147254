import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Assistant from "../../../../components/VirtualAssistant";
import { itensTalk } from "../../../../constants/aroldoTalks";
import { fetchListaDeInformacoes, gerarPDF } from "./../actions";
import ItensLista from "../components/ItensLista";
import { Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";

const Itens = (props) => {
  const [listaDeInformacoes, setListaDeInformacoes] = useState({});

  useEffect(() => {
    fetchListaDeInformacoes(props.dispatch);
  }, []);

  useEffect(() => {
    setListaDeInformacoes(props.listaDeInformacoes);
  }, [JSON.stringify(props.listaDeInformacoes)]);

  return props.companySelected.cnpj ? (
    <React.Fragment>
      <div>
        <Assistant talk={itensTalk} />
      </div>
      <div className="lista-informacoes">
        <ItensLista
          gerarPDF={gerarPDF}
          listaDeInformacoes={listaDeInformacoes}
          dispatch={props.dispatch}
        />
      </div>
    </React.Fragment>
  ) : (
    <Redirect to="/" />
  );
};

const mapStateToProps = (state) => {
  return {
    listaDeInformacoes: state.listaDeInformacoes
      ? state.listaDeInformacoes
      : { listaDeInformacoesDisponiveis: [], itensMarcadosDaEmpresa: [] },
    customizacoes: state.user.userInfo
      ? state.user.userInfo.customizacoes
      : { listaDeInformacoesDisponiveis: [], itensMarcadosDaEmpresa: [] },
    companySelected: state.caracterizacao.companySelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        fetchListaDeInformacoes,
        gerarPDF,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Itens);
