export const UfList = [
    {id: 1, value: "AC", name: "Acre"},
    {id: 2, value: "AL", name: "Alagoas"},
    {id: 3, value: "AP", name: "Amapá"},
    {id: 4, value: "AM", name: "Amazonas"},
    {id: 5, value: "BA", name: "Bahia"},
    {id: 6, value: "CE", name: "Ceará"},
    {id: 7, value: "DF", name: "Distrito Federal"},
    {id: 8, value: "ES", name: "Espírito Santo"},
    {id: 9, value: "GO", name: "Goiás"},
    {id: 10, value: "MA", name: "Maranhão"},
    {id: 11, value: "MT", name: "Mato Grosso"},
    {id: 12, value: "MS", name: "Mato Grosso do Sul"},
    {id: 13, value: "MG", name: "Minas Gerais"},
    {id: 14, value: "PA", name: "Pará"},
    {id: 15, value: "PB", name: "Paraíba"},
    {id: 16, value: "PR", name: "Paraná"},
    {id: 17, value: "PE", name: "Pernambuco"},
    {id: 18, value: "PI", name: "Piauí"},
    {id: 19, value: "RJ", name: "Rio de Janeiro"},
    {id: 20, value: "RN", name: "Rio Grande do Norte"},
    {id: 21, value: "RS", name: "Rio Grande do Sul"},
    {id: 22, value: "RO", name: "Rondônia"},
    {id: 23, value: "RR", name: "Roraima"},
    {id: 24, value: "SC", name: "Santa Catarina"},
    {id: 25, value: "SP", name: "São Paulo"},
    {id: 26, value: "SE", name: "Sergipe"},
    {id: 27, value: "TO", name: "Tocantins"},
]