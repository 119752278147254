import React from "react";

import TalksPreview from "../components/Container/Content/TalksPreview";

export const itensWithRowStyle = [
  "Empresa",
  "Listar",
  "Adicionar",
  "Trabalhador x processo",
  "Pergunta 1",
  "Pergunta 2",
  "Pergunta 3",
  "Pergunta 4",
  "Pergunta 5",
  "Selecionar Empresa",
  "Selecionar Evento",
  "S-2210",
  "S-2220",
  "S-2240",
  "Acompanhar Evento",
  "Não Premium",
  "Assinatura Digital",
];

const talksPreview = (phrase) => <TalksPreview phrase={phrase} />;

export const menuItemsData = [
  {
    id: 1,
    name: "Início",
    active: true,
    options: [
      {
        id: 1,
        reqId: 1,
        name: "Início",
        key: "Início",
        active: true,
        assistant: talksPreview,
      },
      {
        id: 2,
        reqId: 2,
        name: "Selecionar Empresa",
        key: "Seleção empresa",
        active: false,
        assistant: talksPreview,
      },
    ],
  },
  {
    id: 2,
    name: "Categorização",
    active: false,
    options: [
      {
        id: 1,
        reqId: 1,
        name: "Empresa",
        active: false,
        key: "Empresa",
        assistant: talksPreview,
        isVertical: true,
      },
      {
        id: 2,
        name: "Setores",
        active: false,
        key: "Setores",
        subOptions: [
          {
            id: 1,
            reqId: 2,
            name: "Listar",
            key: "listar",
            active: false,
            assistant: talksPreview,
            isVertical: true,
          },
          {
            id: 2,
            reqId: 3,
            key: "criar",
            name: "Adicionar",
            active: false,
            assistant: talksPreview,
            isVertical: true,
          },
        ],
      },
      {
        id: 3,
        name: "Trabalhadores",
        active: false,
        key: "Trabalhadores",
        subOptions: [
          {
            id: 1,
            reqId: 4,
            name: "Listar",
            key: "listar",
            active: false,
            assistant: talksPreview,
            isVertical: true,
          },
          {
            id: 2,
            reqId: 5,
            name: "Adicionar",
            key: "criar",
            active: false,
            assistant: talksPreview,
            isVertical: true,
          },
        ],
      },
      {
        id: 4,
        name: "Processos Produtivos",
        key: "Processos produtivos",
        active: false,
        subOptions: [
          {
            id: 1,
            reqId: 6,
            name: "Listar",
            key: "listar",
            active: false,
            assistant: talksPreview,
            isVertical: true,
          },
          {
            id: 2,
            reqId: 7,
            name: "Adicionar",
            key: "criar",
            active: false,
            assistant: talksPreview,
            isVertical: true,
          },
        ],
      },
      {
        id: 5,
        reqId: 8,
        name: "Trabalhador x processo",
        key: "Trabalhador x Processo",
        active: false,
        assistant: talksPreview,
        isVertical: true,
      },
    ],
  },
  {
    id: 3,
    name: "Acompanhamento",
    active: false,
    options: [
      {
        id: 1,
        reqId: 1,
        name: "Acompanhamento",
        key: "Gerar PGR",
        active: false,
        assistant: talksPreview,
      },
      {
        id: 2,
        name: "Inventário de riscos",
        key: "Inventário de riscos",
        active: false,
        subOptions: [
          {
            id: 1,
            reqId: 1,
            name: "Listagem processos produtivos",
            key: "Lista de processos",
            active: false,
            assistant: talksPreview,
          },
          {
            id: 2,
            reqId: 2,
            name: "Listagem de perigos de um processo",
            key: "Lista de riscos do processo",
            active: false,
            assistant: talksPreview,
          },
        ],
      },
      {
        id: 3,
        reqId: 1,
        key: "Plano de ação",
        name: "Plano de Ação",
        active: false,
        assistant: talksPreview,
      },
    ],
  },
  {
    id: 4,
    name: "Autodeclaracão",
    active: false,
    options: [
      {
        id: 1,
        reqId: 1,
        name: "Início",
        key: "Início",
        active: false,
        assistant: talksPreview,
      },
      {
        id: 2,
        name: "Perguntas",
        key: "Perguntas",
        active: false,
        subOptions: [
          {
            id: 1,
            reqId: 2,
            name: "Pergunta 1",
            key: "pergunta1",
            active: false,
            assistant: talksPreview,
            isVertical: true,
          },
          {
            id: 2,
            reqId: 3,
            name: "Pergunta 2",
            key: "pergunta2",
            active: false,
            assistant: talksPreview,
            isVertical: true,
          },
          {
            id: 3,
            reqId: 4,
            name: "Pergunta 3",
            key: "pergunta3",
            active: false,
            assistant: talksPreview,
            isVertical: true,
          },
          {
            id: 4,
            reqId: 5,
            name: "Pergunta 4",
            key: "pergunta4",
            active: false,
            assistant: talksPreview,
            isVertical: true,
          },
          {
            id: 5,
            reqId: 6,
            name: "Pergunta 5",
            key: "pergunta5",
            active: false,
            assistant: talksPreview,
            isVertical: true,
          },
        ],
      },
      {
        id: 3,
        reqId: 7,
        name: "Gerar Autodeclaração",
        key: "Gerar autodeclaração",
        active: false,
        assistant: talksPreview,
      },
    ],
  },
  {
    id: 5,
    name: "eSocial",
    active: false,
    options: [
      {
        id: 1,
        reqId: 1,
        key: "Início",
        name: "Selecionar Empresa",
        active: false,
        assistant: talksPreview,
        isVertical: true,
      },
      {
        id: 2,
        reqId: 2,
        key: "Gerar Eventos",
        name: "Selecionar Evento",
        active: false,
        assistant: talksPreview,
        isVertical: true,
      },
      {
        id: 3,
        reqId: 4,
        key: "Gerar 2210",
        name: "S-2210",
        active: false,
        assistant: talksPreview,
        isVertical: true,
      },
      {
        id: 4,
        reqId: 3,
        key: "Gerar 2220",
        name: "S-2220",
        active: false,
        assistant: talksPreview,
        isVertical: true,
      },
      {
        id: 5,
        reqId: 5,
        key: "Gerar 2240",
        name: "S-2240",
        active: false,
        assistant: talksPreview,
        isVertical: true,
      },
      {
        id: 6,
        reqId: 6,
        key: "Acompanhar Eventos",
        name: "Acompanhar Evento",
        active: false,
        assistant: talksPreview,
        isVertical: true,
      },
      {
        id: 7,
        reqId: 7,
        name: "Não Premium",
        key: "Nao premium",
        active: false,
        assistant: talksPreview,
        isVertical: true,
      },
    ],
  },
  {
    id: 6,
    name: "Assinatura Digital",
    active: false,
    options: [
      {
        id: 1,
        reqId: 1,
        name: "Assinatura Digital",
        key: "Início",
        active: false,
        assistant: talksPreview,
        isVertical: true,
      },
    ],
  },
];
