import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import IconSESI from "../../../../components/IconsSESI";
import Assistant from "../../../../components/VirtualAssistant";
import {
  AUTODECLARAOFINALRISKS,
  CAPTURALEAD,
  RISKSDESCRIPTION,
} from "../../../../constants/aroldoTalks";
import LogoUpload from "../../../Caracterizacao/_/components/CaracterizacaoEmpresa/_/components/LogoUpload";
import {
  captureLead,
  finishAutodeclaracao,
  generateAutodeclaracao,
  listQuestions,
} from "../actions";
import { assistantTalksNotEditable } from "../constants/index";

const baseUrl = process.env.REACT_APP_BASE_URL;

const GerarAutodeclaracao = (props) => {
  const [link, setLink] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [logo, setLogo] = useState(null);
  const [deleteLogo, setDeleteLogo] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const premiumCompany = props.companySelected.premium;

  const { riskDescription, selfDeclarationRisk, acceptNotification } =
    assistantTalksNotEditable();

  const getLink = (url) => {
    setLink(url.data.url);
    setIsDisabled(false);
  };

  const checkLead = async () => {
    const isLeadChecked = document.getElementById("lead-checkbox");
    if (isLeadChecked && isLeadChecked.checked) {
      await captureLead("declaracao");
    }
  };

  const getLogoFormData = (logo) => {
    if (logo.name) {
      let formData = new FormData();
      formData.append("logo", logo);
      setLogo(formData);
    } else {
      setLogo(null);
    }
  };

  const getDeletedLogoFormData = () => {
    let formData = new FormData();
    formData.append("removerLogo", 1);
    setDeleteLogo(formData);
  };

  const logoLoadingToggle = (bool) => {
    setLogoLoading(bool);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  const [talk, setTalk] = useState();

  useEffect(() => {
    if (props.assistantTalks) {
      const newTalk = props.assistantTalks[6];
      const currentTalk = {
        assistant: "/static/media/vivi-sorrindo-direita.080265ba.svg",
        buttons: null,
        viewMode: "horizontal",
        text: [],
      };

      const staticTalks = {
        [riskDescription]: RISKSDESCRIPTION(false),
        [selfDeclarationRisk]: AUTODECLARAOFINALRISKS(),
        [acceptNotification]: CAPTURALEAD(),
      };

      newTalk.descricao.forEach((talk, index) => {
        if (newTalk.naoEditavel.includes(index.toString())) {
          currentTalk.text.push(staticTalks[talk]);
        } else {
          currentTalk.text.push(talk);
        }
      });

      setTalk(currentTalk);
    }
  }, []);

  return (
    <div className="gerar-autodeclaracao-wrapper">
      <Row gutter={24}>
        <Col span={24}>
          <Assistant talk={talk} />
        </Col>
      </Row>
      <Row gutter={24} className="logo-upload-wrapper">
        <Col span={24}>
          <LogoUpload
            getLogoFormData={getLogoFormData}
            getDeletedLogoFormData={getDeletedLogoFormData}
            perfil={props.perfil}
            logoLoading={logoLoading}
          />
        </Col>
      </Row>
      <Row gutter={24} className="buttons-wrapper">
        <Col span={12} className="generate-selfdeclaration-wrapper">
          <ButtonGroup>
            <div className="generate-selfdeclaration-button">
              <Button
                onClick={() => {
                  checkLead();
                  generateAutodeclaracao(
                    getLink,
                    setLoading,
                    logo,
                    logoLoadingToggle,
                    deleteLogo
                  );
                  finishAutodeclaracao();
                }}
                loading={loading}
              >
                <h3>GERAR AUTODECLARAÇÃO</h3>
              </Button>
            </div>
            <div className="download-button">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${process.env.REACT_APP_BACKEND_URL}${link}`}
                download={true}
              >
                <Button
                  disabled={isDisabled}
                  loading={loading}
                  onClick={() => (!premiumCompany ? showModal() : "")}
                >
                  <DownloadOutlined />
                </Button>
              </a>
            </div>
          </ButtonGroup>
        </Col>
        <Col span={12}>
          <Link>
            <div className="go-to-pgr-button">
              <Link to={"/caracterizacao"}>
                <Button>
                  <h3>IR PARA O PGR</h3>
                </Button>
              </Link>
            </div>
          </Link>
        </Col>
      </Row>
      {/* <Row style={{ marginTop: "250px" }}>
        <Col span={24}>
          <h3 style={{ textAlign: "center" }}>
            {props.companySelected.premium ? (
              <bold>
                Que tal emitir o eSocial a partir da sua autodeclaração?{" "}
                <a href="#" style={{ textDecoration: "underline" }}>
                  Clique aqui
                </a>{" "}
                para começar.
              </bold>
            ) : (
            <bold>
              Aproveite para assinar a versão premium e enviar o eSocial de sua
              auto declaração.{" "}
              <a
                href={`${baseUrl}/web/loja/conversao`}
                style={{ textDecoration: "underline" }}
              >
                Clique aqui
              </a>{" "}
              para garantir sua assinatura.
            </bold>
            )}
          </h3>
        </Col>
      </Row> */}
      {!premiumCompany ? (
        <Row className="become-premium-parent-row">
          <div>
            <div className="become-premim-div">
              <Row gutter={24}>
                <Col span={4} className="premium-icon-col">
                  <IconSESI type="premium" fill={"var(--alto)"} />
                </Col>
                <Col span={10} className="premium-text-col">
                  Conheça todas as vantagens de ser um membro SESI Facilita
                  Premium, clicando no botão ao lado
                </Col>
                <Col span={10} className="premium-button-col">
                  <a href={`${baseUrl}/web/loja/conversao`} target={"_blank"}>
                    <Button>SEJA PREMIUM</Button>
                  </a>
                  <Modal
                    visible={isModalOpen}
                    onOk={hideModal}
                    onCancel={hideModal}
                    className="premium-modal hideAssistent"
                    width={600}
                    footer={[
                      <Row
                        gutter={16}
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Col span={8}>
                          <Button
                            ghost
                            onClick={() => hideModal()}
                            className="premium-cancel-btn"
                          >
                            NÃO, OBRIGADO
                          </Button>
                        </Col>
                        <Col span={8}>
                          <Button
                            className="premium-confirm-btn"
                            onClick={() =>
                              window.open(
                                `${baseUrl}/web/loja/conversao`,
                                "_blank"
                              )
                            }
                          >
                            QUERO ASSINAR
                          </Button>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <Row gutter={24} className="premium-modal-info-row">
                      <Col span={6}>
                        <IconSESI type="missed" fill="#F2C94C" />
                      </Col>
                      <Col span={18} className="premium-modal-text-col">
                        Com o SESI Facilita Premium, você está a 1 clique de
                        distância para enviar a Ausência de Riscos de seus
                        trabalhadores para o eSocial e estar de acordo com a
                        legislação. <br />
                        Seja um usuário premium e envie eventos a qualquer
                        momento, por 12 meses, sem custo adicional.
                        <br />
                        <p
                          style={{
                            margin: "1rem 0 -0.5rem 0",
                            opacity: "0.5",
                          }}
                        >
                          <s>R$ 79,90 / ano</s>
                        </p>
                        <h2>Gratuito*</h2>
                        <h6>* Promoção de lançamento, por tempo limitado.</h6>
                      </Col>
                    </Row>
                  </Modal>
                </Col>
              </Row>
            </div>
          </div>
        </Row>
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    companySelected: state.caracterizacao.companySelected,
    assistantTalks: state.gestorDN.virtualAssistant.autoStatement,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({
      listQuestions,
    }),
  };
};

export default connect(mapStateToProps, {})(GerarAutodeclaracao);
