import React, { useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import "./styles.scss";
import {
  getVirtualAssistantAutostatementTalks,
  getVirtualAssistantBeginningTalks,
  getVirtualAssistantCategorizationTalks,
  getVirtualAssistantEsocialTalks,
  getTalksMonitoring,
  getVirtualAssistantDigitalSignatureTalks,
  patchBeginningTalk,
  patchCaracterizationTalk,
  patchMonitoringTalk,
  patchRisksTalk,
  patchActionPlanTalk,
  patchAutoStatementTalk,
  patchVirtualAssistantEsocialTalk,
  patchVirtualAssistantDigitalSignatureTalk,
} from "../../../actions";
import { ArrowDownOutlined } from "@ant-design/icons";
import { itensWithRowStyle } from "../../../constants";

const { TextArea } = Input;

const Content = ({ activeItem }) => {
  const [form] = Form.useForm();

  const [currentTalk, setCurrentTalk] = useState();
  const [selectedItem, setSelectedItem] = useState();

  useEffect(() => {
    verifyActiveItem();
  }, [activeItem]);

  const verifyActiveItem = async () => {
    if (!activeItem) {
      return;
    }

    const responseTalk = await parseTalks("get");

    const optionActive = activeItem.options.find((option) => option.active);
    const subOptionActive = optionActive?.subOptions?.find(
      (sub) => sub.active === true
    );

    setSelectedItem(subOptionActive ?? optionActive);

    let currentTalk;

    if (subOptionActive === undefined) {
      currentTalk = responseTalk.find(
        (talk) => talk.subcategoria === optionActive.key
      );
    } else {
      currentTalk = responseTalk.find(
        (talk) =>
          talk.subcategoria === optionActive.key &&
          talk.tipo === subOptionActive.key
      );
    }

    if (!currentTalk) return;

    const description = currentTalk.descricao;

    if (currentTalk.naoEditavel) {
      let talksFormatted = description.map((phrase, index) => {
        if (currentTalk.naoEditavel.includes(String(index))) {
          return `[NÃO EDITÁVEL]${phrase}[NÃO EDITÁVEL]`;
        } else {
          return phrase;
        }
      });

      form.setFieldsValue({
        textArea: talksFormatted.join("\n\n"),
      });
    } else {
      form.setFieldsValue({
        textArea: description.join("\n\n"),
      });
    }

    setCurrentTalk(description);
  };

  const parseTalks = async (method, requestId, textAreaValue, notEditable) => {
    let currentResponse = [];
    switch (activeItem.name) {
      case "Início":
        if (method === "get") {
          currentResponse = await getVirtualAssistantBeginningTalks();
        } else {
          await patchBeginningTalk(requestId, textAreaValue);
        }
        break;

      case "Categorização":
        if (method === "get") {
          currentResponse = await getVirtualAssistantCategorizationTalks();
        } else {
          await patchCaracterizationTalk(requestId, textAreaValue);
        }
        break;

      case "Acompanhamento":
        if (method === "get") {
          currentResponse = await getTalksMonitoring();
        } else {
          switch (selectedItem.key) {
            case "Gerar PGR":
              await patchMonitoringTalk(requestId, textAreaValue, notEditable);
              break;
            case "Lista de processos" || "Lista de riscos do processo":
              await patchRisksTalk(requestId, textAreaValue, notEditable);
              break;
            case "Plano de ação":
              await patchActionPlanTalk(requestId, textAreaValue, notEditable);
              break;
          }
        }
        break;

      case "Autodeclaracão":
        if (method === "get") {
          currentResponse = await getVirtualAssistantAutostatementTalks();
        } else {
          await patchAutoStatementTalk(requestId, textAreaValue, notEditable);
        }
        break;

      case "eSocial":
        if (method === "get") {
          currentResponse = await getVirtualAssistantEsocialTalks();
        } else {
          await patchVirtualAssistantEsocialTalk(
            requestId,
            textAreaValue,
            notEditable
          );
        }
        break;

      case "Assinatura Digital":
        if (method === "get") {
          currentResponse = await getVirtualAssistantDigitalSignatureTalks();
        } else {
          await patchVirtualAssistantDigitalSignatureTalk(
            requestId,
            textAreaValue,
            notEditable
          );
        }
        break;
    }

    if (method === "get") {
      return currentResponse;
    }
  };

  const handleSubmit = async (values) => {
    const indexListNotEditable = [];
    const textAreaCleaned = [];
    let textAreaArray = values.textArea.split("\n\n");

    textAreaArray.forEach((value, index) => {
      if (value.includes("[NÃO EDITÁVEL]")) {
        indexListNotEditable.push(index.toString());
        textAreaCleaned.push(value.replaceAll("[NÃO EDITÁVEL]", ""));
      } else {
        textAreaCleaned.push(value);
      }
    });

    await parseTalks(
      "patch",
      selectedItem.reqId,
      textAreaCleaned,
      indexListNotEditable
    );

    verifyActiveItem();
  };

  return (
    <div className="virtual-assistant-content">
      <div className="edit-text-area">
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item name="textArea">
            <TextArea value={currentTalk} />
          </Form.Item>
          <Button style={{ width: "100%" }} htmlType="submit">
            Salvar
          </Button>
        </Form>
      </div>

      <ArrowDownOutlined />
      {selectedItem && selectedItem.assistant && (
        <div
          className="text-preview"
          style={
            itensWithRowStyle.includes(selectedItem.name)
              ? { width: "50%" }
              : { width: "100%" }
          }
        >
          {selectedItem.assistant(currentTalk)}
        </div>
      )}
    </div>
  );
};

export default Content;
