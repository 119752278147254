import { LISTAR_EMPRESAS_ATIVAS } from "../actions/types";

export const empresasAtivasReducer = (
  state = {
    empresasAtivas: [],
  },
  action
) => {
  switch (action.type) {
    case LISTAR_EMPRESAS_ATIVAS:
      return {
        ...state,
        empresasAtivas: action.payload,
      };
    default:
      return state;
  }
};
