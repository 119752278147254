import Axios from "axios";
import {
  virtualAssistantEsocial,
  virtualAssistantBeginning,
  virtualAssistantCategorization,
  virtualAssistantRisks,
  virtualAssistantMonitoring,
  virtualAssistantAutostatement,
  virtualAssistantActionPlans,
  virtualAssistantDigitalSignature,
  virtualAssistantBeginningPatch,
  virtualAssistantCategorizationPatch,
  virtualAssistantRisksPatch,
  virtualAssistantMonitoringPatch,
  virtualAssistantActionPlansPatch,
  virtualAssistantAutostatementPatch,
  virtualAssistantEsocialPatch,
  virtualAssistantDigitalSignaturePatch,
} from "../../../../../../../constants";
import openNotification from "../../../../../../../components/notifications";

export const getTalksMonitoring = async () => {
  const risksTalks = await getVirtualAssistantRisksTalks();
  const actionPlanTalks = await getVirtualAssistantActionPlansTalks();
  const monitoringTalks = await getVirtualAssistantMonitoringTalks();

  const allMonitoringData = [].concat(
    risksTalks,
    actionPlanTalks,
    monitoringTalks
  );
  return allMonitoringData;
};

export const getVirtualAssistantBeginningTalks = async () => {
  return await Axios.get(virtualAssistantBeginning)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
};

export const getVirtualAssistantActionPlansTalks = async () => {
  return await Axios.get(virtualAssistantActionPlans)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
};

export const getVirtualAssistantCategorizationTalks = async () => {
  return await Axios.get(virtualAssistantCategorization)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
};

export const getVirtualAssistantRisksTalks = async () => {
  return await Axios.get(virtualAssistantRisks)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
};

export const getVirtualAssistantMonitoringTalks = async () => {
  return await Axios.get(virtualAssistantMonitoring)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
};

export const getVirtualAssistantAutostatementTalks = async () => {
  return await Axios.get(virtualAssistantAutostatement)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
};

export const getVirtualAssistantEsocialTalks = async () => {
  return await Axios.get(virtualAssistantEsocial)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
};

export const getVirtualAssistantDigitalSignatureTalks = async () => {
  return await Axios.get(virtualAssistantDigitalSignature)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
};

export const patchBeginningTalk = async (id, descricao) => {
  await Axios.patch(virtualAssistantBeginningPatch(id), {
    descricao: descricao,
  })
    .then((response) => {
      openNotification("Fala modificada com sucesso!", "", "success");
    })
    .catch((err) => {
      openNotification("Erro ao modificar a fala!", "Tente novamente", "error");
    });
};

export const patchCaracterizationTalk = async (id, descricao) => {
  await Axios.patch(virtualAssistantCategorizationPatch(id), {
    descricao: descricao,
  })
    .then((response) => {
      openNotification("Fala modificada com sucesso!", "", "success");
    })
    .catch((err) => {
      openNotification("Erro ao modificar a fala!", "Tente novamente", "error");
    });
};

export const patchMonitoringTalk = async (id, descricao, naoEditavel) => {
  await Axios.patch(virtualAssistantMonitoringPatch(id), {
    descricao: descricao,
    naoEditavel: naoEditavel,
  })
    .then((response) => {
      openNotification("Fala modificada com sucesso!", "", "success");
    })
    .catch((err) => {
      openNotification("Erro ao modificar a fala!", "Tente novamente", "error");
    });
};

export const patchRisksTalk = async (id, descricao, naoEditavel) => {
  await Axios.patch(virtualAssistantRisksPatch(id), {
    descricao: descricao,
    naoEditavel: naoEditavel,
  })
    .then((response) => {
      openNotification("Fala modificada com sucesso!", "", "success");
    })
    .catch((err) => {
      openNotification("Erro ao modificar a fala!", "Tente novamente", "error");
    });
};

export const patchActionPlanTalk = async (id, descricao, naoEditavel) => {
  await Axios.patch(virtualAssistantActionPlansPatch(id), {
    descricao: descricao,
    naoEditavel: naoEditavel,
  })
    .then((response) => {
      openNotification("Fala modificada com sucesso!", "", "success");
    })
    .catch((err) => {
      openNotification("Erro ao modificar a fala!", "Tente novamente", "error");
    });
};

export const patchAutoStatementTalk = async (id, descricao, naoEditavel) => {
  await Axios.patch(virtualAssistantAutostatementPatch(id), {
    descricao: descricao,
    naoEditavel: naoEditavel,
  })
    .then((response) => {
      openNotification("Fala modificada com sucesso!", "", "success");
    })
    .catch((err) => {
      openNotification("Erro ao modificar a fala!", "Tente novamente", "error");
    });
};

export const patchVirtualAssistantEsocialTalk = async (
  id,
  descricao,
  naoEditavel
) => {
  await Axios.patch(virtualAssistantEsocialPatch(id), {
    descricao: descricao,
    naoEditavel: naoEditavel,
  })
    .then((response) => {
      openNotification("Fala modificada com sucesso!", "", "success");
    })
    .catch((err) => {
      openNotification("Erro ao modificar a fala!", "Tente novamente", "error");
    });
};

export const patchVirtualAssistantDigitalSignatureTalk = async (
  id,
  descricao,
  naoEditavel
) => {
  await Axios.patch(virtualAssistantDigitalSignaturePatch(id), {
    descricao: descricao,
    naoEditavel: naoEditavel,
  })
    .then((response) => {
      openNotification("Fala modificada com sucesso!", "", "success");
    })
    .catch((err) => {
      openNotification("Erro ao modificar a fala!", "Tente novamente", "error");
    });
};
