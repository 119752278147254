import React from "react";
import { notification } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationOutlined,
} from "@ant-design/icons";
import { colors } from "../../constants/colors";
import "./style.scss";

//tipos possiveis: success, info, warning, error

const openNotification = (title, description, type) => {
  let big = false;
  if (title === undefined) title = "";
  if (description === undefined) description = "";
  if (description !== "" || title.length > 45) {
    big = true;
  }
  notification[type]({
    message: title,
    duration: 7,
    placement: "topRight",
    description,
    style: {
      width: 500,
      height: big ? 90 : 45,
      marginLeft: 375 - 500,
      padding: 0,
      marginTop: 6,
    },
    icon: getIcon(type, big),
  });
};

const getIcon = (type, big) => {
  let icon = "";
  switch (type) {
    case "success":
      icon = (
        <CheckOutlined
          style={{
            color: "white",
            backgroundColor: colors.green,
            width: big ? "75px" : "45px",
            height: big ? "90px" : "45px",
            lineHeight: big ? "4" : "2.2",
          }}
        />
      );
      break;
    case "error":
      icon = (
        <CloseOutlined
          style={{
            color: "white",
            backgroundColor: colors.red,
            width: big ? "75px" : "45px",
            height: big ? "90px" : "45px",
            lineHeight: big ? "4" : "2.2",
          }}
        />
      );
      break;
    default:
      icon = (
        <ExclamationOutlined
          type="exclamation"
          style={{
            color: "white",
            backgroundColor: colors.pendency,
            width: big ? "75px" : "45px",
            height: big ? "90px" : "45px",
            lineHeight: big ? "4" : "2.2",
          }}
        />
      );
      break;
  }
  return icon;
};

export default openNotification;
