import {
  LISTAR_PLANO_DE_ACAO,
  CRIAR_ACAO,
  EXCLUIR_ACAO,
  LISTAR_ACAO,
  RECUSAR_ACAO,
} from "../actions/types";

export const planoDeAcaoReducer = (
  state = {
    planoDeAcao: [],
    acoesPlanejadas: [],
  },
  action
) => {
  switch (action.type) {
    case LISTAR_PLANO_DE_ACAO:
      return {
        ...state,
        planoDeAcao: action.payload,
      };
    case LISTAR_ACAO:
      return {
        ...state,
        acaoSelecionada: action.payload,
      };
    case CRIAR_ACAO:
      return {
        ...state,
        acoesPlanejadas: action.payload,
      };
    case EXCLUIR_ACAO:
      return {
        ...state,
        acoesPlanejadas: [...state.acoesPlanejadas].filter(
          (acao) => acao.id !== action.payload
        ),
      };
    case RECUSAR_ACAO:
      return {
        ...state,
        planoDeAcao: state.planoDeAcao.map((acao) =>
          acao.acoesSugeridas.filter((acao) => acao.id !== action.payload)
        ),
      };
    default:
      return state;
  }
};
