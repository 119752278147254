import { CheckOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React from "react";
import "./style.scss";

const { confirm } = Modal;

export const ReadHTML = (props) => {
  return (
    <div className="textContent">
      {props.html.type === "p" ? props.html : <p>{props.html}</p>}
    </div>
  );
};

export const openAssistantModal = (children, assistant) => {
    const currentAssitant =
    assistant === "vivi"
      ? "showViviAssistant"
      : assistant === "aroldo"
        ? ""
        : assistant === "hide" && "hideAssistent";

  confirm({
    content: (
      <React.Fragment>
        {children && children.map((item, k) => {
          return <ReadHTML html={item} key={k}/>;
        })}
      </React.Fragment>
    ),
    style: { marginTop: "-14rem" },
    okText: (
      <>
        <CheckOutlined />
        OK
      </>
    ),
    className: `hideCancelButton ${currentAssitant}`,
  });
};
