import { combineReducers } from "redux";
import reduceReducers from "reduce-reducers";
import { reducer as formReducer } from "redux-form";
import userReducer from "../shared/LayoutApp/_/reducers";
import caracterizacaoReducers from "../scenes/Caracterizacao/_/reducers";
import buttonsReducer from "../components/Buttons/_/reducers";
import { planoDeAcaoReducer } from "../scenes/PlanoDeAcao/_/reducers";
import { mudancaDeSenhaReducer } from "../scenes/Login/_/reducers";
import gestorDRReducers from "../scenes/Admin/GestorDR/reducers";
import relatorioReducer from "../scenes/Admin/GestorDR/Relatorios/_/reducers";
import { selfDeclarationReducer } from "../scenes/Autodeclaracao/_/reducers";
import { listaDeInformacoesReducer } from "../scenes/Itens/_/reducers";
import { gestorDNReducer } from "../scenes/Admin/GestorDN/_/reducers";
import { dashboardReducer } from "../scenes/Acompanhamento/_/reducers";
import { esocialReducer } from "../scenes/ESocial/_/reducers";

export default reduceReducers(
  combineReducers({
    buttons: buttonsReducer,
    form: formReducer,
    caracterizacao: caracterizacaoReducers,
    relatorio: relatorioReducer,
    dashboard: dashboardReducer,
    esocial: esocialReducer,
    user: userReducer,
    planoDeAcao: planoDeAcaoReducer,
    mudancaDeSenha: mudancaDeSenhaReducer,
    gestorDN: gestorDNReducer,
    gestorDR: gestorDRReducers,
    autodeclaracao: selfDeclarationReducer,
    listaDeInformacoes: listaDeInformacoesReducer,
  })
);
