import { Col, Row, Select, Form, ConfigProvider, DatePicker } from "antd";
import React, { useState } from "react";
import Assistant from "../../components/VirtualAssistant";
import { downloadDocumentosTalk } from "../../constants/aroldoTalks";
import DocumentList from "./_/components/DocumentList";
import "./styles.scss";
import { connect } from "react-redux";
import { getDocuments, getDocumentList } from "./_/actions";
import { bindActionCreators } from "redux";
import IconSESI from "../../components/IconsSESI";
import locale from "antd/lib/locale/pt_BR";
import { useEffect } from "react";

const DownloadDocumentos = (props) => {
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const uniqueCompanies = props.companyList.reduce((acc, obj) => {
    let exist = acc.find(({ cnpj }) => obj.cnpj === cnpj);
    if (!exist) {
      acc.push(obj);
    }
    return acc;
  }, []);
  const companies = uniqueCompanies;
  const [showTable, setShowTable] = useState(false);
  const [allDocuments, setAllDocuments] = useState();
  const [tableData, setTableData] = useState([]);

  const selectCompanyHandler = (cnpj) => {
    getDocuments(
      props.dispatch,
      cnpj,
      setShowTable,
      parseTableData,
      setAllDocuments
    );
    form.resetFields();
  };

  const parseTableData = (data) => {
    let parsedTable = [];

    data.map((document, index) => {
      parsedTable.push({
        key: index,
        name: document.nomeArquivo,
        date: document.dataVersao,
        fileExtension: document.nomeArquivo.slice(-3).toUpperCase(),
        download: document.caminho,
        type: document.tipoDocumento,
      });
    });
    setTableData(parsedTable);
  };

  const disabledDate = (current) => {
    return current.valueOf() > Date.now();
  };

  const handleFilterData = () => {
    let documentType = form.getFieldValue("documentType");
    let dates = form.getFieldValue("dates");

    let filteredData = allDocuments;

    if (dates) {
      const [startDate, endDate] = dates.map((date) => date.format("MM/YYYY"));
      filteredData = filteredData.filter(
        (document) =>
          document.dataVersao >= startDate && document.dataVersao <= endDate
      );
    }

    if (documentType) {
      filteredData = filteredData.filter(
        (document) => document.tipoDocumento === documentType
      );
    }

    parseTableData(filteredData);
  };

  return (
    <React.Fragment>
      <Row gutter={34}>
        <Col span={6}>
          <Assistant talk={downloadDocumentosTalk[0]} perfil={props.perfil} />
        </Col>
        <Col span={18} className="documents-wrapper">
          <div>
            <div className="header-form">
              <h2>Realize o download de documentos emitidos</h2>
            </div>
            <div className="content">
              <Form form={form}>
                <div className="section">
                  <Form.Item label="Empresa::">
                    <Select onSelect={selectCompanyHandler}>
                      {companies.map((company) => (
                        <Option key={company.cnpj} value={company.cnpj}>
                          {company.razaoSocial}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {showTable && (
                    <Row className="filter-row">
                      <IconSESI type="filter" fill="#bfbfbf" />
                      <Form.Item name="documentType" style={{ flex: 1 }}>
                        <Select
                          placeholder="Tipo de documento"
                          allowClear
                          onChange={handleFilterData}
                          // onSelect={handleFilterData}
                          onClear={handleFilterData}
                        >
                          <Option value="s2210">S-2210</Option>
                          <Option value="s2220">S-2220</Option>
                          <Option value="s2240">S-2240</Option>
                          <Option value="s3000">
                            S-3000 (evento de exclusão)
                          </Option>
                          <Option value="pgr">PGR</Option>
                          <Option value="auto">Autodeclaração</Option>
                        </Select>
                      </Form.Item>
                      <ConfigProvider locale={locale}>
                        <Form.Item name="dates">
                          <RangePicker
                            picker="month"
                            placeholder={["Período inicial", "Período final"]}
                            disabledDate={disabledDate}
                            format="MM/YYYY"
                            suffixIcon={false}
                            bordered={false}
                            onChange={handleFilterData}
                            allowEmpty={true}
                          />
                        </Form.Item>
                      </ConfigProvider>
                    </Row>
                  )}
                </div>
              </Form>
            </div>
            <div>
              {showTable ? <DocumentList tableData={tableData} /> : null}
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    companyList: state.user.companies,
    perfil: state.user.userInfo.perfil,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        getDocuments,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadDocumentos);
