import React from "react";
import { Button as ButtonAnt } from "antd";

const Button = props => (
  <ButtonAnt {...props} {...props.disabled}>
    {props.text}
  </ButtonAnt>
);

export default Button;
