import { setupInterceptors } from "../../../../auth/SetupInterceptors";
import { authUrl } from "../../../../constants/index";
import Axios from "axios";
import openNotification from "../../../../components/notifications";
import { AUTH_USER } from "../../../../shared/LayoutApp/_/actions/types";

setupInterceptors();

export const fetchMeuPerfil = () => {
  return dispatch => {
    Axios.get(authUrl).then(resposta => {
      if (resposta.status === 200)
        dispatch({
          type: AUTH_USER,
          payload: resposta.data
        });
    });
  };
};

export function saveMeuPerfil(values, callback) {
  return dispatch => {
    Axios.patch(authUrl, createMeuPerfil(values))
    .then(rsp => {
      openNotification("Customização salva com sucesso!", "Salvo", "success");
      if (rsp.status === 200) {
        Axios.get(authUrl).then(response => {
          if (response.status === 200) {
            dispatch({
              type: AUTH_USER,
              payload: response.data
            });
            callback();
          }
        });
      }
    })
    .catch(() => {
      callback();
      openNotification("Erro ao salvar customização!", "Tente novamente", "error");
    })
  }
}

export function saveLogo(logo) {
  return dispatch => {
    Axios({
        method: 'patch',
        headers: { "Content-Type": "multipart/form-data" },
        data: getLogoFormData(logo),
        url: `${authUrl}/logo`
    })
    .then(() => {
      Axios.get(authUrl).then(response => {
        if (response.status === 200) {
          dispatch({
            type: AUTH_USER,
            payload: response.data
          });
        }
      });
    })
    .catch(() => {
      openNotification("Erro ao salvar logo!", "Tente novamente", "error");
    })
  }
}

export function deleteLogo() {
  return dispatch => {
    Axios({
        method: 'patch',
        headers: { "Content-Type": "multipart/form-data" },
        data: getDeleteLogoFormData(),
        url: `${authUrl}/logo`
    })
    .then(() => {
      Axios.get(authUrl).then(response => {
        if (response.status === 200) {
          dispatch({
            type: AUTH_USER,
            payload: response.data
          });
        }
      });
    })
    .catch(() => {
      openNotification("Erro ao deletar logo!", "Tente novamente", "error");
    })
  }
}

const createMeuPerfil = values => {
  const perfil = {
    "nomeDeExibicao": values.nomeDeExibicao,
    "temaDeCoresDoUsuario": values.temaDeCoresDoUsuario,
    "temasDeCoresDasEmpresas": values.temasDeCoresDasEmpresas,
    "removerTemasDeCoresDasEmpresas": []
  }
  return perfil;
};

const getLogoFormData = logo => {
  let formData = new FormData();
  logo && formData.append("logo", logo);
  return formData;
};

const getDeleteLogoFormData = () => {
  let formData = new FormData();
  formData.append("removerLogo", 1);
  return formData;
};
