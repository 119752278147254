import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import { MaskedInput } from "antd-mask-input";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IconSESI from "../../../../../components/IconsSESI";
import {
  eSocial2240ConfirmNotAllHarmfulAgents,
  eSocialConfirmWorkerChange,
} from "../../../../../components/modals";
import { HeaderWithModal } from "../../../../../components/HeaderWithModal/index";
import { openAssistantModal } from "../../../../../components/AssistantModalHeader";
import { AllConstants } from "../../constants/index";
import {
  alterarEsocialS2240,
  getHarmfulAgents,
  listar2240,
  listarCategorias,
  listarFuncionariosEsocial,
  salvarEsocialS2240,
} from "../../actions";
import { getAssistantTalks } from "../../actions/index"
import {
  formatHarmfulAgents,
  parseResponsavel,
  validateCAEPF,
  validateCNO,
  validateCNPJ,
  validateCpf,
} from "../../utils/index";
import HarmfulAgentCard from "./HarmfulAgentCard";

const { Option } = Select;
const { TextArea } = Input;

const ESocial2240Form = (props) => {
  const [form] = Form.useForm();
  const {
    agentesNocivosComDescricao,
    ufsComplete,
    harmfulAgentsAbsence,
    courtDecisionObject,
  } = AllConstants();

  const [hasMatricula, setHasMatricula] = useState(true);
  const [orgaoClasse, setOrgaoClasse] = useState("");
  const [tipoInscr, setTipoInscr] = useState("");
  const [blockAddResp, setBlockAddResp] = useState(false);
  const [blockAddAgenteNocivo, setBlockAddAgenteNocivo] = useState(false);
  const [eventWasSent, setEventWasSent] = useState(false);
  const [assistantTalk, setAssistantTalk] = useState([]);
  const [utilizaEpcEpi, setUtilizaEpcEpi] = useState([
    {
      id: 1,
      utilizaEpc: "",
      utilizaEpi: "",
      tpAval: "",
      naoPossuiMedicao: false,
      existEpcInPgr: "",
      existEpiInPgr: "",
      existPmInPgr: false,
    },
  ]);

  //Automacao
  const [harmfulAgentsArray, setHarmfulAgentsArray] = useState([]);
  const [usedHarmfulAgents, setUsedHarmfulAgents] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [hasInventarioDeRisco, setHasInventarioDeRisco] = useState(false);

  const onLoadScreen = async () => {
    let harmfulAgents = await getHarmfulAgents();

    getDigitalSignatureAssistantTalks()
    setHarmfulAgentsArray([...harmfulAgents, harmfulAgentsAbsence]);
  };

  const iniciarModoEdicao = async (evento) => {
    setEventWasSent(evento.enviado);
    setTipoInscr(evento.tpInsc);

    let epcEpcConfigs = [];
    if (evento.agentesNocivos) {
      evento.agentesNocivos.forEach((agente) => {
        epcEpcConfigs.push({
          id: epcEpcConfigs.length + 1,
          utilizaEpc: agente?.epcEpi?.utilizEPC,
          utilizaEpi: agente?.epcEpi?.utilizEPI,
          tpAval: agente?.tpAval,
          naoPossuiMedicao: false,
          existEpcInPgr: "",
          existEpiInPgr: "",
          existPmInPgr: false,
        });
      });
    }

    setUtilizaEpcEpi(epcEpcConfigs);
    setSelectedUser(evento.cpfTrab);
    getWorkerInformations(evento.cpfTrab);

    form.setFieldsValue({
      nomeTrab: evento?.nomeTrab,
      cpfTrabalhador: evento.cpfTrab,
      matriculaTrabalhador: evento.matricula,
      categoriaTrabalhador: evento.codCateg ?? "",
      dtIniCondicao: moment(evento.dtIniCondicao),
      dtFimCondicao: null,
      localAmb: evento.informacoesAmbiente
        ? evento.informacoesAmbiente[0].localAmb
        : "",
      dscSetor: evento.informacoesAmbiente
        ? evento.informacoesAmbiente[0].dscSetor
        : "",
      tpInsc: evento.informacoesAmbiente[0].tpInsc,
      ordExame: "NAO_APLICAVEL",
      nrInsc: evento.informacoesAmbiente[0].nrInsc,
      dscAtivDes: evento.dscAtivDes,
      agentesNocivos: evento.agentesNocivos.map((agente) => {
        return {
          codAgNoc: agente?.idFatorDeRisco,
          idFatorDeRisco: agente.idFatorDeRisco,
          hasInventarioDeRisco: agente.hasInventarioDeRisco,
          codigoTabela24: agente.codAgNoc,
          dscAgNoc: agente?.dscAgNoc ? agente?.dscAgNoc : "NÃO SE APLICA",
          tpAval: agente?.tpAval ? agente?.tpAval : "",
          intConc: agente?.intConc ? agente?.intConc : 0,
          unMed: agente?.unMed ? agente?.unMed : "NAO_SE_APLICA",
          nrProcJud: agente?.nrProcJud ?? null,
          limTol: agente?.limTol ? agente?.limTol : 0,
          tecMedicao: agente?.tecMedicao,
          utilizEPC: agente?.epcEpi?.utilizEPC,
          eficEpc: agente?.epcEpi?.eficEpc
            ? agente?.epcEpi?.eficEpc
            : "NAO_SE_APLICA",
          utilizEPI: agente?.epcEpi?.utilizEPI,
          eficEpi: agente?.epcEpi?.eficEpi
            ? agente?.epcEpi?.eficEpi
            : "NAO_SE_APLICA",
          docAvals: agente?.epcEpi?.episCertificadosAprovacao
            ? agente?.epcEpi?.episCertificadosAprovacao?.map((doc) => {
              return {
                docAval: doc?.docAval,
              };
            })
            : [],
          medProtecao: agente?.epcEpi?.epiCompl?.medProtecao,
          condFuncto: agente?.epcEpi?.epiCompl?.condFuncto,
          usoInint: agente?.epcEpi?.epiCompl?.usoInint,
          przValid: agente?.epcEpi?.epiCompl?.przValid,
          periodicTroca: agente?.epcEpi?.epiCompl?.periodicTroca,
          higienizacao: agente?.epcEpi?.epiCompl?.higienizacao,
        };
      }),
      responsaveisRegistroAmbiental: evento.responsaveisRegistroAmbiental.map(
        (responsavel) => {
          return {
            cpfResp: responsavel?.cpfResp,
            ideOC: responsavel?.ideOC,
            nrOC: responsavel?.nrOC,
            ufOC: responsavel?.ufOC,
            dscOC: responsavel?.dscOc ? responsavel?.dscOc : "",
          };
        }
      ),
      obsCompl: evento.obsCompl,
    });

    validateUsedHarmfulAgents();
  };

  const parseDocAval = (docAval) => {
    let docAvalsFormatted = [];
    if (typeof docAval === Object) {
      return [];
    }
    if (docAval && docAval.length !== 0) {
      docAval.forEach((doc) => {
        if (doc.docAval) {
          docAvalsFormatted.push({ docAval: doc.docAval });
        }
      });
    }

    return docAvalsFormatted;
  };

  const parseAgentesNocivos = (agentesNocivos) => {
    return agentesNocivos.map((agente) => {
      return {
        codAgNoc: agente.codigoTabela24,
        idFatorDeRisco: agente.idFatorDeRisco,
        hasInventarioDeRisco: agente.hasInventarioDeRisco,
        dscAgNoc: agentesNocivosComDescricao.includes(agente.codigoTabela24)
          ? agente.dscAgNoc
          : null,
        tpAval:
          agente.tpAval &&
            agente.codigoTabela24 !== harmfulAgentsAbsence.codigoTabela24
            ? agente.tpAval
            : "QUALITATIVO",
        intConc: agente.intConc ? parseFloat(agente.intConc) : 0,
        unMed: agente.tpAval === "QUANTITATIVO" ? agente.unMed : 0,
        nrProcJud: agente.nrProcJud,
        limTol:
          agente.codigoTabela24 === "01.18.001" ||
            agente.codigoTabela24 === "02.01.014"
            ? parseFloat(agente.limTol)
            : null,
        tecMedicao: agente.tecMedicao,
        epcEpi: {
          utilizEPC: !agente.utilizEPC ? "NAO_SE_APLICA" : agente.utilizEPC,
          eficEpc: agente.utilizEPC === "IMPLEMENTA" ? agente.eficEpc : null,
          utilizEPI: !agente.utilizEPI ? "NAO_SE_APLICA" : agente.utilizEPI,
          eficEpi: agente.utilizEPI === "IMPLEMENTA" ? agente.eficEpi : null,
          epis:
            agente.docAvals && agente.docAvals.length !== 0
              ? parseDocAval(agente.docAvals)
              : [],
          epiCompl: {
            medProtecao: agente.medProtecao,
            condFuncto: agente.condFuncto,
            usoInint: agente.usoInint,
            przValid: agente.przValid,
            periodicTroca: agente.periodicTroca,
            higienizacao: agente.higienizacao,
          },
        },
      };
    });
  };

  const handleSubmit = async () => {
    form.validateFields().then(async (values) => {
      let indRetif = "";

      switch (props.formMode) {
        case "new":
          indRetif = "ORIGINAL";
          break;

        case "increment":
          indRetif = "ORIGINAL";
          break;

        default:
          if (props.eventoDuplicado.nrRecibo) {
            indRetif = "RETIFICACAO";
          } else {
            indRetif = "ORIGINAL";
          }
      }

      const new2240 = {
        nomeTrab: values.nomeTrab ? values.nomeTrab : "",
        indRetif,
        nrInsc: props.selectedCompany.cnpj.slice(0, 8),
        cpfTrab: values.cpfTrabalhador,
        matricula: values.matriculaTrabalhador,
        codCateg: values.categoriaTrabalhador
          ? values.categoriaTrabalhador
          : "",
        dtIniCondicao: moment(values.dtIniCondicao).format("YYYY-MM-DD"),
        dtFimCondicao: null,
        informacoesAmbiente: [
          {
            localAmb: values.localAmb,
            dscSetor: values.dscSetor,
            tpInsc: values.tpInsc,
            ordExame: "NAO_APLICAVEL",
            nrInsc: values.nrInsc,
          },
        ],
        dscAtivDes: values.dscAtivDes,
        agentesNocivos: parseAgentesNocivos(values.agentesNocivos),
        responsaveisRegistroAmbiental: parseResponsavel(
          values.responsaveisRegistroAmbiental
        ),
        obsCompl: values.obsCompl,
      };

      let workerHasMoreRegisteredRisks =
        await validateIfWorkerHasMoreRegisteredRisks(
          values.cpfTrabalhador,
          values.agentesNocivos
        );

      if (props.formMode === "rectification" || props.formMode === "update") {
        new2240.id = props.eventoDuplicado.id;
        new2240.nrRecibo = props.eventoDuplicado.nrRecibo;
      }

      if (workerHasMoreRegisteredRisks) {
        eSocial2240ConfirmNotAllHarmfulAgents(async () => {
          props.formMode === "new" || props.formMode === "increment"
            ? await salvarEsocialS2240(
              props.dispatch,
              new2240,
              resetForm,
              props.setFormMode
            )
            : await alterarEsocialS2240(
              props.dispatch,
              new2240,
              resetForm,
              props.setFormMode
            );
        });
        return;
      }

      props.formMode === "new" || props.formMode === "increment"
        ? await salvarEsocialS2240(
          props.dispatch,
          new2240,
          resetForm,
          props.setFormMode
        )
        : await alterarEsocialS2240(
          props.dispatch,
          new2240,
          resetForm,
          props.setFormMode
        );
    });
  };

  const resetForm = (userCpf) => {
    form.resetFields();
    getEnvironmentalRegistryResponsibles();

    setUtilizaEpcEpi([
      {
        id: 1,
        utilizaEpc: "",
        utilizaEpi: "",
        tpAval: "",
        naoPossuiMedicao: false,
        existEpcInPgr: "",
        existEpiInPgr: "",
        existPmInPgr: false,
      },
    ]);

    setUsedHarmfulAgents([]);

    form.setFieldsValue({
      cpfTrabalhador: userCpf ?? "",
      agentesNocivos: [
        {
          docAvals: [
            {
              fieldKey: 0,
              isListField: true,
              key: 0,
              name: 0,
            },
          ],
        },
      ],
      responsaveisRegistroAmbiental: [
        {
          docAvals: {
            fieldKey: 0,
            isListField: true,
            key: 0,
            name: 0,
          },
        },
      ],
    });
  };

  const onCheck = () => {
    setHasMatricula(!hasMatricula);
    listarCategorias(props.dispatch);
  };

  const handleSelectedHarmfulAgents = (harmfulAgentId, agentIndex) => {
    const harmfulAgentData = form.getFieldValue("agentesNocivos");
    let selectedHarmfulAgent = harmfulAgentsArray.find(
      (item) => item.idFatorDeRisco === harmfulAgentId
    );

    if (!selectedHarmfulAgent) {
      return;
    }

    form.setFieldsValue({
      agentesNocivos: harmfulAgentData.map((item, index) => {
        if (
          index !== agentIndex ||
          selectedHarmfulAgent.idFatorDeRisco !== item.codAgNoc
        ) {
          return {
            ...item,
          };
        }

        let { highestValueProcess, usesEPC, usesEPI, protectionMeasure } =
          FormatEpcEpiProtectiveMeasuresInformation(selectedHarmfulAgent);

        if (selectedHarmfulAgent.idFatorDeRisco === harmfulAgentId) {
          let oldValue = [...utilizaEpcEpi];

          oldValue.forEach((item) => {
            if (item.id === agentIndex + 1) {
              item.tpAval = selectedHarmfulAgent.classificacaoDeRisco ?? "";
              if (
                highestValueProcess &&
                highestValueProcess.valorMedicao !== 0
              ) {
                item.utilizaEpc = usesEPC;
                item.utilizaEpi = usesEPI;
                item.medProtecao = protectionMeasure;
                item.existEpcInPgr = usesEPC;
                item.existEpiInPgr = usesEPI;
                item.existPmInPgr = protectionMeasure;
              } else {
                item.existEpcInPgr = "";
                item.existEpiInPgr = "";
                item.existPmInPgr = false;
              }
            }
          });
          setUtilizaEpcEpi(oldValue);
        }

        return {
          dscAgNoc:
            selectedHarmfulAgent.codigoTabela24 !==
              courtDecisionObject.codigoTabela24
              ? selectedHarmfulAgent.descricao
              : "",
          descricao: selectedHarmfulAgent.descricao,
          codigoTabela24: selectedHarmfulAgent.codigoTabela24,
          codAgNoc: selectedHarmfulAgent.idFatorDeRisco,
          idFatorDeRisco: selectedHarmfulAgent.idFatorDeRisco,
          intConc: highestValueProcess ? highestValueProcess.valorMedicao : "",
          tpAval: selectedHarmfulAgent.classificacaoDeRisco ?? "",
          utilizEPC: usesEPC,
          utilizEPI: usesEPI,
          medProtecao: protectionMeasure,
          limTol: highestValueProcess?.limiteTolerancia
            ? parseFloat(highestValueProcess?.limiteTolerancia)
            : "",
          hasInventarioDeRisco: hasInventarioDeRisco,
        };
      }),
    });

    validateUsedHarmfulAgents();
  };

  const FormatEpcEpiProtectiveMeasuresInformation = (harmfulAgent) => {
    let highestValueProcess = null;
    let highestMeasurementValue = 0;
    let usesEPC = "";
    let usesEPI = "";
    let protectionMeasure = null;

    if (harmfulAgent?.processosProdutivos) {
      harmfulAgent.processosProdutivos.forEach((process) => {
        let WorkerExistsInTheProcess = process.funcionariosDoProcesso.some(
          (worker) => worker.cpf === selectedUser
        );

        if (
          process.valorMedicao > highestMeasurementValue &&
          WorkerExistsInTheProcess
        ) {
          highestMeasurementValue = process.valorMedicao;
          highestValueProcess = process;
        }
      });
    }

    if (highestValueProcess && highestValueProcess.valorMedicao !== 0) {
      usesEPC = highestValueProcess?.epcs.some(
        (epc) => epc.codigo !== 0 && epc.codigo !== 33
      )
        ? "IMPLEMENTA"
        : highestValueProcess.epcs.some((epc) => epc.codigo === 33)
          ? "NAO_SE_APLICA"
          : "NAO_IMPLEMENTA";

      usesEPI = highestValueProcess?.epis.some(
        (epi) => epi.codigo !== 0 && epi.codigo !== 3
      )
        ? "IMPLEMENTA"
        : highestValueProcess.epis.some((epi) => epi.codigo === 3)
          ? "NAO_SE_APLICA"
          : "NAO_IMPLEMENTA";

      protectionMeasure =
        usesEPC === "NAO_SE_APLICA" || usesEPC === "IMPLEMENTA" ? true : false;
    }

    return {
      highestValueProcess,
      usesEPC,
      usesEPI,
      protectionMeasure,
    };
  };

  const getEnvironmentalRegistryResponsibles = async () => {
    let list2240 = await listar2240();
    let registryResponsibleList = [];

    list2240.forEach((item) => {
      item.responsaveisRegistroAmbiental.forEach((responsible) => {
        let existingResponsible = registryResponsibleList.find(
          (i) => i.cpfResp == responsible.cpfResp
        );

        if (!existingResponsible) {
          registryResponsibleList.push(responsible);
        }
      });
    });

    let responsibles = [];

    registryResponsibleList.forEach((item) => {
      let newResponsibles = {
        cpfResp: item.cpfResp,
        ideOC: item.ideOC,
        nrOC: item.nrOC,
        ufOC: item.ufOC,
      };
      responsibles.push(newResponsibles);
    });

    if (responsibles.length == 0) return;
    form.setFieldsValue({
      responsaveisRegistroAmbiental: responsibles,
    });
  };

  const validateChangeSelectedUser = async (userCpf) => {
    if (selectedUser == "") {
      getWorkerInformations(userCpf);
      getEnvironmentalRegistryResponsibles();
      fillRegistration(userCpf);
      return;
    }

    form.setFieldsValue({
      cpfTrabalhador: selectedUser,
    });

    eSocialConfirmWorkerChange(() => {
      resetForm(userCpf);
      getWorkerInformations(userCpf);
      fillRegistration(userCpf);
    });
  };

  const fillRegistration = (userCpf) => {
    props.funcionariosEsocial.forEach((funcionario) => {
      if (funcionario.cpf === userCpf) {
        form.setFieldsValue({
          matriculaTrabalhador: funcionario.matricula,
        });
      }
    });
  };

  const getExistingWorkerInformation = (userCpf) => {
    props.funcionariosEsocial.forEach((employee) => {
      if (employee.cpf === userCpf) {
        let dataDeAdmissao = moment(employee.dataDeAdmissao);
        let dtIniCondicao = moment("2022-01-10");

        form.setFieldsValue({
          dtIniCondicao: dataDeAdmissao.isAfter(dtIniCondicao)
            ? dataDeAdmissao
            : dtIniCondicao,
        });
      }
    });
  };

  const getTypeEstablishment = (selectedType) => {
    if (selectedType === "ESTABELECIMENTO_EMPREGADOR") {
      props.funcionariosEsocial.forEach((employee) => {
        if (employee.cpf === selectedUser) {
          let formattedSectorName =
            employee.setor[0].toUpperCase() +
            employee.setor.toLowerCase().substring(1);

          form.setFieldsValue({
            dscSetor: formattedSectorName,
          });
        }
      });

      setTipoInscr("CNPJ");
      form.setFieldsValue({
        tpInsc: "CNPJ",
        nrInsc: props.selectedCompany.cnpj,
      });
    } else {
      setTipoInscr("");
      form.setFieldsValue({
        dscSetor: "",
        tpInsc: "",
        nrInsc: "",
      });
    }
  };

  const getWorkerInformations = async (userCpf) => {
    setSelectedUser(userCpf);
    getExistingWorkerInformation(userCpf);

    let harmfulAgents = await getHarmfulAgents();
    let workerInformations = ``;
    let workerHarmfulAgents = [];
    let usedProductiveProcess = [];

    harmfulAgents.forEach((agenteNocivo) => {
      agenteNocivo.processosProdutivos.forEach((processo) => {
        let workerIsIncludedInProductiveProccess =
          processo.funcionariosDoProcesso.find(
            (funcionario) => funcionario.cpf === userCpf
          );

        if (!workerIsIncludedInProductiveProccess) {
          return;
        }

        if (
          !workerHarmfulAgents.find(
            (item) => item.idFatorDeRisco === agenteNocivo.idFatorDeRisco
          )
        ) {
          workerHarmfulAgents.push(agenteNocivo);
        }

        let productiveProcessAlreadyIncluded = usedProductiveProcess.find(
          (item) => item == processo.nome
        );

        if (productiveProcessAlreadyIncluded) {
          return;
        }

        usedProductiveProcess.push(processo.nome);

        workerInformations += `${workerInformations.length !== 0 ? "; " : ""}${
          processo.nome
          }${processo.descricao !== "" ? " - " : ""}${processo.descricao}`;
      });
    });

    form.setFieldsValue({
      dscAtivDes: workerInformations,
    });

    if (workerHarmfulAgents.length !== 0) {
      setHasInventarioDeRisco(true);
    } else {
      setHasInventarioDeRisco(false);
    }

    setHarmfulAgentsArray(
      workerHarmfulAgents.length !== 0
        ? [...workerHarmfulAgents, harmfulAgentsAbsence]
        : formatHarmfulAgents(props.agentesNocivos)
    );
    return workerHarmfulAgents.length;
  };

  const validateUsedHarmfulAgents = () => {
    let selectedHarmfulAgents = form.getFieldValue("agentesNocivos");

    let allHarmfulAgentsArray = selectedHarmfulAgents.map((item) => {
      return item?.codAgNoc;
    });

    setTimeout(() => {
      setUsedHarmfulAgents(() => {
        return allHarmfulAgentsArray;
      });
    }, 500);
  };

  const validateIfWorkerHasMoreRegisteredRisks = async (
    userCpf,
    selectedHarmfulAgents
  ) => {
    let harmfulAgents = await getHarmfulAgents();

    if (harmfulAgents.length === 0) {
      return false;
    }

    let workerHarmfulAgents = [];

    harmfulAgents.forEach((agenteNocivo) => {
      agenteNocivo.processosProdutivos.forEach((processo) => {
        let workerIsIncludedInProductiveProccess =
          processo.funcionariosDoProcesso.find(
            (funcionario) => funcionario.cpf === userCpf
          );

        if (workerIsIncludedInProductiveProccess) {
          if (
            !workerHarmfulAgents.find(
              (item) => item.codigoTabela24 === agenteNocivo.codigoTabela24
            )
          ) {
            workerHarmfulAgents.push(agenteNocivo);
          }
        }
      });
    });

    if (workerHarmfulAgents.length === 0) {
      return false;
    }

    let harmfulAgentsFiltered = selectedHarmfulAgents.filter(
      (item) => item.codAgNoc !== harmfulAgentsAbsence.codigoTabela24
    );

    return workerHarmfulAgents.length > harmfulAgentsFiltered.length;
  };

  const validateHarmfulAgentsArray = (clear) => {
    if (clear) {
      setHarmfulAgentsArray([...harmfulAgentsArray, harmfulAgentsAbsence]);
      return;
    }

    setHarmfulAgentsArray((oldValue) => {
      return oldValue.filter(
        (agent) => agent.codigoTabela24 !== harmfulAgentsAbsence.codigoTabela24
      );
    });
  };

  const handleAddHarmfulAgent = (add) => {
    validateHarmfulAgentsArray();
    add();
    setUtilizaEpcEpi([
      ...utilizaEpcEpi,
      {
        id: utilizaEpcEpi[utilizaEpcEpi.length - 1].id + 1,
        utilizaEpc: "",
        utilizaEpci: "",
        tpAval: "",
        naoPossuiMedicao: false,
        existEpcInPgr: "",
        existEpiInPgr: "",
        existPmInPgr: false,
      },
    ]);
  };

  useEffect(() => {
    setSelectedUser("");
    if (props.activeTab !== "7") {
      return;
    }

    if (props.formMode === "new") setEventWasSent(false);

    if (
      props.formMode === "rectification" ||
      props.formMode === "increment" ||
      props.formMode === "update"
    ) {
      iniciarModoEdicao(props.eventoDuplicado);
    }
  }, [JSON.stringify(props.formMode), props.activeTab]);

  const getDigitalSignatureAssistantTalks = async () => {
    const talks = await getAssistantTalks('s2240', 'Gerar 2240')
    setAssistantTalk(talks.descricao)
  }

  useEffect(() => {
    onLoadScreen();
  }, []);

  return (
    <React.Fragment>
      <Col md={24} className="esocial-dashboard">
        <HeaderWithModal
          pageTitle="S-2240 - Condições Ambientais do Trabalho - Agentes Nocivos"
          helpText="Clique aqui e saiba mais!"
          assistant={"vivi"}
          openModal={async () =>
            openAssistantModal(assistantTalk, "vivi")
          }
          prevButton={() => {
            form.resetFields();
            props.setActiveTab("2");
            props.setFormMode("new");
          }}
        />
        <div className="content">
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              responsaveisRegistroAmbiental: [undefined],
              codAgNoc: props.selectedCompany.autodeclaracao
                ? harmfulAgentsAbsence.codigoTabela24
                : undefined,
              agentesNocivos: [undefined],
            }}
            onFinish={handleSubmit}
          >
            <div className="esocial-form-card">
              <span className="esocial-card-title">
                Identificação do trabalhador
              </span>
              {props.selectedCompany.autodeclaracao === true &&
                !props.funcionariosEsocial[0] ? (
                <React.Fragment>
                  <Form.Item label="Trabalhador:" name="nomeTrab">
                    <Input
                      disabled={
                        props.formMode === "rectification" || eventWasSent
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="CPF:"
                    name="cpfTrabalhador"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.resolve();
                          }

                          if (!validateCpf(value)) {
                            return Promise.reject(new Error("CPF inválido!"));
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <MaskedInput
                      disabled={
                        props.formMode === "rectification" || eventWasSent
                      }
                      mask="111.111.111-11"
                      placeholder="000.000.000-00"
                      style={{ width: "11rem" }}
                    />
                  </Form.Item>
                </React.Fragment>
              ) : (
                <Form.Item
                  label="Trabalhador:"
                  name="cpfTrabalhador"
                  rules={[
                    {
                      required: true,
                      message: "Selecione um trabalhador!",
                    },
                  ]}
                >
                  <Select
                    disabled={
                      props.formMode === "rectification" || eventWasSent
                    }
                    onChange={(value) => {
                      validateChangeSelectedUser(value);
                    }}
                  >
                    {props.funcionariosEsocial.map((funcionario) => {
                      return (
                        <Option key={funcionario.id} value={funcionario.cpf}>
                          {funcionario.nome}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}
              <div className="matricula-row">
                <Form.Item
                  label="Número de matrícula:"
                  tooltip="A matrícula, é a chave de acesso do trabalhador no eSocial. Certifique-se de inserir a mesma matrícula informada pelo departamento pessoal no evento S-2200, referente ao vínculo empregatício do trabalhador com a empresa."
                  name="matriculaTrabalhador"
                >
                  <Input
                    style={{ width: "90%", marginRight: "1rem" }}
                    disabled={
                      props.formMode === "rectification" ||
                      eventWasSent ||
                      !hasMatricula
                    }
                  />
                </Form.Item>
                <Checkbox
                  disabled={props.formMode === "rectification" || eventWasSent}
                  onChange={() => onCheck()}
                >
                  Não tem matrícula{" "}
                  <Tooltip title="Somente utilizar “Não tem matrícula”, se o trabalhador estiver enquadrado na lista abaixo “categoria do trabalhador”. Caso contrário, consulte o número da matrícula informada no evento S-2200 relacionado aos dados do departamento pessoal, enviado anteriormente a este evento de SST">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Checkbox>
              </div>
              {hasMatricula === false ? (
                <Form.Item
                  label="Categoria do Trabalhador:"
                  name="categoriaTrabalhador"
                >
                  <Select
                    disabled={
                      props.formMode === "rectification" || eventWasSent
                    }
                  >
                    {props.categoriasTrabalhadores.map((categoria) => {
                      return (
                        <Option value={categoria.id}>
                          {categoria.descricao}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              ) : (
                ""
              )}
            </div>
            <div className="esocial-form-card esocial-2240-form">
              <span className="esocial-card-title">
                Informações sobre o ambiente de trabalho e atividades
                desempenhadas
              </span>
              <Row gutter={24}>
                <Form.Item
                  name="dtIniCondicao"
                  labelCol={{ span: 18 }}
                  label="Data do início da condição (exposição) que será informada:"
                  tooltip="Insira a data em que o trabalhador começou a exercer as atividades nas condições descritas ou a data em que o evento se tornou obrigatório para o empregador no eSocial, optando pela mais recente."
                  rules={[
                    {
                      required: true,
                      message:
                        "Insira a data em que o trabalhador iniciou as atividades descritas.",
                    },
                  ]}
                >
                  <DatePicker
                    disabled={selectedUser === ""}
                    format={"DD/MM/YYYY"}
                    placeholder="__/__/____"
                    allowClear
                    suffixIcon={false}
                    disabledDate={(current) =>
                      moment().add(30, "days") <= current
                    }
                  />
                </Form.Item>
              </Row>
              <Form.Item
                name="localAmb"
                label="Tipo de estabelecimento do ambiente de trabalho:"
                rules={[
                  {
                    required: true,
                    message: "Selecione o tipo de estabelecimento.",
                  },
                ]}
              >
                <Radio.Group
                  disabled={selectedUser === ""}
                  onChange={(event) => getTypeEstablishment(event.target.value)}
                >
                  <Radio key={1} value={"ESTABELECIMENTO_EMPREGADOR"}>
                    Estabelecimento do próprio empregador
                  </Radio>
                  <Radio key={2} value={"ESTABELECIMENTO_TERCEIROS"}>
                    Estabelecimento de terceiros
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="dscSetor"
                label="Setor ou Departamento de Lotação do Trabalhador:"
                tooltip="Caso a empresa tenha elaborado PGR no Facilita, o setor do trabalhador será preenchido automaticamente. Caso o campo esteja em branco, por favor, indique o departamento ou local administrativo onde o trabalhador atua na estrutura organizacional da empresa, utilizando até 100 caracteres."
                rules={[
                  {
                    required: true,
                    message: "Descreva o lugar administrativo do trabalhador.",
                  },
                ]}
              >
                <TextArea
                  disabled={selectedUser === ""}
                  showCount
                  maxLength={100}
                  autoSize={{ minRows: 1, maxRows: 1 }}
                />
              </Form.Item>
              <Row className="same-line-form-items" gutter={24}>
                <Col span={8}>
                  <Form.Item
                    name="tpInsc"
                    label="Tipo de Inscrição:"
                    rules={[
                      {
                        required: true,
                        message: "Selecione o tipo de inscrição.",
                      },
                    ]}
                  >
                    <Radio.Group
                      disabled={
                        selectedUser === "" ||
                        props.formMode === "rectification" ||
                        eventWasSent
                      }
                      onChange={(e) => {
                        setTipoInscr(e.target.value);
                        form.setFieldsValue({
                          nrInsc: "",
                        });
                      }}
                      value={tipoInscr}
                    >
                      <Radio value={"CNPJ"}>CNPJ</Radio>
                      <Radio value={"CAEPF"}>CAEPF</Radio>
                      <Radio value={"CNO"}>CNO</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item
                    name="nrInsc"
                    label="Número de inscrição onde está localizado o ambiente:"
                    tooltip="Neste campo, o número inserido, seja CNPJ, CAEPF ou CNO do estabelecimento, precisa ser o mesmo de eventos de fases anteriores que não o de SST, para não ocorrer inconsistências no envio deste evento."
                    rules={[
                      {
                        required: true,
                        message: "Insira o número de inscrição.",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.resolve();
                          }
                          switch (tipoInscr) {
                            case "CNPJ":
                              if (!validateCNPJ(value)) {
                                return Promise.reject(
                                  new Error("CNPJ inválido!")
                                );
                              }
                              break;
                            case "CAEPF":
                              if (!validateCAEPF(value)) {
                                return Promise.reject(
                                  new Error("CAEPF inválido!")
                                );
                              }
                              break;
                            case "CNO":
                              if (!validateCNO(value)) {
                                return Promise.reject(
                                  new Error("CNO inválido!")
                                );
                              }
                              break;
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}
                    labelCol={{ span: 11 }}
                    className="side-label-form-item"
                  >
                    {tipoInscr === "CNPJ" ? (
                      <MaskedInput
                        disabled={
                          props.formMode === "rectification" ||
                          eventWasSent ||
                          tipoInscr === ""
                        }
                        mask="11.111.111/1111-11"
                      />
                    ) : (
                      <MaskedInput
                        disabled={
                          props.formMode === "rectification" ||
                          eventWasSent ||
                          tipoInscr === ""
                        }
                        mask="11111111111111"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="dscAtivDes"
                label="Descrição das atividades desempenhadas:"
                tooltip="Caso a empresa tenha elaborado PGR no Facilita, este campo exibirá a descrição dos processos produtivos inseridos na etapa 'Processos Produtivos' do PGR vinculados ao trabalhador. Revise e complemente, se necessário. Se o campo estiver vazio, será necessário inserir a descrição das atividades realizadas pelo trabalhador. Limite máximo de 999 caracteres:"
                rules={[
                  {
                    required: true,
                    message: "Descreva as atividades desempenhadas.",
                  },
                ]}
              >
                <TextArea
                  disabled={selectedUser === ""}
                  showCount
                  autoSize={{ minRows: 4, maxRows: 10 }}
                  maxLength={999}
                />
              </Form.Item>
            </div>

            <Form.List
              name="agentesNocivos"
              forceRender
              rules={[
                {
                  validator: async (_, agentesNocivos) => {
                    if (agentesNocivos.length >= 999) {
                      setBlockAddAgenteNocivo(true);
                    }
                  },
                },
              ]}
            >
              {(agentesNocivos, { add, remove }) => {
                return (
                  <React.Fragment>
                    {agentesNocivos.map((agenteNocivo, index) => (
                      <div key={index}>
                        <HarmfulAgentCard
                          form={form}
                          harmfulAgentIndex={index}
                          harmfulAgentInfo={agenteNocivo}
                          selectedUser={selectedUser}
                          remove={remove}
                          harmfulAgentsArray={harmfulAgentsArray}
                          usedHarmfulAgents={usedHarmfulAgents}
                          setUsedHarmfulAgents={setUsedHarmfulAgents}
                          validateUsedHarmfulAgents={validateUsedHarmfulAgents}
                          handleSelectedHarmfulAgents={
                            handleSelectedHarmfulAgents
                          }
                          validateHarmfulAgentsArray={
                            validateHarmfulAgentsArray
                          }
                          agentesNocivos={agentesNocivos}
                          utilizaEpcEpi={utilizaEpcEpi}
                          setUtilizaEpcEpi={setUtilizaEpcEpi}
                          formMode={props.formMode}
                          blockAddAgenteNocivo={blockAddAgenteNocivo}
                        />
                      </div>
                    ))}
                    {!form
                      .getFieldValue("agentesNocivos")
                      ?.find(
                        (item) =>
                          item?.codigoTabela24 ===
                          harmfulAgentsAbsence.codigoTabela24
                      ) && (
                        <div className="esocial-exams-button-div">
                          <Tooltip
                            title={
                              blockAddAgenteNocivo
                                ? "O limite de 999 agentes nocivos foi atingido."
                                : null
                            }
                          >
                            <Button
                              ghost
                              className="add"
                              onClick={() => {
                                handleAddHarmfulAgent(add);
                              }}
                              disabled={
                                selectedUser === "" || blockAddAgenteNocivo
                              }
                            >
                              <IconSESI type="add-circle" theme="filled" />
                              Adicionar agente nocivo{" "}
                              <Tooltip title="Clique aqui, para escolher o próximo agente disponível que este trabalhador está exposto.">
                                <QuestionCircleOutlined />
                              </Tooltip>
                            </Button>
                          </Tooltip>
                        </div>
                      )}
                  </React.Fragment>
                );
              }}
            </Form.List>
            <Form.List
              name="responsaveisRegistroAmbiental"
              rules={[
                {
                  validator: async (_, responsaveisRegistroAmbiental) => {
                    if (responsaveisRegistroAmbiental.length >= 99) {
                      setBlockAddResp(true);
                    }
                  },
                },
              ]}
            >
              {(responsaveisRegistroAmbiental, { add, remove }) => {
                return (
                  <div>
                    {responsaveisRegistroAmbiental.map(
                      (responsavelRegistroAmbiental, index) => (
                        <React.Fragment>
                          <div className="esocial-form-card esocial-2240-form">
                            <span className="esocial-card-title">
                              Informações relativas ao responsável pelos
                              registros ambientais{" "}
                              <Tooltip title="Caso as informações tenham sido embasadas na Declaração de Inexistência de Riscos (DIR) cadastrada no gov.com, informe apenas o CPF do responsável pela empresa, dispensando as informações adicionais. Mas, se um LTCAT ou um PGR elaborado por um profissional técnico embasou as informações dos agentes nocivos, insira todos os dados deste(s) responsável(eis) nos campos abaixo. Use a opção “Adicionar responsável” para cadastrar mais que um responsável.">
                                <QuestionCircleOutlined />
                              </Tooltip>
                            </span>
                            <Form.Item
                              name={[index, "cpfResp"]}
                              className="side-label-form-item"
                              label="CPF do responsável pelos registros ambientais:"
                              rules={[
                                {
                                  required: true,
                                  message: "Informe o CPF do responsável.",
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (!value) {
                                      return Promise.resolve();
                                    }

                                    if (!validateCpf(value)) {
                                      return Promise.reject(
                                        new Error("CPF inválido!")
                                      );
                                    }

                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <MaskedInput
                                disabled={selectedUser === ""}
                                mask="111.111.111-11"
                                placeholder="000.000.000-00"
                                style={{ width: "9rem" }}
                              />
                            </Form.Item>
                            {!form
                              .getFieldValue("agentesNocivos")
                              ?.find(
                                (item) =>
                                  item?.codAgNoc ===
                                  harmfulAgentsAbsence.codigoTabela24
                              ) && (
                                <>
                                  <Form.Item
                                    name={[index, "ideOC"]}
                                    label="Órgão de classe ao qual o responsável pelos registros ambientais está vinculado:"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Selecione uma opção",
                                      },
                                    ]}
                                  >
                                    <Radio.Group
                                      disabled={selectedUser === ""}
                                      onChange={(e) =>
                                        setOrgaoClasse([
                                          ...orgaoClasse,
                                          e.target.value,
                                        ])
                                      }
                                      value={orgaoClasse}
                                    >
                                      <Space direction="vertical">
                                        <Radio key={1} value={"CRM"}>
                                          Conselho Regional de Medicina - CRM
                                        </Radio>
                                        <Radio key={4} value={"CREA"}>
                                          Conselho Regional de Engenharia e
                                          Agronomia - CREA
                                        </Radio>
                                        <Radio key={9} value={"OUTROS"}>
                                          Outros
                                        </Radio>
                                      </Space>
                                    </Radio.Group>
                                  </Form.Item>
                                  {form.getFieldValue(
                                    "responsaveisRegistroAmbiental"
                                  )[index]?.ideOC === "OUTROS" && (
                                      <Form.Item
                                        name={[index, "dscOc"]}
                                        label="Descrição (sigla) do órgão de classe ao qual o responsável pelos registros ambientais está vinculado:"
                                        rules={[
                                          {
                                            required:
                                              form.getFieldValue(
                                                "responsaveisRegistroAmbiental"
                                              )[index]?.ideOC === "OUTROS",
                                            message:
                                              "Informe a descrição do órgão de classe.",
                                          },
                                        ]}
                                      >
                                        <TextArea
                                          disabled={selectedUser === ""}
                                          showCount
                                          autoSize={{ minRows: 1, maxRows: 1 }}
                                          maxLength={20}
                                          style={{ width: "20rem" }}
                                        />
                                      </Form.Item>
                                    )}
                                  <Row gutter={24}>
                                    <Col span={12}>
                                      <Form.Item
                                        name={[index, "nrOC"]}
                                        label="Número de inscrição no órgão de classe:"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Informe o número de inscrição do órgão de classe.",
                                          },
                                        ]}
                                      >
                                        <Input
                                          disabled={selectedUser === ""}
                                          maxLength={14}
                                          style={{ width: "14rem" }}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                      <Form.Item
                                        name={[index, "ufOC"]}
                                        label="UF de expedição do órgão de classe:"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Selecione a UF da inscrição.",
                                          },
                                        ]}
                                      >
                                        <Select
                                          disabled={selectedUser === ""}
                                          style={{ width: "10rem" }}
                                        >
                                          {ufsComplete.map((uf) => {
                                            return (
                                              <Option
                                                key={uf.id}
                                                value={uf.value}
                                              >
                                                {uf.name}
                                              </Option>
                                            );
                                          })}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </>
                              )}
                            {responsaveisRegistroAmbiental.length > 1 && (
                              <div className="esocial-exams-button-div">
                                <Button
                                  ghost
                                  className="add"
                                  onClick={() => {
                                    remove(index);
                                  }}
                                  disabled={
                                    selectedUser === "" || blockAddAgenteNocivo
                                  }
                                >
                                  <IconSESI
                                    fill="white"
                                    type="trash-white"
                                    theme="filled"
                                  />
                                  Excluir responsável
                                  <Tooltip title="Clique aqui, para excluir este repsonsável cadastrado.">
                                    <QuestionCircleOutlined />
                                  </Tooltip>
                                </Button>
                              </div>
                            )}
                          </div>
                        </React.Fragment>
                      )
                    )}
                    <div className="esocial-exams-button-div">
                      <Button
                        ghost
                        className="add"
                        onClick={() => {
                          add();
                        }}
                        disabled={selectedUser === "" || blockAddResp}
                      >
                        <IconSESI type="add-circle" theme="filled" />
                        Adicionar responsável
                      </Button>
                    </div>
                  </div>
                );
              }}
            </Form.List>
            <div className="esocial-form-card esocial-2240-form">
              <span className="esocial-card-title">
                Observação(ões) complementar(es) referente(s) a registros
                ambientais
              </span>
              <Form.Item name={"obsCompl"}>
                <TextArea
                  disabled={selectedUser === ""}
                  showCount
                  autoSize={{ minRows: 4 }}
                  maxLength={999}
                />
              </Form.Item>
            </div>
            <Row className="esocial-submit-button-row">
              <Button htmlType="submit">Salvar</Button>
            </Row>
          </Form>
        </div>
      </Col>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    categoriasTrabalhadores: state.esocial.categoriasTrabalhadores
      ? state.esocial.categoriasTrabalhadores
      : [],
    funcionariosEsocial: state.esocial.funcionariosEsocial
      ? state.esocial.funcionariosEsocial
      : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        listarCategorias,
        listarFuncionariosEsocial,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ESocial2240Form);
