import React from "react";
import "./styles.scss";

const VirtualAssistantHeader = ({ menuItems, setMenuItems }) => {
  const handleChangeActiveItem = (item) => {
    setMenuItems((oldValue) =>
      oldValue.map((old) => ({
        ...old,
        active: old.id === item.id,
        options: old.options.map((option) => ({
          ...option,
          active: option.id === 1 && old.id === item.id,
          subOptions: option.subOptions
            ? option.subOptions.map((subOption) => ({
                ...subOption,
                active: false,
              }))
            : [],
        })),
      }))
    );
  };

  return (
    <div className="header-menu-items">
      {menuItems &&
        menuItems.map((item) => (
          <h4
            className={
              item.active ? "active header-item" : "default header-item"
            }
            key={item.id}
            onClick={() => handleChangeActiveItem(item)}
          >
            {item.name}
          </h4>
        ))}
    </div>
  );
};

export default VirtualAssistantHeader;
