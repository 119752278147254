import React, { useEffect, useState } from "react";
import { List, Tag, Row, Col } from "antd";
import { SETORES } from "../../../../../../../constants/stepTypes";
import FinishStep from "../../../../../../../components/FinishStep";
import DropdownActionMenu from "../../../../../../../components/DropdownActionMenu";
import SearchBar from "./SearchBar";
import { isPerfilCliente } from "../../../../../../../constants/userTypes";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const SetorList = (props) => {
  const [setores, setSetores] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    if (props.setores) {
      const setores = props.setores;
      setSetores(setores);
    }
  }, [JSON.stringify(props.setores)]);

  const updateState = () => {
    if (props.setores) {
      let setores = Object.values(props.setores).flat();
      return setores;
    }
  };

  const filterList = (event) => {
    const value = event.target.value;
    if (value.length > 2) {
      let filteredSetores = setores.filter((setor) => {
        return setor.nome.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      });
      setSetores(filteredSetores);
      setIsFiltered(true);
    } else {
      setSetores(Object.values(props.setores).flat());
      setIsFiltered(false);
    }
  };

  const handleOnCargo = (value) => {
    const setores = updateState();
    let filteredSetores = setores.filter((setor) => {
      let isCargo = false;
      setor.cargos.forEach((element) => {
        if (element === value) {
          isCargo = true;
        }
      });
      return isCargo;
    });

    if (filteredSetores.length > 0) {
      setSetores(filteredSetores);
      setIsFiltered(true);
    } else {
      setSetores([]);
      setIsFiltered(false);
    }
    if (value === undefined) {
      setSetores(updateState());
    }
  };

  const showFilters = () => {
    const size = setores.length;
    let show = false;
    if (isFiltered || size >= 5) {
      show = true;
    } else {
      show = false;
    }
    return show;
  };

  const emptyText = `Nenhum setor adicionado.`;

  const nomeCargos = props.setores
    .map((setor) => {
      return setor.cargos;
    })
    .flat();

  return (
    <>
      {showFilters() && (
        <SearchBar
          filterList={filterList}
          handleOnCargos={handleOnCargo}
          dataSelect={setores}
          cargos={nomeCargos}
          name={"setores"}
          selectCargoName={"cargo"}
        />
      )}
      <List
        itemLayout="horizontal"
        className="setores-list"
        dataSource={setores}
        locale={{ emptyText: emptyText }}
        footer={
          <Row type="flex" justify="space-around" align="middle">
            <Col span={12}>
              <p>
                Total de <b>{setores.length}</b>{" "}
                {setores.length === 1 ? "setor" : "setores"}.
              </p>
            </Col>
            {isPerfilCliente(props.perfil) ? (
              <Col span={12}>
                <FinishStep
                  completed={props.completed}
                  step={SETORES}
                  url="setores"
                  perfil={props.perfil}
                  disabled={
                    props.statusAnaliseTecnica === "PENDENTE" ||
                    props.statusAnaliseTecnica === "EM_ANALISE" ||
                    props.statusCaracterizacao === "PPRA_GERADO"
                  }
                />
              </Col>
            ) : (
              <Col span={12}></Col>
            )}
          </Row>
        }
        renderItem={(item, k) => (
          <List.Item key={k}>
            <List.Item.Meta
              onClick={() => props.edit(props.dispatch, item.id, true)}
              title={item.nome}
              description={
                <div className="tag-list">
                  {item.cargos.length === 0 ? (
                    <div className="ant-tag pendente">
                      <ExclamationCircleOutlined />
                      <span> Sem cargos vinculados</span>
                    </div>
                  ) : (
                    "Cargo(s): "
                  )}
                  {item.cargos.map((cargo, i) => (
                    <Tag key={i}>{cargo.replace(/['"]+/g, "")}</Tag>
                  ))}
                </div>
              }
            />
            {!props.viewOnly ? (
              <DropdownActionMenu
                dispatch={props.dispatch}
                entidade={SETORES}
                item={item}
                edit={props.edit}
                remove={props.remove}
              />
            ) : null}
          </List.Item>
        )}
      />
    </>
  );
};

export default SetorList;
