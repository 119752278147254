import axios from "axios";
import {
  empresasComPGREmitidoUrl,
  planilha1,
  planilhaX,
} from "../../../../../constants";
import { LISTAR_EMPRESAS_COM_PGR_EMITIDO } from "./types";

export const getEmpresasComPGREmitido = (dispatch) => {
  axios
    .get(empresasComPGREmitidoUrl)
    .then((response) => {
      dispatch({
        type: LISTAR_EMPRESAS_COM_PGR_EMITIDO,
        payload: response.data,
      });
    })
    .catch((err) => console.error(err));
};

export const generatePlanilha1 = (cnpj) => {
  axios({
    url: planilha1(cnpj),
    method: "GET",
    responseType: "blob",
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `PLANILHA1_${cnpj}.xls`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => console.error(err));
};

export const generatePlanilhaX = (cnpj) => {
  axios({
    url: planilhaX(cnpj),
    method: "GET",
    responseType: "blob",
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `PLANILHAX_${cnpj}.xls`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => console.error(err));
};
