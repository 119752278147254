import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SideBar from "../SideBar";
import Content from "../Content";
import { bindActionCreators } from "redux";
import { patchCaracterizationTalk } from "../../actions";
import { CARACTERIZACAO, CARACTERIZACAOKEYS } from "../../constants";

const CategorizacaoAssistenteVirtual = (props) => {
  const [currentTalk, setCurrentTalk] = useState({});

  useEffect(() => {
    props.talksCategorizacao.sort((a, b) => a.id - b.id);
    setCurrentTalk(props.talksCategorizacao[0]);
  }, [JSON.stringify(props.talksCategorizacao)]);

  const changeTalk = (key) => {
    setCurrentTalk(props.talksCategorizacao[CARACTERIZACAOKEYS[key] - 1]);
  };

  return (
    <div className="admin-assistente-virtual-wrapper">
      <div className="admin-assistente-virtual-lateral">
        <SideBar
          content={props.talksCategorizacao}
          changeTalk={changeTalk}
          category={CARACTERIZACAO}
        />
      </div>
      <div className="admin-assistente-virtual-falas">
        <Content
          currentTalk={currentTalk}
          dispatch={props.dispatch}
          patchCaracterizationTalk={patchCaracterizationTalk}
          category={CARACTERIZACAO}
          changeTalk={changeTalk}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    talksCategorizacao: state.gestorDN.virtualAssistant.categorizationTalks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ patchCaracterizationTalk }, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategorizacaoAssistenteVirtual);
