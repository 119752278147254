import {
  CPF_INVALIDO,
  NUMERO_MAXIMO,
  CPF_JA_CADASTRADO
} from "./errorMessagesTypes";

export const erroMessage = messageType => {
  switch (messageType) {
    case CPF_INVALIDO:
      return "O CPF informado não é válido!";
    case NUMERO_MAXIMO:
      return "O número máximo de trabalhadores ativos foi atingido!\nÉ necessário inativar um trabalhador para adicionar outro.";
    case CPF_JA_CADASTRADO:
      return "O CPF informado já foi cadastrado!";
    default:
      break;
  }
};
