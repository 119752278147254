import React, { Component, useEffect } from "react";
import Buttons from "../../../Buttons";
import { Row, Col, Button, Modal } from "antd";

class VirtualAssistant extends Component {
  state = { saibaMaisVisible: false, talk: {} };

  showSaibaMais = () => {
    this.setState({
      saibaMaisVisible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      saibaMaisVisible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      saibaMaisVisible: false,
    });
  };

  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

  componentDidMount() {
    if (this.props.talk) {
      this.setState({
        talk: this.props.talk,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.talk, this.getCircularReplacer()) !==
      JSON.stringify(prevProps.talk, this.getCircularReplacer())
    ) {
      this.setState({
        talk: this.props.talk,
      });
    }
  }

  render() {
    if (this.state.talk) {
      const {
        viewMode,
        assistant,
        assistantHelp,
        buttons,
        links,
        bolds,
        saibaMais,
        assistantStyle,
      } = this.state.talk;

      return (
        <>
          <div
            id="assistant"
            className={
              this.props.showBothAssistants
                ? `assistant-wrapper tabs ${
                    this.props.responsivo ? "responsivo" : "responsivo-hidden"
                  }`
                : `assistant-wrapper ${viewMode ? viewMode : "default"} ${
                    this.props.responsivo ? "responsivo" : "responsivo-hidden"
                  }`
            }
            style={{ innerHeight: `30px` }}
          >
            <div className="comet-bg">
              {assistantHelp === undefined ? null : (
                <div className="assistant assistent-help">
                  <img alt="Assistente Virtual" src={assistantHelp} />
                </div>
              )}
              {!assistantStyle ? (
                <div className="content">
                  {this.state.talk.text
                    ? this.state.talk.text.map((paragraph, i) => {
                        if (bolds && bolds.find((boldIndex) => boldIndex === i))
                          return (
                            <h5 key={i}>
                              <u>{paragraph}</u>
                            </h5>
                          );
                        else return <h5 key={i}>{paragraph}</h5>;
                      })
                    : null}
                </div>
              ) : (
                <div className="content primeiros-passos-talk">
                  {this.state.talk.text.map((paragraph, i) => {
                    if (bolds && bolds.find((boldIndex) => boldIndex === i))
                      return (
                        <h5 key={i}>
                          <u>{paragraph}</u>
                        </h5>
                      );
                    else return <h5 key={i}>{paragraph}</h5>;
                  })}
                </div>
              )}
              <div className="assistant">
                {viewMode === "default" && saibaMais !== undefined ? (
                  <Row type="flex" justify="space-around" align="middle">
                    <Col span={8}>
                      <img alt="Assistente Virtual" src={assistant} />
                    </Col>
                    <Col span={16} style={{ textAlign: "center" }}>
                      <Button ghost onClick={this.showSaibaMais}>
                        Saiba mais
                      </Button>
                      <Modal
                        visible={this.state.saibaMaisVisible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        centered={true}
                        cancelText="VOLTAR"
                        okText="CONTINUAR"
                        okButtonProps={{ type: "primary" }}
                      >
                        {saibaMais.map((paragraph, i) => (
                          <h5 key={i}>{paragraph}</h5>
                        ))}
                      </Modal>
                    </Col>
                  </Row>
                ) : (
                  <img alt="Assistente Virtual" src={assistant} />
                )}
              </div>
            </div>
            <Buttons buttons={buttons} links={links} />
          </div>
        </>
      );
    } else {
      return <h1>Carregando</h1>;
    }
  }
}

export default VirtualAssistant;
