import React from "react";
import Button from "./_/components/button.js";
import { Component } from "react";
import { connect } from "react-redux";
import { runAction, runCustomAction } from "./_/actions";
import { Link } from "react-router-dom";

class Buttons extends Component {
  dispatchActionType = (action_type, custom_action) => {
    this.props.runAction(action_type);
    if (custom_action) {
      this.props.runCustomAction(custom_action);
    }
  };

  render() {
    const { buttons, links, hideButton, disabled } = this.props;

    return (
      <div className="button-list">
        {links &&
          links.map((link, index) => {
            if (disabled) {
              return (
                <Button
                  className="ant-btn ant-btn-block"
                  key={index}
                  text={link.text}
                  block
                  disabled
                />
              );
            } else {
              return link.download ? (
                <a
                  href={link.href}
                  className="ant-btn ant-btn-block"
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  {link.text}
                </a>
              ) : (
                <Link
                  className="ant-btn ant-btn-block"
                  key={index}
                  to={link.href}
                >
                  {link.text}
                </Link>
              );
            }
          })}
        {buttons &&
          !hideButton &&
          buttons.map((button, index) => {
            if (disabled) {
              return (
                <Button
                  key={index}
                  text={button.text}
                  type={button.type}
                  block
                  disabled
                  onClick={() =>
                    this.dispatchActionType(
                      button.action_type,
                      button.custom_action
                    )
                  }
                />
              );
            } else {
              if (button.href) {
                return (
                  <Link
                    className="ant-btn ant-btn-block"
                    key={index}
                    to={button.href}
                    onClick={() =>
                      this.dispatchActionType(
                        button.action_type,
                        button.custom_action
                      )
                    }
                  >
                    {button.text}
                  </Link>
                );
              } else {
                return (
                  <Button
                    key={index}
                    text={button.text}
                    type={button.type}
                    ghost={button.ghost}
                    block
                    onClick={() =>
                      this.dispatchActionType(
                        button.action_type,
                        button.custom_action
                      )
                    }
                  />
                );
              }
            }
          })}
      </div>
    );
  }
}

export default connect(null, { runAction, runCustomAction })(Buttons);
