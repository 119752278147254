import React, { useEffect, useState } from "react";
import CompanyExportCard from "./components/CompanyExportCard";
import "./styles.scss";
import { Col, Row } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { getEmpresasComPGREmitido } from "./actions";
import SearchBar from "./components/SearchBar";

const ExportacaoDeDadosDR = (props) => {
  const [empresasComPGREmitido, setEmpresasComPGREmitido] = useState([]);

  useEffect(() => {
    getEmpresasComPGREmitido(props.dispatch);
  }, []);

  useEffect(() => {
    setEmpresasComPGREmitido(props.empresasComPGREmitido.empresasComPGREmitido);
  }, [JSON.stringify(props.empresasComPGREmitido)]);

  const filterList = (event) => {
    const value = event.target.value;
    if (value.length > 2) {
      let filteredEmpresasComPGREmitido = empresasComPGREmitido.filter(
        (company) => {
          return (
            company.nomeFantasia.toLowerCase().indexOf(value.toLowerCase()) !==
            -1
          );
        }
      );
      setEmpresasComPGREmitido(filteredEmpresasComPGREmitido);
    } else {
      setEmpresasComPGREmitido(
        props.empresasComPGREmitido.empresasComPGREmitido
      );
    }
  };

  return (
    <React.Fragment>
      <div className="gestor-dr-header">
        <Row gutter={24}>
          <Col span={16}>
            <h1>Olá, {props.userInfo.nome}!</h1>
            <span>{moment().format("DD/MM/YYYY")}</span>
            <div className="gestor-dr-filters"></div>
          </Col>
        </Row>
        <Row>
          <SearchBar
            filterList={filterList}
            name="empresas por nome fantasia"
          />
        </Row>
      </div>
      <div className="company-list-gestor-dr">
        {empresasComPGREmitido.length > 0
          ? empresasComPGREmitido.map((empresa) => (
              <CompanyExportCard company={empresa} />
            ))
          : null}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.userInfo,
    empresasComPGREmitido: state.gestorDR.empresasComPGREmitido,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ getEmpresasComPGREmitido }, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportacaoDeDadosDR);
