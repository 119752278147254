import {
  fatorDeRiscoEditURL,
  incluirFuncionarios,
  setoresUrl,
  cargosUrl,
  funcionariosUrl,
  listarProcesso
} from "../../../../../../../constants";
import axios from "axios";
import { setupInterceptors } from "../../../../../../../auth/SetupInterceptors";
import openNotification from "../../../../../../../components/notifications";
import { getInventarioDeRisco } from "../../../../../../InventarioRiscos/_/actions";
setupInterceptors();

export const addRealizadorProcessos = async (
  realizadorProcessos,
  dispatch
) => {
  await axios
    .post(`${incluirFuncionarios}`, realizadorProcessos)
    .then(() => {
      openNotification(
        "As associações foram realizadas com sucesso!",
        "",
        "success"
      );
      getInventarioDeRisco(dispatch);
    })
    .catch(() => {
      openNotification(
        "Erro ao tentar realizar as associações!",
        "Tente novamente",
        "error"
      );
    });
};

export const getRealizadorProcesso = async (idProcesso) => {
  let response = await axios.get(`${listarProcesso}`)
    .then((response) => {
      let selectedProccess = response.data.find(item => item.idProcesso === idProcesso)

      return selectedProccess
    })
    .catch((error) => {
      return null
    })

  return response
}

export const getSectors = async () => {
  let response = await axios
    .get(setoresUrl)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      // console.log(res);
      return res.data;
    })
    .catch((erro) => {
      // console.log(erro);
      return [];
    });
  return response;
};

export const getJobTitles = async () => {
  let response = await axios
    .get(cargosUrl)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      // console.log(res);
      return res.data;
    })
    .catch((erro) => {
      // console.log(erro);
      return [];
    });
  return response;
};

export const getEmployees = async () => {
  let response = await axios
    .get(funcionariosUrl)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      return res.data;
    })
    .catch((erro) => {
      // console.log(erro);
      return [];
    });
  return response;
};

// const updateRisco = (id, frequency, time) => {
//   const values = {
//     frequencia: parseInt(frequency),
//     tempoDeExposicao: parseInt(time),
//     percepcaoIntensidade: 2,
//     acidenteRegistrado: 1,
//     epcs: [],
//     epis: [],
//     maos: [],
//   };
//   return axios.patch(fatorDeRiscoEditURL(id), values);
// };
