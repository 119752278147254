import React, { Component, useState } from "react";
import { Card } from "antd";
import { Link } from "react-router-dom";
import IconSESI from "../../../../../../../components/IconsSESI";

const ExportacaoDeDadosCard = (props) => {
  return (
    <Link to={"/exportacao-dados"}>
      <Card bordered={false} className="small-card">
        <div className="header">
          <div>
            <h3>Exportação de dados</h3>
            <h3>Planilha Modelo 1</h3>
            <h3>Planilha Modelo X</h3>
          </div>
          <IconSESI type="transfer-right" />
        </div>
      </Card>
    </Link>
  );
};

export default ExportacaoDeDadosCard;
