import React from "react";
import { Dropdown, Menu } from "antd";
import IconSESI from "../IconsSESI";
import Button from "../Buttons/_/components/button";
import OnConfirm from "../OnConfirm";
import { EllipsisOutlined } from "@ant-design/icons";

const DropdownActionMenu = (props) => {
  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item
            key={props.item.id + 1}
            className="ant-dropdown-menu-item"
            onClick={() => props.edit(props.dispatch, props.item.id)}
          >
            <IconSESI type="editItem" /> Editar
          </Menu.Item>
          {props.remove ? (
            <React.Fragment>
              <Menu.Divider />
              <Menu.Item
                key={props.item.id}
                className="ant-dropdown-menu-item"
                onClick={() => {
                  OnConfirm(
                    props.dispatch,
                    props.remove,
                    props.item.id,
                    `Você tem certeza que deseja excluir o(a) ${props.entidade.slice(
                      0,
                      -2
                    )} ${props.item.nome}? `
                  );
                }}
              >
                <IconSESI type="trash" /> Excluir
              </Menu.Item>
            </React.Fragment>
          ) : (
            ""
          )}
        </Menu>
      }
      trigger={["click"]}
      placement="bottomRight"
    >
      <Button
        ghost
        className="action-button"
        text={<EllipsisOutlined />}
        type="link"
      />
    </Dropdown>
  );
};

export default DropdownActionMenu;
