import {
  LISTAR_EPC_EPI_MAO,
  LISTAR_INVENTARIO_DE_RISCO,
  LISTAR_INVENTARIO_DE_RISCO_DO_PROCESSO,
  REMOVER_INVENTARIO_DE_RISCO,
} from "../actions/types";

export const inventarioDeRiscosReducer = (
  state = {
    inventarioDeRiscos: [],
    epi: [],
    epc: [],
    mao: [],
  },
  action
) => {
  switch (action.type) {
    case LISTAR_INVENTARIO_DE_RISCO:
      return {
        ...state,
        inventarioDeRiscos: action.payload,
      };
    case LISTAR_INVENTARIO_DE_RISCO_DO_PROCESSO:
      return {
        ...state,
        inventarioDeRiscosDoProcesso: action.payload,
      };
    case LISTAR_EPC_EPI_MAO:
      return {
        ...state,
        epi: action.payload.epis,
        epc: action.payload.epcs,
        mao: action.payload.maos,
      };
    case REMOVER_INVENTARIO_DE_RISCO:
      return {
        ...state,
        inventarioDeRiscosDoProcesso: {
          ...state.inventarioDeRiscosDoProcesso,
          inventarios: [
            ...state.inventarioDeRiscosDoProcesso.inventarios,
          ].filter((risco) => risco.id !== action.payload),
        },
      };
    default:
      return state;
  }
};
