import React from "react";
import { Col, Input } from "antd";

const SearchBar = (props) => {
  const { filterList, name } = props;

  return (
    <Col lg={6} md={6}>
      <Input placeholder={`Pesquisar ${name}`} onChange={filterList} />
    </Col>
  );
};

export default SearchBar;
