import React from "react";
import { Col } from "antd";

const Itens = (props) => {
  return (
    <React.Fragment>
      <Col
        span={23}
        className={
          props.selected
            ? "lista-informacoes-descricao-selected"
            : "lista-informacoes-descricao"
        }
      >
        <h5>{props.description}</h5>
      </Col>
    </React.Fragment>
  );
};

export default Itens;
