import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import { getAssistantTalks } from "../actions/index";
import { AllConstants } from "../constants/index";
import { MaskedInput } from "antd-mask-input";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { openAssistantModal } from "../../../../components/AssistantModalHeader";
import { HeaderWithModal } from "../../../../components/HeaderWithModal/index";
import { eSocialConfirmWorkerChange } from "../../../../components/modals";
import openNotification from "../../../../components/notifications";
import cities from "../../../../util/cities.json";
import { UfList } from "../../../../util/esocialData";
import { validateCpf } from "../../../../util/methods";
import {
  getCepInformation,
  listarCategorias,
  listarExames,
  listarFuncionariosEsocial,
  post2210,
  update2210,
} from "../actions";
import { validateCAEPF, validateCNO, validateCNPJ } from "../utils";

const { Option } = Select;

const Esocial2010Form = (props) => {
  const [form] = Form.useForm();
  const [hasMatricula, setHasMatricula] = useState(true);
  const [selectedTest, setSelectedTest] = useState([]);
  const [assistantTalk, setAssistantTalk] = useState([]);
  const [deathSelectDisabled, setDeathSelectDisabled] = useState(false);
  const [accidentTimeDisabled, setAccidentTimeDisabled] = useState(false);
  const [accidentTimeIsRequired, setAccidentTimeIsRequired] = useState(false);
  const [workedTimeDisabled, setWorkedTimeDisabled] = useState(false);
  const [workedTimeIsRequired, setWorkedTimeIsRequired] = useState(false);
  const [deathDateDisabled, setDeathDateDisabled] = useState(false);
  const [lastDayWorkedIsRequired, setLastDayWorkedIsRequired] = useState(false);
  const [zipcodeIsRequired, setZipcodeIsRequired] = useState(false);
  const [zipcodedisabled, setZipcodedisabled] = useState(false);
  const [ufIsRequired, setUfIsRequired] = useState(false);
  const [countryIsRequired, setCountryIsRequired] = useState(false);
  const [selectedUf, setSelectedUf] = useState("");
  const [ufOcIsRequired, setUfOcIsRequired] = useState(false);
  const [tpInscLocalAcid, setTpInscLocalAcid] = useState("");
  const [noStreetNumber, setNoStreetNumber] = useState(false);

  const clearFields = () => {
    setHasMatricula(true);
    setSelectedTest([]);
    setDeathSelectDisabled(false);
    setAccidentTimeDisabled(false);
    setAccidentTimeIsRequired(false);
    setWorkedTimeDisabled(false);
    setWorkedTimeIsRequired(false);
    setDeathDateDisabled(false);
    setLastDayWorkedIsRequired(false);
    setZipcodeIsRequired(false);
    setZipcodedisabled(false);
    setUfIsRequired(false);
    setCountryIsRequired(false);
    setSelectedUf("");
    setUfOcIsRequired(false);
  };

  useEffect(() => {
    listarCategorias(props.dispatch);
    listarExames(props.dispatch);
    listarFuncionariosEsocial(props.dispatch);
    getDigitalSignatureAssistantTalks()
  }, []);

  useEffect(() => {
    if (props.activeTab !== "6") {
      return;
    }

    if (props.formMode === "new") setEventWasSent(false);

    setSelectedUser("");
    if (
      props.formMode === "rectification" ||
      props.formMode === "increment" ||
      props.formMode === "update"
    ) {
      iniciarModoEdicao(props.eventoDuplicado);
    }
  }, [JSON.stringify(props.formMode), props.activeTab]);

  const onCheck = () => {
    setHasMatricula(!hasMatricula);
    listarCategorias(props.dispatch);
  };

  const [eventWasSent, setEventWasSent] = useState(false);

  const iniciarModoEdicao = (evento) => {
    setEventWasSent(evento.enviado);
    setSelectedUf(evento.uf);
    setSelectedUser(evento.cpfTrab);
    setTpInscLocalAcid(evento.tpInscLocalAcid);

    form.setFieldsValue({
      trabalhador:
        props.selectedCompany.autodeclaracao && props.funcionariosEsocial[0]
          ? evento.cpfTrab
          : undefined,
      nomeTrab: props.selectedCompany.autodeclaracao ? evento.nomeTrab : "",
      cpfTrab: props.selectedCompany.autodeclaracao
        ? evento.cpfTrab
        : undefined,
      matricula: evento.matricula,
      codCateg: evento.codCateg,
      tpCat: evento.tpCat,
      dtAcid: moment(evento.dtAcid),
      nrRecCatOrig: evento.nrRecCatOrig ? evento.nrRecCatOrig : undefined,
      indCatObito: evento.indCatObito ? "sim" : "nao",
      dtObito: moment(evento.dtObito),
      tpAcid: evento.tpAcid,
      hrAcid: moment(`2023-02-23 ${evento.hrAcid}`),
      hrsTrabAntesAcid: evento.hrsTrabAntesAcid,
      indComunPolicia: evento.indComunPolicia ? "sim" : "nao",
      codSitGeradora: evento.codSitGeradora,
      iniciatCat: evento.iniciatCat,
      ultDiaTrab: moment(evento.ultDiaTrab),
      houveAfast: evento.houveAfast,
      obsCat: evento.obsCat ? evento.obsCat : undefined,
      tpLocal: evento.tpLocal,
      dscLocal: evento.dscLocal ? evento.dscLocal : undefined,
      tpLograd: evento.tpLograd ? evento.tpLograd : undefined,
      dscLograd: evento.dscLograd,
      nrLograd: evento.nrLograd,
      complemento: evento.complemento ? evento.complemento : undefined,
      bairro: evento.bairro ? evento.bairro : undefined,
      cep: evento.cep ? String(evento.cep) : undefined,
      codMunic: String(evento.codMunic),
      uf: evento.uf,
      pais: evento.pais ? evento.pais : undefined,
      codPostal: evento.codPostal ? evento.codPostal : undefined,
      tpInscLocalAcid: evento.tpInscLocalAcid,
      nrInscLocalAcid: evento.nrInscLocalAcid,
      codParteAting: evento.codParteAting,
      lateralidade: evento.lateralidade,
      codAgntCausador: evento.codAgntCausador,
      dtAtendimento: moment(evento.dtAtendimento),
      hrAtendimento: moment(`2023-02-23 ${evento.hrAtendimento}`),
      indInternecao: evento.indInternecao ? "sim" : "nao",
      durTrat: String(evento.durTrat),
      indAfast: evento.indAfast ? "sim" : "nao",
      dscLesao: evento.dscLesao,
      dscCompLesao: evento.dscCompLesao ? evento.dscCompLesao : undefined,
      diagProvavel: evento.diagProvavel ? evento.diagProvavel : undefined,
      codCid: evento.codCid,
      observacao: evento.observacao ? evento.observacao : undefined,
      nmEmit: evento.nmEmit,
      orgaoClasse: evento.orgaoClasse,
      nrOc: String(evento.nrOc),
      ufOc: evento.ufOc ? evento.ufOc : undefined,
    });

    if (
      form.getFieldValue("tpLocal") ===
        "ESTABELECIMENTO_DO_EMPREGADOR_NO_BRASIL" ||
      form.getFieldValue("tpLocal") === "ESTABELECIMENTO_DE_TERCEIROS" ||
      form.getFieldValue("tpLocal") === "AREA_RURAL"
    ) {
      setUfIsRequired(true);
    }
  };

  const handleSubmit = async () => {
    form.validateFields().then(async (values) => {
      let selectedWorker = props.funcionariosEsocial.find(
        (item) => item.cpf === values.trabalhador
      );

      let indRetif = "";

      switch (props.formMode) {
        case "new":
          indRetif = "ORIGINAL";
          break;

        case "increment":
          indRetif = "ORIGINAL";
          break;

        default:
          if (props.eventoDuplicado.nrRecibo) {
            indRetif = "RETIFICACAO";
          } else {
            indRetif = "ORIGINAL";
          }
      }

      let new2210 = {
        indRetif,
        nrRecibo: "",
        nrInsc: props.selectedCompany.cnpj.slice(0, 8),
        nomeTrab:
          props.selectedCompany.autodeclaracao === true &&
          !props.funcionariosEsocial[0]
            ? values.nomeTrab
            : selectedWorker.nome,
        cpfTrab:
          props.selectedCompany.autodeclaracao === true &&
          !props.funcionariosEsocial[0]
            ? values.cpfTrab.replace(/\D/g, "")
            : selectedWorker.cpf.replace(/\D/g, ""),
        matricula: values.matricula ? values.matricula : "",
        codCateg: values.codCateg ? values.codCateg.toString() : "",
        tpCat: values.tpCat,
        dtAcid: moment(values.dtAcid).format("YYYY-MM-DD"),
        nrRecCatOrig: values.nrRecCatOrig ? values.nrRecCatOrig : "",
        indCatObito: values.indCatObito === "sim" ? 1 : 0,
        dtObito:
          values.indCatObito === "sim"
            ? moment(values.dtObito).format("YYYY-MM-DD")
            : null,
        tpAcid: values.tpAcid,
        hrAcid: moment(values.hrAcid).format("HH:mm"),
        hrsTrabAntesAcid: values.hrsTrabAntesAcid,
        indComunPolicia: values.indComunPolicia === "sim" ? 1 : 0,
        codSitGeradora: values.codSitGeradora,
        iniciatCat: values.iniciatCat,
        ultDiaTrab: moment(values.ultDiaTrab).format("YYYY-MM-DD"),
        houveAfast: values.houveAfast,
        obsCat: values.obsCat ? values.obsCat : "",
        tpLocal: values.tpLocal,
        dscLocal: values.dscLocal ? values.dscLocal : "",
        tpLograd: values.tpLograd ? values.tpLograd : 0,
        dscLograd: values.dscLograd,
        nrLograd:
          values.nrLograd && !noStreetNumber
            ? values.nrLograd.replace(/\D/g, "")
            : "",
        complemento: values.complemento ? values.complemento : "",
        bairro: values.bairro ? values.bairro : "",
        cep: values.cep ? Number(values.cep.replace(/\D/g, "")) : 0,
        codMunic: Number(values.codMunic),
        uf: values.uf,
        pais: values.pais ? values.pais : "",
        codPostal: values.codPostal ? values.codPostal : "",
        tpInscLocalAcid: values.tpInscLocalAcid,
        nrInscLocalAcid: String(values.nrInscLocalAcid.replace(/\D/g, "")),
        codParteAting: Number(values.codParteAting),
        lateralidade: values.lateralidade,
        codAgntCausador: Number(values.codAgntCausador),
        dtAtendimento: moment(values.dtAtendimento).format("YYYY-MM-DD"),
        hrAtendimento: moment(values.hrAtendimento).format("HH:mm"),
        indInternecao: values.indInternecao === "sim" ? 1 : 0,
        durTrat: Number(values.durTrat),
        indAfast: values.indAfast === "sim" ? 1 : 0,
        dscLesao: values.dscLesao,
        dscCompLesao: values.dscCompLesao ? values.dscCompLesao : "",
        diagProvavel: values.diagProvavel ? values.diagProvavel : "",
        codCid: values.codCid,
        observacao: values.observacao ? values.observacao : "",
        nmEmit: values.nmEmit,
        orgaoClasse: values.orgaoClasse,
        nrOc: values.nrOc,
        ufOc: values.ufOc ? values.ufOc : "",
      };

      if (props.formMode === "rectification" || props.formMode === "update") {
        new2210.id = props.eventoDuplicado.id;
        new2210.nrRecibo = props.eventoDuplicado.nrRecibo;
      }

      props.formMode === "new" || props.formMode === "increment"
        ? await post2210(
            new2210,
            form.resetFields,
            props.setFormMode,
            props.dispatch
          )
        : await update2210(
            new2210,
            form.resetFields,
            props.setFormMode,
            props.dispatch
          );

      // await listarEventos(props.dispatch);
      // props.listarEventos(props.dispatch);
    });
  };

  //Funções de validação
  const onChangeEvent = (name, event) => {
    if (event.target.value !== "CNPJ" && name === "tpLocal") {
      form.setFieldsValue({
        tpInscLocalAcid: "",
        nrInscLocalAcid: "",
      });
    }

    switch (event.target.value) {
      case "REABERTURA":
        form.setFieldsValue({
          indCatObito: "nao",
        });
        form.setFieldsValue({ dtObito: undefined });
        setDeathDateDisabled(false);
        setDeathSelectDisabled(true);
        break;
      case "OBITO":
        form.setFieldsValue({
          indCatObito: "sim",
        });
        form.setFieldsValue({
          indAfast: "nao",
        });
        form.setFieldsValue({ dtObito: undefined });
        setDeathDateDisabled(true);
        setDeathSelectDisabled(true);
        break;
      case "INICIAL":
        form.setFieldsValue({
          indCatObito: undefined,
        });
        form.setFieldsValue({ dtObito: undefined });
        setDeathDateDisabled(false);
        setDeathSelectDisabled(false);
      case "TIPICO":
        setAccidentTimeDisabled(false);
        setAccidentTimeIsRequired(true);
        setWorkedTimeIsRequired(true);
        setWorkedTimeDisabled(false);
        break;
      case "DOENÇA":
        setAccidentTimeDisabled(true);
        setAccidentTimeIsRequired(false);
        setWorkedTimeIsRequired(false);
        setWorkedTimeDisabled(true);
        break;
      case "TRAJETO":
        let accidentDate = form.getFieldValue("dtAcid");
        let accidentFlagDate = new Date("1/26/2022");
        let pathDate = new Date("07/20/2022");

        setAccidentTimeIsRequired(false);
        setAccidentTimeDisabled(false);
        setWorkedTimeIsRequired(false);
        setWorkedTimeDisabled(false);

        if (accidentDate) {
          let eventDate = new Date(accidentDate._d);
          setAccidentTimeIsRequired(eventDate >= accidentFlagDate);
          setWorkedTimeIsRequired(eventDate >= pathDate);
          return;
        }
        break;
      case "ESTABELECIMENTO_DO_EMPREGADOR_NO_BRASIL":
        setZipcodedisabled(false);
        setZipcodeIsRequired(true);
        setCountryIsRequired(false);
        setUfIsRequired(true);

        setTpInscLocalAcid("CNPJ");
        form.setFieldsValue({
          tpInscLocalAcid: "CNPJ",
          nrInscLocalAcid: props.selectedCompany.cnpj,
        });

        break;
      case "ESTABELECIMENTO_DO_EMPREGADOR_NO_EXTERIOR":
        setZipcodedisabled(true);
        setUfIsRequired(false);
        setCountryIsRequired(true);
        setZipcodeIsRequired(false);
        break;
      case "ESTABELECIMENTO_DE_TERCEIROS":
        setZipcodedisabled(false);
        setCountryIsRequired(false);
        setUfIsRequired(true);
        setZipcodeIsRequired(true);
        break;
      case "VIA_PUBLICA":
        setZipcodedisabled(false);
        setCountryIsRequired(false);
        setUfIsRequired(false);
        setZipcodeIsRequired(false);
        break;
      case "AREA_RURAL":
        setZipcodedisabled(false);
        setUfIsRequired(true);
        setCountryIsRequired(false);
        setZipcodeIsRequired(true);
        break;
      case "EMBARCACAO":
        setZipcodedisabled(false);
        setUfIsRequired(false);
        setZipcodeIsRequired(false);
        setCountryIsRequired(false);
        break;
      case "OUTROS":
        setZipcodedisabled(false);
        setUfIsRequired(false);
        setCountryIsRequired(false);
        setZipcodeIsRequired(false);
        break;
    }
  };

  const [selectedUser, setSelectedUser] = useState("");

  const validateChangeSelectedUser = (userCpf) => {
    if (selectedUser == "") {
      setSelectedUser(userCpf);
      fillRegistration(userCpf);
      return;
    }

    form.setFieldsValue({
      trabalhador: selectedUser,
    });

    eSocialConfirmWorkerChange(() => {
      form.resetFields();

      form.setFieldsValue({
        trabalhador: userCpf,
      });

      setSelectedUser(userCpf);
      fillRegistration(userCpf);
    });
  };

  const fillRegistration = (userCpf) => {
    props.funcionariosEsocial.forEach((funcionario) => {
      if (funcionario.cpf === userCpf) {
        form.setFieldsValue({
          matricula: funcionario.matricula,
        });
      }
    });
  };

  const handleChangeCEP = async (cep) => {
    if (cep.replace(/\D/g, "").length < 8) {
      return;
    }

    let data = await getCepInformation(cep.replace(/\D/g, ""));
    // console.log(response);
    form.setFieldsValue({
      dscLograd: data?.logradouro,
      bairro: data?.bairro,
      uf: data?.uf,
      codMunic: "",
    });

    setSelectedUf(data?.uf ?? "");
  };

  const handleVerifyingDeathAndRemoval = (name, value) => {
    if (name === "indCatObito") {
      form.setFieldsValue({
        indAfast: value && value === "sim" && "nao",
      });
      form.setFieldsValue({ dtObito: undefined });
      setDeathDateDisabled(value === "sim");
    }

    let deathFieldValue = form.getFieldValue("indCatObito");
    let removalFieldValue = form.getFieldValue("houveAfast");

    if (deathFieldValue === "nao") {
      form.setFieldsValue({
        indAfast: removalFieldValue === "SIM" ? "sim" : "nao",
      });
    }
  };

  const getDigitalSignatureAssistantTalks = async () => {
    const talks = await getAssistantTalks('s2210', 'Gerar 2210')
    setAssistantTalk(talks.descricao)
  }

  return (
    <React.Fragment>
      <Col lg={24} className="esocial-dashboard">
        <HeaderWithModal
          pageTitle="S-2210 - Comunicação de Acidente de trabalho"
          helpText="Clique aqui e saiba mais!"
          assistant={"vivi"}
          openModal={() =>
            openAssistantModal(assistantTalk, "vivi")
          }
          prevButton={() => {
            props.setActiveTab("2");
            props.setFormMode("new");
            clearFields();
            form.resetFields();
          }}
        />
        <div className="content">
          <Form form={form} onFinish={handleSubmit}>
            <div className="esocial-form-card">
              <span className="esocial-card-title">
                Identificação do trabalhador
              </span>
              {props.selectedCompany.autodeclaracao === true &&
              !props.funcionariosEsocial[0] ? (
                <Form.Item
                  label="Nome do trabalhador:"
                  name="nomeTrab"
                  rules={[
                    {
                      required: hasMatricula,
                      message:
                        "Informe a matrícula ou a categoria do trabalhador!",
                    },
                  ]}
                >
                  <Input
                    style={{ width: "90%", marginRight: "1rem" }}
                    disabled={
                      props.formMode === "rectification" ||
                      eventWasSent ||
                      !hasMatricula
                    }
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Selecione o trabalhador!",
                    },
                  ]}
                  label="Trabalhador::"
                  name="trabalhador"
                >
                  <Select
                    onChange={(value) => validateChangeSelectedUser(value)}
                    disabled={
                      props.formMode === "rectification" || eventWasSent
                    }
                  >
                    {props.funcionariosEsocial.map((funcionario) => {
                      return (
                        <Option key={funcionario.id} value={funcionario.cpf}>
                          {funcionario.nome}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}

              <div className="matricula-row">
                {props.selectedCompany.autodeclaracao === true &&
                !props.funcionariosEsocial[0] ? (
                  <Form.Item
                    label="CPF::"
                    name="cpfTrab"
                    rules={[
                      {
                        required: true,
                        message: "Informe o CPF!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.resolve();
                          }

                          if (!validateCpf(value)) {
                            return Promise.reject(new Error("CPF inválido!"));
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <MaskedInput
                      disabled={
                        props.formMode === "rectification" || eventWasSent
                      }
                      mask="111.111.111-11"
                    />
                  </Form.Item>
                ) : null}
                <Form.Item
                  label="Número de matrícula::"
                  name="matricula"
                  tooltip="A matrícula, é a chave de acesso do trabalhador no eSocial. Certifique-se de inserir a mesma matrícula informada pelo departamento pessoal no evento S-2200, referente ao vínculo empregatício do trabalhador com a empresa."
                  rules={[
                    {
                      required: hasMatricula,
                      message:
                        "Informe a matrícula ou a categoria do trabalhador!",
                    },
                  ]}
                >
                  <Input
                    style={{ width: "90%", marginRight: "1rem" }}
                    disabled={
                      props.formMode === "rectification" ||
                      eventWasSent ||
                      !hasMatricula
                    }
                  />
                </Form.Item>
                <Checkbox
                  disabled={props.formMode === "rectification" || eventWasSent}
                  onChange={() => onCheck()}
                >
                  Não tem matrícula{" "}
                  <Tooltip title="Somente utilizar “Não tem matrícula”, se o trabalhador estiver enquadrado na lista abaixo “categoria do trabalhador”. Caso contrário, consulte o número da matrícula informada no evento S-2200 relacionado aos dados do departamento pessoal, enviado anteriormente a este evento de SST">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Checkbox>
              </div>
              {!hasMatricula && (
                <Form.Item
                  rules={[
                    {
                      required: !hasMatricula,
                      message: "Informe a categoria do trabalhador!",
                    },
                  ]}
                  label="Categoria do Trabalhador::"
                  name="codCateg"
                >
                  <Select
                    disabled={
                      props.formMode === "rectification" || eventWasSent
                    }
                  >
                    {props.categoriasTrabalhadores.map((categoria) => {
                      return (
                        <Option value={categoria.id}>
                          {categoria.descricao}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}
            </div>

            <div className="esocial-form-card">
              <span className="esocial-card-title">
                Comunicação de Acidente de Trabalho – CAT
              </span>
              <Form.Item
                label="Tipo de Comunicação de Acidente de Trabalho::"
                name="tpCat"
                tooltip={
                  <div>
                    Indique um dos tipos de CAT:
                    <br />
                    - Inicial: Use esta opção para o primeiro registro de um
                    acidente de trabalho, seja ele típico, trajeto, doença
                    profissional, doença do trabalho ou óbito imediato.
                    <br />
                    - Reabertura: Use esta opção se houver uma piora na saúde do
                    acidentado ou se o tratamento for reiniciado, referente a
                    uma CAT já aberta.
                    <br />
                    - Comunicação de óbito: Use esta opção caso o trabalhador
                    venha a falecer após a abertura da CAT inicial.
                    <br />
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message:
                      "Informe o tipo de Comunicação de Acidente de Trabalho!",
                  },
                ]}
              >
                <Radio.Group
                  disabled={selectedUser === ""}
                  onChange={(value) => {
                    onChangeEvent("tpCat", value);
                  }}
                >
                  <Radio value="INICIAL">Inicial</Radio>
                  <Radio value="REABERTURA">Reabertura</Radio>
                  <Radio value="OBITO">Comunicação de óbito</Radio>
                </Radio.Group>
              </Form.Item>
              <Row className="esocial-doctor-info">
                {deathSelectDisabled && (
                  <Form.Item
                    label="Número do recibo da última CAT::"
                    name="nrRecCatOrig"
                  >
                    <Input
                      type="number"
                      placeholder="0000000000"
                      disabled={selectedUser === ""}
                    />
                  </Form.Item>
                )}

                <Form.Item
                  label="Data do acidente::"
                  name="dtAcid"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.resolve();
                        }

                        let currentDate = new Date();
                        let eventDate = new Date(value._d);
                        let startDate = new Date("1/10/2022");
                        let flagDate = new Date("1/26/2022");
                        let pathDate = new Date("07/20/2022");
                        let accidentDate = form.getFieldValue("tpAcid");

                        if (accidentDate && accidentDate == "TRAJETO") {
                          setAccidentTimeIsRequired(eventDate >= flagDate);
                          setWorkedTimeIsRequired(eventDate >= pathDate);
                        }

                        if (eventDate < startDate) {
                          return Promise.reject(
                            new Error(
                              "Deve ser posterior à data de início da obrigatoriedade deste evento para o empregador no eSocial (10/01/2022)!"
                            )
                          );
                        }
                        if (currentDate >= eventDate) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Deve ser igual ou anterior à data atual!")
                        );
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    disabled={selectedUser === ""}
                    format={"DD/MM/YYYY"}
                    onChange={(value) => {
                      if (!value) {
                        setLastDayWorkedIsRequired(false);
                        return;
                      }

                      let eventDate = new Date(value._d);
                      let flagDate = new Date("1/16/2023");
                      setLastDayWorkedIsRequired(eventDate >= flagDate);
                    }}
                  />
                </Form.Item>
              </Row>

              <Row className="esocial-doctor-info">
                <Form.Item
                  label="Tipo de acidente de trabalho::"
                  name="tpAcid"
                  tooltip={`
                      Típico: são aqueles que ocorrem no local de trabalho (nas dependências da empresa), ou fora dele a serviço da empresa 
                      Doença:  a adquirida ou desencadeada em função de condições especiais em que o trabalho é realizado e com ele se relacione diretamente 
                      Trajeto:  ocorre fora do local de trabalho e horário de trabalho, durante o deslocamento de casa para o trabalho ou do trabalho para casa e vice-versa, porém, no trajeto feito habitualmente pelo trabalhador
                    `}
                  rules={[
                    {
                      required: true,
                      message: "Informe o tipo de Acidente de Trabalho!",
                    },
                  ]}
                >
                  <Radio.Group
                    disabled={selectedUser === ""}
                    onChange={(value) => {
                      onChangeEvent("tpAcid", value);
                    }}
                  >
                    <Radio value="TIPICO">Típico</Radio>
                    <Radio value="DOENÇA">Doença</Radio>
                    <Radio value="TRAJETO">Trajeto</Radio>
                  </Radio.Group>
                </Form.Item>
                {!accidentTimeDisabled && (
                  <Form.Item
                    label="Hora do acidente::"
                    name="hrAcid"
                    rules={[
                      {
                        required: accidentTimeIsRequired,
                        message: "Informe a hora do acidente!",
                      },
                    ]}
                  >
                    <DatePicker
                      format={"HH:mm"}
                      picker="time"
                      disabled={selectedUser === ""}
                    />
                  </Form.Item>
                )}
              </Row>
              {!workedTimeDisabled && (
                <Row className="esocial-doctor-info">
                  <Form.Item
                    label="Horas trabalhadas antes da ocorrência do acidente::"
                    name="hrsTrabAntesAcid"
                    rules={[
                      {
                        required: workedTimeIsRequired,
                        message:
                          "Informe as horas trabalhadas antes da ocorrência do acidente!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.resolve();
                          }

                          if (value.replace(/\D/g, "").length !== 4) {
                            return Promise.reject(
                              new Error("Horas inválidas!")
                            );
                          }

                          let time = value.replace("_", "").split(":");

                          if (Number(time[1]) > 59) {
                            return Promise.reject(
                              new Error("Horas inválidas!")
                            );
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <MaskedInput
                      placeholder="HH:MM"
                      mask="11:11"
                      disabled={selectedUser === ""}
                    />
                  </Form.Item>
                </Row>
              )}

              <Row className="esocial-doctor-info">
                <Form.Item
                  label="Houve óbito?"
                  name="indCatObito"
                  rules={[
                    {
                      required: true,
                      message: "Informe se houve óbito!",
                    },
                  ]}
                >
                  <Select
                    disabled={selectedUser === "" || deathSelectDisabled}
                    placeholder="Selecione"
                    onSelect={(value) => {
                      handleVerifyingDeathAndRemoval("indCatObito", value);
                    }}
                  >
                    <Option value="sim">Sim</Option>
                    <Option value="nao">Não</Option>
                  </Select>
                </Form.Item>
                {deathDateDisabled && (
                  <Form.Item
                    label="Data do óbito::"
                    name="dtObito"
                    rules={[
                      {
                        required: true,
                        message: "Informe a data do óbito!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.resolve();
                          }

                          let currentDate = new Date();
                          let deathDate = new Date(value._d);
                          let accidentDate = form.getFieldValue("dtAcid");

                          if (!accidentDate) {
                            return Promise.reject(
                              new Error("Informe primeiro a data do acidente!")
                            );
                          }

                          let accidentDateFormatted = new Date(accidentDate._d);

                          if (
                            deathDate <= currentDate &&
                            accidentDateFormatted <= deathDate
                          ) {
                            return Promise.resolve();
                          }

                          return Promise.reject(new Error("Data inválida!"));
                        },
                      }),
                    ]}
                  >
                    <DatePicker
                      format={"DD/MM/YYYY"}
                      disabled={deathSelectDisabled}
                    />
                  </Form.Item>
                )}

                <Form.Item
                  label="Houve a comunicação à autoridade policial?"
                  name="indComunPolicia"
                  rules={[
                    {
                      required: true,
                      message:
                        "Informe se houve a comunicação à autoridade policial!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Selecione"
                    disabled={selectedUser === ""}
                  >
                    <Option value="sim">Sim</Option>
                    <Option value="nao">Não</Option>
                  </Select>
                </Form.Item>
              </Row>

              <Form.Item
                label="Situação causadora do acidente::"
                name="codSitGeradora"
                tooltip="Busque/digite por código ou nome, neste campo, você precisa saber o que aconteceu para causar o acidente, a partir da lista apresentada abaixo."
                rules={[
                  {
                    required: true,
                    message:
                      "Informe o código da situação geradora do acidente ou da doença profissional!",
                  },
                ]}
              >
                <Select
                  disabled={selectedUser === ""}
                  showSearch
                  showArrow={false}
                  allowClear
                  optionFilterProp="children"
                  filterOptions={(input, option) =>
                    (option.children[2] ?? "").includes(input)
                  }
                >
                  {props.situacoesGeradoras.map((situacao) => {
                    return (
                      <Option key={situacao.id} value={situacao.id}>
                        {situacao.id} - {situacao.descricao}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Iniciativa da CAT::"
                name="iniciatCat"
                initialValue="EMPREGADOR"
                rules={[
                  {
                    required: true,
                    message: "Informe a iniciativa da CAT!",
                  },
                ]}
              >
                <Radio.Group
                  defaultValue="EMPREGADOR"
                  disabled={selectedUser === ""}
                >
                  <Radio value="EMPREGADOR">Empregador</Radio>
                  <Radio value="ORDEM_JUDICIAL">Ordem judicial</Radio>
                  <Radio value="DETERMINACAO_DE_ORGAO_FISCALIZADOR">
                    Determinação de órgão fiscalizador
                  </Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label="Observações::"
                name="obsCat"
                tooltip="Campo opcional: Insira aqui observações relevantes sobre o acidente, se desejar."
              >
                <Input.TextArea
                  disabled={selectedUser === ""}
                  showCount
                  maxLength="999"
                  placeholder="Campo opcional: Insira aqui observações relevantes sobre o acidente, se desejar."
                />
              </Form.Item>

              <Row className="esocial-doctor-info">
                <Form.Item
                  label="Último dia trabalhado::"
                  name="ultDiaTrab"
                  rules={[
                    {
                      required: lastDayWorkedIsRequired,
                      message: "Informe o último dia trabalhado!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.resolve();
                        }

                        let currentDate = new Date();
                        let eventDate = new Date(value._d);

                        if (currentDate >= eventDate) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Deve ser igual ou anterior à data atual!")
                        );
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    format={"DD/MM/YYYY"}
                    disabled={selectedUser === ""}
                  />
                </Form.Item>
                <Form.Item
                  label="Houve afastamento?"
                  name="houveAfast"
                  rules={[
                    {
                      required: lastDayWorkedIsRequired,
                      message: "Informe se houve afastamento!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Selecione"
                    disabled={selectedUser === ""}
                    onSelect={(value) =>
                      handleVerifyingDeathAndRemoval("houveAfast", value)
                    }
                  >
                    <Option value="SIM">Sim</Option>
                    <Option value="NAO">Não</Option>
                  </Select>
                </Form.Item>
              </Row>
            </div>

            <div className="esocial-form-card">
              <span className="esocial-card-title">Local do acidente</span>
              <Form.Item
                label="Tipo do local de acidente::"
                name="tpLocal"
                rules={[
                  {
                    required: true,
                    message: "Informe o tipo do local de acidente!",
                  },
                ]}
              >
                <Radio.Group
                  disabled={selectedUser === ""}
                  onChange={(value) => {
                    onChangeEvent("tpLocal", value);
                  }}
                >
                  <Space direction="vertical">
                    <Radio value="ESTABELECIMENTO_DO_EMPREGADOR_NO_BRASIL">
                      Estabelecimento do empregador no Brasil
                    </Radio>
                    <Radio value="ESTABELECIMENTO_DO_EMPREGADOR_NO_EXTERIOR">
                      Estabelecimento do empregador no exterior
                    </Radio>
                    <Radio value="ESTABELECIMENTO_DE_TERCEIROS">
                      Estabelecimento de terceiros onde o empregador presta
                      serviços
                    </Radio>
                    <Radio value="VIA_PUBLICA">Via pública</Radio>
                    <Radio value="AREA_RURAL">Área rural</Radio>
                    <Radio value="EMBARCACAO">Embarcação</Radio>
                    <Radio value="OUTROS">Outros</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label="Especificação do local do acidente::"
                name="dscLocal"
                tooltip="Campo opcional: Insira aqui especificação do local do acidente, por exemplo: pátio, rampa de acesso, posto de trabalho, máquina de corte"
              >
                <Input.TextArea
                  disabled={selectedUser === ""}
                  showCount
                  maxLength="255"
                  placeholder="Campo opcional: Insira aqui especificação do local do acidente, por exemplo: pátio, rampa de acesso, posto de trabalho, máquina de corte."
                />
              </Form.Item>

              <Row className="input-rows">
                {!zipcodedisabled && (
                  <Form.Item
                    label="CEP::"
                    name="cep"
                    rules={[
                      {
                        required: zipcodeIsRequired,
                        message: "Informe o CEP!",
                      },
                    ]}
                  >
                    <MaskedInput
                      mask="11.111-111"
                      disabled={selectedUser === ""}
                      onChange={(e) => handleChangeCEP(e.target.value)}
                    />
                  </Form.Item>
                )}

                <Form.Item
                  label="Tipo de logradouro::"
                  name="tpLograd"
                  tooltip="Busque/digite por nome o tipo de logradouro para se referir ao tipo de endereço de um imóvel ou local específico. Exemplo: rua, avenida, praça, estrada, entre outros."
                >
                  <Select
                    disabled={selectedUser === ""}
                    showSearch
                    showArrow={false}
                    allowClear
                    optionFilterProp="children"
                    filterOptions={(input, option) =>
                      (option.children[2] ?? "").includes(input)
                    }
                    placeholder="Selecione o código do tipo de logradouro"
                  >
                    {props.logradouros.map((logradouro) => {
                      return (
                        <Option key={logradouro.id} value={logradouro.id}>
                          {logradouro.codigo} - {logradouro.descricao}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Row>

              <Form.Item
                label="Descrição do logradouro::"
                name="dscLograd"
                tooltip="Insira aqui a descrição do endereço onde ocorreu o acidente. Exemplo: Rua das Flores ou Avenida dos Ipês ou Estrada Geral, etc."
                rules={[
                  {
                    required: true,
                    message: "Informe a descrição do logradouro!",
                  },
                ]}
              >
                <Input.TextArea
                  disabled={selectedUser === ""}
                  showCount
                  maxLength="100"
                  placeholder="Insira aqui a descrição do endereço onde ocorreu o acidente. Exemplo: Rua das Flores ou Avenida dos Ipês ou Estrada Geral, etc."
                />
              </Form.Item>

              <Row className="esocial-doctor-info">
                <Form.Item
                  label="Número do logradouro::"
                  name="nrLograd"
                  rules={[
                    {
                      max: 10,
                      message: "Máximo de 10 caracteres!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Digite o número"
                    type="number"
                    disabled={selectedUser === "" || noStreetNumber}
                  />
                </Form.Item>
                <Checkbox
                  style={{
                    alignSelf: "center",
                    padding: 0,
                    margin: 0,
                  }}
                  disabled={selectedUser === ""}
                  onChange={() => setNoStreetNumber(!noStreetNumber)}
                >
                  Sem número
                </Checkbox>

                <Form.Item
                  label="Complemento do logradouro::"
                  name="complemento"
                  rules={[
                    {
                      max: 30,
                      message: "Máximo de 30 caracteres!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Complemento"
                    disabled={selectedUser === ""}
                  />
                </Form.Item>
              </Row>

              <Row className="esocial-doctor-info">
                <Form.Item
                  label="Bairro::"
                  name="bairro"
                  rules={[
                    {
                      max: 90,
                      message: "Máximo de 90 caracteres!",
                    },
                  ]}
                >
                  <Input placeholder="Bairro" disabled={selectedUser === ""} />
                </Form.Item>

                {!zipcodedisabled && (
                  <>
                    <Form.Item
                      label="UF::"
                      name="uf"
                      rules={[
                        {
                          required: ufIsRequired,
                          message: "Informe a UF!",
                        },
                      ]}
                    >
                      <Select
                        disabled={selectedUser === ""}
                        placeholder="Selecione"
                        onSelect={(value) => {
                          setSelectedUf(value ? value : "");
                          form.setFieldsValue({ codMunic: undefined });
                        }}
                      >
                        {UfList.map((uf, key) => (
                          <Option key={key} value={uf.value}>
                            {uf.value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    {selectedUf && (
                      <Col span={12}>
                        <Form.Item
                          label="Código de município:"
                          name="codMunic"
                          rules={[
                            {
                              required: !zipcodedisabled,
                              message: "Informe o código do município!",
                            },
                          ]}
                        >
                          <Select
                            disabled={selectedUser === ""}
                            showSearch
                            showArrow={false}
                            allowClear
                            optionFilterProp="children"
                            filterOptions={(input, option) =>
                              (option.children[2] ?? "").includes(input)
                            }
                            placeholder="Selecione"
                          >
                            {cities.map((city, key) => {
                              if (city.uf == selectedUf)
                                return (
                                  <Option key={key} value={city.code}>
                                    {city.code} - {city.name}
                                  </Option>
                                );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                  </>
                )}
              </Row>

              {countryIsRequired && (
                <Row className="esocial-doctor-info">
                  <Form.Item
                    label="PAÍS::"
                    name="pais"
                    rules={[
                      {
                        required: countryIsRequired,
                        message: "Informe o país!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Selecione"
                      disabled={selectedUser === ""}
                    >
                      {props.paises.map((pais, key) => (
                        <Option key={key} value={pais.id}>
                          {pais.nome}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Código de Endereçamento Postal (CEP país exterior)::"
                    name="enderecoPostal"
                    rules={[
                      {
                        required: countryIsRequired,
                        message: "Informe o código de Endereçamento Postal!",
                      },
                      {
                        max: 12,
                        message: "Máximo de 12 caracteres.",
                      },
                      {
                        min: 4,
                        message: "Mínimo de 4 caracteres.",
                      },
                    ]}
                  >
                    <input disabled={selectedUser === ""} />
                  </Form.Item>
                </Row>
              )}

              <Form.Item
                label="Tipo de Inscrição::"
                name="tpInscLocalAcid"
                rules={[
                  {
                    required: true,
                    message: "Informe o tipo de inscrição!",
                  },
                ]}
              >
                <Radio.Group
                  disabled={selectedUser === ""}
                  onChange={(event) => {
                    setTpInscLocalAcid(event.target.value);
                    form.setFieldsValue({
                      nrInscLocalAcid: "",
                    });
                  }}
                >
                  <Radio value="CNPJ">CNPJ</Radio>
                  <Radio value="CAEPF">CAEPF</Radio>
                  <Radio value="CNO">CNO</Radio>
                </Radio.Group>
              </Form.Item>

              <Row className="esocial-doctor-info">
                <Form.Item
                  label="Número de inscrição do estabelecimento, de acordo com o tipo de inscrição indicado no campo acima::"
                  name="nrInscLocalAcid"
                  tooltip="Neste campo, o número inserido, seja CNPJ, CAEPF ou CNO do estabelecimento, precisa ser o mesmo de eventos de fases anteriores que não o de SST, para não ocorrer inconsistências no envio deste evento."
                  rules={[
                    {
                      required: true,
                      message:
                        "Informe o número de inscrição do estabelecimento!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.resolve();
                        }
                        switch (tpInscLocalAcid) {
                          case "CNPJ":
                            if (!validateCNPJ(value)) {
                              return Promise.reject(
                                new Error("CNPJ inválido!")
                              );
                            }
                            break;
                          case "CAEPF":
                            if (!validateCAEPF(value)) {
                              return Promise.reject(
                                new Error("CAEPF inválido!")
                              );
                            }
                            break;
                          case "CNO":
                            if (!validateCNO(value)) {
                              return Promise.reject(new Error("CNO inválido!"));
                            }
                            break;
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  {tpInscLocalAcid === "CNPJ" ? (
                    <MaskedInput
                      disabled={
                        props.formMode === "rectification" ||
                        tpInscLocalAcid === ""
                      }
                      mask="11.111.111/1111-11"
                    />
                  ) : (
                    <MaskedInput
                      disabled={
                        props.formMode === "rectification" ||
                        tpInscLocalAcid === ""
                      }
                      mask="11111111111111"
                    />
                  )}
                </Form.Item>
              </Row>
            </div>

            <div className="esocial-form-card">
              <span className="esocial-card-title">
                Detalhamento da parte atingida pelo acidente de trabalho e
                agente causador do acidente
              </span>

              <Form.Item
                label="Parte do corpo afetada pelo acidente::"
                name="codParteAting"
                tooltip="Busque/digite por código ou nome, a partir da lista, a parte do corpo atingida no acidente. O atestado médico pode ajudar."
                rules={[
                  {
                    required: true,
                    message:
                      "Informe o código correspondente à parte atingida!",
                  },
                ]}
              >
                <Select
                  disabled={selectedUser === ""}
                  showSearch
                  showArrow={false}
                  allowClear
                  optionFilterProp="children"
                  filterOptions={(input, option) =>
                    (option.children[2] ?? "").includes(input)
                  }
                  placeholder="Selecione o código referente à parte do corpo atingida"
                >
                  {props.partesAtingidas.map((parteAtingida) => {
                    return (
                      <Option key={parteAtingida.id} value={parteAtingida.id}>
                        {parteAtingida.id} - {parteAtingida.descricao}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Lateralidade das partes atingidas::"
                name="lateralidade"
                rules={[
                  {
                    required: true,
                    message: "Informe a lateralidade das partes atingidas!",
                  },
                ]}
                title={
                  "Nos casos de órgãos bilaterais, ou seja, que se situam dos lados do corpo, assinalar o lado (direito ou esquerdo). Ex.: Caso o órgão atingido seja perna, apontar qual foi a atingida (perna direita, perna esquerda ou ambas). Se o órgão atingido é único (como, por exemplo, a cabeça), assinalar este campo como não aplicável"
                }
              >
                <Radio.Group disabled={selectedUser === ""}>
                  <Radio value="NAO_APLICAVEL">Não aplicável</Radio>
                  <Radio value="DIREITA">Direita</Radio>
                  <Radio value="ESQUERDA">Esquerda</Radio>
                  <Radio value="AMBAS">Ambas</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label="Agente causador do acidente::"
                name="codAgntCausador"
                tooltip="Busque/digite por código ou nome do agente (exemplo: máquina, equipamento, veículo, objeto ou substância química) que causou a lesão ao trabalhador."
                rules={[
                  {
                    required: true,
                    message:
                      "Informe o código correspondente ao agente causador do acidente!",
                  },
                ]}
              >
                <Select
                  disabled={selectedUser === ""}
                  showSearch
                  showArrow={false}
                  allowClear
                  optionFilterProp="children"
                  filterOptions={(input, option) =>
                    (option.children[2] ?? "").includes(input)
                  }
                  placeholder="Selecione o código referente ao agente causador do acidente"
                >
                  {props.agentesCausadores.map((agenteCausador) => {
                    return (
                      <Option key={agenteCausador.id} value={agenteCausador.id}>
                        {agenteCausador.id} - {agenteCausador.descricao}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>

            <div className="esocial-form-card">
              <span className="esocial-card-title">Atestado Médico</span>

              <Row className="esocial-doctor-info">
                <Form.Item
                  label="Data do atendimento::"
                  name="dtAtendimento"
                  rules={[
                    {
                      required: true,
                      message: "Informe a data do atendimento!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.resolve();
                        }

                        let currentDate = new Date();
                        let deathDate = new Date(value._d);
                        let accidentDate = form.getFieldValue("dtAcid");

                        if (!accidentDate) {
                          return Promise.reject(
                            new Error("Informe primeiro a data do acidente!")
                          );
                        }

                        let accidentDateFormatted = new Date(accidentDate._d);

                        if (
                          deathDate <= currentDate &&
                          accidentDateFormatted <= deathDate
                        ) {
                          return Promise.resolve();
                        }

                        return Promise.reject(new Error("Data inválida!"));
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    format={"DD/MM/YYYY"}
                    disabled={selectedUser === ""}
                  />
                </Form.Item>

                <Form.Item
                  label="Hora do atendimento::"
                  name="hrAtendimento"
                  rules={[
                    {
                      required: true,
                      message: "Informe a hora do atendimento!",
                    },
                  ]}
                >
                  <DatePicker
                    picker="time"
                    format={"HH:mm"}
                    disabled={selectedUser === ""}
                  />
                </Form.Item>
              </Row>

              <Row className="esocial-doctor-info">
                <Form.Item
                  label="Indicativo de internação::"
                  name="indInternecao"
                  rules={[
                    {
                      required: true,
                      message: "Informe o indicativo de internação!",
                    },
                  ]}
                >
                  <Radio.Group disabled={selectedUser === ""}>
                    <Radio value="sim">Sim</Radio>
                    <Radio value="nao">Não</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  label="Duração estimada do tratamento, em dias::"
                  name="durTrat"
                  rules={[
                    {
                      required: true,
                      message: "Informe a duração estimada do tratamento!",
                    },
                    {
                      max: 4,
                      message: "Máximo de 4 dígitos!",
                    },
                  ]}
                >
                  <Input
                    placeholder="0000"
                    type="number"
                    disabled={selectedUser === ""}
                  />
                </Form.Item>
              </Row>

              <Form.Item
                label="Houve afastamento do trabalho durante o tratamento?"
                name="indAfast"
                rules={[
                  {
                    required: true,
                    message:
                      "Informe se houve afastamento do trabalho durante o tratamento!",
                  },
                ]}
              >
                <Radio.Group
                  disabled={
                    selectedUser === "" ||
                    form.getFieldValue("indCatObito") === "sim"
                  }
                  onChange={(value) => {
                    if (!value) return;

                    if (value === "sim")
                      openNotification(
                        "Não esqueça de comunicar o afastamento ao governo por meio do evento S-2230",
                        "",
                        "warning"
                      );
                  }}
                >
                  <Radio value="sim">Sim</Radio>
                  <Radio value="nao">Não</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label="Descrição da natureza da lesão::"
                name="dscLesao"
                tooltip="Busque/digite por código ou nome o tipo de lesão sofrida pelo trabalhador. Essa informação pode constar no atestado médico."
                rules={[
                  {
                    required: true,
                    message: "Informe a descrição da natureza da lesão!",
                  },
                ]}
              >
                <Select
                  disabled={selectedUser === ""}
                  showSearch
                  showArrow={false}
                  allowClear
                  optionFilterProp="children"
                  filterOptions={(input, option) =>
                    (option.children[2].toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  placeholder="Selecione o código gerado"
                >
                  {props.naturezaLesoes.map((naturezaLesao) => {
                    return (
                      <Option key={naturezaLesao.id} value={naturezaLesao.id}>
                        {naturezaLesao.id} - {naturezaLesao.descricao}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Descrição complementar da lesão::"
                name="dscCompLesao"
                tooltip="Campo opcional: Insira aqui uma descrição complementar da lesão, se desejar."
              >
                <Input.TextArea
                  disabled={selectedUser === ""}
                  showCount
                  maxLength="200"
                  placeholder="Campo opcional: Insira aqui uma descrição complementar da lesão, se desejar"
                />
              </Form.Item>

              <Form.Item
                label="Diagnóstico provável::"
                name="diagProvavel"
                tooltip="Campo opcional: Insira aqui uma descrição sobre o diagnóstico médico, se desejar"
              >
                <Input.TextArea
                  disabled={selectedUser === ""}
                  showCount
                  maxLength="100"
                  placeholder="Campo opcional: Insira aqui uma descrição sobre o diagnóstico médico, se desejar"
                />
              </Form.Item>

              <Form.Item
                label="Código da tabela de Classificação Internacional de Doenças - CID::"
                name="codCid"
                tooltip="Insira o código do CID presente no atestado médico. O código deve começar com uma letra seguida de dois ou três números (por exemplo, A123 ou U49)."
                rules={[
                  {
                    required: true,
                    message:
                      "Informe o código da tabela de classificação internacional de Doenças!",
                  },
                  {
                    min: 3,
                    message: "Mínimo de 3 caracteres!",
                  },
                  {
                    max: 4,
                    message: "Máximo de 4 caracteres!",
                  },
                ]}
              >
                <Input
                  placeholder="Insira o código do CID presente no atestado médico. O código deve começar com uma letra seguida de dois ou três números (por exemplo, A123 ou U49)"
                  disabled={selectedUser === ""}
                />
              </Form.Item>

              <Form.Item
                label="Observações::"
                name="observacao"
                tooltip="Campo opcional: Insira aqui observações que considere pertinentes ao atestado médico, se desejar"
              >
                <Input.TextArea
                  disabled={selectedUser === ""}
                  showCount
                  maxLength="255"
                  placeholder="Campo opcional: Insira aqui observações que considere pertinentes ao atestado médico, se desejar."
                />
              </Form.Item>
            </div>

            <div className="esocial-form-card">
              <span className="esocial-card-title">
                Médico/Dentista que emitiu o atestado
              </span>
              <Form.Item
                label="Nome do médico/dentista que emitiu o atestado::"
                name="nmEmit"
                rules={[
                  {
                    required: true,
                    message:
                      "Informe o nome do médico/dentista que emitiu o atestado!",
                  },
                  {
                    max: 70,
                    message: "Máximo de 70 caracteres!",
                  },
                  {
                    min: 2,
                    message: "Mínimo de 2 caracteres!",
                  },
                ]}
              >
                <Input
                  placeholder="Nome do médico/dentista"
                  disabled={selectedUser === ""}
                />
              </Form.Item>

              <Form.Item
                label="Órgão de Classe:*"
                name="orgaoClasse"
                rules={[
                  {
                    required: true,
                    message:
                      "Informe o nome do médico/dentista que emitiu o atestado!",
                  },
                ]}
              >
                <Radio.Group
                  disabled={selectedUser === ""}
                  onChange={(value) => {
                    setUfOcIsRequired(
                      value.target.value
                        ? value.target.value == "CRM" ||
                            value.target.value == "CRO"
                        : false
                    );
                  }}
                >
                  <Space direction="vertical">
                    <Radio value="CRM">
                      Conselho Regional de Medicina - CRM
                    </Radio>
                    <Radio value="CRO">
                      Conselho Regional de Odontologia - CRO
                    </Radio>
                    <Radio value="RMS">
                      Registro do Ministério da Saúde - RMS
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>

              <Row className="esocial-doctor-info">
                <Form.Item
                  label="Número de inscrição no órgão de classe ou Registro do Ministério da Saúde - RMS::"
                  name="nrOc"
                  rules={[
                    {
                      required: true,
                      message:
                        "Informe o número de inscrição no órgão de classe ou registro do Ministério de Saúde!",
                    },
                    {
                      max: 14,
                      message: "Máximo de 14 caracteres!",
                    },
                  ]}
                >
                  <Input
                    placeholder="00000000000000"
                    type="number"
                    disabled={selectedUser === ""}
                  />
                </Form.Item>
              </Row>

              <Row className="esocial-doctor-info">
                <Form.Item
                  label="UF da expedição do órgão de classe::"
                  name="ufOc"
                  rules={[
                    {
                      required: ufOcIsRequired,
                      message: "Informe a UF do õrgão de classe",
                    },
                  ]}
                >
                  <Select
                    placeholder="Selecione"
                    disabled={selectedUser === ""}
                  >
                    {UfList.map((uf, key) => (
                      <Option key={key} value={uf.value}>
                        {uf.value}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Row>
            </div>

            <Row className="esocial-submit-button-row">
              <Button htmlType="submit">Salvar</Button>
            </Row>
          </Form>
        </div>
      </Col>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    categoriasTrabalhadores: state.esocial.categoriasTrabalhadores
      ? state.esocial.categoriasTrabalhadores
      : [],
    tiposExames: state.esocial.tiposExames ? state.esocial.tiposExames : [],
    funcionariosEsocial: state.esocial.funcionariosEsocial
      ? state.esocial.funcionariosEsocial
      : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        listarCategorias,
        listarExames,
        listarFuncionariosEsocial,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Esocial2010Form);
