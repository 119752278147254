import { Button, Checkbox, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  postEPC,
  getEPCFromList,
  editEPC,
  emptyEPCSelecionado,
} from "../../actions";
import "../../../styles.scss";

const CadastroEPCForm = (props) => {
  const [form] = Form.useForm();
  const [mode, setMode] = useState("new");
  const [epcSelecionado, setEpcSelecionado] = useState({});
  const [active, setActive] = useState(false);
  const [epcDefault, setEpcDefault] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (epcSelecionado) setMode("edit");
    if (props.epcSelecionado) setEpcSelecionado(props.epcSelecionado);
    setEdit(props.epcSelecionado);
  }, [JSON.stringify(props.epcSelecionado)]);

  const setEdit = (epcSelecionado) => {
    if (mode == "edit" || props.epcSelecionado) {
      form.setFieldsValue({
        epcCode: epcSelecionado.id,
        epcName: epcSelecionado.nome,
        epcActive: epcSelecionado.ativo,
        epcDefault: epcSelecionado.padrao,
      });
      setActive(epcSelecionado.ativo);
      setEpcDefault(epcSelecionado.padrao);
    }
  };

  const handleEdit = () => {
    setLoading(true);
    form.validateFields().then((values) => {
      let newEPC = {
        id: values.epcCode,
        nome: values.epcName,
        ativo: active,
        padrao: epcDefault,
      };
      editEPC(values.epcCode, newEPC, props.dispatch, setLoading);
      form.resetFields();
    });
  };

  const handleNew = () => {
    setLoading(true);
    form.validateFields().then((values) => {
      let newEPC = {
        id: values.epcCode,
        nome: values.epcName,
        ativo: active,
        padrao: epcDefault,
      };
      postEPC(newEPC, props.dispatch, setLoading);
      form.resetFields();
    });
  };

  const checkboxOnChange = (e) => {
    if (e.target.id === "epcActive") {
      setActive(e.target.checked);
    } else {
      setEpcDefault(e.target.checked);
    }
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={mode === "new" ? () => handleNew() : () => handleEdit()}
      >
        <Form.Item label="Código do EPC" name="epcCode">
          <Input type="number" />
        </Form.Item>
        <Form.Item label="EPC" name="epcName">
          <Input />
        </Form.Item>
        <Form.Item label="Ativo" name="epcActive">
          <Checkbox checked={active} onChange={checkboxOnChange} />
        </Form.Item>
        <Form.Item label="Padrão para químicos" name="epcDefault">
          <Checkbox checked={epcDefault} onChange={checkboxOnChange} />
        </Form.Item>

        <div className="epc-button-div">
          {!epcSelecionado.nome ? (
            <Button loading={loading} onClick={() => handleNew()}>
              Salvar
            </Button>
          ) : (
            ""
          )}
          {epcSelecionado.nome ? (
            <React.Fragment>
              <Button loading={loading} onClick={() => handleEdit()}>
                Salvar
              </Button>
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    epcSelecionado: state.gestorDN.epcSelecionado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...dispatch,
    ...bindActionCreators({
      getEPCFromList,
      emptyEPCSelecionado,
    }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CadastroEPCForm);
