import React from "react";
import { Switch, Router } from "react-router-dom";

import { createBrowserHistory } from "history";
import LayoutApp from "../../shared/LayoutApp/LayoutApp";
import Routes from "./routes";

import { setup401HandlerInterceptor } from '../../auth/Setup401HandlerInterceptor'

let PUBLIC_URL = "";
let basename = "";

if (process.env.PUBLIC_URL) {
  PUBLIC_URL = `${process.env.PUBLIC_URL}`;
  basename = PUBLIC_URL.split("/").pop();
}

export const history = createBrowserHistory({ basename: basename});

const RootRoutes = () => {
  setup401HandlerInterceptor();
  
  return (
    <Router history={history} >
      <LayoutApp>
        <Switch>
          <Routes />
        </Switch>
      </LayoutApp>
    </Router>
  );
};

export default RootRoutes;
