import axios from "axios";
import { SEND_MESSAGE } from "./types";
import openNotification from "../../../../components/notifications";
import { supportMessage } from "../../../../constants";

export const sendMessage = (dispatch, feedbackObj) => {
  axios
    .post(`${supportMessage}`, feedbackObj)
    .then(() => {
      openNotification("Mensagem enviada com sucesso!", "", "success");
      dispatch({
        type: SEND_MESSAGE,
      });
    })
    .catch((err) => {
      openNotification("Erro ao enviar a mensagem.", "", "error");
      console.log(err);
    });
};
