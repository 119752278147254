import {
  EllipsisOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Input,
  List,
  Menu,
  Select,
  Tag,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { openAssistantModal } from "../../../../components/AssistantModalHeader";
import { HeaderWithModal } from "../../../../components/HeaderWithModal/index";
import IconSESI from "../../../../components/IconsSESI";
import {
  alterarCertificado,
  deleteCertificado,
  listarCertificados,
  postCertificado,
} from "../actions";
import { getAssistantTalks } from "../actions/index";
import ESocialBanner from "./ESocialBanner";

const ESocialSignatures = (props) => {
  const [form] = Form.useForm();
  const [isDefault, setIsDefault] = useState(false);
  const [assistantTalk, setAssistantTalk] = useState([]);

  const CryptoJS = require("crypto-js");
  const { Option } = Select;

  const premiumCompanies = props.companies.filter((company) => company.premium);
  const premiumCompany = props.selectedCompany.premium;

  useEffect(() => {
    listarCertificados(props.dispatch);
  }, [JSON.stringify(props.certificadosCadastrados)]);

  const onChange = () => {
    setIsDefault(!isDefault);
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const senha = values.senhaCertificado;
      const secret = "NdRgUkXp2s5v8y/B";
      let encrypted = CryptoJS.AES.encrypt(senha, secret).toString();
      let formData = new FormData();
      values.empresas.map((empresa) => formData.append("cnpjs", empresa));
      formData.append(
        "certificado",
        values.arquivoCertificado.fileList[0].originFileObj
      );
      formData.append("senha", encrypted);
      formData.append("isDefault", isDefault === true ? 1 : 0);
      postCertificado(formData, props.dispatch);
      form.resetFields();
    });
  };

  const handleDelete = (id) => {
    deleteCertificado(id, props.dispatch);
  };

  const handleDefault = (id, certificado) => {
    let newBody = {
      id: certificado.id,
      url: certificado.url,
      nomeArquivo: certificado.nomeArquivo,
      isDefault: 1,
    };
    alterarCertificado(id, newBody, props.dispatch);
  };

  const menu = (certificado) => {
    return (
      <Menu>
        <Menu.Item key="1" onClick={() => handleDelete(certificado.id)}>
          <IconSESI type="trash" /> Excluir
        </Menu.Item>
        {certificado.default ? (
          ""
        ) : (
          <Menu.Item
            key="2"
            onClick={() => handleDefault(certificado.id, certificado)}
          >
            <IconSESI type="checklist" />
            Definir como padrão
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const getDigitalSignatureAssistantTalks = async () => {
    const talks = await getAssistantTalks('assinatura-digital', 'Início')
    setAssistantTalk(talks.descricao)
  }

  useEffect(() => {
    getDigitalSignatureAssistantTalks()
  }, [])

  return (
    <React.Fragment>
      {premiumCompany ? (
        <div className="container">
          <HeaderWithModal
            pageTitle="Gerenciar Assinaturas Digitais do tipo A1"
            helpText={"Clique aqui e saiba mais!"}
            assistant={"vivi"}
            openModal={async () =>
              openAssistantModal(
                assistantTalk,
                "vivi"
              )
            }
          />

          <div className="esocial-dashboard">
            <div className="pageContent">
              <Form form={form} onFinish={handleSubmit}>
                <div className="esocial-card-signature">
                  <Form.Item
                    label="Empresa::"
                    name="empresas"
                    tooltip={
                      "Se você tiver outorga poderá associar esta mesma assinatura a outras empresas"
                    }
                  >
                    <Select
                      mode="multiple"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0 ||
                        option.props.value.toString().indexOf(input) >= 0
                      }
                    >
                      {premiumCompanies.map((company) => (
                        <Option key={company.cnpj} value={company.cnpj}>
                          {company.razaoSocial}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="arquivoCertificado">
                    <Upload
                      multiple={false}
                      maxCount={1}
                      accept=".pfx,.p12"
                      beforeUpload={() => {
                        return false;
                      }}
                    >
                      <Button icon={<UploadOutlined />}>
                        Enviar certificado
                      </Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item label="Senha" name="senhaCertificado">
                    <Input.Password
                      placeholder="digite a senha do seu certificado"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label=":"
                    name="isDefault"
                    tooltip={
                      "É possível vincular mais de um certificado e tornar um deles como padrão"
                    }
                  >
                    <Checkbox onChange={onChange}>
                      Definir como assinatura padrão.
                    </Checkbox>
                  </Form.Item>
                  <div className="esocial-signature-btn-div">
                    <Button htmlType="submit">Salvar</Button>
                  </div>
                </div>
              </Form>
              <div>
                <List>
                  {props.certificadosCadastrados[0]
                    ? props.certificadosCadastrados.map((certificado) => {
                      return (
                        <List.Item>
                          <div>
                            <span>
                              <h3>{certificado.nomeArquivo}</h3>
                              {certificado.default ? (
                                <Tag>Assinatura Padrão</Tag>
                              ) : (
                                ""
                              )}
                              <h5>Empresas:</h5>
                              <Tag>{certificado.nomeFantasia}</Tag>
                            </span>
                          </div>
                          <Dropdown
                            overlay={menu(certificado)}
                            trigger={["click"]}
                          >
                            <Button>
                              <EllipsisOutlined />
                            </Button>
                          </Dropdown>
                        </List.Item>
                      );
                    })
                    : ""}
                </List>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ESocialBanner />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    certificadosCadastrados: state.esocial.certificadosCadastrados
      ? state.esocial.certificadosCadastrados
      : [],
    companies: state.user.companies,
    selectedCompany: state.caracterizacao.companySelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        listarCertificados,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ESocialSignatures);
