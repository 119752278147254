import React, { Component } from "react";
import { Card, Tag } from "antd";
import IconSESI from "../../../IconsSESI";

const statusEnum = Object.freeze({
  1: "Em preenchimento",
  2: "Classificação de riscos",
  3: "Plano de ação",
  4: "Gerar PGR",
});

class CompanyList extends Component {
  render() {
    const { companies } = this.props;

    const getCompanyClass = (company) => {
      if (!this.props.selectedCompany) return "company";
      return company.cnpj === this.props.selectedCompany.cnpj
        ? "company selected-company"
        : "company";
    };

    return (
      <div className="company-card">
        <div className="">
          {companies.map((company) => {
            return (
              <div className="">
                <Card
                  bordered={false}
                  key={company.cnpj}
                  className={getCompanyClass(company)}
                  onClick={() => {
                    this.props.selectCompanyAction(
                      this.props.dispatch,
                      company
                    );
                  }}
                >
                  <div>
                    {company.premium && (
                      <IconSESI type="premium" fill="var(--alto)" />
                    )}
                    <h6>{company.razaoSocial}</h6>
                    <div>
                      <h3 className="responsivo-hidden">
                        {company.nomeFantasia}{" "}
                      </h3>
                    </div>
                    <div>
                      <h6>
                        {company.cnpj &&
                          company.cnpj.replace(
                            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                            "$1.$2.$3/$4-$5"
                          )}
                      </h6>
                    </div>
                  </div>
                  <Tag>{statusEnum[company.statusFluxo]}</Tag>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default CompanyList;
