import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SideBar from "../SideBar";
import Content from "../Content";
import { bindActionCreators } from "redux";
import { patchMonitoringTalk } from "../../actions";
import { ACOMPANHAMENTO } from "../../constants";

const AcompanhamentoAssistenteVirtual = (props) => {
  const [currentTalk, setCurrentTalk] = useState({});
  const [unchangeableParagraphs, setUnchangeableParagraphs] = useState([]);

  useEffect(() => {
    props.talksAcompanhamento.monitoringTalks.sort((a, b) => a.id - b.id);
    setCurrentTalk(props.talksAcompanhamento.monitoringTalks[0]);
    setUnchangeableParagraphs(
      props.talksAcompanhamento.monitoringTalks[0].naoEditavel
    );
  }, [JSON.stringify(props.talksAcompanhamento)]);

  const changeTalk = (keyArr) => {
    let key = keyArr[0];
    switch (key) {
      case "0-0":
        setCurrentTalk(props.talksAcompanhamento.monitoringTalks[0]);
        setUnchangeableParagraphs(
          props.talksAcompanhamento.monitoringTalks[0].naoEditavel
        );
        break;
      case "0-1-0":
        setCurrentTalk(props.talksAcompanhamento.risksTalks[0]);
        setUnchangeableParagraphs(
          props.talksAcompanhamento.risksTalks[0].naoEditavel
        );
        break;
      case "0-1-1":
        setCurrentTalk(props.talksAcompanhamento.risksTalks[1]);
        setUnchangeableParagraphs(
          props.talksAcompanhamento.risksTalks[1].naoEditavel
        );
        break;
      case "0-2":
        setCurrentTalk(props.talksAcompanhamento.actionPlanTalks[0]);
        setUnchangeableParagraphs(
          props.talksAcompanhamento.actionPlanTalks[0].naoEditavel
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className="admin-assistente-virtual-wrapper">
      <div className="admin-assistente-virtual-lateral">
        <SideBar
          content={props.talksAcompanhamento}
          changeTalk={changeTalk}
          category={ACOMPANHAMENTO}
        />
      </div>
      <div className="admin-assistente-virtual-falas">
        <Content
          currentTalk={currentTalk}
          dispatch={props.dispatch}
          patchMonitoringTalk={patchMonitoringTalk}
          category={ACOMPANHAMENTO}
          changeTalk={changeTalk}
          unchangeableParagraphs={unchangeableParagraphs}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    talksAcompanhamento: state.gestorDN.virtualAssistant.monitoringTalks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ patchMonitoringTalk }, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcompanhamentoAssistenteVirtual);
