import React, { Component } from "react";
import { Switch, Modal } from "antd";
import { connect } from "react-redux";
import {
  toggleStepStatusCliente,
  toggleStepStatusTecnico,
  toggleStatusFluxo,
} from "./_/actions";
import "./style.scss";

const { confirm } = Modal;
class FinishStep extends Component {
  onConfirm(func, url, bool, apenasErgonomico) {
    const titleNenhum = `Você não cadastrou nenhum fator de risco!`;
    const msgNenhum = `Será necessário assinar uma autodeclaração confirmando a inexistência de fator de risco na empresa.`;

    const titleApenasErgo = `Você cadastrou apenas fatores de risco do tipo ERGONÔMICO!`;
    const msgApenasErgo = `Será necessário assinar uma autodeclaração confirmando que o fator de risco do tipo ergonômico é o único existente na empresa.`;

    confirm({
      title: apenasErgonomico ? titleApenasErgo : titleNenhum,
      content: apenasErgonomico ? msgApenasErgo : msgNenhum,
      centered: true,
      okText: "ACEITAR",
      cancelText: "CANCELAR",
      onOk() {
        func(url, bool);
      },
      onCancel() {
        Modal.destroyAll();
      },
    });
  }

  render() {
    const {
      completed,
      url,
      avisoAutoDeclaracao = false,
      apenasErgonomico,
      statusFluxo,
    } = this.props;
    return (
      <div className="finish-step">
        <label title="Etapa concluída">Etapa concluída</label>
        <Switch
          checkedChildren="Sim"
          unCheckedChildren="Não"
          checked={completed}
          onChange={(checked) => {
            checked
              ? avisoAutoDeclaracao
                ? this.onConfirm(
                    this.props.toggleStepStatusCliente,
                    url,
                    !completed,
                    apenasErgonomico
                  )
                : this.props.toggleStepStatusCliente(url, !completed)
              : this.props.toggleStepStatusCliente(url, !completed) &&
                this.props.toggleStatusFluxo({ statusFluxo: 1 });
          }}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  toggleStepStatusCliente,
  toggleStepStatusTecnico,
  toggleStatusFluxo,
})(FinishStep);
