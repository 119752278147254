export const colors = {
  // não são alteradas
  alto: "#EEEEEE",
  pendency: "#f2c94c",
  green: "#00738c",
  red: "#a34949",
  // são alteradas
  martinique: "#23304D",
  melrose: "#D1DEFF",
  comet: "#475F92",
  gigas: "#114680",
  marguerite: "#17508F",
  ebony: "#192136",
  disabled_color: "#8d8ab4",
  degrade_menu: "gigas",
  degrade_fundo: "linear-gradient(180deg, ebony, ebony)",
  degrade_step: "linear-gradient(180deg, marguerite, marguerite), marguerite"
};
