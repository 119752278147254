import React, { useEffect, useState } from "react";
import { Tag } from "antd";
import DropdownActionMenu from "../../../../components/DropdownActionMenu";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/pt-br";
import { ACAO_PLANEJADA } from "../../../../constants/stepTypes";

const PlannedAction = (props) => {
  const [plannedAction, setPlannedAction] = useState(
    props.planoDeAcao.acoesPlanejadas
  );

  useEffect(() => {
    setPlannedAction(props.planoDeAcao.acoesPlanejadas);
  }, [JSON.stringify(props.planoDeAcao.acoesPlanejadas)]);

  return (
    <React.Fragment>
      {plannedAction
        .filter((action) => action.idFatorDeRisco === props.idFatorDeRisco)
        .map((action) => (
          <div className="action-item">
            <div className="planned-action-info">
              <div className="action-name">
                <h3>{action.descricao}</h3>
              </div>
              <div className="planed-action-info">
                <Tag>
                  <h5>{`Responsável: ${action.responsavel}`}</h5>
                </Tag>
                <Tag>
                  <h5>{`${moment(action.previsaoInicio).format(
                    "MMM/YYYY"
                  )} a ${moment(action.previsaoFim)
                    .locale("pt-br")
                    .format("MMM/YYYY")}`}</h5>
                </Tag>
              </div>
            </div>
            <DropdownActionMenu
              dispatch={props.dispatch}
              entidade={ACAO_PLANEJADA}
              item={{ ...action, ...{ nome: action.descricao } }}
              edit={() => props.configEdit(action.id)}
              remove={props.deleteAction}
            />
          </div>
        ))}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    acoesPlanejadas: state.planoDeAcao.acoesPlanejadas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PlannedAction);
