export const inventarioDeRiscoPreviewColumns = [
  {
    title: "Perigo",
    dataIndex: "risk",
    key: "risk",
  },
  {
    title: "Grupo",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Possíveis lesões",
    dataIndex: "possiblesInjures",
    key: "possiblesInjures",
  },
  {
    title: "Número de trabalhadores expostos",
    dataIndex: "numberExposed",
    key: "numberExposed",
  },
  {
    title: "Padrão legal",
    dataIndex: "legal",
    key: "legal",
  },
  {
    title: "Fonte geradora",
    dataIndex: "source",
    key: "source",
  },
  {
    title: "Meio de propagação",
    dataIndex: "propagation",
    key: "propagation",
  },
  {
    title: "Resultado da medição",
    dataIndex: "measure",
    key: "measure",
  },
  {
    title: "Probabilidade",
    dataIndex: "probalitity",
    key: "probalitity",
  },
  {
    title: "Severidade",
    dataIndex: "severity",
    key: "severity",
  },
  {
    title: "Nível de risco",
    dataIndex: "riskLevel",
    key: "riskLevel",
  },
  {
    title: "Classificação de prioridade",
    dataIndex: "priority",
    key: "priority",
  },
];

export const planoDeAcaoPreviewColumns = [
  {
    title: "Ação",
    dataIndex: "actionPlan",
    key: "actionPlan",
  },
  {
    title: "Responsável",
    dataIndex: "responsible",
    key: "responsible",
  },
  {
    title: "Previsão início",
    dataIndex: "startDate",
    key: "startDate",
  },
  {
    title: "Previsão fim",
    dataIndex: "endDate",
    key: "endDate",
  },
  {
    title: "Prioridade de ação",
    dataIndex: "actionPriority",
    key: "actionPriority",
  },
];

export const trabalhadoresPreviewColumns = [
  {
    title: "Trabalhador",
    dataIndex: "nome",
    key: "nome",
  },
  {
    title: "Cargo",
    dataIndex: "cargo",
    key: "cargo",
  },
  {
    title: "Setor",
    dataIndex: "setor",
    key: "setor",
  },
];
