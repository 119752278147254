import React, { useEffect, useState } from "react";
import CompanyExportCard from "./ExportacaoEmpresas/components/CompanyExportCard";
import "./styles.scss";
import { Col, Row } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import SearchBar from "./ExportacaoEmpresas/components/SearchBar";
import RelatoriosHome from "./Relatorios/_/containers/RelatoriosHome";
import {
  getGestorDRDashboad,
  getGestorDRRelatorioStatus,
} from "./Relatorios/_/actions";

const GestorDR = (props) => {
  const [dashboard, setDashboard] = useState([]);
  const [relatorioStatus, setRelatorioStatus] = useState([]);

  useEffect(() => {
    getGestorDRDashboad(props.dispatch);
    getGestorDRRelatorioStatus(props.dispatch);
  }, []);

  useEffect(() => {
    setDashboard(props.dashboard);
  }, [JSON.stringify(props.dashboard)]);

  useEffect(() => {
    setRelatorioStatus(props.relatorioStatus);
  }, [JSON.stringify(props.relatorioStatus)]);

  // const filterList = (event) => {
  //   const value = event.target.value;
  //   if (value.length > 2) {
  //     let filteredEmpresasComPGREmitido = empresasComPGREmitido.filter(
  //       (company) => {
  //         return (
  //           company.nomeFantasia.toLowerCase().indexOf(value.toLowerCase()) !==
  //           -1
  //         );
  //       }
  //     );
  //     setEmpresasComPGREmitido(filteredEmpresasComPGREmitido);
  //   } else {
  //     setEmpresasComPGREmitido(props.empresasComPGREmitido);
  //   }
  // };

  return (
    <RelatoriosHome dashboard={dashboard} relatorioStatus={relatorioStatus} />
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.userInfo,
    dashboard: state.gestorDR.relatoriosHome.dashboard,
    relatorioStatus: state.gestorDR.relatoriosHome.relatorioStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ getGestorDRDashboad }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GestorDR);
