export const EMPRESA = "empresa";
export const SETORES = "setores";
export const PROCESSOS_PRODUTIVOS = "PROCESSOS_PRODUTIVOS";
export const FUNCIONARIOS = "funcionarios";
export const MAQUINAS = "maquinas";
export const PRODUTOS_QUIMICOS = "produtosQuimicos";
export const FATORES_DE_RISCO = "fatoresDeRisco";
export const ESPACO_FISICO = "espacoFisico";
export const PLANO_DE_ACAO = "planoDeAcao";
export const ACAO_PLANEJADA = "ação planejadada";
export const REALIZADOR_DE_PROCESSOS = "REALIZADOR_DO_PROCESSO";
export const INICIO = "INICIO";
export const CARACTERIZACAO = "CARACTERIZACAO";
export const ACOMPANHAMENTO = "ACOMPANHAMENTO";
export const AUTODECLARACAO = "AUTODECLARACAO";
