import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { stepsTalks } from "../../../../../../../constants/aroldoTalks";
import { Row, Col, Spin, Button as ButtonAnt, Tabs } from "antd";
import {
  fetchFuncionarios,
  postFuncionario,
  ativarDesativarFuncionario,
  editarFuncionario,
  obterFuncionario,
  fetchCargos,
} from "../actions";
import Button from "../../../../../../../components/Buttons/_/components/button";
import FuncionarioList from "../components/FuncionarioList";
import FuncionarioForm from "../components/FuncionarioForm";
import IconSESI from "../../../../../../../components/IconsSESI";
import usePrevious from "../../../../../../../util/usePrevious";
import { bindActionCreators } from "redux";
import { fetchSetores } from "../../../CaracterizacaoSetores/_/actions";

const CaracterizacaoFuncionarios = (props) => {
  const [funcionarios, setFuncionarios] = useState([]);
  const previousFuncionarios = usePrevious(funcionarios);
  const [currentTab, setCurrentTab] = useState("lista");
  const [mode, setMode] = useState("add");
  const [viewOnly, setViewOnly] = useState();
  const [completed] = useState(false);

  const { TabPane } = Tabs;

  useEffect(() => {
    fetchFuncionarios(props.dispatch);
    fetchSetores(props.dispatch);
    fetchCargos(props.dispatch);
    setViewOnly(props.stepsEmpresa.FUNCIONARIOS);
    if (previousFuncionarios !== props.funcionarios) {
      setFuncionarios(props.funcionarios);
    }
    if (props.stepsEmpresa.FUNCIONARIOS) {
      props.onChangeTalk("talk3", 2);
    } else {
      props.onChangeTalk(currentTab === "form" ? "talk2" : "talk1", 2);
    }
  }, [JSON.stringify(props.funcionarios)]);

  const setEditMode = (viewOnly) => {
    setMode("edit");
    if (viewOnly === true) setViewOnly(viewOnly);
    else if (viewOnly === false) setViewOnly(viewOnly);
    else setViewOnly(props.stepsEmpresa.FUNCIONARIOS);
  };

  const setAddMode = () => {
    setMode("add");
    setViewOnly(props.stepsEmpresa.FUNCIONARIOS);
  };

  const configFuncionariosTab = () => {
    setCurrentTab(currentTab === "lista" ? "form" : "lista");
    props.onChangeTalk(currentTab !== "lista" ? "talk1" : "talk2", 2);
  };

  const funcionarioSelecionado = (id) => {
    props.obterFuncionario(id);
  };

  useEffect(() => {
    setViewOnly(props.stepsEmpresa.FUNCIONARIOS);
    if (props.stepsEmpresa.FUNCIONARIOS) {
      props.onChangeTalk("talk3", 2);
    } else {
      props.onChangeTalk("talk1", 2);
    }
  }, [props.stepsEmpresa.FUNCIONARIOS]);

  if (!props.funcionarios) {
    return (
      <div className="spin">
        <Spin tip="Carregando..." />
      </div>
    );
  }

  return (
    <div className="caracterizacao-funcionarios-wrapper">
      <Row gutter={32}>
        <Col lg={24} md={24}>
          <Tabs activeKey={currentTab}>
            <TabPane tab="" key="lista">
              <div className="caracterizacao-funcionarios container">
                <Row
                  gutter={16}
                  type="flex"
                  justify="space-around"
                  align="middle"
                >
                  <Col span={18}>
                    <div className="header-form">
                      <Button
                        shape="circle"
                        ghost
                        text={<IconSESI type="arrow-left" />}
                        onClick={() => props.nextStep(props.current - 1)}
                      />
                      <h2>Trabalhadores</h2>
                    </div>
                  </Col>
                  <Col span={6}>
                    <Button
                      onClick={() => props.nextStep(props.current + 1)}
                      type="primary"
                      text="PRÓXIMO PASSO"
                      className="next-step"
                      block
                    />
                  </Col>
                </Row>
                <div className="content">
                  {!props.stepsEmpresa.FUNCIONARIOS ? (
                    <ButtonAnt
                      ghost
                      className="add"
                      onClick={configFuncionariosTab}
                    >
                      <IconSESI type="add-circle" theme="filled" />
                      Adicionar Trabalhador
                    </ButtonAnt>
                  ) : null}
                  <FuncionarioList
                    completed={completed}
                    selectFuncionario={funcionarioSelecionado}
                    funcionarios={funcionarios}
                    setores={props.setores}
                    tab={configFuncionariosTab}
                    modo={mode}
                    changeModo={setEditMode}
                    ativarDesativar={ativarDesativarFuncionario}
                    fetch={fetchFuncionarios}
                    perfil={props.perfil}
                    viewOnly={viewOnly}
                    cargos={props.cargos}
                    dispatch={props.dispatch}
                  />
                </div>
              </div>
            </TabPane>

            <TabPane tab="" key="form">
              <FuncionarioForm
                dispatch={props.dispatch}
                funcionario={mode === "edit" ? props.funcionario : []}
                post={postFuncionario}
                tab={configFuncionariosTab}
                edit={editarFuncionario}
                modo={mode}
                changeModo={setAddMode}
                path={props.cnpj}
                perfil={props.perfil}
                viewOnly={viewOnly}
                cargos={props.cargos}
                changeTalk
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  const customizacoes = state.user.userInfo
    ? state.user.userInfo.customizacoes
    : {};
  return {
    perfil: state.user.userInfo.perfil,
    page: state.buttons.pageStepsTrabalhadores,
    cnpj: state.caracterizacao.companySelected.cnpj,
    funcionarios: state.caracterizacao.funcionarios.funcionarios,
    funcionario: state.caracterizacao.funcionarios.funcionario,
    setores: state.caracterizacao.setores.setores,
    erro: state.caracterizacao.funcionarios.erro,
    cargos: state.caracterizacao.funcionarios.cargos,
    companySelected: state.caracterizacao.companySelected,
    stepsEmpresa: state.caracterizacao.stepsEmpresa.stepsEmpresa,
    customizacoes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        fetchFuncionarios,
        postFuncionario,
        ativarDesativarFuncionario,
        editarFuncionario,
        obterFuncionario,
        fetchCargos,
        fetchSetores,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaracterizacaoFuncionarios);
