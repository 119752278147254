import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Steps, Row, Col } from "antd";
import IconsSESI from "../../components/IconsSESI";
import { steps as allSteps } from "../../constants/steps";
import { isPerfilCliente } from "../../constants/userTypes";
import Assistant from "../VirtualAssistant";
import {
  stepsTalks,
  talksEmpresa,
  talksProcessos,
  talksRealizadorProcesso,
  talksSetores,
  talksTrabalhadores,
} from "../../constants/aroldoTalks";

const StepsComponent = (props) => {
  const { getStepsCliente, perfil, stepsEmpresa } = props;
  const [current, setCurrent] = useState(0);
  const [talk, setTalk] = useState({});

  useEffect(() => {
    getStepsCliente();
  }, [JSON.stringify(props.stepsEmpresa)]);

  useEffect(() => {
    if (props.talks) {
      let talks = talksEmpresa(props.talks[0].descricao);
      setTalk(talks[props.talks[0].id]);
    }

    if(props?.perfil === "OPERADOR"){
      onChangeTalk(props?.stepsEmpresa?.EMPRESA ? "talk2" : "talk1")
    }
  }, [JSON.stringify(props.talks)]);

  const onChange = (current) => {
    setCurrent(current);
  };

  const onChangeTalk = (talk) => {
    let talks;
    switch (current) {
      case 0:
        if (talk === "talk1") {
          talks = talksEmpresa(props.talks[0].descricao);
          setTalk(talks[props.talks[0].id]);
        }
        if (talk === "talk2") {
          talks = stepsTalks.talksFinishedStep[1];
          setTalk(talks);
        }
        break;
      case 1:
        if (talk === "talk1") {
          talks = talksSetores(props.talks[1].descricao);
          setTalk(talks[props.talks[1].id]);
        }
        if (talk === "talk2") {
          talks = talksSetores(props.talks[2].descricao);
          setTalk(talks[props.talks[2].id]);
        }
        if (talk === "talk3") {
          talks = stepsTalks.talksFinishedStep[1];
          setTalk(talks);
        }
        break;
      case 2:
        if (talk === "talk1") {
          talks = talksTrabalhadores(props.talks[3].descricao);
          setTalk(talks[props.talks[3].id]);
        }
        if (talk === "talk2") {
          talks = talksTrabalhadores(props.talks[4].descricao);
          setTalk(talks[props.talks[4].id]);
        }
        if (talk === "talk3") {
          talks = stepsTalks.talksFinishedStep[1];
          setTalk(talks);
        }
        break;
      case 3:
        if (talk === "talk1") {
          talks = talksProcessos(props.talks[5].descricao);
          setTalk(talks[props.talks[5].id]);
        }
        if (talk === "talk2") {
          talks = talksProcessos(props.talks[6].descricao);
          setTalk(talks[props.talks[6].id]);
        }
        if (talk === "talk3") {
          talks = stepsTalks.talksFinishedStep[1];
          setTalk(talks);
        }
        break;
      case 4:
        if (talk === "talk1") {
          talks = talksRealizadorProcesso(props.talks[7].descricao);
          setTalk(talks[props.talks[7].id]);
        } 
        
        if(talk === "talk3") {
          talks = stepsTalks.talksFinishedStep[1];
          setTalk(talks);
        }
        break;
    }
  };

  const renderChild = (stepsOptions, current) => {
    return stepsOptions[current].content({
      current: current,
      nextStep: onChange,
      onChangeTalk: onChangeTalk,
      id: stepsOptions[current].id,
    });
  };

  const stepItem = (item, status) => {
    return (
      <Steps.Step
        key={item.id}
        title={item.title}
        className={status ? `${item.title} completed` : `${item.title}`}
        icon={<IconsSESI type={item.icon} status={status} />}
      />
    );
  };

  const stepsOptions = allSteps[perfil];

  return (
    <Row gutter={32}>
      <Assistant
        perfil={props.perfil}
        talk={talk}
        step={current}
        completed={false}
        customizacoes={props.customizacoes}
        companySelected={props.companySelected}
        path="caracterizacao"
      />
      <Col lg={16} md={24}>
        <Steps type="navigation" current={current} onChange={onChange}>
          {stepsOptions.map((item) => {
            if (isPerfilCliente(perfil)) {
              let stepAtual = item.id.toUpperCase();
              return stepItem(item, stepsEmpresa[stepAtual]);
            }
            return stepItem(item);
          })}
        </Steps>
        <div className="steps-content">
          {renderChild(stepsOptions, current)}
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    perfil: state.user.userInfo.perfil,
    customizacoes: state.user.userInfo ? state.user.userInfo.customizacoes : {},
    companySelected: state.caracterizacao.companySelected,
    talks: state.gestorDN.virtualAssistant.categorizationTalks,
  };
};

export default connect(mapStateToProps, {})(StepsComponent);
