export const AUTH_USER = "AUTH_USER";
export const LOGOUT = "LOGOUT";
export const SIGNIN_FAILURE = "SIGNIN_FAILURE";
export const USER_COMPANIES = "USER_COMPANIES";
export const MAKE_ADMIN = "MAKE_ADMIN";
export const MAKE_RESPONSAVEL = "MAKE_RESPONSAVEL";
export const MAKE_TECNICO = "MAKE_TECNICO";
export const MAKE_MEDICO = "MAKE_MEDICO";
export const MAKE_GESTOR_DR = "MAKE_GESTOR_DR";
export const EMPRESA_NAO_SELECIONADA = "EMPRESA_NAO_SELECIONADA";
export const FETCH_ANALISES = "FETCH_ANALISES";
export const UPDATE_ANALISE = "UPDATE_ANALISE";
export const TOGGLE_COLOR = "TOGGLE_COLOR";
export const TOGGLE_STEP = "TOGGLE_STEP";
export const UPDATE_CARACTERIZACAO = "UPDATE_CARACTERIZACAO";
export const AUTO_DECLARACAO = "AUTO_DECLARACAO";
