import { Button, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  getMAOPorGrupo,
  getMAO,
  emptyRelacaoMAOSelecionanda,
  getMAOsPorGrupo,
} from "../../actions";
import "../../../styles.scss";

const { Option } = Select;

const MAOList = (props) => {
  const [riskFactorList, setRiskFactorList] = useState([]);

  const handleGroupSelection = async (value) => {
    let riskFactorByGroup = await getMAOsPorGrupo(value);
    setRiskFactorList(riskFactorByGroup);
  };

  const handleEdit = (id) => {
    emptyRelacaoMAOSelecionanda(props.dispatch);
    getMAO(id, props.dispatch);
  };

  return (
    <React.Fragment>
      <div>
        <div>
          <Select
            style={{
              width: 250,
            }}
            placeholder="Selecione um grupo de risco"
            onSelect={(value) => handleGroupSelection(value)}
          >
            <Option value="1">Físico</Option>
            <Option value="2">Químico</Option>
            <Option value="3">Biológico</Option>
            <Option value="4">Ergonômico</Option>
            <Option value="5">Acidente</Option>
            <Option value="6">Inespecífico</Option>
          </Select>
        </div>
        <div className="dn-content">
          {riskFactorList.map((risk) => (
            <div key={risk.id} className="fatores-de-risco-item">
              <p>Fator de risco: {risk.descricao}</p>
              <p>Código: {risk.codigo}</p>
              {risk.maos.map((mao) => {
                return (
                  <div className="mao" key={mao.id}>
                    <p>MAO: {mao.nome}</p>
                    <p>Código MAO: {mao.id}</p>
                  </div>
                );
              })}
              <Link to="/formulario-mao">
                <Button onClick={() => handleEdit(risk.id)}>Editar</Button>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.userInfo,
    listaMAOsPorGrupo: state.gestorDN.listaMAOsPorGrupo
      ? state.gestorDN.listaMAOsPorGrupo
      : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ getMAOPorGrupo }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MAOList);
