import React, { useState } from "react";
import Button from "../../../../../../../components/Buttons/_/components/button";
import IconSESI from "../../../../../../../components/IconsSESI";
import { Form } from "antd";
import "../../styles.scss";
//import questions from "./questions";
import ProcessoProdutivoForm from "./ProcessoProdutivoForm";
//import ProcessoProdutivoQuiz from "./ProcessoProdutivoQuiz";

//const { Option } = Select;

const ProcessoProdutivoFormContainer = (props) => {
  const [form] = Form.useForm();
  const [viewOnly, setViewOnly] = useState(false);
  //const [loading, setLoading] = useState(false);
  //const [clearInput, setClearInput] = useState(false);
  //const [hasFinishedQuiz, setHasFinishedQuiz] = useState(false);

  //useState(() => {});

  // const iniciarModoEdicao = (process) => {};

  // const finishQuiz = () => {
  //   setHasFinishedQuiz(true);
  // };

  // const toggleLoading = () => {
  //   setLoading(loading ? false : true);
  // };

  // const resetState = () => {
  //   setViewOnly(false);
  //   form.resetFields();
  // };

  // useEffect(() => {
  //   setFatoresDeRisco(props.processoProdSelecionado.inventarios);
  // });

  const goToList = () => {
    form.resetFields();
    props.tab();
    props.changeMode("new");
    setViewOnly(false);
    props.onChangeTalk("talk1", 3);
  };

  return (
    <React.Fragment>
      <div className="header-form transparent">
        <Button
          shape="circle"
          ghost
          text={<IconSESI type="arrow-left" />}
          onClick={() => {
            goToList();
            props.changeMode("new");
          }}
        />
        <h2>
          {props.mode === "new" ? "Adicionar" : "Editar"} Processo Produtivo
        </h2>
      </div>

      <ProcessoProdutivoForm
        mode={props.mode}
        disabled={viewOnly}
        dispatch={props.dispatch}
        cnae={props.cnae}
        addProcessoProdutivo={props.addProcessoProdutivo}
        processosProdutivosDisponiveis={props.processosProdutivosDisponiveis}
        goToList={goToList}
        changeMode={props.changeMode}
        processoProdSelecionado={
          props.mode === "edit" || props.mode === "accept"
            ? props.processoProdSelecionado
            : {}
        }
        processos={props.processos}
        acceptProductiveProcess={props.acceptProductiveProcess}
      />
    </React.Fragment>
  );
};

export default ProcessoProdutivoFormContainer;
