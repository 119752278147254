import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { listarEmpresasAtivas } from "./actions";

import "./styles.scss";
import EmpresasAtivasCard from "./components/EmpresasAtivasCard";

const RelatorioEmpresas = (props) => {
  useEffect(() => {
    listarEmpresasAtivas(props.dispatch);
  }, []);

  return <EmpresasAtivasCard empresasAtivas={props.empresasAtivas} />;
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.userInfo,
    empresasAtivas: state.gestorDR.empresasAtivas.empresasAtivas,
  };
};

export default connect(mapStateToProps)(RelatorioEmpresas);
