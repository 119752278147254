import React, { Component } from "react";
import VirtualAssistant from "./_/components/VirtualAssistant";
import "./style.scss";
import { Col } from "antd";
import { TECNICO } from "../../constants/userTypes";

class Assistant extends Component {
  state = {
    extended: false,
  };

  extendCol = () => {
    const isExtended = this.state.extended ? false : true;
    this.setState({
      extended: isExtended,
    });
  };

  render() {
    if (this.props.perfil !== TECNICO) {
      if (this.props.path === "caracterizacao") {
        return (
          <Col lg={8} md={24}>
            <VirtualAssistant {...this.props} />
          </Col>
        );
      } else {
        return <VirtualAssistant {...this.props} />;
      }
    }
  }
}

export default Assistant;
