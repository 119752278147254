import React, {  useState } from "react";
import FinishStep from "../../../../../../../components/FinishStep";
import { RightCircleOutlined } from "@ant-design/icons";
import { Collapse} from "antd";
import { REALIZADOR_DE_PROCESSOS } from "../../../../../../../constants/stepTypes";
import moment from "moment";
import ProcessCard from "./ProcessCard";

const ProcessesList = (props) => {
  const { Panel } = Collapse;
  const [activeKey, setActiveKey] = useState(0);

  return (
    <div>
      <Collapse
        expandIconPosition="right"
        bordered={false}
        expandIcon={({ isActive }) => (
          <RightCircleOutlined rotate={isActive ? 90 : 0} />
        )}
        accordion
        activeKey={activeKey}
        onChange={(key) => {
          setActiveKey(key)
        }}
      >
        {props.processosProdutivos
          .filter(
            (proc) => proc.dataFim === null || moment(proc.dataFim) > new Date()
          )
          .map((process) => (
            <Panel
              header={process.nome}
              key={process.idProcessoProdutivoDaEmpresa}
            >
              <ProcessCard process={process} props={props} />
            </Panel>
          ))}
      </Collapse>
      <FinishStep
        completed={props.completed}
        url="realizador-processo"
        perfil={props.perfil}
        step={REALIZADOR_DE_PROCESSOS}
      />
    </div>
  );
};

export default ProcessesList;
