import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Inicio from "../../scenes/Inicio";
import PageNotFound from "../../scenes/PageNotFound";
import { logout } from "../../shared/LayoutApp/_/actions";

//Data Imports
import MenuItems from "../../constants/menuItems";

class Routes extends Component {
  render() {
    return (
      <>
        <Switch>
          <Route exact={true} path="/" component={Inicio} />
          <Route
            path="/logout"
            component={() => {
              this.props.logout(() => {}, true);
              return null;
            }}
          />
          {MenuItems(this.props.company.nomeFantasia)[this.props.perfil].map(
            (route) => {
              return (
                <Route
                  key={route.key}
                  path={route.path}
                  render={(props) => route.component({ ...props })}
                />
              );
            }
          )}
          <Route path="" component={PageNotFound} />
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    perfil: state.user.userInfo ? state.user.userInfo.perfil : "DESLOGADO",
    company: state.caracterizacao ? state.caracterizacao.companySelected : {},
  };
};

export default connect(mapStateToProps, { logout })(Routes);
