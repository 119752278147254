import React, { useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Input, Tag, Tooltip } from "antd";

const TagsList = (props) => {
  const [tags, setTags] = useState(["test"]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const inputRef = useRef(null);
  const editInputRef = useRef(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    if (editInputRef.current) editInputRef.current.focus();
  }, [inputValue]);

  useEffect(() => {
    if (props.type === "lote") setTags([props.tags]);
    if (props.type === "processos") setTags(props.tags);
    if (props.type === "tags") setTags(props.tags);
  }, [props.tags]);

  const handleClose = (removedTag, id) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    props.changeTags(id, newTags);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = (id) => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
    props.changeTags(id, [...tags, inputValue]);
  };

  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue("");
  };

  return (
    <>
      {props.type === "lote" && tags ? (
        <>
          {tags.map((tag) => (
            <Tag closable={false} key={tag}>
              <span>{`Lote: ${tag}`}</span>
            </Tag>
          ))}
          {/* {inputVisible && (
            <Input
              ref={inputRef}
              type="text"
              size="small"
              className="tag-input"
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
            />
          )} */}
        </>
      ) : props.type === "tags" ? (
        <>
          {tags?.map((tag) => (
            <Tag
              closable={true}
              key={tag}
              onClose={() => handleClose(tag, props.id)}
            >
              <span>{`${tag}`}</span>
            </Tag>
          ))}
          {inputVisible && (
            <Input
              ref={inputRef}
              type="text"
              size="small"
              className="tag-input"
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={() => handleInputConfirm(props.id)}
            />
          )}
          {!inputVisible && (
            <Tag className="site-tag-plus" onClick={() => showInput()}>
              <PlusOutlined /> Nova Tag
            </Tag>
          )}
        </>
      ) : (
        <>
          {tags.map((tag, index) => {
            if (editInputIndex === index) {
              return (
                <Input
                  ref={editInputRef}
                  key={tag}
                  size="small"
                  className="tag-input"
                  value={editInputValue}
                  onChange={handleEditInputChange}
                  onBlur={handleEditInputConfirm}
                  onPressEnter={handleEditInputConfirm}
                />
              );
            }

            const isLongTag = tag.length > 20;
            const tagElem = (
              <Tag
                className="edit-tag"
                key={tag}
                onClose={() => handleClose(tag)}
              >
                <span
                  onDoubleClick={(e) => {
                    if (index !== 0) {
                      setEditInputIndex(index);
                      setEditInputValue(tag);
                      e.preventDefault();
                    }
                  }}
                >
                  {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                </span>
              </Tag>
            );
            return isLongTag ? (
              <Tooltip title={tag} key={tag}>
                {tagElem}
              </Tooltip>
            ) : (
              tagElem
            );
          })}
        </>
      )}
    </>
  );
};

export default TagsList;
