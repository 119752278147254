import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { selectCompany } from "./_/actions";
import CompanyCard from "./_/components/CompanyCard";

const Companies = (props) => {
  const { company } = props;

  return (
    <CompanyCard
      dispatch={props.dispatch}
      colSpan={props.colSpan}
      key={company.cnpj}
      company={company}
      statusCaracterizacao={{}}
      selectCompanyAction={selectCompany}
      selectedCompany={props.selectedCompany}
      perfil={props.perfil}
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    perfil: state.user.userInfo,
    selectedCompany: state.caracterizacao.companySelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ selectCompany }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Companies);
