import { TECNICO } from "./userTypes";

var url = "";
if (process.env.REACT_APP_BACKEND_URL) {
  url = `${process.env.REACT_APP_BACKEND_URL}`;
}

const basePath = "api";
//export const userUrl = cpf => `${url}/rst/api/public/v1/usuarios/dados/${cpf}`;
export const authUrl = `${url}/${basePath}/perfis`;
export const usuarioUrl = `${url}/${basePath}/usuario`;
export const responsavelUrl = `${url}/${basePath}/fake/usuarios/cliente`;
export const tecnicoUrl = `${url}/${basePath}/fake/usuarios/tecnico`;
export const medicoUrl = `${url}/${basePath}/fake/usuarios/medico`;
export const administradorUrl = `${url}/${basePath}/fake/usuarios/administrador`;
export const gestorDrUrl = `${url}/${basePath}/fake/usuarios/gestor-dr`;
export const planoUrl = `${url}/${basePath}/acoes`;
export const acoesCatalogoUrl = `${url}/${basePath}/acoes/novo`;
export const maquinaUrl = `${url}/${basePath}/maquinas`;
export const possuiMaquinasUrl = (possuiMaq) =>
  `${url}/${basePath}/empresas/possui-maquinas/${possuiMaq}`;
export const maquinasCatalogoURL = `${url}/${basePath}/maquinas/novo`;

export const empresasUrl = `${url}/${basePath}/empresas`;
export const companiesUrl = `${url}/${basePath}/empresas/perfil`;
export const preenchimentoUrl = `${url}/${basePath}/empresas/iniciar-preenchimento`;
export const nomeFantasiaUrl = `${url}/${basePath}/empresas/nome-fantasia`;
export const responsavelEmpresa = `${url}/${basePath}/empresas/responsaveis `;
export const contatoEmpresa = `${url}/${basePath}/empresas/contatos `;
export const responsavelPreenchimentoEmpresa = `${url}/${basePath}/empresas/responsaveis-preenchimento`;
export const finishQuestionarioUrl = `${url}/${basePath}/empresas/questionario-autodeclaracao`;
export const finishAutodeclaracaoUrl = `${url}/${basePath}/empresas/autodeclaracao`;
export const documentosUrl = `${url}/${basePath}/documentos`;

export const setoresUrl = `${url}/${basePath}/setores`;
export const setorUrl = (id) => `${url}/${basePath}/setores/${id}`;
export const novoSetoresUrl = `${url}/${basePath}/setores/novo`;
export const atividadesUrl = `${url}/${basePath}/atividades`;
export const funcionariosUrl = `${url}/${basePath}/funcionarios`;
export const cargosUrl = `${url}/${basePath}/funcionarios/novo`;
export const produtosQuimicosUrl = `${url}/${basePath}/produtos-quimicos`;
export const fatoresRiscoUrl = `${url}/${basePath}/fatores-de-risco`;
export const possuiFatoresRiscoUrl = (possuiFatorRisco) =>
  `${url}/${basePath}/empresas/possui-fatores-de-risco/${possuiFatorRisco}`;
export const usaProdutosQuimicosUrl = (usaProdQuim) =>
  `${url}/${basePath}/empresas/usa-produtos-quimicos/${usaProdQuim}`;
export const espacosFisicosUrl = `${url}/${basePath}/espacos-fisicos`;
export const dashboardUrl = `${url}/${basePath}/documentos/visualizar-pgr`;
export const dashboardTrabalhadoresUrl = `${url}/${basePath}/documentos/visualizar-trabalhadores-pgr`;
export const inventariosFatoresDeRiscoUrl = `${url}/${basePath}/fatores-de-risco`;
export const inventariosFatoresDeRiscoProcessosUrl = `${url}/${basePath}/inventarios/fatores-de-risco`;
export const listaDeInformacoesUrl = `${url}/${basePath}/lista-informacoes`;
export const autoDeclaracaoUrl = `${url}/${basePath}/auto-declaracao`;
export const fatorDeRiscoURL = `${url}/${basePath}/fatores-de-risco`;
export const fatorDeRiscoPorTipo = (id) => `${fatorDeRiscoURL}/por-tipo/${id}`;
export const processosProdutivos = `${url}/${basePath}/processos-produtivos`;
export const recusarProcessoProdutivo = `${url}/${basePath}/processos-produtivos/recusar`;
export const processosProdutivosPorCnae = `${url}/${basePath}/processos-produtivos/cnaes`;
export const incluirFuncionarios = `${url}/${basePath}/processos-produtivos/incluir-funcionarios`;
export const listarProcesso = `${url}/${basePath}/processos-produtivos`;
export const incluirProcessoProdutivo = `${url}/${basePath}/inventarios/fatores-de-risco/incluir-do-catalogo`;
export const inventarioUrl = `${url}/${basePath}/processos-produtivos`;
export const deletarFatorDeRisco = (id) =>
  `${url}/${basePath}/inventarios/${id}`;
export const inventarioDeRiscoPorProcesso = (id) =>
  `${url}/${basePath}/inventarios/processos-produtivos/${id}`;
export const fatorDeRiscoEditURL = (id) =>
  `${url}/${basePath}/inventarios/fatores-de-risco/${id}`;
export const epcepimaoUrl = `${url}/${basePath}/epc-epi-mao`;

export const planoDeAcaoURL = `${url}/${basePath}/inventarios/fatores-de-risco`;
export const finalizarPlanoDeAcao = `${url}/${basePath}/empresas/controle/fatores-de-risco`;
export const acoesCatalogo = `${url}/${basePath}/acoes`;
export const recusarAcao = `${url}/${basePath}/acoes/recusar`;

export const redefinirSenhaUrl = (cpf) =>
  `${url}/usuario/forgot-password/${cpf}`;
export const redefinirSenha = `${url}/usuario/forgot-password`;

export const perguntasAutodeclaracaoUrl = `${url}/${basePath}/questionario-autodeclaracao`;
export const respostasAutodeclaracao = `${url}/${basePath}/questionario-autodeclaracao-respostas`;

export const lead = `${url}/${basePath}/leads`;

export const supportMessage = `${url}/emails/suporte`;

// ESOCIAL
export const categoriasTrabsEsocial = `${url}/${basePath}/categorias-trabalhadores`;
export const examesEsocial = `${url}/${basePath}/procedimentos`;
export const postMonitoramento = `${url}/${basePath}/empresas/esocial/s2220`;
export const certificadoDigital = `${url}/${basePath}/certificados-digitais`;
export const getCertificadoDigital = (id) =>
  `${url}/${basePath}/certificados-digitais/${id}`;
export const eventosEsocial = `${url}/${basePath}/empresas/esocial/s2220`;
export const obterEsocial = (id) =>
  `${url}/${basePath}/empresas/esocial/s2220/${id}`;
export const alterarS2220 = (id) =>
  `${url}/${basePath}/empresas/esocial/s2220/${id}`;
export const postS2210 = `${url}/${basePath}/empresas/esocial/s2210`;
export const generativeSituation = `${url}/${basePath}/situacoes-geradoras`;
export const publicPlace = `${url}/${basePath}/logradouros`;
export const affeectedParts = `${url}/${basePath}/partes-atingidas`;
export const causativeAgents = `${url}/${basePath}/agentes-causadores`;
export const natureInjures = `${url}/${basePath}/natureza-lesoes`;
export const countries = `${url}/${basePath}/paises`;
export const eventosEsocials2210 = `${url}/${basePath}/empresas/esocial/s2210`;
export const obterEventoS2210 = (id) =>
  `${url}/${basePath}/empresas/esocial/s2210/${id}`;
export const updateS2210 = (id) =>
  `${url}/${basePath}/empresas/esocial/s2210/${id}`;
export const harmfulAgents = `${url}/${basePath}/inventarios/agentes-causadores/fatores-de-risco`;
export const consultarCep = (cep) =>
  `${url}/${basePath}/empresas/esocial/s2210/consulta/${cep}`;

// New URLs eSocial
export const sendEsocial = (eventType) =>
  `${url}/${basePath}/documentos/esociais/${eventType}/enviar`;
export const generateEsocial = (eventType, id) =>
  `${url}/${basePath}/documentos/esociais/${eventType}/${id}`;
export const consultEsocial = (eventType) =>
  `${url}/${basePath}/documentos/esociais/${eventType}/consultar`;
export const getOrPostEsocial = (eventType) =>
  `${url}/${basePath}/empresas/esocial/${eventType}`;

//// S-3000
export const salvarS3000 = `${url}/${basePath}/empresas/esocial/s3000`;
export const gerarXMLS3000 = (id) =>
  `${url}/${basePath}/documentos/esociais/s3000/${id}`;
export const listarS3000 = `${url}/${basePath}/empresas/esocial/s3000`;
export const obterXMLS3000 = (id) =>
  `${url}/${basePath}/empresas/esocial/s3000/${id}`;

//// S-2240
export const salvarS2240 = `${url}/${basePath}/empresas/esocial/s2240`;
export const alterarS2240 = (id) =>
  `${url}/${basePath}/empresas/esocial/s2240/${id}`;
export const listarS2240 = `${url}/${basePath}/empresas/esocial/s2240`;
export const obterS2240 = (id) =>
  `${url}/${basePath}/empresas/esocial/s2240/${id}`;
export const listarAgentesNocivos = `${url}/${basePath}/codigos-agente-nocivo`;
export const obterAgenteNocivo = (id) =>
  `${url}/${basePath}/codigos-agente-nocivo/${id}`;

// ADMIN URLS
export const adminUrl = `${url}/${basePath}/admin`;
export const listAgentesNocivos = `${adminUrl}/codigos-agente-nocivo`;
export const produtosQuimicosAdminUrl = `${adminUrl}/produtos-quimicos`;
export const substanciasQuimicasUrl = `${adminUrl}/substancias-quimicas`;
export const fatoresDeRiscoAdminUrl = `${adminUrl}/fatores-de-risco`;
export const fatorDeRiscoAdminUrl = (id) =>
  `${adminUrl}/fatores-de-risco/${id}`;
export const EPCsUrl = `${adminUrl}/epcs`;
export const EPIsUrl = `${adminUrl}/epis`;
export const MAOsUrl = `${adminUrl}/maos`;
export const EPIUrl = (id) => `${adminUrl}/epis/${id}`;
export const EPCUrl = (id) => `${adminUrl}/epcs/${id}`;
export const MAOUrl = (id) => `${adminUrl}/maos/${id}`;
export const substanciaQuimicaUrl = (id) =>
  `${adminUrl}/substancias-quimicas/${id}`;
export const setoresCatalogoUrl = `${adminUrl}/setores`;
export const examesUrl = `${adminUrl}/exames`;
export const exameUrl = (id) => `${adminUrl}/exames/${id}`;
export const formasFisicasUrl = `${adminUrl}/formas-fisicas`;
export const formaFisicaUrl = (id) => `${adminUrl}/formas-fisicas/${id}`;
export const plantasBaixasUrl = `${adminUrl}/plantas-baixas`;
export const plantaBaixaUrl = (id) => `${adminUrl}/plantas-baixas/${id}`;
export const itensChecklistAdminUrl = `${adminUrl}/lista-informacoes`;
export const itemChecklistAdminUrl = (id) =>
  `${adminUrl}/lista-informacoes/${id}`;
export const emailsGestoresUrl = `${adminUrl}/responsaveis-departamentos`;
export const emailGestoresUrl = (id) =>
  `${adminUrl}/responsaveis-departamentos/${id}`;
export const sugestoesTecnicasAdminUrl = `${adminUrl}/sugestoes`;
export const sugestaoTecnicasAdminUrl = (id) => `${adminUrl}/sugestoes/${id}`;
export const colaboradoresUrl = `${adminUrl}/usuarios/colaboradores`;
export const colaboradorUrl = (cpf) => `${colaboradoresUrl}/${cpf}`;
export const colaboradorBloqueioUrl = (cpf, status) =>
  `${colaboradoresUrl}/${cpf}/bloquear/${status}`;
export const colaboradorAssociarDepartamentoUrl = (
  cpf,
  idDepartamento,
  status
) => `${colaboradoresUrl}/${cpf}/departamentos/${idDepartamento}/${status}`;
export const empresasComPGREmitidoUrl = `${url}/${basePath}/empresas/perfil-dr`;
export const gestorDRDashboard = `${url}/${basePath}/empresas/perfil-dr/dashboard`;
export const empresasAtivasComPGREmitidoUrl = `${empresasComPGREmitidoUrl}/ativas`;
export const planilha1 = (cnpj) =>
  `${url}/${basePath}/planilhas/planilha1/${cnpj}`;
export const planilhaX = (cnpj) =>
  `${url}/${basePath}/planilhas/planilhaX/${cnpj}`;

export const relatorioSMD = `${adminUrl}/exportar/funcionarios`;
export const relatorioEmpresasAtivas = `${url}/${basePath}/empresas/perfil-dr/planilha`;

export const listarTodosFatoresDeRiscoPath = `${adminUrl}/fatores-de-risco`;
export const listarFatoresDeRiscoPorGrupo = `${adminUrl}/fatores-de-risco/por-grupo`;
export const incluirFatorDeRisco = `${adminUrl}/fatores-de-risco`;
export const editarFatorDeRisco = (id) => `${adminUrl}/fatores-de-risco/${id}`;
export const obterFatorDeRisco = (id) => `${adminUrl}/fatores-de-risco/${id}`;

export const listarCNAEsAutomatizados = `${adminUrl}/cnaes-automatizados`;
export const listarCNAEsNaoAutomatizados = `${adminUrl}/cnaes-nao-automatizados`;
export const listarCNAEsFaltantes = `${adminUrl}/cnaes-faltantes`;
export const alterarTagsCNAESAutomatizados = (id) =>
  `${adminUrl}/cnaes-automatizados/${id}`;
export const salvarCNAEFaltante = `${adminUrl}/cnaes-faltantes`;

export const listarMAOs = `${adminUrl}/maos`;
// export const listarEPCs = `${adminUrl}/epcs`;

export const epcsPorGrupo = (id) =>
  `${adminUrl}/fatores-de-risco-epcs/por-grupo/${id}`;
export const obterRelacaoFatorDeRiscoEPC = (id) =>
  `${adminUrl}/fatores-de-risco-epcs/${id}`;

export const episPorGrupo = (id) =>
  `${adminUrl}/fatores-de-risco-epis/por-grupo/${id}`;
export const obterRelacaoFatorDeRiscoEPI = (id) =>
  `${adminUrl}/fatores-de-risco-epis/${id}`;

export const maosPorGrupo = (id) =>
  `${adminUrl}/fatores-de-risco-maos/por-grupo/${id}`;
export const obterRelacaoFatorDeRiscoMAO = (id) =>
  `${adminUrl}/fatores-de-risco-maos/${id}`;

export const listarMAOPorGrupo = `${adminUrl}/fatores-de-risco-maos/por-grupo`;
export const listarEPCPorGrupo = `${adminUrl}/fatores-de-risco-epcs/por-grupo`;
export const alterarRelacaoFatorDeRiscoMAO = (id) =>
  `${adminUrl}/fatores-de-risco-maos/${id}`;

export const listarEPIs = `${adminUrl}/epis`;
export const listarEPIPorGrupo = `${adminUrl}/fatores-de-risco-epis/por-grupo`;
export const alterarRelacaoFatorDeRiscoEPI = (id) =>
  `${adminUrl}/fatores-de-risco-epis/${id}`;

export const exportExcelCnaes = `${adminUrl}/cnaes-automatizados/planilha-cnaes-automatizados`;

//Sugestoes URL
export const sugestoesUrl = `${url}/${basePath}/tecnicos/analises/sugestoes`;
export const sugestoesClienteUrl = `${url}/${basePath}/analises/sugestoes`;
export const sugestaoTecnicasUrl = `${url}/${basePath}/tecnicos/analises/sugestoes/novo`;
export const sugestaoUrl = (id) =>
  `${url}/${basePath}/tecnicos/analises/sugestoes/${id}`;
export const responderSugestaoUrl = (id, answer) =>
  `${url}/${basePath}/analises/sugestoes/${id}/${answer}`;
export const analisesUrl = (perfil) =>
  `${url}/${basePath}/${perfil === TECNICO ? "tecnicos" : "medicos"}/analises`;
export const analiseUrl = (perfil, id) =>
  `${url}/${basePath}/${
    perfil === TECNICO ? "tecnicos" : "medicos"
  }/analises/${id}`;
export const createAnaliseUrl = `${url}/${basePath}/analises/tecnica`;
export const generatePGRUrl = `${url}/${basePath}/documentos/pgr`;
export const generateAutodeclaracaoUrl = `${url}/${basePath}/documentos/autodeclaracao`;
export const generatePCMSOUrl = `${url}/${basePath}/medicos/analises/pcmso`;
export const examesMedicos = (str) =>
  `${url}/${basePath}/medicos/exames?nome=${str}`;
export const selecionarDrUrl = (id) => `${url}/${basePath}/selecionardr/${id}`;

//Relatorios URL
export const relatorioTecnicoUrl = `${url}/${basePath}/tecnicos/analises/relatorios`;
export const relatorioMedicoUrl = `${url}/${basePath}/medicos/analises/relatorios`;
export const relatorioAdminUrl = `${adminUrl}/relatorios/analises`;
export const relatorioDiarioAdminUrl = `${adminUrl}/relatorios/diario`;
export const relatoriosHomeDiario = `${adminUrl}/relatorios/resumo/diario`;
export const relatoriosHomeMensal = `${adminUrl}/relatorios/resumo/mensal`;
export const relatoriosHomeEmpresasEfetivadas = `${adminUrl}/relatorios/resumo/empresas-efetivadas`;
export const relatoriosAnalistaEmpresas = `${adminUrl}/relatorios/analistas-empresas`;

//Switches
export const switchGetStepsCliente = `${url}/${basePath}/etapas`;
export const switchClienteUrl = (step, accepted) =>
  `${url}/${basePath}/${step}/concluida/${accepted}`;
export const switchTecnicoUrl = `${url}/${basePath}/tecnicos/analises/etapas`;
export const switchStatusFluxo = `${url}/${basePath}/empresas/status-fluxo`;

//Session
export const logoutUrl = `${url}/${basePath}/sair`;

//Editar Textos Assistente Virtual
export const virtualAssistantBeginning = `${url}/${basePath}/textos/assistentes-virtuais-inicio`;
export const virtualAssistantBeginningPatch = (id) =>
  `${adminUrl}/textos/assistentes-virtuais-inicio/${id}`;
export const virtualAssistantCategorization = `${url}/${basePath}/textos/assistentes-virtuais-categorizacao`;
export const virtualAssistantCategorizationPatch = (id) =>
  `${adminUrl}/textos/assistentes-virtuais-categorizacao/${id}`;
export const virtualAssistantRisks = `${url}/${basePath}/textos/assistentes-virtuais-inventario-riscos`;
export const virtualAssistantRisksPatch = (id) =>
  `${adminUrl}/textos/assistentes-virtuais-inventario-riscos/${id}`;
export const virtualAssistantActionPlans = `${url}/${basePath}/textos/assistentes-virtuais-plano-acoes`;
export const virtualAssistantActionPlansPatch = (id) =>
  `${adminUrl}/textos/assistentes-virtuais-plano-acoes/${id}`;
export const virtualAssistantMonitoring = `${url}/${basePath}/textos/assistentes-virtuais-acompanhamentos`;
export const virtualAssistantMonitoringPatch = (id) =>
  `${adminUrl}/textos/assistentes-virtuais-acompanhamentos/${id}`;
export const virtualAssistantAutostatement = `${url}/${basePath}/textos/assistentes-virtuais-autodeclaracoes`;
export const virtualAssistantAutostatementPatch = (id) =>
  `${adminUrl}/textos/assistentes-virtuais-autodeclaracoes/${id}`;
export const virtualAssistantEsocial = `${url}/${basePath}/textos/assistentes-virtuais-esociais`;
export const virtualAssistantEsocialPatch = (id) =>
  `${adminUrl}/textos/assistentes-virtuais-esociais/${id}`;
export const virtualAssistantDigitalSignature = `${url}/${basePath}/textos/assistentes-virtuais-assinaturas`;
export const virtualAssistantDigitalSignaturePatch = (id) =>
  `${adminUrl}/textos/assistentes-virtuais-assinaturas/${id}`;
