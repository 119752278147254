import React, { useState } from "react";
import { Form, Row, Col, List, Tooltip, Input } from "antd";
import Button from "../../../../../../../components/Buttons/_/components/button";
import { QuestionCircleOutlined, CloseOutlined } from "@ant-design/icons";

const FormList = (props) => {
  const [inputValue, setInputValue] = useState("");

  const addItem = () => {
    setInputValue("");
    props.addItem(props.type, inputValue);
  };

  const emptyText = " ";

  const onChange = (data) => {
    setInputValue(data.target.value);
  };

  return (
    <>
      {!props.disabled ? (
        <Form.Item
          name={
            props.type === "maquinas"
              ? "processMachines"
              : "processChemicalProducts"
          }
          label={
            props.type === "maquinas" ? (
              <span>
                Máquinas utilizadas no processo produtivo&nbsp;
                <Tooltip title="Adicione aqui máquinas e equipamentos utilizados neste processo produtivo.">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            ) : (
              <span>
                Produtos químicos utilizados no processo produtivo&nbsp;
                <Tooltip title="Adicione aqui os produtos químicos utilizados neste processo produtivo.">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            )
          }
        >
          <Row gutter={24}>
            <Col span={21}>
              <Input value={inputValue} onChange={(e) => onChange(e)} />
            </Col>
            <Col span={3}>
              <Button
                block
                ghost
                text="Adicionar"
                style={{ padding: "6px 15px", width: "auto" }}
                onClick={() => addItem()}
                disabled={!inputValue}
              />
            </Col>
          </Row>
        </Form.Item>
      ) : (
        <Form.Item
          label="Cargos do setor"
          style={{ marginBottom: 0 }}
        ></Form.Item>
      )}
      <List
        dataSource={
          props.type === "maquinas"
            ? props.processMachines
            : props.processChemicalProducts
        }
        locale={{ emptyText: emptyText }}
        renderItem={(item) => (
          <List.Item
            className={`item-list cargos-list ${
              props.disabled ? "input-disabled" : ""
            }`}
          >
            {item}
            {!props.disabled && (
              <Button
                onClick={() => props.removeItem(props.type, item)}
                type="link"
                ghost
                text={<CloseOutlined />}
              />
            )}
          </List.Item>
        )}
      />
    </>
  );
};

export default FormList;
