import React, { Component, useEffect, useState } from "react";
import { Card } from "antd";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { Link } from "react-router-dom";
import moment from "moment";
import IconSESI from "../../../../../../../components/IconsSESI";

const CustomTooltip = ({ active, payload, label }) => {
  const getMonth = (month) => {
    let monthName;
    switch (month) {
      case "01":
        monthName = "Janeiro";
        break;
      case "02":
        monthName = "Fevereiro";
        break;
      case "03":
        monthName = "Março";
        break;
      case "04":
        monthName = "Abril";
        break;
      case "05":
        monthName = "Maio";
        break;
      case "06":
        monthName = "Junho";
        break;
      case "07":
        monthName = "Julho";
        break;
      case "08":
        monthName = "Agosto";
        break;
      case "09":
        monthName = "Setembro";
        break;
      case "10":
        monthName = "Outubro";
        break;
      case "11":
        monthName = "Novembro";
        break;
      case "12":
        monthName = "Dezembro";
        break;
      default:
        break;
    }
    return monthName;
  };

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">
          <strong>{`${getMonth(label.split("/")[0])}`}</strong>
        </p>
        <p>{`PGR: ${payload[0].value}`}</p>
        <p>{`Autodeclaração: ${payload[1].value}`}</p>
      </div>
    );
  }

  return null;
};

const RelatorioMensalCard = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (Object.keys(props.data).length) {
      parseData(props.data);
    }
  }, [JSON.stringify(props.data)]);

  const parseData = (data) => {
    const parsedData = [];
    let parsedMonths = [];
    const months = Object.keys(data.autoDeclaracoesUltimoAno);
    const lastYearMonths = months
      .filter((month) =>
        month.includes(moment().subtract(1, "year").format("YYYY"))
      )
      .sort();
    const currentYearMonths = months
      .filter((month) => month.includes(moment().format("YYYY")))
      .sort();
    parsedMonths = [...lastYearMonths, ...currentYearMonths];
    parsedMonths.map((month) =>
      parsedData.push({
        mes: month,
        PGR: data.pgrsDoUltimoAno[month],
        Autodeclaração: data.autoDeclaracoesUltimoAno[month],
      })
    );
    setData(parsedData);
    return parsedData;
  };

  return (
    <div className="relatorio-mensal">
      <Card className="last-card" bordered={false} hoverable={true}>
        <div className="header">
          <h3>Quantitativo mensal</h3>
        </div>
        <div className="content">
          <ResponsiveContainer width={1200} height="100%">
            <LineChart
              data={data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dy={10} dataKey="mes" stroke="#192136" />
              <YAxis dx={-10} stroke="#192136" allowDecimals={false} />
              <CartesianGrid strokeDasharray="3 3" stroke="#192136" />
              <Tooltip content={<CustomTooltip />} />
              <Legend
                layout="vertical"
                align="right"
                verticalAlign="middle"
                iconType="square"
              />
              <Line
                type="linear"
                strokeWidth={3}
                dot={{ stroke: "var(--melrose)", strokeWidth: 5 }}
                dataKey="PGR"
                stroke="var(--melrose)"
              />
              <Line
                type="linear"
                strokeWidth={3}
                dot={{ stroke: "var(--comet)", strokeWidth: 5 }}
                dataKey="Autodeclaração"
                stroke="var(--comet)"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Card>
    </div>
  );
};

export default RelatorioMensalCard;
