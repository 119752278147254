import axios from "axios";
import { setupInterceptors } from "../../../../../../../auth/SetupInterceptors";
import { SELECT_COMPANY } from "../../../../../../../components/CompanyList/_/containers/Companies/_/actions/types";
import openNotification from "../../../../../../../components/notifications";
import {
  nomeFantasiaUrl,
  responsavelEmpresa,
  contatoEmpresa,
  responsavelPreenchimentoEmpresa,
  companiesUrl,
  empresasUrl,
} from "../../../../../../../constants";
import { USER_COMPANIES } from "../../../../../../../shared/LayoutApp/_/actions/types";

setupInterceptors();

export const saveNomeFantasia = async (dispatch, nomeFantasia) => {
  await axios.patch(nomeFantasiaUrl, { nomeFantasia }).then(async () => {
    await axios
      .get(companiesUrl, { withCredentials: true })
      .then((response) => {
        dispatch({ type: USER_COMPANIES, payload: response.data });
      })
      .catch((err) => {
        console.log(err);
      });
    openNotification("Nome fantasia salvo com sucesso!", "Salvo", "success");
  });
};

export const saveResponsavelEmpresa = (
  dispatch,
  responsavelEmpresaBody,
  toggleCanEditResponsible
) => {
  axios
    .patch(responsavelEmpresa, responsavelEmpresaBody)
    .then(() => {
      axios
        .get(empresasUrl)
        .then((response) => {
          dispatch({ type: SELECT_COMPANY, payload: response.data });
          openNotification(
            "Responsável pela empresa salvo com sucesso!",
            "Salvo",
            "success"
          );
          toggleCanEditResponsible(true);
        })
        .catch((err) => {
          console.log(err);
        });
    })
    .catch((err) => {
      console.log(err);
      openNotification(
        "Erro ao salvar responsável pela empresa!",
        "Tente novamente",
        "error"
      );
      toggleCanEditResponsible(false);
    });
};

export const saveContatoEmpresa = (
  dispatch,
  contatoEmpresaBody,
  toggleCanEditContact
) => {
  axios
    .patch(contatoEmpresa, contatoEmpresaBody)
    .then(() => {
      axios
        .get(empresasUrl)
        .then((response) => {
          dispatch({ type: SELECT_COMPANY, payload: response.data });
          openNotification(
            "Contato da empresa salvo com sucesso!",
            "Salvo",
            "success"
          );
          toggleCanEditContact(false);
        })
        .catch((err) => {
          console.log(err);
        });
    })
    .catch((err) => {
      console.log(err);
      openNotification(
        "Erro ao salvar o contato da empresa!",
        "Tente novamente",
        "error"
      );
      toggleCanEditContact(true);
    });
};

export const saveResponsavelPreenchimentoEmpresa = (
  dispatch,
  responsavelPreenchimentoEmpresaBody,
  toggleCanEditOperator
) => {
  axios
    .patch(responsavelPreenchimentoEmpresa, responsavelPreenchimentoEmpresaBody)
    .then(() => {
      axios
        .get(empresasUrl)
        .then((response) => {
          dispatch({ type: SELECT_COMPANY, payload: response.data });
          openNotification(
            "Responsável pelo preenchimento da empresa salvo com sucesso!",
            "Salvo",
            "success"
          );
          toggleCanEditOperator(false);
        })
        .catch((err) => {
          console.log(err);
        });
    })
    .catch((err) => {
      console.log(err);
      openNotification(
        "Erro ao salvar o contato da empresa!",
        "Tente novamente",
        "error"
      );
      toggleCanEditOperator(true);
    });
};
