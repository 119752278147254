import React from "react";
import { Form, Row, Col, Select, Input } from "antd";

const { Option } = Select;
const { Search } = Input;

const SearchBar = (props) => {
  const {
    filterList,
    handleOnCargos,
    handleOnSetores,
    handleOnTipo,
    name,
    selectSetorName,
    selectCargoName,
    selectTipoName,
    cargos,
    setores,
    tipo,
  } = props;

  return (
    <Form>
      <Row gutter={24}>
        <Col lg={6} md={12}>
          <Form.Item>
            <Search placeholder={`Pesquisar ${name}`} onChange={filterList} />
          </Form.Item>
        </Col>

        <Col lg={6} md={12}>
          <Form.Item>
            <Select
              showSearch
              allowClear
              placeholder={`Filtrar por ${selectSetorName}`}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleOnSetores}
            >
              {setores.map((setor, i) => (
                <Option key={setor} value={setor}>
                  {setor}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col lg={6} md={12}>
          <Form.Item>
            <Select
              showSearch
              allowClear
              placeholder={`Filtrar por ${selectCargoName}`}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleOnCargos}
            >
              {cargos.map((cargo, i) => (
                <Option key={i} value={cargo}>
                  {cargo}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col lg={6} md={12}>
          <Form.Item>
            <Select
              showSearch
              allowClear
              placeholder={`Filtrar por ${selectTipoName}`}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleOnTipo}
            >
              {tipo.map((stat, i) => (
                <Option key={stat} value={stat}>
                  {stat}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchBar;
