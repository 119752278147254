import { Button } from "antd";
import React from "react";
import IconSESI from "../../components/IconsSESI";
import imageAroldo from "../../constants/images/aroldo-header-icon.svg";
import imageVivi from "../../constants/images/vivi-header-icon.svg";
import "./style.scss";

export const HeaderWithModal = ({
  pageTitle,
  helpText,
  assistant,
  openModal,
  prevButton,
}) => {
  return (
    <div className={"headerAssistant"}>
      <div className={"buttonBack"}>
        {prevButton && (
          <Button shape="circle" ghost onClick={prevButton}>
            <IconSESI type="arrow-left" />
          </Button>
        )}
      </div>
      <div className={"pageTitle"}>
        <h2>{pageTitle}</h2>
      </div>
      <div className={"modalHelp"} onClick={openModal}>
        <p>{helpText}</p>
        <img
          src={assistant === "vivi" ? imageVivi : imageAroldo}
          alt="image-assistant-header"
        />
      </div>
    </div>
  );
};
