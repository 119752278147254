import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { emptyEPCSelecionado, getEPCFromList, getEPCs } from "../../actions";
import "../../../styles.scss";

const CadastroEPCList = (props) => {
  const [epcs, setEpcs] = useState([]);

  const onLoadScreen = async () => {
    let epcList = await getEPCs();

    setEpcs(epcList);
  };

  useEffect(() => {
    onLoadScreen();
  }, []);

  const handleEdit = (id) => {
    getEPCFromList(id, props.dispatch);
  };

  return (
    <React.Fragment>
      <div>
        <Link to="formulario-epc">
          <Button onClick={() => emptyEPCSelecionado(props.dispatch)}>
            Cadastrar EPC
          </Button>
        </Link>
      </div>
      <div className="dn-content">
        {epcs.map((epc) => (
          <div className="epc-item" key={epc.id}>
            <p>Código: {epc.id} </p>
            <p>EPC: {epc.nome}</p>
            <p>Situação: {epc.ativo ? "Ativo" : "Inativo"}</p>
            {epc.padrao && <p>Padrão para químicos</p>}
            <Link to="/formulario-epc">
              <Button onClick={() => handleEdit(epc.id)}>Editar</Button>
            </Link>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    listaEPCs: state.gestorDN.listaEPCs ? state.gestorDN.listaEPCs : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ getEPCs }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CadastroEPCList);
