import axios from "axios";
import openNotification from "../../../../components/notifications";
import {
  alterarS2220,
  alterarS2240,
  categoriasTrabsEsocial,
  certificadoDigital,
  consultEsocial,
  consultarCep,
  eventosEsocial,
  eventosEsocials2210,
  examesEsocial,
  funcionariosUrl,
  generateEsocial,
  gerarXMLS3000,
  getCertificadoDigital,
  getOrPostEsocial,
  harmfulAgents,
  listarAgentesNocivos,
  listarS2240,
  obterAgenteNocivo,
  obterEsocial,
  obterEventoS2210,
  obterS2240,
  postMonitoramento,
  postS2210,
  salvarS2240,
  salvarS3000,
  sendEsocial,
  updateS2210,
} from "../../../../constants";
import {
  getVirtualAssistantDigitalSignatureTalks,
  getVirtualAssistantEsocialTalks,
} from "../../../Admin/GestorDN/_/components/NewVirtualAssistant/actions/index";
import {
  ALTERAR_ASSINATURA,
  ALTERAR_S2220,
  ALTERAR_S2240,
  DELETAR_ASSINATURA,
  ENVIAR_MONITORAMENTO,
  GERAR_XML_S3000,
  LISTAR_AGENTES_NOCIVOS,
  LISTAR_ASSINATURAS,
  LISTAR_CATEGORIAS,
  LISTAR_EVENTOS,
  LISTAR_EXAMES,
  LISTAR_FUNCIONARIOS_ESOCIAL,
  LISTAR_S2240,
  OBTER_AGENTE_NOCIVO,
  OBTER_EVENTO,
  OBTER_S2240,
  SALVAR_ASSINATURA,
  SALVAR_S2240,
  SALVAR_S3000,
} from "./types";

export const listarCategorias = (dispatch) => {
  axios
    .get(categoriasTrabsEsocial)
    .then((response) => {
      dispatch({
        type: LISTAR_CATEGORIAS,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const listarExames = (dispatch) => {
  axios
    .get(examesEsocial)
    .then((response) => {
      dispatch({
        type: LISTAR_EXAMES,
        payload: response.data,
      });
    })
    .catch((err) => {});
};

export const listarFuncionariosEsocial = (dispatch) => {
  axios
    .get(funcionariosUrl)
    .then((response) => {
      dispatch({
        type: LISTAR_FUNCIONARIOS_ESOCIAL,
        payload: response.data,
      });
    })
    .catch((err) => {});
};

export const post2220 = async (new2220, resetFields, setFormMode, dispatch) => {
  await axios
    .post(postMonitoramento, new2220)
    .then((response) => {
      dispatch({
        type: ENVIAR_MONITORAMENTO,
        payload: response.data,
      });
      openNotification("Evento salvo com sucesso!", "", "success");
      resetFields();
      setFormMode("new");
    })
    .catch((err) =>
      openNotification("Erro ao salvar evento.", "Tente novamente.", "error")
    );
};

export const edit2220 = async (new2220, resetFields, setFormMode, dispatch) => {
  await axios
    .patch(alterarS2220(new2220.id), new2220)
    .then((response) => {
      dispatch({
        type: ALTERAR_S2220,
        payload: response.data,
      });
      openNotification("Evento salvo com sucesso!", "", "success");
      resetFields();
      setFormMode("new");
    })
    .catch((err) =>
      openNotification("Erro ao retificar evento.", "Tente novamente.", "error")
    );
};

export const listarCertificados = (dispatch) => {
  axios
    .get(certificadoDigital)
    .then((response) => {
      dispatch({
        type: LISTAR_ASSINATURAS,
        payload: response.data,
      });
    })
    .catch((err) => {});
};

export const postCertificado = (newSignature, dispatch) => {
  axios({
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
    data: newSignature,
    url: certificadoDigital,
  })
    .then((response) => {
      dispatch({
        type: SALVAR_ASSINATURA,
        payload: response.data,
      });
      openNotification("Assinatura salva com sucesso!", "", "success");
      listarCertificados(dispatch);
    })
    .catch((err) =>
      openNotification(
        "Erro ao salvar a assinatura.",
        "Tente novamente.",
        "error"
      )
    );
};

export const deleteCertificado = (id, dispatch) => {
  axios
    .delete(getCertificadoDigital(id))
    .then((response) => {
      dispatch({
        type: DELETAR_ASSINATURA,
      });
      openNotification("Assinatura excluída com sucesso.", "", "success");
      listarCertificados(dispatch);
    })
    .catch((err) =>
      openNotification("Erro ao excluir assinatura.", "", "error")
    );
};

export const listarEventos = async (dispatch) => {
  await axios
    .get(eventosEsocial)
    .then((response) => {
      dispatch({
        type: LISTAR_EVENTOS,
        payload: response.data,
      });
    })
    .catch((err) => {});
};

export const listar2220 = async (dispatch) => {
  let response = await axios
    .get(eventosEsocial)
    .then((response) => {
      return response.data.esociais2220;
    })
    .catch((err) => {
      return [];
    });
  return response;
};

export const listar2210 = async (dispatch) => {
  let response = await axios
    .get(eventosEsocials2210)
    .then((response) => {
      return response.data.esociais2210;
    })
    .catch((err) => {
      return [];
    });
  return response;
};

export const alterarCertificado = (id, newBody, dispatch) => {
  axios.patch(getCertificadoDigital(id), newBody).then((response) => {
    dispatch({
      type: ALTERAR_ASSINATURA,
    });
    openNotification("Assinatura padrão alterada com sucesso", "", "success");
  });
};

//FollowEvents
export const sendESocialEvent = async (eventType, formData, onLoadScreen) => {
  return await axios
    .post(sendEsocial(eventType), formData, { headers: { "ngrok-skip-browser-warning": "69420" } })
    .then((response) => {
      openNotification("Evento enviado com sucesso!", "", "success");
      return response.data;
    })
    .catch((err) => {
      openNotification("Erro ao enviar evento.", "", "error");
      return [];
    });
};

export const generateEsocialXml = async (eventType, id) => {
  return await axios
    .get(generateEsocial(eventType, id), {
      headers: { "ngrok-skip-browser-warning": "69420" },
    })
    .then((response) => {
      openNotification("XML gerado com sucesso!", "", "success");
      return response.data.url;
    })
    .catch((error) => {
      openNotification("Erro ao baixar XML.", "", "error");
      return "";
    });
};

export const consultEsocialEvent = async (eventType, formData) => {
  await axios
    .post(consultEsocial(eventType), formData, {
      headers: { "ngrok-skip-browser-warning": "69420" }
    })
    .then((response) => {
      if (eventType !== "s3000") {
        openNotification("Processamento realizado com sucesso!", "", "success");
      } else {
        openNotification(
          "Evento de exclusão enviado com sucesso.",
          "",
          "success"
        );
      }
      return response
    })
    .catch((error) => {
      openNotification("Erro ao realizar a consulta.", "", "error");
    });
};

export const createAndSendS3000 = async (formData) => {
  let responseConsult

  await axios
    .post(
      getOrPostEsocial("s3000"),
      formData,
      { headers: { "ngrok-skip-browser-warning": "69420" }, })
    .then(async (responseCreate) => {
      let newS3000FormData = new FormData();

      newS3000FormData.append("idEvento", responseCreate.data.id);
      newS3000FormData.append("tipoDoEvento", "s3000");

      await sendESocialEvent("s3000", newS3000FormData, undefined).then(
        async (responseSend) => {
          let consultS3000 = new FormData();
          consultS3000.append("protocolo", responseSend.protocoloEnvio);
          consultS3000.append("tipoDoEvento", "s3000");

          responseConsult = await consultEsocialEvent("s3000", consultS3000);

          return responseConsult
        });
    })
    .catch((err) =>
      openNotification("Erro ao enviar evento de exclusão", "", "error")
    );
};

export const obterEvento = async (dispatch, idEvento) => {
  axios
    .get(obterEsocial(idEvento))
    .then((response) => {
      dispatch({
        type: OBTER_EVENTO,
        payload: response.data,
      });
    })
    .catch((err) =>
      openNotification("Erro ao selecionar o evento", "", "error")
    );
};

export const obterEvento2210 = async (dispatch, idEvento) => {
  axios
    .get(obterEventoS2210(idEvento))
    .then((response) => {
      dispatch({
        type: OBTER_EVENTO,
        payload: response.data,
      });
    })
    .catch((err) =>
      openNotification("Erro ao selecionar o evento", "", "error")
    );
};

export const salvarESocial3000 = (dispatch, newS3000) => {
  axios
    .post(salvarS3000, newS3000)
    .then((response) => {
      dispatch({
        type: SALVAR_S3000,
        payload: response.data,
      });
    })
    .catch((err) =>
      openNotification("Erro ao salvar evento de exclusão", "", "error")
    );
};

export const gerarXMLESocial3000 = (dispatch, id) => {
  axios
    .get(gerarXMLS3000(id))
    .then((response) => {
      dispatch({
        type: GERAR_XML_S3000,
        payload: response.data,
      });
    })
    .catch((err) =>
      openNotification("Erro ao gerar evento de exclusão.", "", "error")
    );
};

export const salvarEsocialS2240 = async (
  dispatch,
  newS2240,
  resetFields,
  setFormMode
) => {
  await axios
    .post(salvarS2240, newS2240)
    .then((response) => {
      resetFields();
      setFormMode("new");
      dispatch({
        type: SALVAR_S2240,
        payload: response.data,
      });
      openNotification("Evento salvo com sucesso!", "", "success");
    })
    .catch((err) => openNotification("Erro ao salvar evento.", "", "error"));
};

export const alterarEsocialS2240 = async (
  dispatch,
  edittedS2240,
  resetFields,
  setFormMode
) => {
  await axios
    .patch(alterarS2240(edittedS2240.id), edittedS2240)
    .then((response) => {
      resetFields();
      setFormMode("new");
      dispatch({
        type: ALTERAR_S2240,
        payload: response.data,
      });
      openNotification("Evento alterado com sucesso.", "", "success");
    })
    .catch((err) => openNotification("Erro ao alterar o evento.", "", "error"));
};

export const listar2240 = async (dispatch) => {
  let response = await axios
    .get(listarS2240)
    .then((response) => {
      return response.data.esociais2240;
    })
    .catch((err) => {
      return [];
    });
  return response;
};
export const listarEsocialS2240 = (dispatch) => {
  axios
    .get(listarS2240)
    .then((response) => {
      dispatch({
        type: LISTAR_S2240,
        payload: response.data,
      });
    })
    .catch((err) =>
      openNotification("Erro ao listar eventos S-2240.", "", "error")
    );
};

export const obterEsocialS2240 = (dispatch, id) => {
  axios
    .get(obterS2240(id))
    .then((response) => {
      dispatch({
        type: OBTER_S2240,
        payload: response.data,
      });
    })
    .catch((err) =>
      openNotification("Erro ao selecionar o evento.", "", "error")
    );
};

export const listarAgtsNocivos = (dispatch) => {
  axios
    .get(listarAgentesNocivos)
    .then((response) => {
      dispatch({
        type: LISTAR_AGENTES_NOCIVOS,
        payload: response.data,
      });
    })
    .catch((err) =>
      openNotification("Erro ao listar agentes nocivos", "", "error")
    );
};

export const obterAgtNocivo = (dispatch, id) => {
  axios
    .get(obterAgenteNocivo(id))
    .then((response) => {
      dispatch({
        type: OBTER_AGENTE_NOCIVO,
        payload: response.data,
      });
    })
    .catch((err) =>
      openNotification("Erro ao selecionar o agente nocivo.", "", "error")
    );
};

export const post2210 = async (new2210, resetFields, setFormMode, dispatch) => {
  await axios
    .post(postS2210, new2210)
    .then((response) => {
      openNotification("Evento salvo com sucesso!", "", "success");
      resetFields();
      setFormMode("new");
    })
    .catch((err) => {
      openNotification("Erro ao salvar evento.", "Tente novamente.", "error");
    });
};

export const update2210 = async (
  new2210,
  resetFields,
  setFormMode,
  dispatch
) => {
  await axios
    .patch(updateS2210(new2210.id), new2210)
    .then((response) => {
      openNotification("Evento salvo com sucesso!", "", "success");
      resetFields();
      setFormMode("new");
    })
    .catch((err) => {
      openNotification("Erro ao salvar evento.", "Tente novamente.", "error");
    });
};

export const getHarmfulAgents = async () => {
  let response = await axios
    .get(harmfulAgents)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      openNotification(
        "Erro ao carregar os fatores de risco desta empresa.",
        "Tente novamente.",
        "error"
      );
      return [];
    });

  return response;
};

export const getCepInformation = async (cep) => {
  return await axios
    .get(consultarCep(cep))
    .then((response) => {
      if (response.data.erro) {
        openNotification(
          "Erro ao carregar as informações do CEP, verifique se o mesmo foi digitado corretamente.",
          "Tente novamente.",
          "error"
        );
        return [];
      }
      return response.data;
    })
    .catch((err) => {
      openNotification(
        "Erro ao carregar as informações do CEP, verifique se o mesmo foi digitado corretamente.",
        "Tente novamente.",
        "error"
      );
      return [];
    });
};

export const getAssistantTalks = async (pageTalks, pageName) => {
  const findCurrentTalk = (talks) => {
    return talks?.find((talk) => talk.subcategoria === pageName);
  };

  switch (pageTalks) {
    case "assinatura-digital":
      // apenas pagina da `Assinatura Digital`
      return findCurrentTalk(await getVirtualAssistantDigitalSignatureTalks());
    default:
      // todas as outras paginas do `eSocial`
      return findCurrentTalk(await getVirtualAssistantEsocialTalks());
  }
};
