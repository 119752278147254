import React from "react";
import { Form } from "antd";
import MaskedInput from "antd-mask-input";
import Button from "../../../../components/Buttons/_/components/button";
import IconSESI from "../../../../components/IconsSESI";
import openNotification from "../../../../components/notifications";

const CodeForm = (props) => {
  const [form] = Form.useForm();

  const resetState = () => {
    form.resetFields();
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (
          values.codigo === props.mudancaDeSenha.codigoValidador.codeValidator
        ) {
          props.tab("newPassword");
        } else {
          openNotification("Código inválido.", "", "error");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Form onFinish={() => handleSubmit()} form={form}>
      <div className="header-form transparent">
        <Button
          shape="circle"
          ghost
          text={<IconSESI type="arrow-left" />}
          onClick={() => {
            resetState();
            props.tab("password");
          }}
        />
        <h2>Recuperar Senha</h2>
      </div>
      <div className="login-form-body">
        <Form.Item
          name="codigo"
          rules={[{ required: true, message: "Este campo é obrigatório." }]}
          label={
            <React.Fragment>
              <h4>Insira o código de 8 dígitos enviado</h4>
              <h4>para o e-mail cadastrado.</h4>
            </React.Fragment>
          }
        >
          <MaskedInput placeholder="CÓDIGO" mask="********" />
        </Form.Item>
      </div>
      <div className="login-form-footer">
        <Button htmlType="submit" text="Enviar" />
      </div>
    </Form>
  );
};

export default CodeForm;
