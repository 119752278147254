export function validateCpf(cpfNumber) {
    cpfNumber = cpfNumber.replace(/\D/g, "");
    if (typeof cpfNumber !== "string") return false;

    cpfNumber = cpfNumber.replace(/[\s.-]*/gim, "");
    if (
      !cpfNumber ||
      cpfNumber.length != 11 ||
      cpfNumber == "00000000000" ||
      cpfNumber == "11111111111" ||
      cpfNumber == "22222222222" ||
      cpfNumber == "33333333333" ||
      cpfNumber == "44444444444" ||
      cpfNumber == "55555555555" ||
      cpfNumber == "66666666666" ||
      cpfNumber == "77777777777" ||
      cpfNumber == "88888888888" ||
      cpfNumber == "99999999999"
    ) {
      return false;
    }
    var soma = 0;
    var resto;
    for (var i = 1; i <= 9; i++)
      soma = soma + parseInt(cpfNumber.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;
    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(cpfNumber.substring(9, 10))) return false;
    soma = 0;
    for (var i = 1; i <= 10; i++)
      soma = soma + parseInt(cpfNumber.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;
    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(cpfNumber.substring(10, 11))) return false;
    return true;
  }