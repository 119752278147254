import axios from "axios";
import {
  planoDeAcaoURL,
  acoesCatalogo,
  recusarAcao,
  finalizarPlanoDeAcao,
} from "../../../../constants";
import {
  CRIAR_ACAO,
  EXCLUIR_ACAO,
  LISTAR_ACAO,
  LISTAR_PLANO_DE_ACAO,
  RECUSAR_ACAO,
} from "./types";
import openNotification from "../../../../components/notifications";

export const getPlanoDeAcao = (dispatch) => {
  axios
    .get(planoDeAcaoURL)
    .then((response) => {
      dispatch({
        type: LISTAR_PLANO_DE_ACAO,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const addAction = (
  dispatch,
  newPlannedAction,
  handleSwitchTab,
  loading
) => {
  loading(true);
  axios
    .post(`${acoesCatalogo}`, newPlannedAction)
    .then((response) => {
      openNotification("Ação adicionada com sucesso!", "", "success");
      dispatch({
        type: CRIAR_ACAO,
        payload: response.data,
      });
      loading(false);
      handleSwitchTab("list");
    })
    .catch((err) => {
      openNotification("Erro ao adicionar a ação!", "Tente novamente", "error");
      console.log(err);
    });
};

export const editAction = (
  dispatch,
  idAcao,
  action,
  handleSwitchTab,
  loading
) => {
  loading(true);
  axios
    .patch(`${acoesCatalogo}/${idAcao}`, action)
    .then(() => {
      axios
        .get(planoDeAcaoURL)
        .then((response) => {
          dispatch({
            type: LISTAR_PLANO_DE_ACAO,
            payload: response.data,
          });
          openNotification("Ação editada com sucesso!", "", "success");
          loading(false);
          handleSwitchTab("list");
        })
        .catch((err) => console.log(err));
    })
    .catch(() => {
      loading(false);
      handleSwitchTab("list");
      openNotification("Erro ao editar a ação!", "Tente novamente.", "error");
    });
};

export const fetchAction = (dispatch, idAcao) => {
  axios
    .get(`${acoesCatalogo}/${idAcao}`)
    .then((response) => {
      dispatch({
        type: LISTAR_ACAO,
        payload: response.data,
      });
    })
    .catch();
};

export const deleteAction = (dispatch, idAcao) => {
  axios
    .delete(`${acoesCatalogo}/${idAcao}`)
    .then((response) => {
      if (response.status === 200) {
        console.log(response);
        openNotification("Ação excluída com sucesso!", "", "success");
        dispatch({
          type: EXCLUIR_ACAO,
          payload: response.data,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      if (err) {
        openNotification("Falha ao excluir ação.", "", "error");
      }
    });
};

export const rejectSuggestedAction = (dispatch, id) => {
  axios
    .post(recusarAcao, { acaoDoCatalogo: id })
    .then((response) => {
      dispatch({
        type: RECUSAR_ACAO,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const finishActionPlanForRisk = (dispatch, riskId) => {
  console.log(riskId);
  axios
    .post(finalizarPlanoDeAcao, { idFatorDeRisco: riskId })
    .then(() => {
      axios
        .get(planoDeAcaoURL)
        .then((response) => {
          dispatch({
            type: LISTAR_PLANO_DE_ACAO,
            payload: response.data,
          });
          openNotification(
            "Ações do fator de risco concluídas com sucesso!",
            "Você pode passar para o próximo risco",
            "success"
          );
        })
        .catch((err) => console.log(err));
    })
    .catch((err) => console.error(err));
};
