import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Select, Switch, Input, Radio } from "antd";
import {
  percentageList,
  frequencyList,
  exposureTimeList,
  intensityPerceptionList,
  getDangerLevel,
} from "../constants/riskConfig";
import { alertNoControl } from "../../../../components/modals";
import { editRiskInventory } from "../actions";

const RiskCard = ({
  riskInventory,
  risk,
  dispatch,
  epcList,
  epiList,
  maoList,
  activeKey,
}) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [riskSavedStatus, setRiskSavedStatus] = useState({});

  const [measureFields, setMeasureFields] = useState([]);
  const [accidentFields, setAccidentFileds] = useState([]);

  const [epcOptions, setEpcOptions] = useState([]);
  const [epiOptions, setEpiOptions] = useState([]);
  const [maoOptions, setMaoOptions] = useState([]);
  const [loadingSelect, setLoadingSelect] = useState(true);

  const setOptionsList = () => {
    let currentEPC = [];
    let currentEPI = [];
    let currentMAO = [];

    epcList.forEach((epc) => {
      if (epc.idFatores.includes(risk.idFatorDeRisco) || epc.id === 0) {
        epc.ativo &&
          currentEPC.push({
            id: epc.id,
            nome: epc.nome,
          });
      }
    });
    risk.epcs.forEach((epc) => {
      if (!currentEPC.some((item) => item.id === epc.id)) {
        currentEPC.push({
          id: epc.id,
          nome: epc.nome,
        });
      }
    });

    epiList.forEach((epi) => {
      if (epi.idFatores.includes(risk.idFatorDeRisco) || epi.id === 0) {
        epi.ativo &&
          currentEPI.push({
            id: epi.id,
            nome: epi.nome,
          });
      }
    });
    risk.epis.forEach((epi) => {
      if (!currentEPI.some((item) => item.id === epi.id)) {
        currentEPI.push({
          id: epi.id,
          nome: epi.nome,
        });
      }
    });

    maoList.forEach((mao) => {
      if (mao.idFatores.includes(risk.idFatorDeRisco) || mao.id === 0) {
        mao.ativo &&
          currentMAO.push({
            id: mao.id,
            nome: mao.nome,
          });
      }
    });
    risk.maos.forEach((mao) => {
      if (!currentMAO.some((item) => item.id === mao.id)) {
        currentMAO.push({
          id: mao.id,
          nome: mao.nome,
        });
      }
    });

    if (epcList.length != 0 && epiList.length != 0 && maoList.length != 0)
      setLoadingSelect(false);

    setEpcOptions(currentEPC);
    setEpiOptions(currentEPI);
    setMaoOptions(currentMAO);
  };

  useEffect(() => {
    if (activeKey != risk.id) {
      return;
    }

    form.setFieldsValue({
      frequency: risk.frequencia
        ? frequencyList[risk.frequencia - 1].value
        : frequencyList[0].value,
      exposureTime: risk.tempoDeExposicao
        ? exposureTimeList[risk.tempoDeExposicao - 1].value
        : exposureTimeList[0].value,
      intensityPerception: risk.percepcaoDeIntensidade
        ? intensityPerceptionList[risk.percepcaoDeIntensidade - 1].value
        : intensityPerceptionList[0].value,
      hasMeasured: risk.valorMedicao > 0,
      toleranceLimit:
        risk.limiteDeToleranciaLimite === -1
          ? ""
          : risk.limiteDeToleranciaLimite,
      actionLevel:
        risk.limiteDeToleranciaNivelDeAcao === -1 ||
        risk.limiteDeToleranciaNivelDeAcao === -2 ||
        risk.limiteDeToleranciaNivelDeAcao === 0
          ? ""
          : risk.limiteDeToleranciaNivelDeAcao,
      measure: risk.valorMedicao,
      hasRegisterAccident: risk.acidenteOuDoencaRegistrada > 1 ? risk.acidenteOuDoencaRegistrada : 1,
      accidentTime:
        risk.acidenteOuDoencaRegistrada === 1 ? undefined
          : risk.acidenteOuDoencaRegistrada,
      fatorDeRiscoNome: risk.fatorDeRiscoNome,
      epcs: risk.epcs.length > 0 ? risk.epcs.map((epc) => epc.id) : 0,
      epis: risk.epis.length > 0 ? risk.epis.map((epi) => epi.id) : 0,
      maos: risk.maos.length > 0 ? risk.maos.map((mao) => mao.id) : 0,
      fonteGeradora: risk.fonteGeradora
    });

  }, [activeKey]);

  useEffect(() => {
    setOptionsList();
  }, [risk, epcList, epiList, maoList]);

  useEffect(() => {
    if (riskInventory) {
      let riskMap = {};
      if (riskInventory.inventarios && riskInventory.inventarios.length > 0) {
        riskInventory.inventarios.map(
          (risk) =>
            (riskMap[risk.idFatorDeRisco] =
              risk.dataUltimaModificacao === null ? false : true)
        );
        setRiskSavedStatus(riskMap);

        setAccidentFileds(riskInventory.inventarios.map((item) => {
            return {
              name: item.fatorDeRiscoNome,
              acidenteOuDoencaRegistrada: item.acidenteOuDoencaRegistrada > 1,
            };
          }));

        setMeasureFields(riskInventory.inventarios.map((item) => {
            return {
              name: item.fatorDeRiscoNome,
              hasMeasure: risk.realizouMedicao,
            };
          }));
      }
    }
  }, [riskInventory]);

  const handleMeasureFormatting = (
    measure,
    toleranceLimit,
    actionLevel,
    riskFactorName
  ) => {
    let value;

    switch (riskFactorName) {
      case "Ruído contínuo ou intermitente (trabalhista)":
        if (measure < actionLevel) {
          value = 1;
        } else if (measure >= actionLevel && measure <= toleranceLimit) {
          value = 2;
        } else if (measure >= toleranceLimit && measure <= 90) {
          value = 3;
        } else if (measure >= toleranceLimit && measure >= 90) {
          value = 4;
        }
        break;

      case "Temperaturas anormais (calor)":
        if (measure < actionLevel) {
          value = 1;
        } else if (measure >= actionLevel && measure <= toleranceLimit) {
          value = 2;
        } else if (measure > toleranceLimit) {
          value = 4;
        }
        break;

      default:
        if (measure < actionLevel) {
          value = 1;
        } else if (measure >= actionLevel && measure <= toleranceLimit) {
          value = 2;
        } else if (measure >= toleranceLimit && measure <= toleranceLimit * 2) {
          value = 3;
        } else if (measure >= toleranceLimit && measure >= toleranceLimit * 2) {
          value = 4;
        }
    }

    return value;
  };

  const handleSubmit = (id, fatorDeRiscoNome) => {
    form
      .validateFields()
      .then((values) => {

        let formData = {
          fonteGeradora: values.fonteGeradora,
          frequencia: values.frequency,
          tempoDeExposicao: values.exposureTime,
          percepcaoIntensidade: values.exposureTime,
          realizouMedicao: values.hasMeasured ? 1 : 0,
          limiteTolerancia: values.toleranceLimit,
          nivelAcao: parseFloat(values.actionLevel),
          resultadoMedicao: values.hasMeasured
            ? handleMeasureFormatting(
                parseFloat(values.measure),
                parseFloat(values.toleranceLimit),
                parseFloat(values.actionLevel),
                values.fatorDeRiscoNome
              )
            : 0,
          valorMedicao: values.hasMeasured ? parseFloat(values.measure) : 0,
          acidenteRegistrado: values.hasRegisterAccident
            ? values.accidentTime
            : 1,
          epcs: values.epcs == 0 ? [0] : values.epcs,
          epis: values.epis == 0 ? [0] : values.epis,
          maos: values.maos == 0 ? [0] : values.maos,
        };

        editRiskInventory(dispatch, formData, id, fatorDeRiscoNome, riskInventory.processoProdutivoId);
        setOptionsList();
      })
      .catch((err) => console.log(err));
  };

  const toggleRiskSavedStatus = (id) => {
    setRiskSavedStatus({ ...riskSavedStatus, ...{ [id]: true } });
  };

  const setHasMeasured = (riskName) => {
    setMeasureFields(oldValue => {
      return oldValue.map(old => {
        if(old.name === riskName){
          return {
            ...old,
            hasMeasure: !old.hasMeasure
          }
        } else {
          return old
        }
      })
    })
  };

  const hasRegisterAccident = (riskName) => {
    setAccidentFileds(oldValue => {
      return oldValue.map(old => {
        if(old.name === riskName){
          return {
            ...old,
            acidenteOuDoencaRegistrada: !old.acidenteOuDoencaRegistrada
          }
        } else {
          return old
        }
      })
    })
  };

  const handleSelectChange = (value, formName) => {
    if (value.length === 0) {
      form.setFieldsValue({
        [formName]: [0],
      });
    } else {
      form.setFieldsValue({
        [formName]: value.filter((value) => value != 0),
      });
    }
  };

  const checkNoControl = (id) => {
    const result = form.getFieldsValue();
    if (result.epcs == 0 && result.epis == 0 && result.maos == 0) {
      alertNoControl();
    }
  };

  return (
    <div className="RiskCard">
      <Form
        form={form}
        onFinish={() => handleSubmit(risk.id, risk.fatorDeRiscoNome)}
        name={risk.id}
      >
        <div className="field-div">
          <Row gutter={24}>
            <Col span={12} className="risk-info">
              <h3>Percentual de trabalhadores em contato com o risco:</h3>{" "}
              <h4>{percentageList[risk.indiceDePessoasExpostas - 1].label}</h4>
            </Col>
            <Col span={12} className="risk-info">
              <Form.Item
                label={<h3>Fonte ou circunstância geradora:</h3>}
                name={"fonteGeradora"}
              >
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className="field-div">
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label={<h3>Frequência: </h3>} name={"frequency"}>
                <Select
                  onSelect={() => toggleRiskSavedStatus(risk.idFatorDeRisco)}
                >
                  {frequencyList.map((frequency) => (
                    <Option key={frequency.value} value={frequency.value}>
                      {frequency.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<h3>Tempo de exposição: </h3>}
                name={"exposureTime"}
              >
                <Select
                  onSelect={() => toggleRiskSavedStatus(risk.idFatorDeRisco)}
                >
                  {exposureTimeList.map((exposure) => (
                    <Option key={exposure.value} value={exposure.value}>
                      {exposure.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className="field-div">
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label={<h3>Percepção de intensidade: </h3>}
                name={"intensityPerception"}
              >
                <Select
                  onSelect={() => toggleRiskSavedStatus(risk.idFatorDeRisco)}
                >
                  {intensityPerceptionList.map((perception) => (
                    <Option key={perception.value} value={perception.value}>
                      {perception.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} className="risk-info">
              <h3>Meio de propagação:</h3>{" "}
              <h4>{risk.fatorDeRiscoMeioDePropagacao}</h4>
            </Col>
          </Row>
        </div>

        {risk.fatorDeRiscoClassificacao === 1 && (
          <div className="field-div">
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  label={
                    <h3>
                      Já foi realizado algum processo de medição na empresa?
                    </h3>
                  }
                  name={"hasMeasured"}
                >
                  <Switch
                    checkedChildren="Sim"
                    unCheckedChildren="Não"
                    onChange={(value) => {
                      setHasMeasured(risk.fatorDeRiscoNome);
                      toggleRiskSavedStatus(risk.idFatorDeRisco);
                    }}
                    defaultChecked={risk.realizouMedicao}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}

        {measureFields.map(field => {
          if(field.name === risk.fatorDeRiscoNome){
            if(field.hasMeasure){
              return (
                <div className="field-div measure-div">
                  <Row gutter={24}>
                    <Col span={6}>
                      <Form.Item
                        label={<h3>Limite de tolerância: </h3>}
                        name={"toleranceLimit"}
                      >
                        <Input></Input>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label={<h3>Nível de ação: </h3>}
                        name={"actionLevel"}
                      >
                        <Input></Input>
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        label={
                          <h3>{`Resultado da medição do risco  ${
                            risk.limiteDeToleranciaUnidadeDeMedida
                              ? `(em ${risk.limiteDeToleranciaUnidadeDeMedida})`
                              : ""
                          }:`}</h3>
                        }
                        name={"measure"}
                      >
                        <Input></Input>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              )
            }
          }
        })}

        <div className="field-div">
          <Row gutter={24}>
            <Col span={16} className="risk-info">
              <h3>Possíveis lesões ou agravos à saúde:</h3>{" "}
              <h4>{risk.fatorDeRiscoDanosPossiveis}</h4>
            </Col>
            <Col span={8} className="risk-info">
              <h3>Índice de gravidade:</h3>
              <h4>{getDangerLevel(risk.indiceGravidade)}</h4>
            </Col>
          </Row>
        </div>

        <div className="field-div">
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                className="accident-label"
                label={
                  <>
                    <h3>Acidente/doença registrado nos últimos 2 anos</h3>
                    <h3>relacionado a esse perigo: </h3>
                  </>
                }
                name={"hasRegisterAccident"}
              >
                <Switch
                  checkedChildren="Sim"
                  unCheckedChildren="Não"
                  onChange={(value) => {
                    hasRegisterAccident(risk.fatorDeRiscoNome);
                    toggleRiskSavedStatus(risk.idFatorDeRisco);
                  }}
                  defaultChecked={risk.acidenteOuDoencaRegistrada > 1}
                />
              </Form.Item>
            </Col>
            {accidentFields.map((accident) => {
              if (accident.name === risk.fatorDeRiscoNome) {
                if (accident.acidenteOuDoencaRegistrada) {
                  return (
                    <Col span={12}>
                      <Form.Item
                        name={"accidentTime"}
                        rules={[
                          {
                            required: true,
                            message: "Selecione uma opção!",
                          },
                        ]}
                      >
                        <Radio.Group>
                          <Radio value={2}>1 a 2 anos atrás</Radio>
                          <Radio value={3}>No último ano</Radio>
                          <Radio value={4}>Nos últimos 6 meses</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  );
                }
              }
            })}
          </Row>
        </div>

        <div className="tags-div">
          <div className="field-div">
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  label={
                    <h3>MPC (Medida de Proteção Coletiva) Implementado: </h3>
                  }
                  name={"epcs"}
                >
                  <Select
                    mode="tags"
                    onChange={(value) => handleSelectChange(value, "epcs")}
                    loading={loadingSelect}
                  >
                    {epcOptions.map((epc) => (
                      <Option key={epc.id} value={epc.id}>
                        {epc.nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="field-div">
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item label={<h3>EPI Implementado: </h3>} name={"epis"}>
                  <Select
                    mode="tags"
                    onChange={(value) => handleSelectChange(value, "epis")}
                    loading={loadingSelect}
                  >
                    {epiOptions.map((epi) => (
                      <Option key={epi.id} value={epi.id}>
                        {epi.nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="field-div">
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  label={
                    <h3>
                      MAO (Medida Administrativa ou Organizacional)
                      Implementado:
                    </h3>
                  }
                  name={"maos"}
                >
                  <Select
                    mode="tags"
                    onChange={(value) => handleSelectChange(value, "maos")}
                    loading={loadingSelect}
                  >
                    {maoOptions.map((mao) => (
                      <Option key={mao.id} value={mao.id}>
                        {mao.nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div style={{ display: "none" }}>
            <Form.Item name={"fatorDeRiscoNome"}></Form.Item>
          </div>
        </div>

        <div className="field-div save-button-div">
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  onClick={() => checkNoControl(risk.id)}
                >
                  {riskSavedStatus[risk.idFatorDeRisco] === false
                    ? "Confirmo os dados sem alteração"
                    : "Salvar"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default RiskCard;
