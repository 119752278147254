import { Button, Form, Input, Popover } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { sendMessage } from "../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const ContactUsForm = (props) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const feedbackObj = {
        nome: values.nome,
        email: values.email,
        feedback: values.mensagem,
      };
      sendMessage(props.dispatch, feedbackObj);
      form.resetFields();
    });
  };

  return (
    <Popover
      content={
        <Form name="contactUsForm" form={form} onFinish={handleSubmit}>
          <Form.Item
            name="nome"
            label="Nome:"
            rules={[{ required: true, message: "Por favor, insira seu nome." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-mail:"
            rules={[
              { required: true, message: "Por favor, insira seu email." },
              { type: "email", message: "Insira um email válido." },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="mensagem"
            label="Mensagem:"
            rules={[
              { required: true, message: "Por favor, insira uma mensagem." },
            ]}
          >
            <Input.TextArea autoSize={{ minRows: 5, maxRows: 5 }} />
          </Form.Item>
          <Button onClick={() => handleSubmit()}>Enviar</Button>
        </Form>
      }
      trigger="click"
      placement="topRight"
    >
      <Button
        className="support-button"
        shape="circle"
        onClick={() => {
          setVisible(!visible);
        }}
      >
        <MessageOutlined />
      </Button>
    </Popover>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        sendMessage,
      },
      dispatch
    ),
  };
};

export default connect(mapDispatchToProps)(ContactUsForm);
