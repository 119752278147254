import React, { useState } from "react";
import { Tree } from "antd";
import { CarryOutOutlined } from "@ant-design/icons";
import {
  INICIO,
  CARACTERIZACAO,
  ACOMPANHAMENTO,
  AUTODECLARACAO,
} from "../../constants";

const SideBar = (props) => {
  const [treeData, setTreeData] = useState([]);

  const parseTreeData = (category, data) => {
    switch (category) {
      case INICIO:
        return setTreeData(
          data.map((category) => {
            return {
              title: category.subcategoria,
              key: category.id,
            };
          })
        );
      case CARACTERIZACAO:
        return setTreeData([
          {
            title: "Empresa",
            key: "0-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Setores",
            key: "0-1",
            icon: <CarryOutOutlined />,
            selectable: false,
            children: [
              {
                title: "Listar",
                key: "0-1-0",
                icon: <CarryOutOutlined />,
              },
              {
                title: "Adicionar",
                key: "0-1-1",
                icon: <CarryOutOutlined />,
              },
            ],
          },
          {
            title: "Trabalhadores",
            key: "0-2",
            icon: <CarryOutOutlined />,
            selectable: false,
            children: [
              {
                title: "Listar",
                key: "0-2-0",
                icon: <CarryOutOutlined />,
              },
              {
                title: "Adicionar",
                key: "0-2-1",
                icon: <CarryOutOutlined />,
              },
            ],
          },
          {
            title: "Processos produtivos",
            key: "0-3",
            icon: <CarryOutOutlined />,
            selectable: false,
            children: [
              {
                title: "Listar",
                key: "0-3-0",
                icon: <CarryOutOutlined />,
              },
              {
                title: "Adicionar",
                key: "0-3-1",
                icon: <CarryOutOutlined />,
              },
            ],
          },
          {
            title: "Trabalhador x Processo",
            key: "0-4",
            icon: <CarryOutOutlined />,
          },
        ]);
      case ACOMPANHAMENTO:
        return setTreeData([
          {
            title: "Acompanhamento",
            key: "0-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Inventário de Riscos",
            key: "0-1",
            selectable: false,
            icon: <CarryOutOutlined />,
            children: [
              {
                title: "Listagem de processos produtivos",
                key: "0-1-0",
                icon: <CarryOutOutlined />,
              },
              {
                title: "Listagem de perigos de um processo",
                key: "0-1-1",
                icon: <CarryOutOutlined />,
              },
            ],
          },
          {
            title: "Plano de Ação",
            key: "0-2",
            icon: <CarryOutOutlined />,
          },
        ]);
      case AUTODECLARACAO:
        return setTreeData([
          {
            title: "Início",
            key: "0-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Perguntas",
            key: "0-1",
            selectable: false,
            icon: <CarryOutOutlined />,
            children: [
              {
                title: "Pergunta 1",
                key: "0-1-0",
                icon: <CarryOutOutlined />,
              },
              {
                title: "Pergunta 2",
                key: "0-1-1",
                icon: <CarryOutOutlined />,
              },
              {
                title: "Pergunta 3",
                key: "0-1-2",
                icon: <CarryOutOutlined />,
              },
              {
                title: "Pergunta 4",
                key: "0-1-3",
                icon: <CarryOutOutlined />,
              },
              {
                title: "Pergunta 5",
                key: "0-1-4",
                icon: <CarryOutOutlined />,
              },
            ],
          },
          {
            title: "Gerar Autodeclaração",
            key: "0-2",
            icon: <CarryOutOutlined />,
          },
        ]);
    }
  };

  useState(() => {
    if (props.content) parseTreeData(props.category, props.content);
  }, [JSON.stringify(props.content)]);

  return (
    <Tree
      treeData={treeData}
      onSelect={(e) => props.changeTalk(e)}
      // showLine={props.category !== INICIO ? true : false}
    />
  );
};

export default SideBar;
