export function changeTheme(color) {
  switch (color) {
    case "azul":
      changeThemeToAzul();
      break;
    case "azulEscuro":
      changeThemeToAzulEscuro();
      break;
    case "roxo":
      changeThemeToRoxo();
      break;
    case "roxoClaro":
      changeThemeToRoxoClaro();
      break;
    default:
      break;
  }
}
export function changeThemeToAzul() {
  document.documentElement.classList.add("azul");
  document.documentElement.style.setProperty("--ebony", "#192136");
  document.documentElement.style.setProperty("--martinique", "#23304D");
  document.documentElement.style.setProperty("--alto", "#EEEEEE");
  document.documentElement.style.setProperty("--melrose", "#D1DEFF");
  document.documentElement.style.setProperty("--comet", "#475F92");
  document.documentElement.style.setProperty("--gigas", "#114680");
  document.documentElement.style.setProperty("--marguerite", "#17508F");
  document.documentElement.style.setProperty(
    "--degrade-fundo",
    "linear-gradient(180deg, #192136, #192136)"
  );
  document.documentElement.style.setProperty(
    "--degrade-menu",
    "linear-gradient(180deg, #17508F, #17508F)"
  );
  document.documentElement.style.setProperty(
    "--degrade-step",
    "linear-gradient(180deg, #17508F, #17508F)"
  );
  document.documentElement.style.setProperty("--pendency", "#F7B91C");
}
export function changeThemeToRoxo() {
  document.documentElement.classList.add("roxo");
  document.documentElement.style.setProperty("--ebony", "#211A35");
  document.documentElement.style.setProperty("--ebony", "#211A35");
  document.documentElement.style.setProperty("--martinique", "#2C224A");
  document.documentElement.style.setProperty("--alto", "#EEEEEE");
  document.documentElement.style.setProperty("--melrose", "#D6D3FD");
  document.documentElement.style.setProperty("--comet", "#524774");
  document.documentElement.style.setProperty("--gigas", "#44217D");
  document.documentElement.style.setProperty("--marguerite", "#553C9C");
  document.documentElement.style.setProperty(
    "--degrade-fundo",
    "linear-gradient(180deg, #211A35, #211A35)"
  );
  document.documentElement.style.setProperty(
    "--degrade-menu",
    "linear-gradient(180deg, #553C9C, #553C9C)"
  );
  document.documentElement.style.setProperty(
    "--degrade-step",
    "linear-gradient(180deg, #553C9C, #553C9C)"
  );
  document.documentElement.style.setProperty("--pendency", "#F7B91C");
}
export function changeThemeToRoxoClaro() {
  document.documentElement.classList.add("roxoClaro");
  document.documentElement.style.setProperty("--ebony", "#F8F8F8");
  document.documentElement.style.setProperty("--martinique", "#E8E8E8");
  document.documentElement.style.setProperty("--alto", "#303030");
  document.documentElement.style.setProperty("--melrose", "#EEEEEE");
  document.documentElement.style.setProperty("--comet", "#D8D8D8");
  document.documentElement.style.setProperty("--gigas", "#44217D");
  document.documentElement.style.setProperty("--marguerite", "#553C9C");
  document.documentElement.style.setProperty(
    "--degrade-fundo",
    "linear-gradient(180deg, #F8F8F8, #F8F8F8)"
  );
  document.documentElement.style.setProperty(
    "--degrade-menu",
    "linear-gradient(180deg, #553C9C, #553C9C)"
  );
  document.documentElement.style.setProperty(
    "--degrade-step",
    "linear-gradient(180deg, #553C9C, #553C9C)"
  );
  document.documentElement.style.setProperty("--pendency", "#a34949");
}
export function changeThemeToAzulEscuro() {
  document.documentElement.classList.add("azulEscuro");
  document.documentElement.style.setProperty("--ebony", "#181818");
  document.documentElement.style.setProperty("--martinique", "#303030");
  document.documentElement.style.setProperty("--alto", "#EEEEEE");
  document.documentElement.style.setProperty("--melrose", "#EEEEEE");
  document.documentElement.style.setProperty("--comet", "#484848");
  document.documentElement.style.setProperty("--gigas", "#114680");
  document.documentElement.style.setProperty("--marguerite", "#17508F");
  document.documentElement.style.setProperty(
    "--degrade-fundo",
    "linear-gradient(180deg, #181818, #181818)"
  );
  document.documentElement.style.setProperty(
    "--degrade-menu",
    "linear-gradient(180deg, #17508F, #17508F)"
  );
  document.documentElement.style.setProperty(
    "--degrade-step",
    "linear-gradient(180deg, #17508F, #17508F)"
  );
  document.documentElement.style.setProperty("--pendency", "#F7B91C");
}
