import { combineReducers } from "redux";
import { selectCompanyRedux } from "../../../../components/CompanyList/_/containers/Companies/_/reducers";

import { caracterizacaoEmpresasReducer } from "../components/CaracterizacaoEmpresa/_/reducers";
import { caracterizacaoSetoresReducer } from "../components/CaracterizacaoSetores/_/reducers";
import { caracterizacaoFuncionariosClienteReducer } from "../components/CaracterizacaoFuncionarios/_/reducers";
import { caracterizacaoProcessosProdutivosReducer } from "../components/CaracterizacaoProcessosProdutivos/_/reducers";
import stepsEmpresaReducer from "../../../../components/FinishStep/_/reducers";
import { inventarioDeRiscosReducer } from "../../../InventarioRiscos/_/reducers";

export default combineReducers({
  companySelected: selectCompanyRedux,
  empresa: caracterizacaoEmpresasReducer,
  setores: caracterizacaoSetoresReducer,
  funcionarios: caracterizacaoFuncionariosClienteReducer,
  processosProdutivos: caracterizacaoProcessosProdutivosReducer,
  inventarioDeRisco: inventarioDeRiscosReducer,
  stepsEmpresa: stepsEmpresaReducer,
});
