import axios from "axios";
import {
  empresasAtivasComPGREmitidoUrl,
  relatorioEmpresasAtivas,
} from "../../../../../constants";
import { LISTAR_EMPRESAS_ATIVAS } from "./types";

export const listarEmpresasAtivas = (dispatch) => {
  axios
    .get(empresasAtivasComPGREmitidoUrl)
    .then((response) => {
      dispatch({
        type: LISTAR_EMPRESAS_ATIVAS,
        payload: response.data,
      });
    })
    .catch();
};

export const getPlanilhas = () => {
  axios({
    url: relatorioEmpresasAtivas,
    method: "GET",
    responseType: "blob",
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `EmpresasAtivas.xls`);
      document.body.appendChild(link);
      link.click();
    })
    .catch();
};
