import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  stepsTalks,
  talksSetores,
} from "../../../../../../../constants/aroldoTalks";
import { Row, Col, Spin, Button as ButtonAnt, Tabs } from "antd";
import {
  fetchSetores,
  fetchSetoresAdmin,
  addSetor,
  fetchSetor,
  removerSetor,
  editSetor,
} from "../actions";
import SetorList from "../components/setorList";
import Button from "../../../../../../../components/Buttons/_/components/button";
import IconSESI from "../../../../../../../components/IconsSESI";
import SetorForm from "../components/SetorForm";
import usePrevious from "../../../../../../../util/usePrevious";
import { bindActionCreators } from "redux";

const CaracterizacaoSetores = (props) => {
  const [currentTab, setCurrentTab] = useState("lista");
  const [mode, setMode] = useState("new");
  const [, setSetores] = useState([]);
  const [setoresCatalogo, setSetoresCatalogo] = useState([]);
  const previousCatalogo = usePrevious(setoresCatalogo);
  const [viewOnly, setViewOnly] = useState(props.stepsEmpresa.SETORES);
  const { TabPane } = Tabs;

  useEffect(() => {
    fetchSetores(props.dispatch);
    fetchSetoresAdmin(props.dispatch);
    setSetores(props.setores);

    // if (typeof setores !== "undefined" && setores.length > 0) {
    //   if (objectsAreSame(previousSetores, setores)) {
    //     fetchSetores();
    //   }
    // }

    if (previousCatalogo !== props.setoresCatalogo) {
      setSetoresCatalogo(props.setoresCatalogo);
    }
  }, [JSON.stringify(props.setores)]);

  useEffect(() => {
    setViewOnly(props.stepsEmpresa.SETORES);
    if (props.stepsEmpresa.SETORES) {
      props.onChangeTalk("talk3", 1);
    } else {
      props.onChangeTalk("talk1", 1);
    }
  }, [props.stepsEmpresa.SETORES]);

  const configAdd = (callback) => {
    const toggleTab = currentTab === "lista" ? "form" : "lista";
    setCurrentTab(toggleTab);
    props.onChangeTalk("talk2", 1);
    setMode("new");
    setViewOnly(props.stepsEmpresa.SETORES);
    if (callback) callback();
  };

  const configEdit = (dispatch, id, viewOnly = false) => {
    fetchSetor(dispatch, id);
    const toggleTab = currentTab === "lista" ? "form" : "lista";
    setCurrentTab(toggleTab);
    props.onChangeTalk("talk2", 1);
    setMode("edit");
    setViewOnly(viewOnly || props.stepsEmpresa.SETORES);
  };

  if (!props.setores) {
    return (
      <div className="spin">
        <Spin tip="Carregando..." />
      </div>
    );
  }

  return (
    <div className="caracterizacao-setores">
      <Row gutter={32}>
        <Col lg={24} md={24}>
          <Tabs activeKey={currentTab}>
            <TabPane tab="" key="lista">
              <div className="container">
                <Row
                  gutter={16}
                  type="flex"
                  justify="space-around"
                  align="middle"
                >
                  <Col span={18}>
                    <div className="header-form">
                      <Button
                        shape="circle"
                        ghost
                        onClick={() => props.nextStep(props.current - 1)}
                        text={<IconSESI type="arrow-left" />}
                      />
                      <h2>Setores</h2>
                    </div>
                  </Col>
                  <Col span={6}>
                    <Button
                      onClick={() => props.nextStep(props.current + 1)}
                      type="primary"
                      text="PRÓXIMO PASSO"
                      className="next-step"
                      block
                    />
                  </Col>
                </Row>
                <div className="content">
                  {!viewOnly ? (
                    <ButtonAnt
                      ghost
                      className="add"
                      onClick={() => configAdd()}
                      disabled={props.setoresCatalogo.sugestoes ? false : true}
                    >
                      <IconSESI type="add-circle" theme="filled" />
                      Adicionar Setor
                    </ButtonAnt>
                  ) : (
                    ""
                  )}
                  <SetorList
                    completed={props.stepsEmpresa.SETORES}
                    dispatch={props.dispatch}
                    setores={props.setores}
                    edit={configEdit}
                    remove={removerSetor}
                    perfil={props.perfil}
                    viewOnly={viewOnly}
                    fetchSetores={fetchSetores}
                  />
                </div>
              </div>
            </TabPane>

            <TabPane tab="" key="form">
              <SetorForm
                dispatch={props.dispatch}
                setSetores={setSetores}
                tab={configAdd}
                setoresCatalogo={props.setoresCatalogo}
                addSetor={addSetor}
                patch={editSetor}
                mode={mode}
                viewOnly={viewOnly}
                companySelected={props.companySelected}
                setor={mode === "edit" ? props.setorSelecionado : []}
                setoresIndisponiveis={props.setoresIndisponiveis}
                fetch={fetchSetores}
                onChangeTalk={props.onChangeTalk}
                setores={props.setores}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  const customizacoes = state.user.userInfo
    ? state.user.userInfo.customizacoes
    : {};
  return {
    erro: state.caracterizacao.setores.erro,
    setores: state.caracterizacao.setores.setores,
    page: state.buttons.pageStepsSetores,
    perfil: state.user.userInfo.perfil,
    setoresCatalogo: state.caracterizacao.setores.setoresCatalogo,
    companySelected: state.caracterizacao.companySelected,
    setorSelecionado: state.caracterizacao.setores.setorSelecionado,
    setoresIndisponiveis: state.caracterizacao.setores.setoresIndisponiveis,
    stepsEmpresa: state.caracterizacao.stepsEmpresa.stepsEmpresa,
    customizacoes,
    talks: state.gestorDN.virtualAssistant.categorizationTalks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        fetchSetores,
        fetchSetoresAdmin,
        addSetor,
        fetchSetor,
        removerSetor,
        editSetor,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaracterizacaoSetores);
