export const NEXT_PAGE_INTRO = "NEXT_PAGE_INTRO";
export const BACK_PAGE_INTRO = "BACK_PAGE_INTRO";
export const NEXT_PAGE_STEPS_ATIVIDADES = "NEXT_PAGE_STEPS_ATIVIDADES";
export const NEXT_PAGE_STEPS_EMPRESA = "NEXT_PAGE_STEPS_EMPRESA";
export const NEXT_PAGE_STEPS_SETORES = "NEXT_PAGE_STEPS_SETORES";
export const NEXT_PAGE_STEPS_CARGO = "NEXT_PAGE_STEPS_CARGO";
export const NEXT_PAGE_STEPS_TRABALHADORES = "NEXT_PAGE_STEPS_TRABALHADORES";
export const NEXT_PAGE_STEPS_MAQUINAS = "NEXT_PAGE_STEPS_MAQUINAS";
export const NEXT_PAGE_STEPS_PROD_QUIM = "NEXT_PAGE_STEPS_PROD_QUIM";
export const NEXT_PAGE_STEPS_ESPACO = "NEXT_PAGE_STEPS_ESPACO";
export const NEXT_PAGE_FATOR_RISCO = "NEXT_PAGE_FATOR_RISCO";
export const NEXT_PAGE_STEPS_PLANO = "NEXT_PAGE_STEPS_PLANO";
export const NEXT_PAGE_COMPANY_SELECTION = "NEXT_PAGE_COMPANY_SELECTION";
export const INTRO_OVER = "INTRO_OVER";
export const NEXT_PAGE_PRIMEIROS_PASSOS = "NEXT_PAGE_PRIMEIROS_PASSOS";
export const BACK_PAGE_PRIMEIROS_PASSOS = "BACK_PAGE_PRIMEIROS_PASSOS";
export const FINISH_PRIMEIROS_PASSOS = "FINISH_PRIMEIROS_PASSOS";
