import axios from "axios";
import { setupInterceptors } from "../../../../auth/SetupInterceptors";
import {
  authUrl,
  logoutUrl,
  companiesUrl,
  autoDeclaracaoUrl,
} from "../../../../constants";

import {
  AUTH_USER,
  LOGOUT,
  SIGNIN_FAILURE,
  USER_COMPANIES,
  AUTO_DECLARACAO,
} from "./types";
import openNotification from "../../../../components/notifications/index";

setupInterceptors();

export function signIn(dispatch, credentials, loading) {
  if (credentials) {
    axios
      .get(authUrl, { auth: credentials })
      .then((response) => {
        dispatch({ type: AUTH_USER, payload: response.data });
      })
      .catch((error) => {
        openNotification(
          "Login ou senha incorretos!",
          "Tente novamente",
          "error"
        );
        dispatch(authError(SIGNIN_FAILURE, error));
      });
  } else {
    return (dispatch) => {
      axios
        .get(authUrl)
        .then((response) => {
          dispatch({ type: AUTH_USER, payload: response.data });
          loading();
        })
        .catch((error) => {
          console.error(error);
          dispatch(authError(SIGNIN_FAILURE, error));
        });
    };
  }
}

export const logout = (redirect = false) => {
  localStorage.removeItem("persist:rootPersist");
  const url = window.location.origin;
  return (dispatch) => {
    axios.get(logoutUrl).then((res) => {
      dispatch({
        type: LOGOUT,
        payload: res.data,
      });
      localStorage.removeItem("persist:rootPersist");
      if (redirect) window.location.href = `${url}`;
    });
  };
};

const downloadPDF = () => {
  axios(autoDeclaracaoUrl, {
    method: "POST",
    responseType: "blob",
  }).then((res) => {
    const url = URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", res.headers["content-disposition"]);
    document.body.appendChild(link);
    link.click();
  });
};

export function getAutoDeclaracao() {
  return (dispatch) => {
    axios.post(autoDeclaracaoUrl).then((res) => {
      dispatch({
        type: AUTO_DECLARACAO,
        payload: res.data,
      });
      downloadPDF();
    });
  };
}

export function fetchCompanies(dispatch) {
  axios
    .get(companiesUrl, { withCredentials: true })
    .then((response) => {
      dispatch({ type: USER_COMPANIES, payload: response.data });
    })
    .catch((err) => console.log(err));
}

/**
 * Error helper
 */
export function authError(CONST, error) {
  return {
    type: CONST,
    payload: error,
  };
}
