import Axios from "axios";
import openNotification from "../../../../../../../components/notifications";
import {
  virtualAssistantActionPlans,
  virtualAssistantActionPlansPatch,
  virtualAssistantAutostatement,
  virtualAssistantAutostatementPatch,
  virtualAssistantBeginning,
  virtualAssistantBeginningPatch,
  virtualAssistantCategorization,
  virtualAssistantCategorizationPatch,
  virtualAssistantMonitoring,
  virtualAssistantMonitoringPatch,
  virtualAssistantRisks,
  virtualAssistantRisksPatch,
} from "../../../../../../../constants";
import {
  GET_ACTION_PLAN_TALKS,
  GET_AUTOSTATEMENT_TALKS,
  GET_BEGINNING_TALKS,
  GET_CATEGORIZATION_TALKS,
  GET_MONITORING_TALKS,
  GET_RISKS_TALKS,
  PATCH_ACTION_PLAN_TALKS,
  PATCH_AUTOSTATEMENT_TALKS,
  PATCH_BEGINNING_TALK,
  PATCH_CATEGORIZATION_TALK,
  PATCH_MONITORING_TALK,
  PATCH_RISKS_TALKS,
} from "./types";

export const getTalks = (dispatch) => {
  const beginningTalks = Axios.get(virtualAssistantBeginning);
  const categorizationTalks = Axios.get(virtualAssistantCategorization);
  const risksTalks = Axios.get(virtualAssistantRisks);
  const actionPlanTalks = Axios.get(virtualAssistantActionPlans);
  const monitoringTalks = Axios.get(virtualAssistantMonitoring);
  const autostatementTalks = Axios.get(virtualAssistantAutostatement);

  Axios.all([
    beginningTalks,
    categorizationTalks,
    risksTalks,
    actionPlanTalks,
    monitoringTalks,
    autostatementTalks,
  ]).then((responses) => {
    dispatch({
      type: GET_BEGINNING_TALKS,
      payload: responses[0].data,
    });
    dispatch({
      type: GET_CATEGORIZATION_TALKS,
      payload: responses[1].data,
    });
    dispatch({
      type: GET_RISKS_TALKS,
      payload: responses[2].data,
    });
    dispatch({
      type: GET_ACTION_PLAN_TALKS,
      payload: responses[3].data,
    });
    dispatch({
      type: GET_MONITORING_TALKS,
      payload: responses[4].data,
    });
    dispatch({
      type: GET_AUTOSTATEMENT_TALKS,
      payload: responses[5].data,
    });
  });
};

export const patchBeginningTalk = (dispatch, id, descricao) => {
  Axios.patch(virtualAssistantBeginningPatch(id), { descricao: descricao })
    .then((response) => {
      dispatch({
        type: PATCH_BEGINNING_TALK,
        payload: response.data,
      });
      openNotification("Fala modificada com sucesso!", "", "success");
    })
    .catch((err) => {
      openNotification("Erro ao modificar a fala!", "Tente novamente", "error");
    });
};

export const patchCaracterizationTalk = (dispatch, id, descricao) => {
  Axios.patch(virtualAssistantCategorizationPatch(id), { descricao: descricao })
    .then((response) => {
      dispatch({
        type: PATCH_CATEGORIZATION_TALK,
        payload: response.data,
      });
      openNotification("Fala modificada com sucesso!", "", "success");
    })
    .catch((err) => {
      openNotification("Erro ao modificar a fala!", "Tente novamente", "error");
    });
};

export const patchMonitoringTalk = (dispatch, id, descricao, naoEditavel) => {
  Axios.patch(virtualAssistantMonitoringPatch(id), {
    descricao: descricao,
    naoEditavel: ["5"],
  })
    .then((response) => {
      dispatch({
        type: PATCH_MONITORING_TALK,
        payload: response.data,
      });
      openNotification("Fala modificada com sucesso!", "", "success");
    })
    .catch((err) => {
      openNotification("Erro ao modificar a fala!", "Tente novamente", "error");
    });
};

export const patchRisksTalk = (dispatch, id, descricao, naoEditavel) => {
  Axios.patch(virtualAssistantRisksPatch(id), {
    descricao: descricao,
    naoEditavel: naoEditavel.map((index) => index.toString()),
  })
    .then((response) => {
      dispatch({
        type: PATCH_RISKS_TALKS,
        payload: response.data,
      });
      openNotification("Fala modificada com sucesso!", "", "success");
    })
    .catch((err) => {
      openNotification("Erro ao modificar a fala!", "Tente novamente", "error");
    });
};

export const patchActionPlanTalk = (dispatch, id, descricao, naoEditavel) => {
  Axios.patch(virtualAssistantActionPlansPatch(id), {
    descricao: descricao,
    naoEditavel: naoEditavel.map((index) => index.toString()),
  })
    .then((response) => {
      dispatch({
        type: PATCH_ACTION_PLAN_TALKS,
        payload: response.data,
      });
      openNotification("Fala modificada com sucesso!", "", "success");
    })
    .catch((err) => {
      openNotification("Erro ao modificar a fala!", "Tente novamente", "error");
    });
};

export const patchAutoStatementTalk = (
  dispatch,
  id,
  descricao,
  naoEditavel
) => {
  Axios.patch(virtualAssistantAutostatementPatch(id), {
    descricao: descricao,
    naoEditavel: naoEditavel.map((index) => index.toString()),
  })
    .then((response) => {
      dispatch({
        type: PATCH_AUTOSTATEMENT_TALKS,
        payload: response.data,
      });
      openNotification("Fala modificada com sucesso!", "", "success");
    })
    .catch((err) => {
      openNotification("Erro ao modificar a fala!", "Tente novamente", "error");
    });
};
