import React, { useEffect, useState } from "react";
import { Button, Row, Col, Tooltip } from "antd";
import {
  EyeOutlined,
  QuestionCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import moment from "moment";

const ProcessesList = (props) => {
  const [inventario, setInventario] = useState([]);
  // const listaDeRiscos = process.riscos;

  useEffect(() => {
    setInventario(
      props.processes.filter(
        (proc) => proc.dataFim === null || moment(proc.dataFim) > new Date()
      )
    );
  }, [JSON.stringify(props.processes)]);

  const getExposedGroupText = (exposedGroup, process) => {
    return (
      <React.Fragment>
        <p>
          O grupo de exposição podem ser cargos, setores ou trabalhadores que
          atuam nesta área.
        </p>
        {!exposedGroup.categoria ? (
          <p>
            Associe os trabalhadores na página de realizadores de processos para
            que apareçam aqui.{" "}
          </p>
        ) : (
          <React.Fragment>
            <p>
              {exposedGroup.categoria.toUpperCase()} que atuam em {process}:
            </p>
            {exposedGroup.valor.map((value) => (
              <p>{value}</p>
            ))}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const getRiskLevelText = () => {
    return (
      <React.Fragment>
        {/* <p>
          O nível de risco só aparecerá ao salvar todas as informações do fator
          de risco.{" "}
        </p> */}
        <p>
          Clicando no botão “Ver perigos” você poderá adicionar informações que
          contribuirá ao nível de risco.
        </p>
      </React.Fragment>
    );
  };

  const getRiskLevel = (level) => {
    switch (level) {
      case "irrelevante":
        return "Irrelevante";
      case "baixo":
        return "Baixo";
      case "médio":
        return "Médio";
      case "alto":
        return "Alto";
      case "crítico":
        return "Crítico";
      default:
        break;
    }
  };

  return (
    <Row justify="space-between">
      {inventario.map((process) => (
        <Col className="process-wrapper" span={11}>
          <div className="name-wrapper">
            <h4>{process.nome}</h4>
            {process.completo ? <div id="base"></div> : null}
            {!process.inventarioDoProcesso.length ? (
              <Button
                icon={<CloseOutlined />}
                onClick={() => {
                  props.addAusenciaDeRisco(props.dispatch, process.idProcesso);
                }}
              >
                Confirmar que esse processo não tem perigos
              </Button>
            ) : null}
            <Button
              icon={<EyeOutlined />}
              onClick={() => {
                props.toggleTab();
                if (process.inventarioDoProcesso.length > 0) {
                  props.getInventarioDeRiscoByProcess(
                    props.dispatch,
                    process.idProcesso
                  );
                } else {
                  props.getProcessWithoutInventarioDeRisco(
                    props.dispatch,
                    process.idProcessoProdutivoDaEmpresa
                  );
                }
              }}
            >
              Ver perigos
            </Button>
          </div>
          <div className="exposed-group-wrapper">
            <h4>Grupo de exposição</h4>
            <Tooltip
              title={getExposedGroupText(process.grupoExposto, process.nome)}
            >
              <EyeOutlined />
            </Tooltip>
          </div>
          <div className="column-header-wrapper">
            <h4>Perigos</h4>
            <div className="column-risk-wrapper">
              <h4>Nível de risco</h4>
              <Tooltip title={getRiskLevelText()}>
                <QuestionCircleOutlined />
              </Tooltip>
            </div>
          </div>
          {process.inventarioDoProcesso
            ? process.inventarioDoProcesso
                .sort((a, b) => b.valorDoNivel - a.valorDoNivel)
                .map((risk) => (
                  <div className="risk-wrapper">
                    <div className="risk-name-wrapper">
                      <h4>{risk.fatorDeRiscoNome}</h4>
                      {risk.dataUltimaModificacao ? <CheckOutlined /> : null}
                    </div>
                    <h4>{risk.nivel ? getRiskLevel(risk.nivel) : "-"}</h4>
                  </div>
                ))
            : null}
        </Col>
      ))}
    </Row>
  );
};

export default ProcessesList;
