import React, { useEffect, useState } from "react";
import { Card, Tag, Row, Col, Select, Form, Button, Tabs, Switch } from "antd";
import { getPlanilhas } from "../actions";
import IconSESI from "../../../../../components/IconsSESI";
import EmpresaAtivaCard from "./EmpresaAtivaCard";
import "../styles.scss";

const { Option } = Select;
const { TabPane } = Tabs;

const EmpresasAtivasCard = (props) => {
  const [activeCompanies, setActiveCompanies] = useState([]);
  const [activeDocument, setActiveDocument] = useState("PGR");

  useEffect(() => {
    setActiveCompanies(props.empresasAtivas);
  }, [JSON.stringify(props.empresasAtivas)]);

  const handleOnType = (type) => {
    if (type) {
      setActiveCompanies(
        activeCompanies.filter((company) =>
          type === "premium" ? company.premium : !company.premium
        )
      );
    }
  };

  const handleOnPurchaseDate = (date) => {
    if (date) {
      setActiveCompanies(
        activeCompanies.filter((company) => date === company.dataCompra)
      );
    }
  };

  const onChangeDocument = (checked) => {
    if (checked) setActiveDocument("PGR");
    if (!checked) setActiveDocument("Autodeclaração");
  };

  return (
    <div>
      <div className="relatorio-diario-header">
        <h2>Status de elaboração</h2>
        <Switch
          checkedChildren="PGR"
          unCheckedChildren="Autodeclaração"
          defaultChecked
          onChange={onChangeDocument}
        />
      </div>
      <div className="card-container relatorio-diario">
        {activeDocument === "PGR" ? (
          <Tabs defaultActiveKey="1">
            <TabPane tab="Em Preenchimento" key="1">
              <EmpresaAtivaCard
                companies={activeCompanies.filter(
                  (company) => company.status === "Em Preenchimento"
                )}
              />
            </TabPane>
            <TabPane tab="Classificação de Riscos" key="2">
              <EmpresaAtivaCard
                companies={activeCompanies.filter(
                  (company) => company.status === "Classificação de Riscos"
                )}
              />
            </TabPane>
            <TabPane tab="Plano de Ação" key="3">
              <EmpresaAtivaCard
                companies={activeCompanies.filter(
                  (company) => company.status === "Plano de Ação"
                )}
              />
            </TabPane>
            <TabPane tab="Gerar PGR" key="4">
              <EmpresaAtivaCard
                companies={activeCompanies.filter(
                  (company) => company.status === "Gerar PGR"
                )}
              />
            </TabPane>
          </Tabs>
        ) : (
          <Tabs defaultActiveKey="1">
            <TabPane tab="Autodeclaração" key="5">
              <EmpresaAtivaCard
                companies={activeCompanies.filter(
                  (company) => company.status === "Autodeclaração"
                )}
              />
            </TabPane>
          </Tabs>
        )}
      </div>
    </div>
  );
};

export default EmpresasAtivasCard;
