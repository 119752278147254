const basePath = "api";

var url = "";
if (process.env.REACT_APP_BACKEND_URL) {
  url = `${process.env.REACT_APP_BACKEND_URL}`;
}

const routeCertificate = `${url}/${basePath}/certificado/a3`

export const routeInitialize = `${routeCertificate}/inicializar`
export const routeFinalize = `${routeCertificate}/finalizar`
export const routeGetToken = `${routeCertificate}/jwt`

// export const installLinkChrome = "https://chrome.google.com/webstore/detail/dhikfimimcjpoaliefjlffaebdeomeni"
// export const installLinkFirefox = "https://addons.mozilla.org/pt-BR/firefox/addon/assinatura-digital-navegador/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search"
