import { LISTAR_PROCESSOS_PRODUTIVOS } from "../../../CaracterizacaoRealizadorProcessos/_/actions/types";
import {
  ACEITAR_PROCESSO,
  LISTAR_PROCESSOS_PRODUTIVOS_DISPONIVEIS,
  CRIAR_PROCESSO_PRODUTIVO,
  EXCLUIR_PROCESSO_PRODUTIVO,
  LISTAR_PROCESSO_PRODUTIVO,
  RECUSAR_PROCESSO_PRODUTIVO,
  EDITAR_PROCESSO_PRODUTIVO,
} from "../actions/types";
import { LISTAR_FATORES_DE_RISCO_DISPONIVEIS } from "../../../../../../InventarioRiscos/_/actions/types";

export const caracterizacaoProcessosProdutivosReducer = (
  state = {
    processosProdutivos: [],
    fatoresDeRiscoDisponiveis: [],
    processosProdutivosDisponiveis: [],
    processoProdSelecionado: {},
    processosCNAE: ["placeholder"],
  },
  action
) => {
  switch (action.type) {
    case CRIAR_PROCESSO_PRODUTIVO:
      return {
        ...state,
        processosProdutivos: [...state.processosProdutivos, action.payload],
      };
    case EDITAR_PROCESSO_PRODUTIVO:
      const editedProcess = state.processosProdutivos.find(
        (process) => process.idProcesso === action.payload.idProcessoProdutivo
      );
      editedProcess.nome = action.payload.nome;
      editedProcess.dataFim = action.payload.dataFim;
      return {
        ...state,
        processosProdutivos: state.processosProdutivos,
        processoProdSelecionado: {},
      };
    case EXCLUIR_PROCESSO_PRODUTIVO:
      return {
        ...state,
        processosProdutivos: [state.processosProdutivos].filter(
          (processo) => processo.id !== action.payload
        ),
      };
    case ACEITAR_PROCESSO:
      return {
        ...state,
        processosProdutivos: [...state.processosProdutivos, action.payload],
      };
    case LISTAR_PROCESSOS_PRODUTIVOS_DISPONIVEIS:
      return {
        ...state,
        processosProdutivosDisponiveis: action.payload.filter((e) => {
          return !state.processosProdutivos.find((el) => {
            return el.nome === e.nome;
          });
        }),
        processosCNAE: action.payload,
      };
    case LISTAR_PROCESSOS_PRODUTIVOS:
      return {
        ...state,
        processosProdutivos: action.payload,
      };
    case LISTAR_PROCESSO_PRODUTIVO:
      return {
        ...state,
        processoProdSelecionado: action.payload,
      };
    case RECUSAR_PROCESSO_PRODUTIVO:
      return {
        ...state,
        processosProdutivosDisponiveis: [
          state.processosProdutivosDisponiveis,
        ].filter((processo) => processo.id !== action.payload),
      };
    case LISTAR_FATORES_DE_RISCO_DISPONIVEIS:
      return {
        ...state,
        fatoresDeRiscoDisponiveis: action.payload,
      };
    default:
      return state;
  }
};
