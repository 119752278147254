import { Button, Checkbox, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getMAOs,
  getMAO,
  listarFatoresforGrupoDeRisco,
  patchMAO,
  emptyRelacaoMAOSelecionanda,
  addMAO,
} from "../../actions";
import "../../../styles.scss";

const { Option } = Select;

const MAOForm = (props) => {
  const [form] = Form.useForm();
  const [mode, setMode] = useState("new");
  const [maosOptions, setMaosOptions] = useState([]);

  const onLoadScreen = async () => {
    let maoListFormated = [];
    let maoList = await getMAOs();

    maoList.forEach((mao) => {
      if (mao.ativo) {
        maoListFormated.push(mao);
      } else {
        let maoAlreadyExists =
          props.relacaoMAOFatorDeRiscoSelecionada?.maos?.find(
            (item) => item.id == mao.id
          );
        if (maoAlreadyExists) {
          maoListFormated.push(mao);
        }
      }
    });

    setMaosOptions(maoListFormated);
  };

  useEffect(() => {
    if (props.relacaoMAOFatorDeRiscoSelecionada) {
      onLoadScreen();
      setMode("edit");
    }

    setEdit(props.relacaoMAOFatorDeRiscoSelecionada);
  }, [JSON.stringify(props.relacaoMAOFatorDeRiscoSelecionada)]);

  const setEdit = (relacaoMAOFatorDeRiscoSelecionada) => {
    if (mode === "edit") {
      form.setFieldsValue({
        riskName: relacaoMAOFatorDeRiscoSelecionada.descricao,
        riskMAOs: relacaoMAOFatorDeRiscoSelecionada.maos.map((mao) => mao.id),
      });
    }
  };

  const handleGroupSelection = () => {
    form
      .validateFields()
      .then((values) => {
        listarFatoresforGrupoDeRisco(values.riskGroup, props.dispatch);
      })
      .catch((err) => {});
  };

  const addNewMAO = () => {
    form.validateFields().then((values) => {
      let newRelacaoMAO = {
        maos: values.riskMAOs,
      };
      addMAO(values.riskName, newRelacaoMAO, props.dispatch);
    });
  };

  const patchRelacaoMAO = () => {
    form.validateFields().then((values) => {
      let edittedMAO = {
        maos: values.riskMAOs,
      };
      patchMAO(
        props.relacaoMAOFatorDeRiscoSelecionada.id,
        edittedMAO,
        props.dispatch
      );
      emptyRelacaoMAOSelecionanda(props.dispatch);
    });
  };

  return (
    <React.Fragment>
      <Form
        form={form}
        onFinish={mode === "new" ? () => addNewMAO() : () => patchRelacaoMAO()}
      >
        <Form.Item name="riskGroup">
          <Select
            style={{
              width: 250,
            }}
            placeholder="Selecione um grupo de risco"
            onSelect={() => handleGroupSelection()}
          >
            <Option value="1">Físico</Option>
            <Option value="2">Químico</Option>
            <Option value="3">Biológico</Option>
            <Option value="4">Ergonômico</Option>
            <Option value="5">Acidente</Option>
            <Option value="6">Inespecífico</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Fator de risco" name="riskName">
          <Select>
            {props.fatoresDeRiscoPorGrupo
              ? props.fatoresDeRiscoPorGrupo.map((fator) => (
                  <Option value={fator.id}>{fator.descricao}</Option>
                ))
              : ""}
          </Select>
        </Form.Item>
        <Form.Item label="MAOs associados" name="riskMAOs">
          <Select
            mode="multiple"
            style={{
              width: "100%",
            }}
            filterOption={(input, option) => {
              let value = option.props.children.toLowerCase();

              value = value.replace(/[àáâã]/g, "a");
              value = value.replace(/[éêè]/g, "e");
              value = value.replace(/[íîì]/g, "i");
              value = value.replace(/[óòôõ]/g, "o");
              value = value.replace(/[úùû]/g, "u");
              value = value.replace(/[ç]/g, "c");

              return (
                value.indexOf(input.toLowerCase()) >= 0 ||
                value.indexOf(input) >= 0
              );
            }}
          >
            {maosOptions.map((mao) => (
              <Option key={mao.id} value={mao.id}>
                {mao.nome}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Button htmlType="submit">Salvar</Button>
      </Form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    relacaoMAOFatorDeRiscoSelecionada:
      state.gestorDN.relacaoMAOFatorDeRiscoSelecionada,
    maos: state.gestorDN.listaMAOs,
    fatoresDeRiscoPorGrupo: state.gestorDN.fatoresDeRiscoPorGrupo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({
      getMAOs,
      getMAO,
      listarFatoresforGrupoDeRisco,
    }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MAOForm);
