import React, { useState } from "react";
import { Form, Row, Col, List, Tooltip, AutoComplete } from "antd";
import Button from "../../../../../../../components/Buttons/_/components/button";
import { QuestionCircleOutlined, CloseOutlined } from "@ant-design/icons";

const { Option } = AutoComplete;

const CargosList = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);

  const addCargo = () => {
    setInputValue("");
    setOptions([]);
    props.addCargo({ descricao: inputValue });
  };

  const emptyText = `Nenhum cargo adicionado`;

  const onSearch = () => {
    if (inputValue.length >= 2) {
      setOptions(
        props.setores
          .map((setor) => setor.cargos)
          .flat()
          .filter((cargo) =>
            cargo.toLowerCase().startsWith(inputValue.toLowerCase())
          )
      );
    } else {
      setOptions([]);
    }
  };

  const onChange = (data) => {
    setInputValue(data);
  };

  return (
    <>
      {!props.disabled ? (
        <Form.Item
          name="cargoList"
          rules={[
            {
              required: props.mode === "new" ? true : false,
              message: "Insira um cargo.",
            },
          ]}
          label={
            <span>
              Adicione cargos do setor&nbsp;
              <Tooltip title="Clique em adicionar para cadastrar o cargo ao setor. Você pode cadastrar mais de um cargo no setor.">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
        >
          <Row gutter={24}>
            <Col span={21}>
              <AutoComplete
                value={inputValue}
                onSearch={onSearch}
                onChange={onChange}
                filterOption={true}
              >
                {options.map((option, i) => (
                  <Option key={i} value={option}>
                    {option}
                  </Option>
                ))}
              </AutoComplete>
            </Col>
            <Col span={3}>
              <Button
                block
                ghost
                text="Adicionar"
                style={{ padding: "6px 15px", width: "auto" }}
                onClick={() => addCargo()}
                disabled={
                  props.cargos.find(
                    (cargo) => cargo.descricao === inputValue
                  ) || inputValue === ""
                }
              />
            </Col>
          </Row>
        </Form.Item>
      ) : (
        <Form.Item
          label="Cargos do setor"
          style={{ marginBottom: 0 }}
        ></Form.Item>
      )}
      <List
        dataSource={props.cargos}
        locale={{ emptyText: emptyText }}
        renderItem={(cargo) => (
          <List.Item
            className={`item-list cargos-list ${
              props.disabled ? "input-disabled" : ""
            }`}
          >
            {cargo.descricao}
            {!props.disabled && (
              <Button
                onClick={() => props.removeCargo(cargo)}
                type="link"
                ghost
                text={<CloseOutlined />}
              />
            )}
          </List.Item>
        )}
      />
    </>
  );
};

export default CargosList;
