import { Button, Form, Modal, Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "../certificateModal/styles.scss";
import openNotification from "../../../../../components/notifications/index";
import {
  routeInitialize,
  routeFinalize,
  routeGetToken,
} from "../constants/index";
import { isExtensionInstalled } from "../utils/index";
// import { useHistory } from "react-router-dom/";

export const CertificateModal = ({
  eventType,
  eventId,
  handleRefreshTable,
  destroyModal,
}) => {
  const [form] = Form.useForm();
  // const history = useHistory();
  // const { destroyAll } = Modal;

  const extensionInstalled = isExtensionInstalled();

  const [installedCertificates, setInstalledCertificates] = useState();

  const [selectedCertificate, setSelectedCertificate] = useState("");

  const handleSubmit = () => {
    form.validateFields().then(() => {
      if (!selectedCertificate) {
        openNotification("Nenhum certificado selecionado!", "", "error");
      } else {
        sign();
      }
    });
  };

  async function sign() {
    let eventTypeFormatted = "";
    let currentCertificate;

    switch (eventType) {
      case "S-2210":
        eventTypeFormatted = "s2210";
        break;
      case "S-2220":
        eventTypeFormatted = "s2220";
        break;
      case "S-2240":
        eventTypeFormatted = "s2240";
        break;
    }

    currentCertificate = installedCertificates.find(
      (certificate) => certificate.certId === selectedCertificate
    );

    const data = new FormData();

    data.append("certificate", currentCertificate.certificateData);
    data.append("tipoevento", eventTypeFormatted);
    data.append("idevento", eventId);

    try {
      await axios
        .get(routeGetToken)
        .then(async (response) => {
          await axios
            .post(routeInitialize, data, {
              headers: {
                Authorization: response.data.access_token,
              },
            })
            .then((res) => {
              signExtension(
                res.data,
                currentCertificate.certificateData,
                response.data.access_token
              );
            })
            .catch((err) => {
              console.log({ err });
            });
          return;
        })
        .catch((error) => {
          console.log({ error });
          return false;
        });
    } catch (err) {
      console.log({ err });
      alert(err.response);
    }
  }

  async function signExtension(response, certificate, signToken) {
    response.formatoDadosEntrada = "Base64";
    response.formatoDadosSaida = "Base64";
    response.algoritmoHash = "SHA256";
    response.assinaturas = response.signedAttributes;
    response.assinaturas[0].hashes = response.assinaturas[0].content;
    response.assinaturas.forEach(
      (assinatura) => (assinatura.hashes = [assinatura.hashes])
    );

    await window.BryExtension.sign(
      selectedCertificate,
      JSON.stringify(response)
    )
      .then(async (signature) => {
        let eventTypeFormatted = "";

        switch (eventType) {
          case "S-2210":
            eventTypeFormatted = "s2210";
            break;
          case "S-2220":
            eventTypeFormatted = "s2220";
            break;
          case "S-2240":
            eventTypeFormatted = "s2240";
            break;
        }

        let data = new FormData();

        data.append(
          "initializedDocuments",
          response.initializedDocuments[0].content
        );
        data.append("certificate", certificate);
        data.append("cifrado", signature.assinaturas[0].hashes[0]);
        data.append("tipoevento", eventTypeFormatted);
        data.append("idevento", eventId);

        try {
          await axios
            .post(routeFinalize, data, {
              headers: {
                Authorization: signToken,
              },
            })
            .then(() => {
              openNotification(
                "Sucesso! Assinado com certificado A3.",
                "",
                "success"
              );
              handleRefreshTable();
              destroyModal();
            })
            .catch((err) => {
              console.log({ err });
            });
        } catch (err) {
          console.log({ err });
        }
      })
      .catch((err) => {
        if (err.code === "-10041") {
          openNotification(
            `Não foi encontrado nenhum certificado A3 na sua máquina.`,
            "",
            "error"
          );
          setInstalledCertificates(undefined);
        }
      });
  }

  useEffect(() => {
    if (extensionInstalled) {
      window.BryExtension.listCertificates().then((certificates) => {
        certificates.forEach((certificate) => {
          certificate.label = certificate.name;
          certificate.value = certificate.certId;
        });
        setInstalledCertificates(certificates);
      });
    }
  }, []);

  // const handleRedirect = () => {
  //   history.push("/gerenciar-assinaturas");
  //   destroyAll();
  // };

  return (
    <>
      <Form form={form} onFinish={handleSubmit}>
        <div className="containerModal">
          <Form.Item name="certificateId">
            <h1 style={{ marginBottom: 24 }}>Assinatura de Certificado</h1>
            {/* {(installedCertificates && installedCertificates.lenght !== 0) ? (
              <>
                <p style={{ marginBottom: 32 }}>
                  Selecione um certificado A3 abaixo e clique
                  em <strong>Assinar</strong>.
                </p>
              </>
            ) : ( */}
            <div style={{ alignItems: "center" }}>
              <p>
                Sua empresa não possui uma assinatura digital cadastrada no
                sistema.
              </p>
              <p>
                Se o seu certificado digital for do tipo A1, por favor, retorne
                ao menu lateral e selecione a opção Gerenciar Assinaturas” e
                realize o cadastro.
              </p>
              {/* <p>Se o seu certificado digital for do tipo A3, por favor, insira o seu dispositivo para realizar a assinatura e envio do seu evento do eSocial.
                </p> */}
            </div>
            {/* )} */}

            {/* {installedCertificates && installedCertificates.lenght !== 0 && (
              <Select
                style={{
                  width: 368,
                }}
                placeholder="Selecione um certificado"
                options={installedCertificates}
                onSelect={(event) => {
                  setSelectedCertificate(event);
                }}
              />
            )} */}
          </Form.Item>
        </div>
        {/* {installedCertificates && installedCertificates.lenght !== 0 && (
          <Button style={{ marginBottom: 8, marginTop: 24 }} htmlType="submit">
            Assinar
          </Button>
        )} */}
      </Form>
    </>
  );
};
