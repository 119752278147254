import React, { Component } from "react";
import ApresentacaoEmpresas from "../ApresentacaoEmpresas";
import { connect } from "react-redux";
import { introTalks } from "../../constants/aroldoTalks";
import Assistant from "../../components/VirtualAssistant";
import { isPerfilCliente } from "../../constants/userTypes";
import { runAction } from "../../components/Buttons/_/actions";
import {
  INTRO_OVER,
  BACK_PAGE_INTRO,
} from "../../components/Buttons/_/actions/types";
import Button from "../../components/Buttons/_/components/button";
import IconSESI from "../../components/IconsSESI";
import "./style.scss";
import { fetchMeuPerfil } from "../MeuPerfil/_/actions/index";
import LoginPage from "../Login";
import GestorDR from "../Admin/GestorDR";
import GestorDN from "../Admin/GestorDN";

class Inicio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
    };
    if (this.props.authenticated) {
      this.props.fetchMeuPerfil();
      this.state = { authenticated: true };
    }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.authenticated) !==
      JSON.stringify(this.props.authenticated)
    ) {
      this.props.fetchMeuPerfil();
      this.setState({ authenticated: this.props.authenticated });
    }
  }

  render() {
    if (!this.state.authenticated) {
      return <LoginPage />;
    }

    if (!isPerfilCliente(this.props.perfil.perfil)) {
      if (this.props.perfil.perfil === "ADMINISTRADOR") {
        return <GestorDN />;
      } else {
        return <GestorDR />;
      }
    }

    if (!isPerfilCliente(this.props.perfil) || window.innerWidth < 808) {
      this.props.runAction(INTRO_OVER);
      return <ApresentacaoEmpresas />;
    }

    return (
      <div className="presentation">
        <Assistant />

        <div className="goback">
          {this.props.page === 2 && (
            <Button
              onClick={() => this.props.runAction(BACK_PAGE_INTRO)}
              shape="circle"
              text={<IconSESI type="arrow-left" />}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const isAuthenticated = !state.user.hasOwnProperty("authenticated")
    ? false
    : state.user.authenticated;
  return {
    customizacoes: state.user.userInfo ? state.user.userInfo.customizacoes : {},
    page: state.buttons.pageIntro,
    perfil: state.user.userInfo,
    authenticated: isAuthenticated,
  };
};

export default connect(mapStateToProps, {
  runAction,
  fetchMeuPerfil,
})(Inicio);
