import React, { Component, useEffect, useState } from "react";
import { List, Checkbox, Form, Col } from "antd";
import Button from "../../../../components/Buttons/_/components/button";
import Itens from "./Itens";
import IconSESI from "../../../../components/IconsSESI";
// import Loading from "../../../../components/Loading";

const ItensListaForm = (props) => {
  const [form] = Form.useForm();
  const [checkedList, setCheckedList] = useState([]);

  useEffect(() => {
    if (Object.keys(props.listaDeInformacoes).length > 0) {
      console.log(props.listaDeInformacoes);
      setCheckedList(
        props.listaDeInformacoes.itensMarcadosDaEmpresa
          ? props.listaDeInformacoes.itensMarcadosDaEmpresa.map((i) =>
              i.checado ? true : false
            )
          : ""
      );
    }
  }, [JSON.stringify(props.listaDeInformacoes)]);

  const onCheck = (e, key) => {
    const checkedListCopy = [...checkedList];
    checkedListCopy[key] = e.target.checked;
    setCheckedList(checkedListCopy);
  };

  const handleSubmit = (e) => {
    let data = [];
    for (
      var i = 0;
      i <= props.listaDeInformacoes.listaDeInformacoesDisponiveis.length - 1;
      i++
    ) {
      data.push({
        id: i + 1,
        check: checkedList[i] === true ? 1 : 0,
      });
    }
    props.gerarPDF(data, props.dispatch);
  };

  return (
    <Form onFinish={handleSubmit} form={form}>
      <List
        itemLayout="horizontal"
        dataSource={props.listaDeInformacoes.listaDeInformacoesDisponiveis}
        renderItem={(item) => (
          <List.Item>
            <Col span={1}>
              <Form.Item>
                <Checkbox
                  className="lista-informacoes-checkbox"
                  onChange={(e) => {
                    onCheck(e, item.id - 1);
                  }}
                  defaultChecked={
                    props.listaDeInformacoes.itensMarcadosDaEmpresa.length > 0
                      ? props.listaDeInformacoes.itensMarcadosDaEmpresa[
                          item.id - 1
                        ].checado === 1
                        ? true
                        : false
                      : false
                  }
                  defaultValue={
                    props.listaDeInformacoes.itensMarcadosDaEmpresa.length > 0
                      ? props.listaDeInformacoes.itensMarcadosDaEmpresa[
                          item.id - 1
                        ].checado === 1
                        ? true
                        : false
                      : false
                  }
                ></Checkbox>
              </Form.Item>
            </Col>
            <Itens
              key={item.id - 1}
              title={item.nome}
              description={item.descricao}
              selected={checkedList[item.id - 1]}
            />
            {console.log(checkedList)}
          </List.Item>
        )}
      ></List>
      <div className="footer">
        <Col span={12}>
          <IconSESI type="file-outlined"></IconSESI>
          <h4>PDF da lista de informações:</h4>
        </Col>
        <Col span={12}>
          <Button
            block
            type="primary"
            text="Fazer download do PDF"
            htmlType="submit"
          />
        </Col>
      </div>
    </Form>
  );
};

export default ItensListaForm;
