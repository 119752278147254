import React from "react";
import AcompanhamentoAssistenteVirtual from "../scenes/Admin/GestorDN/_/components/AssistenteVirtual/components/AcompanhamentoAssistenteVirtual";
import AutodeclaracaoAssistenteVirtual from "../scenes/Admin/GestorDN/_/components/AssistenteVirtual/components/AutodeclaracaoAssistenteVirtual";
import CaracterizacaoAssistenteVirtual from "../scenes/Admin/GestorDN/_/components/AssistenteVirtual/components/CaracterizacaoAssistenteVirtual";
import InicioAssistenteVirtual from "../scenes/Admin/GestorDN/_/components/AssistenteVirtual/components/InicioAssistenteVirtual";

import {
  CaracterizacaoEmpresa,
  CaracterizacaoFuncionarios,
  CaracterizacaoSetores,
  CaracterizacaoProcessosProdutivos,
  CaracterizacaoRealizadorProcessos,
} from "../scenes/Caracterizacao";
import {
  EMPRESA,
  SETORES,
  FUNCIONARIOS,
  PROCESSOS_PRODUTIVOS,
  REALIZADOR_DE_PROCESSOS,
  INICIO,
  CARACTERIZACAO,
  ACOMPANHAMENTO,
  AUTODECLARACAO,
} from "./stepTypes";

export const StepsResponsavelOperadorTecnico = [
  {
    id: EMPRESA,
    title: "Empresa",
    icon: "building-bold",
    number: 0,
    // talk1: stepsTalks.talksEmpresa[1],
    // talk2: stepsTalks.talksEmpresa[1],
    content: (props) => <CaracterizacaoEmpresa {...props} />,
  },
  {
    id: SETORES,
    title: "Setores",
    icon: "project-diagram",
    number: 1,
    // talk1: stepsTalks.talksSetores[1],
    // talk2: stepsTalks.talksSetores[2],
    content: (props) => <CaracterizacaoSetores {...props} />,
  },
  {
    id: FUNCIONARIOS,
    title: "Trabalhadores",
    icon: "users",
    number: 2,
    // talk1: stepsTalks.talksTrabalhadores[1],
    // talk2: stepsTalks.talksTrabalhadores[2],
    content: (props) => <CaracterizacaoFuncionarios {...props} />,
  },
  {
    id: PROCESSOS_PRODUTIVOS,
    title: "Processos Produtivos",
    icon: "tasks",
    number: 3,
    // talk1: stepsTalks.talksProcessos[1],
    // talk2: stepsTalks.talksProcessos[2],
    content: (props) => <CaracterizacaoProcessosProdutivos {...props} />,
  },
  {
    id: REALIZADOR_DE_PROCESSOS,
    title: "Trabalhador x Processo",
    icon: "process-maker",
    number: 4,
    // talk1: stepsTalks.talksRealizadorProcesso[1],
    // talk2: stepsTalks.talksRealizadorProcesso[1],
    content: (props) => <CaracterizacaoRealizadorProcessos {...props} />,
  },
];

export const StepsAssistenteVirtual = [
  {
    id: INICIO,
    title: "Início",
    icon: "process-maker",
    number: 0,
    content: (props) => <InicioAssistenteVirtual {...props} />,
  },
  {
    id: CARACTERIZACAO,
    title: "Categorização",
    icon: "process-maker",
    number: 1,
    content: (props) => <CaracterizacaoAssistenteVirtual {...props} />,
  },
  {
    id: ACOMPANHAMENTO,
    title: "Acompanhamento",
    icon: "process-maker",
    number: 2,
    content: (props) => <AcompanhamentoAssistenteVirtual {...props} />,
  },
  {
    id: AUTODECLARACAO,
    title: "Autodeclaração",
    icon: "process-maker",
    number: 3,
    content: (props) => <AutodeclaracaoAssistenteVirtual {...props} />,
  },
];

export const steps = {
  RESPONSAVEL: StepsResponsavelOperadorTecnico,
  OPERADOR: StepsResponsavelOperadorTecnico,
  ADMINASSISTENTEVIRTUAL: StepsAssistenteVirtual,
};
