export const LISTAR_CATEGORIAS = "LISTAR_CATEGORIAS";
export const LISTAR_EXAMES = "LISTAR_EXAMES";
export const LISTAR_FUNCIONARIOS_ESOCIAL = "LISTAR_FUNCIONARIOS_ESOCIAL";
export const ENVIAR_MONITORAMENTO = "ENVIAR_MONITORAMENTO";
export const SALVAR_ASSINATURA = "SALVAR_ASSINATURA";
export const LISTAR_ASSINATURAS = "LISTAR_ASSINATURAS";
export const DELETAR_ASSINATURA = "DELETAR_ASSINATURA";
export const ALTERAR_ASSINATURA = "ALTERAR_ASSINATURA";
export const LISTAR_EVENTOS = "LISTAR_EVENTOS";
export const GERAR_DOCUMENTO = "GERAR_DOCUMENTO";
export const ENVIAR_ESOCIAL = "ENVIAR_ESOCIAL";
export const OBTER_EVENTO = "OBTER_EVENTO";
export const ALTERAR_S2220 = "ALTERAR_S2220";
export const CONSULTAR_S2220 = "CONSULTAR_S2220";
export const SALVAR_S3000 = "SALVAR_S3000";
export const GERAR_XML_S3000 = "GERAR_XML_S3000";
export const ENVIAR_S3000 = "ENVIAR_S3000";
export const CONSULTAR_S3000 = "CONSULTAR_S3000";
export const SALVAR_S2240 = "SALVAR_S2240";
export const ALTERAR_S2240 = "ALTERAR_S2240";
export const LISTAR_S2240 = "LISTAR_S2240";
export const OBTER_S2240 = "OBTER_S2240";
export const ENVIAR_S2240 = "ENVIAR_S2240";
export const GERAR_XML_S2240 = "GERAR_XML_S2240";
export const CONSULTAR_S2240 = "CONSULTAR_S2240";
export const LISTAR_AGENTES_NOCIVOS = "LISTAR_AGENTES_NOCIVOS";
export const OBTER_AGENTE_NOCIVO = "OBTER_AGENTE_NOCIVO";
