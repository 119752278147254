import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Upload, Button, Icon } from "antd";
import { MEDICO, TECNICO } from "../../../../../../../constants/userTypes";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";

class LogoUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: [],
      fileList: [{ uid: -1 }],
      showLogoError: false,
    };
  }
  componentDidMount() {
    if (this.props.logo) {
      const fileList = [];
      fileList.push(this.props.logo);
      this.setState({ fileList: fileList });
    }
  }

  onChangeLogo = (logo) => {
    let fileList = logo.fileList;
    if (fileList.length > 1) {
      fileList = fileList.slice(-1);
    }
    this.setState({ fileList });
    if (logo.fileList.length > 0) {
      this.setState({ showLogoError: false });
      this.props.getLogoFormData(fileList[0].originFileObj);
    }
  };

  deleteLogo = (logo) => {
    const { fileList } = this.state;
    this.setState({
      fileList: fileList.filter((item) => item.iud !== logo.iud),
    });
    this.props.getDeletedLogoFormData();
    let formData = new FormData();
    formData.append("removerLogo", 1);
    this.setState({ showLogoError: true });
  };

  render() {
    const { fileList } = this.state;
    return (
      <Form.Item
        label={
          <div>
            <span style={{ color: "var(--pendency)" }}>*</span>
            <span> Envie a logomarca da empresa</span>
          </div>
        }
      >
        <Upload
          name="logo"
          multiple={false}
          accept="image/*"
          disabled={this.props.viewOnly}
          onChange={this.onChangeLogo}
          fileList={this.state.fileList}
          showUploadList={false}
          beforeUpload={() => {
            return false;
          }}
        >
          <Button ghost block>
            SELECIONAR ARQUIVO
          </Button>
          {this.state.showLogoError ? (
            <div
              className="ant-form-explain"
              style={{ color: "var(--pendency)" }}
            >
              Insira a logomarca da empresa.
            </div>
          ) : null}
        </Upload>
        {fileList.length > 0 && fileList[0] !== null && fileList[0].uid !== -1
          ? fileList.map((logo, i) => {
              return (
                <div className="ant-upload-list-item-custom" key={i}>
                  <div className="file-name">{logo.name}</div>
                  <div className="file-name">
                    <a
                      href={`${process.env.REACT_APP_BACKEND_URL}${logo.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {logo.nome}
                    </a>
                  </div>
                  {this.props.perfil === TECNICO ||
                  this.props.perfil === MEDICO ||
                  this.props.completed ? null : this.props.logoLoading ? (
                    <LoadingOutlined style={{ color: "var(--alto)" }} />
                  ) : (
                    <CloseOutlined
                      style={{ color: "var(--alto)" }}
                      onClick={() => {
                        this.deleteLogo(logo);
                      }}
                    />
                  )}
                </div>
              );
            })
          : null}
      </Form.Item>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    logo: state.caracterizacao.companySelected.logo,
  };
};

export default connect(mapStateToProps, {})(LogoUpload);
