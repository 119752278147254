import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { generatePlanilha1, generatePlanilhaX } from "../actions";

const CompanyExportCard = (props) => {
  const [company, setCompany] = useState({});

  useEffect(() => {
    setCompany(props.company);
  }, [JSON.stringify(props.company)]);

  const handleGeneratePlanilha1 = (cnpj) => {
    generatePlanilha1(cnpj);
  };

  const handleGeneratePlanilhaX = (cnpj) => {
    generatePlanilhaX(cnpj);
  };

  return (
    <div className="company-card-div">
      <div className="company-name-div">
        <h6>Nome fantasia</h6>
        <h4>{company.nomeFantasia}</h4>
      </div>

      <div className="company-data-div">
        <div className="cnpj-div">
          <h6>CNPJ:</h6>
          <h5>{company.cnpj}</h5>
        </div>

        <div className="razao-social-div">
          <h6>Razão social:</h6>
          <h5>{company.razaoSocial}</h5>
        </div>
      </div>

      <div className="export-button-div">
        <Button onClick={() => handleGeneratePlanilha1(company.cnpj)}>
          EXPORTAR PLANILHA MOD. 1
        </Button>
        <Button onClick={() => handleGeneratePlanilhaX(company.cnpj)}>
          EXPORTAR PLANILHA MOD. X
        </Button>
      </div>
    </div>
  );
};

export default CompanyExportCard;
