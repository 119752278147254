import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Row,
  Select,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import locale from "antd/lib/locale/pt_BR";
import "../styles.scss";
import { values } from "lodash";

const DateCard = (props) => {
  const [form] = Form.useForm();
  const { Option } = Select;

  let starterYear = 2022;
  let currentYear = new Date().getFullYear();
  let years = [];
  while (starterYear <= currentYear) {
    years.push(starterYear++);
  }

  const onFinish = () => {
    form
      .validateFields()
      .then((values) =>
        props.emitirSMD(
          {
            dataConsulta: `${
              values.chosenMonth
            }/${values.chosenYear.toString()}`,
          },
          values.chosenMonth,
          values.chosenYear
        )
      )
      .catch((err) => console.error(err));
  };

  return (
    <React.Fragment>
      <Row gutter={24}>
        <Col span={12}>
          <div className="content-group">
            <Form form={form} onFinish={() => onFinish()}>
              <div className="date-card">
                <Form.Item
                  label={<h3>Selecione o mês da pesquisa</h3>}
                  name="chosenMonth"
                >
                  <Select>
                    <Option value="01">Janeiro</Option>
                    <Option value="02">Fevereiro</Option>
                    <Option value="03">Março</Option>
                    <Option value="04">Abril</Option>
                    <Option value="05">Maio</Option>
                    <Option value="06">Junho</Option>
                    <Option value="07">Julho</Option>
                    <Option value="08">Agosto</Option>
                    <Option value="09">Setembro</Option>
                    <Option value="10">Outubro</Option>
                    <Option value="11">Novembro</Option>
                    <Option value="12">Dezembro</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label={<h3>Selecione o ano da pesquisa</h3>}
                  name="chosenYear"
                >
                  <Select>
                    {years.map((year) => (
                      <Option key={year} value={year}>
                        {year}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="btn-div">
                <Button htmlType="submit">BUSCAR</Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default DateCard;
