export const percentageList = [
  {
    value: 1,
    label: "Até 10% da empresa",
  },
  {
    value: 2,
    label: "De 10% a 30% da empresa",
  },
  {
    value: 3,
    label: "De 30% a 60% da empresa",
  },
  {
    value: 4,
    label: "Acima de 60% da empresa",
  },
];

export const frequencyList = [
  {
    value: 1,
    label: "1 vez por mês ou menos",
  },
  {
    value: 2,
    label: "A cada 15 dias",
  },
  {
    value: 3,
    label: "1 ou 2 vezes por semana",
  },
  {
    value: 4,
    label: "Diariamente",
  },
];

export const exposureTimeList = [
  {
    value: 1,
    label: "Até 2h",
  },
  {
    value: 2,
    label: "De 2h a 4h",
  },
  {
    value: 3,
    label: "De 4h a 6h",
  },
  {
    value: 4,
    label: "De 6h a 8h",
  },
];

export const intensityPerceptionList = [
  {
    value: 1,
    label: "Baixa",
  },
  {
    value: 2,
    label: "Média",
  },
  {
    value: 3,
    label: "Alta",
  },
  {
    value: 4,
    label: "Muito Alta",
  },
];

export const getDangerLevel = (levelId) => {
  switch (levelId) {
    case 0:
      return "Não Aplicável";
    case 1:
      return "Baixo";
    case 2:
      return "Médio";
    case 3:
      return "Alto";
    case 4:
      return "Excessivo";
    default:
      break;
  }
};
