import React from "react";
import { Form, Input } from "antd";
import Button from "../../../../components/Buttons/_/components/button";
import IconSESI from "../../../../components/IconsSESI";

import openNotification from "../../../../components/notifications";

const NewPasswordForm = (props) => {
  const [form] = Form.useForm();

  const resetState = () => {
    form.resetFields();
  };

  const nextStep = (callback) => {
    props.tab("resetResponse");
    if (callback) callback();
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        let newPasswordRequestBody = {
          cpf: props.mudancaDeSenha.userCpf,
          codeValidator: props.mudancaDeSenha.codigoValidador.codeValidator,
          newPassword: values.newPassword,
        };
        props.novaSenha(
          props.dispatch,
          newPasswordRequestBody,
          resetState,
          nextStep
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Form form={form} onFinish={() => handleSubmit()}>
      <div className="header-form transparent">
        <Button
          shape="circle"
          ghost
          text={<IconSESI type="arrow-left" />}
          onClick={() => {
            resetState();
            props.tab("code");
          }}
        />
        <h2>Nova Senha</h2>
      </div>
      <div className="login-form-body">
        <Form.Item
          name="newPassword"
          rules={[{ required: true, message: "Insira uma senha!" }]}
          label={
            <React.Fragment>
              <h4>A senha deve conter, no mínimo</h4>
              <h4>8 caracteres, 1 letra maiúscula, </h4>
              <h4>1 número e 1 símbolo</h4>
            </React.Fragment>
          }
        >
          <Input.Password placeholder="Senha" />
        </Form.Item>
        <Form.Item
          name="confirmNewPassword"
          dependencies={["newPassword"]}
          rules={[
            { required: true, message: "Confirme sua senha!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("As senhas inseridas não são idênticas.")
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirmar Senha" />
        </Form.Item>
      </div>
      <div className="login-form-footer">
        <Button text="Enviar" htmlType="submit" />
      </div>
    </Form>
  );
};

export default NewPasswordForm;
