import React from "react";
import { Col, Row } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import DateCard from "./components/DateCard";
import "./styles.scss";
import { emitirSMD } from "./actions";

const RelatorioSMD = (props) => {
  return (
    <div className="smd-container">
      <div className="gestor-dr-header">
        <Row gutter={24}>
          <Col span={16}>
            <h1>Olá, {props.userInfo.nome}!</h1>
            <span>{moment().format("DD/MM/YYYY")}</span>
            <div className="gestor-dr-filters"></div>
          </Col>
        </Row>
        <Row>
          <Col lg={18} md={16}>
            <div className="header-form">
              <h2>Pesquisar relatório SMD</h2>
            </div>
          </Col>
        </Row>
      </div>
      <div className="content">
        <DateCard emitirSMD={emitirSMD} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.userInfo,
  };
};

export default connect(mapStateToProps)(RelatorioSMD);
