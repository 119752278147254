// ./utils/auth.js
import axios from "axios";
import { history } from "../components/Routes";
// import openNotification from "../components/notifications";

export const setupInterceptors = () => {
  axios.interceptors.request.use(
    (config) => {
      config = {
        ...config,
        withCredentials: true,
      };
      config.headers = {
        ...config.headers,
        "content-type": "application/json",
      };

      return config;
    },
    (error) =>
      // Do something with request error
      Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => {
      if (response.data["accessToken"]) {
        persistAuthHeadersInDeviceStorage(response.data);
      }

      return response;
    },
    function (error) {
      // Do something with response error
      const EMPRESA_NAO_SELECIONADA = "EMPRESA_NAO_SELECIONADA";
      if (error.response) {
        if (
          error.response.status === 500 &&
          error.response.data.message === EMPRESA_NAO_SELECIONADA
        ) {
          onUnauthenticated();
        }
      }
      return Promise.reject(error);
    }
  );

  function persistAuthHeadersInDeviceStorage(data) {
    localStorage.setItem("accessToken", data["accessToken"]);
    localStorage.setItem("tokenType", "Bearer");
    localStorage.setItem("content-type", "application/json");
  }
};

export function onUnauthenticated() {
  history.push("/");
  // openNotification("Empresa não selecionada!", "", "error");
}
