import React from "react";
import { Form, Row, Col, Input } from "antd";

const { Search } = Input;

const SearchBar = (props) => {
  const { filterList, name } = props;

  return (
    <Form>
      <Row gutter={24}>
        <Col lg={6} md={12}>
          <Form.Item>
            <Search placeholder={`Pesquisar ${name}`} onChange={filterList} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchBar;
