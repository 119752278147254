import { FETCH_LISTADEINFORMACOES, GERAR_PDF } from "./types";
import { setupInterceptors } from "../../../../auth/SetupInterceptors";
import { listaDeInformacoesUrl } from "../../../../constants/index";
import axios from "axios";

//import openNotification from "../../../../components/notifications";

setupInterceptors();

const downloadPDF = (data) => {
  axios(listaDeInformacoesUrl, {
    method: "POST",
    responseType: "blob",
    data: data,
  }).then((response) => {
    const url = URL.createObjectURL(
      new Blob([response.data], { type: "application/pdf" })
    );
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    //link.setAttribute("download", response.headers["content-disposition"]);
    document.body.appendChild(link);
    link.click();
  });
};
export const fetchListaDeInformacoes = (dispatch) => {
  axios.get(listaDeInformacoesUrl).then((resposta) => {
    dispatch({
      type: FETCH_LISTADEINFORMACOES,
      payload: resposta.data,
    });
  });
};
export const gerarPDF = (data, dispatch) => {
  axios.post(listaDeInformacoesUrl, data).then((resposta) => {
    dispatch({
      type: GERAR_PDF,
      payload: resposta.data,
    });
    downloadPDF(data);
  });
};
