import { Button, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  getEPCPorGrupo,
  emptyRelacaoMAOSelecionanda,
  getEPC,
  getEPCsPorGrupo,
} from "../../actions";
import "../../../styles.scss";

const { Option } = Select;

const RelacaoEPCList = (props) => {
  const [riskFactorList, setRiskFactorList] = useState([]);

  const handleGroupSelection = async (value) => {
    let riskFactorByGroup = await getEPCsPorGrupo(value);
    setRiskFactorList(riskFactorByGroup);
  };

  const handleEdit = (id) => {
    emptyRelacaoMAOSelecionanda(props.dispatch);
    getEPC(id, props.dispatch);
  };

  return (
    <React.Fragment>
      <div>
        <div className="filter">
          <Select
            style={{
              width: 250,
            }}
            placeholder="Selecione um grupo de risco"
            onSelect={(value) => handleGroupSelection(value)}
          >
            <Option value="1">Físico</Option>
            <Option value="2">Químico</Option>
            <Option value="3">Biológico</Option>
            <Option value="4">Ergonômico</Option>
            <Option value="5">Acidente</Option>
            <Option value="6">Inespecífico</Option>
          </Select>
        </div>
        <div className="dn-content">
          {riskFactorList.map((risk) => (
            <div key={risk.id} className="fatores-de-risco-item">
              <p>Fator de risco: {risk.descricao}</p>
              <p>Código: {risk.codigo}</p>
              {risk.epcs.map((epc) => (
                <div className="epc" key={epc.id}>
                  <p>EPC: {epc.nome}</p>
                  <p>Código EPC: {epc.id}</p>
                </div>
              ))}
              <Link to="/formulario-relacao-epc">
                <Button onClick={() => handleEdit(risk.id)}>Editar</Button>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.userInfo,
    listaEPCsPorGrupo: state.gestorDN.listaEPCsPorGrupo
      ? state.gestorDN.listaEPCsPorGrupo
      : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ getEPCPorGrupo, getEPC }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RelacaoEPCList);
