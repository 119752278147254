import React, { useEffect, useState } from "react";
import Button from "../../../../../../../components/Buttons/_/components/button";
import IconSESI from "../../../../../../../components/IconsSESI";
import { Form, Select, Row, Col, Input } from "antd";
import CargosList from "./CargosList";
import "../../styles.scss";
import usePrevious from "../../../../../../../util/usePrevious";

const { Option } = Select;

const SetorForm = (props) => {
  const [form] = Form.useForm();
  const previousSetor = usePrevious(props.setor);
  const [cargos, setCargos] = useState([]);
  const [viewOnly, setViewOnly] = useState(false);
  const [setoresCatalogo, setSetoresCatalogo] = useState(props.setoresCatalogo);
  const [cargosRemovidos, setCargosRemovidos] = useState([]);
  const [, setCargosSaved] = useState([]);
  const [otherSetor, setOtherSetor] = useState(false);
  const [cargoObrigatorio, setCargoObrigatorio] = useState(false);
  const [nomeSetorValido, setNomeSetorValido] = useState(true);
  const [, setNomeOutroSetor] = useState("");
  const [loading, setLoading] = useState(false);
  const [clearInput, setClearInput] = useState(false);
  const [mode, setMode] = useState("");
  let isOncatalogAttr = false;

  const iniciarModoEdicao = (setor) => {
    if (setor) {
      const { nome, quantidadeDeTrabalhadores, cargos } = setor;
      form.setFieldsValue({
        setor: isOncatalog(nome) ? nome : "outro",
        quantidadeDeTrabalhadores: quantidadeDeTrabalhadores,
        outroSetor: isOncatalog(nome) ? "" : nome,
      });

      setCargos(cargos);
      setCargosRemovidos([]);
      setViewOnly(props.viewOnly);
      setMode("edit");
      setOtherSetor(
        props.setoresCatalogo.sugestoes.find(
          (setorCatalogo) => setorCatalogo.nome === nome
        ) === undefined
      );
    }
  };

  useEffect(() => {
    if (props.mode === "edit") {
      iniciarModoEdicao(props.setor);
    }
    if (previousSetor !== props.setor) {
      if (props.mode === "edit") {
        iniciarModoEdicao(props.setor);
      } else {
        setSetoresCatalogo(props.setoresCatalogo);
      }
    }
  }, [JSON.stringify(props.setor)]);

  // useEffect(() => {
  //   const outroSetor = form.getFieldValue("outroSetor");
  //   if (outroSetor && nomeOutroSetor !== outroSetor) {
  //     setNomeSetorValido(
  //       validateSetorNome(props.setoresIndisponiveis, outroSetor, props.setor)
  //     );
  //     setNomeOutroSetor(outroSetor);
  //   }
  // });

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const validateSetorNome = (setoresIndisponiveis, outroSetor, setorAtual) => {
    setNomeOutroSetor(outroSetor);
    if (outroSetor === setorAtual.nome) {
      return true;
    } else {
      return (
        setoresIndisponiveis
          .map((setor) => setor.toLowerCase())
          .indexOf(outroSetor.toLowerCase()) === -1
      );
    }
  };

  const isOncatalog = (setorNome) => {
    if (setorNome === undefined) return false;
    return (
      setoresCatalogo.sugestoes
        .map((setor) => setor.nome.toLowerCase())
        .indexOf(setorNome.toLowerCase()) !== -1
    );
  };

  const addCargo = (cargo) => {
    setCargos([...cargos, cargo]);
    setMode("new");
    setCargoObrigatorio(false);
  };

  const removeCargo = (cargo) => {
    const oldCargo = cargos.find(
      (cargoExistente) => cargoExistente.descricao === cargo.descricao
    );
    const newList = cargos.filter(
      (cargoExistente) => cargoExistente.descricao !== cargo.descricao
    );
    setCargos(newList);
    setCargosRemovidos(
      oldCargo.id ? [...cargosRemovidos, oldCargo] : cargosRemovidos
    );
  };

  const resetState = () => {
    setViewOnly(false);
    setOtherSetor(false);
    setCargos([]);
    form.resetFields();
  };

  const handleSubmit = (e) => {
    form
      .validateFields()
      .then((values) => {
        const nome = otherSetor ? values.outroSetor : values.setor;
        if (props.mode === "new") {
          setCargosSaved(
            cargos.length > 0 ? cargos.map((cargo) => cargo.descricao) : []
          );
          const setor = {
            cargos: cargos.map((cargo) => cargo.descricao),
            nome,
            quantidadeTrabalhadores: 0, //values.quantidadeDeTrabalhadores,
          };
          props.addSetor(
            props.dispatch,
            setor,
            props.tab,
            resetState,
            toggleLoading
          );
        } else {
          const newCargos =
            cargos.length > 0
              ? cargos
                  .filter((cargo) => cargo.id === undefined)
                  .map((cargo) => cargo.descricao)
              : [];
          const newCargosRemovidos =
            cargosRemovidos.length > 0 ? cargosRemovidos : [];
          const setor = {
            cargos: newCargos,
            nome,
            cargosRemovidos: newCargosRemovidos.map((cargo) => cargo.id),
            quantidadeTrabalhadores: 0, //values.quantidadeDeTrabalhadores,
          };
          props.patch(
            props.dispatch,
            props.setor.id,
            setor,
            newCargosRemovidos,
            toggleLoading,
            props.tab,
            resetState
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const goToList = () => {
    form.resetFields();
    props.tab();
    setCargos([]);
    setViewOnly(false);
    setClearInput(true);
    setOtherSetor(false);
    props.onChangeTalk("talk1", 1);
  };

  const checkIfIsOther = (value) => {
    setOtherSetor(value === "outro");
  };

  if (props.setor) {
    isOncatalogAttr = isOncatalog(props.setor.nome);
  }

  return (
    <React.Fragment>
      <div className="header-form transparent">
        <Button
          shape="circle"
          ghost
          text={<IconSESI type="arrow-left" />}
          onClick={() => {
            goToList();
          }}
        />
        <h2>{mode === "edit" ? "Editar" : "Adicionar"} Setor</h2>
      </div>
      <Form
        onFinish={handleSubmit}
        form={form}
        className={cargoObrigatorio ? "setor-form has-error" : "setor-form"}
      >
        <div className="section">
          <Form.Item
            label={viewOnly ? "Setor" : "Selecione ou cadastre um novo setor"}
            name="setor"
            rules={[
              {
                required: true,
                message: "Selecione um setor!",
              },
            ]}
          >
            <Select
              dropdownClassName="dropdown-setores"
              onChange={checkIfIsOther}
              showSearch
              disabled={viewOnly}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option
                key="outros"
                value="outro"
                style={{
                  borderBottom: "gray",
                  borderBottomStyle: "dashed",
                  borderBottomWidth: "1px",
                }}
              >
                OUTRO
              </Option>
              {setoresCatalogo.sugestoes
                .filter((setor) => {
                  return (
                    props.setoresIndisponiveis.find(
                      (setorIndisponivel) => setor.nome === setorIndisponivel
                    ) === undefined ||
                    (props.setor && setor.nome === props.setor.nome)
                  );
                })
                .map((setor) => (
                  <Option
                    key={setor.id ? setor.id : setor.nome}
                    value={setor.nome}
                  >
                    {setor.nome}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          {otherSetor && !viewOnly && (
            <Form.Item
              className={nomeSetorValido ? "" : "has-error"}
              name="outroSetor"
              rules={[
                {
                  required: true,
                  message: "Insira um setor!",
                },
              ]}
            >
              <Input
                placeholder="Insira o nome do setor."
                onChange={(e) =>
                  setNomeSetorValido(
                    validateSetorNome(
                      props.setoresIndisponiveis,
                      e.target.value,
                      props.setor
                    )
                  )
                }
              />
            </Form.Item>
          )}
          {!nomeSetorValido && (
            <div className="ant-form-explain">Setor já cadastrado!</div>
          )}
          <Row gutter={24}>
            <Col span={24}>
              <CargosList
                mode={props.mode}
                removeCargo={removeCargo}
                addCargo={addCargo}
                cargos={cargos}
                disabled={viewOnly}
                cargoObrigatorio={cargoObrigatorio}
                clearInput={clearInput}
                setores={props.setores}
              />
            </Col>
            {/* <Col span={8}>
              <Form.Item
                label="Número de trabalhadores"
                name="quantidadeDeTrabalhadores"
                rules={[
                  {
                    required: props.mode === "new" ? true : false,
                    message: "Informe o número de trabalhadores.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col> */}
          </Row>
        </div>
        {!viewOnly && (
          <Form.Item>
            <Button
              disabled={!nomeSetorValido}
              block
              type="primary"
              text="Salvar"
              htmlType="submit"
              loading={loading}
            />
          </Form.Item>
        )}
      </Form>
    </React.Fragment>
  );
};

export default SetorForm;
