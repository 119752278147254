import { empresasUrl } from "../../../../../../../constants";
import { setupInterceptors } from "../../../../../../../auth/SetupInterceptors";
import { SELECT_COMPANY } from "../../../../../../../components/CompanyList/_/containers/Companies/_/actions/types";
import axios from "axios";
import "react-notifications/lib/notifications.css";
import openNotification from "../../../../../../../components/notifications";
setupInterceptors();

export function saveEmpresa(
  values,
  logo,
  deleteLogo,
  anexos,
  deleteAnexos,
  loading,
  logoLoading
) {
  const empresa = createEmpresaForm(values);
  let calls = [];
  let logoUploaded = false;
  loading(true);
  return (dispatch) => {
    if (logo instanceof FormData) {
      logoLoading(true);
      logoUploaded = true;
      if (logoUploaded) logoLoading(true);
      calls.push(
        axios({
          method: "patch",
          headers: { "Content-Type": "multipart/form-data" },
          data: logo,
          url: `${empresasUrl}/logo`,
        })
      );
    }
    // if(anexos instanceof FormData) {
    //   anexosLoading();
    //   anexosUploaded = true;
    //   calls.push(
    //     axios({
    //       method: 'patch',
    //       headers: { "Content-Type": "multipart/form-data" },
    //       data: anexos,
    //       url: `${empresasUrl}/anexos`
    //     })
    //   )
    // }
    if (deleteLogo instanceof FormData) {
      calls.push(
        axios({
          url: `${empresasUrl}/logo`,
          method: "PATCH",
          headers: { "Content-Type": "multipart/form-data" },
          data: deleteLogo,
        })
      );
    }
    // if(deleteAnexos instanceof FormData) {
    //   calls.push(
    //     axios({
    //       url: `${empresasUrl}/anexos`,
    //       method: "PATCH",
    //       headers: { "Content-Type": "multipart/form-data" },
    //       data: deleteAnexos
    //     })
    //   )
    // }
    axios
      .all(calls)
      .then(() => {
        axios
          .patch(empresasUrl, empresa)
          .then(() => {
            axios.get(empresasUrl).then((response) => {
              loading(false);
              if (logoUploaded) logoLoading(false);
              openNotification("Empresa salva com sucesso!", "", "success");
              dispatch({ type: SELECT_COMPANY, payload: response.data });
            });
          })
          .catch((error) => {
            openNotification(
              "Erro ao salvar empresa",
              "Tente novamente",
              "error"
            );
            loading(false);
            if (logoUploaded) logoLoading(false);
          });
      })
      .catch((error) => {
        openNotification("Erro ao salvar empresa!", "Tente novamente", "error");
        loading(false);
        if (logoUploaded) logoLoading();
      });
  };
}

const createEmpresaForm = (values) => {
  return {
    descricao: values.descricao,
  };
};

// const createLogo = (logo, deleteLogo) => {
//   return {
//     logo: logo,
//     removerLogo: deleteLogo
//   }

// }
// //nao esta 100%
// const createLogoForm = logo => {
//   if (shouldRemoveLogo(logo)) {
//     return null;
//   } else {
//     if (logo === undefined) return null;
//     return {
//       nome: logo.file.name,
//       url: logo.file.response.Location
//     };
//   }
// };

// const shouldRemoveLogo = logo => {
//   if (logo !== undefined) {
//     if (logo.file.status === "removed") {
//       return 1;
//     } else {
//       return 0;
//     }
//   }
//   return 0;
// };

// const removedAttachments = remove => {
//   if (remove) {
//     return remove;
//   } else {
//     return [];
//   }
// };

// //nao esta 100%

// const mapAttachments = attachments => {
//   if (attachments === undefined) return [];
//   if (attachments.fileList !== undefined) {
//     return attachments.fileList
//       .map(attach => {
//         // gambira
//         if (!Number.isInteger(attach.uid)) {
//           if (attach.url) {
//             return {
//               nome: attach.name,
//               url: attach.url
//             };
//           }
//         }
//         return {};
//       })
//       .filter(value => Object.keys(value).length !== 0);
//   } else {
//     return [];
//   }
// };
