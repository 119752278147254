import React, { useState } from "react";
import { Form, Input } from "antd";
import MaskedInput from "antd-mask-input";
import Button from "../../../../components/Buttons/_/components/button";

const LoginForm = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const toggleLoading = () => {
    loading ? setLoading(false) : setLoading(true);
  };

  const handleSubmit = (values) => {
    form
      .validateFields()
      .then((values) => {
        const cpfMaskOff = values.cpf.replace(/[.-]/gi, "");
        const credentials = {
          username: cpfMaskOff,
          password: values.password,
        };
        props.signIn(props.dispatch, credentials, toggleLoading);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Form onFinish={() => handleSubmit()} form={form}>
      <div className="login-form-header">
        <h1>Login</h1>
      </div>
      <div className="login-form-body">
        <Form.Item
          name="cpf"
          rules={[{ required: true, message: "Insira um CPF válido!" }]}
        >
          <MaskedInput name="cpf" placeholder="CPF" mask="111.111.111-11" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Insira uma senha!" }]}
        >
          <Input.Password name="password" placeholder="Senha" />
        </Form.Item>
        <a onClick={() => props.tab("password")} href="#">
          esqueceu o usuário ou a senha?
        </a>
      </div>
      <div className="login-form-footer">
        <Button text="Entrar" htmlType="submit" />
      </div>
    </Form>
  );
};

export default LoginForm;
