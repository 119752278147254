import {
  TOGGLE_STEP_STATUS_CLIENTE,
  GET_STATUS_STEPS_TECNICO,
  TOGGLE_STEP_STATUS_TECNICO,
  TOGGLE_STATUS_FLUXO,
} from "../actions/types";

const stepEnum = Object.freeze({
  empresa: "empresa",
  setores: "setores",
  atividades: "atividades",
  funcionarios: "funcionarios",
  maquinas: "maquinas",
  "produtos-quimicos": "produtosQuimicos",
  "fatores-de-risco": "fatoresDeRisco",
  "espaco-fisico": "espacoFisico",
  "plano-de-acao": "planoDeAcao",
});

export default function (
  state = {
    stepsEmpresa: {},
  },
  action
) {
  switch (action.type) {
    case TOGGLE_STEP_STATUS_CLIENTE:
      return {
        ...state,
        stepsEmpresa: action.payload,
      };
    case GET_STATUS_STEPS_TECNICO:
      return {
        ...state,
        stepsTecnico: action.payload,
      };
    case TOGGLE_STEP_STATUS_TECNICO:
      return {
        ...state,
        stepsTecnico: helper(state, action),
      };
    case TOGGLE_STATUS_FLUXO:
      return {
        ...state,
        statusFluxo: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}

function helper(state, action) {
  return state.stepsTecnico.map((e) =>
    e.etapa === stepEnum[action.payload.etapa]
      ? { ...e, concluida: action.payload.concluida }
      : e
  );
}
