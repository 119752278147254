import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Tooltip,
} from "antd";
import { MaskedInput } from "antd-mask-input";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { openAssistantModal } from "../../../../components/AssistantModalHeader";
import { HeaderWithModal } from "../../../../components/HeaderWithModal/index";
import IconSESI from "../../../../components/IconsSESI";
import { eSocialConfirmWorkerChange } from "../../../../components/modals";
import openNotification from "../../../../components/notifications";
import { validateCpf } from "../../../../util/methods";
import {
  edit2220,
  listar2220,
  listarCategorias,
  listarExames,
  listarFuncionariosEsocial,
  post2220,
} from "../actions";
import { getAssistantTalks } from "../actions/index";


const { Option } = Select;

const ESocialForm = (props) => {
  const [form] = Form.useForm();
  const [hasMatricula, setHasMatricula] = useState(true);
  const [selectedTest, setSelectedTest] = useState([]);
  const [assistantTalk, setAssistantTalk] = useState([]);
  const [responsibleDataIsRequired, setResponsibleDataIsRequired] =
    useState(false);
  const [blockAddTest, setBlockAddTest] = useState(false);

  const audiometria = [279, 280, 281, 282, 283];
  const examesObservacao = [
    583, 998, 999, 1128, 1230, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999,
    9999,
  ];

  const [inputObservation, setInputObservation] = useState([]);

  useEffect(() => {
    listarCategorias(props.dispatch);
    listarExames(props.dispatch);
    listarFuncionariosEsocial(props.dispatch);
    getDigitalSignatureAssistantTalks()
  }, []);

  useEffect(() => {
    setSelectedUser("");
    if (props.activeTab !== "3") {
      return;
    }

    if (props.formMode === "new") setEventWasSent(false);

    if (props.formMode === "new") setEventWasSent(false);

    if (
      props.formMode === "rectification" ||
      props.formMode === "increment" ||
      props.formMode === "update"
    ) {
      iniciarModoEdicao(props.eventoDuplicado);
    }
  }, [JSON.stringify(props.eventoDuplicado), props.activeTab]);

  const disabledDate = (current) => {
    return current.valueOf() > Date.now();
  };

  const onCheck = () => {
    setHasMatricula(!hasMatricula);
    listarCategorias(props.dispatch);
  };

  const handleClearTest = (index) => {
    let test = selectedTest;
    test.splice(index, 1);
    setSelectedTest(test);
    setInputObservation(inputObservation.filter((input) => input !== index));
  };

  const parseExams = (exams) => {
    return exams.map((exam) => {
      return {
        dtExm: moment(exam.dtExm).format("YYYY-MM-DD"),
        procRealizado: exam.procRealizado,
        obsProc: exam.obsProc ? exam.obsProc : "",
        ordExame: exam.ordExame ? exam.ordExame : "NAO_APLICAVEL",
        indResult: "NAO_APLICAVEL",
      };
    });
  };

  const [eventWasSent, setEventWasSent] = useState(false);

  const iniciarModoEdicao = (evento) => {
    setSelectedTest([]);
    setEventWasSent(evento.enviado);
    setSelectedUser(evento.cpfTrab);
    verifyExamEdition(evento?.exames);
    form.setFieldsValue({
      nomeTrab: evento?.nomeTrab,
      cpfTrabalhador: evento.cpfTrab,
      matriculaTrabalhador: evento.matricula,
      categoriaTrabalhador: evento.codCateg,
      tipoExame: evento.tpExameOcup,
      dataEmissaoASO: moment(evento.dtAso),
      resultadoASO: evento.resAso,
      additionalExams: evento?.exames?.map((exame) => {
        setSelectedTest((oldValue) => {
          return [...oldValue, exame.procRealizado];
        });

        return {
          procRealizado: exame?.procRealizado,
          dtExm: moment(exame?.dtExm),
          obsProc: exame?.obsProc,
          ordExame: exame?.ordExame,
        };
      }),
      nomeMedico: evento.nmMed,
      crmMedico: evento.nrCRMMed,
      ufCRM: evento.ufCRM,
      cpfResponsavel: evento.cpfResp,
      nomeResposavel: evento.nmResp,
      crmResponsavel: evento.nrCRMResp
        ? evento?.nrCRMResp.replace(/\D/g, "")
        : "",
      ufCRMResp: evento.ufCRMResp,
    });

    if (evento.nmResp) setResponsibleDataIsRequired(true);
  };

  const handleSubmit = async () => {
    let exams = form.getFieldValue("additionalExams");

    if (!exams || exams.length === 0) {
      return openNotification(
        "Exames",
        "É necessário incluir ao menos um exame!",
        "warning"
      );
    }

    form.validateFields().then(async (values) => {
      let indRetif = "";

      switch (props.formMode) {
        case "new":
          indRetif = "ORIGINAL";
          break;

        case "increment":
          indRetif = "ORIGINAL";
          break;

        default:
          if (props.eventoDuplicado.nrRecibo) {
            indRetif = "RETIFICACAO";
          } else {
            indRetif = "ORIGINAL";
          }
      }

      let new2220 = {
        nomeTrab: values.nomeTrab ? values.nomeTrab : "",
        indRetif,
        nrInsc: props.selectedCompany.cnpj.slice(0, 8),
        cpfTrab: values.cpfTrabalhador,
        matricula: values.matriculaTrabalhador
          ? values.matriculaTrabalhador
          : "",
        codCateg: values.categoriaTrabalhador
          ? values.categoriaTrabalhador.toString()
          : "",
        tpExameOcup: values.tipoExame,
        dtAso: moment(values.dataEmissaoASO).format("YYYY-MM-DD"),
        resAso: values.resultadoASO,
        exames: values.additionalExams
          ? parseExams(values.additionalExams)
          : [],
        nmMed: values.nomeMedico,
        nrCRMMed: values.crmMedico,
        ufCRM: values.ufCRM,
        cpfResp: values.cpfResponsavel
          ? values.cpfResponsavel.replace(/\D/g, "")
          : "",
        nmResp: values.nomeResposavel ? values.nomeResposavel : "",
        nrCRMResp: values.nomeResposavel ? values.crmResponsavel : "",
        ufCRMResp: values.nomeResposavel ? values.ufCRMResp : "",
      };

      if (props.formMode === "rectification" || props.formMode === "update") {
        new2220.id = props.eventoDuplicado.id;
        new2220.nrRecibo = props.eventoDuplicado.nrRecibo;
      }

      props.formMode === "new" || props.formMode === "increment"
        ? await post2220(
            new2220,
            form.resetFields,
            props.setFormMode,
            props.dispatch
          )
        : await edit2220(
            new2220,
            form.resetFields,
            props.setFormMode,
            props.dispatch
          );
    });
  };

  const [selectedUser, setSelectedUser] = useState("");

  const validateChangeSelectedUser = (userCpf) => {
    if (selectedUser == "") {
      setSelectedUser(userCpf);
      fillRegistration(userCpf);
      getResponsiblePCMSO();
      return;
    }

    form.setFieldsValue({
      cpfTrabalhador: selectedUser,
    });

    eSocialConfirmWorkerChange(() => {
      form.resetFields();
      getResponsiblePCMSO();

      form.setFieldsValue({
        cpfTrabalhador: userCpf,
      });

      setSelectedUser(userCpf);
      fillRegistration(userCpf);
    });
  };

  const fillRegistration = (userCpf) => {
    props.funcionariosEsocial.forEach((funcionario) => {
      if (funcionario.cpf === userCpf) {
        form.setFieldsValue({
          matriculaTrabalhador: funcionario.matricula,
        });
      }
    });
  };

  const getResponsiblePCMSO = async () => {
    let list2220 = await listar2220();

    if (list2220.length > 0) {
      setResponsibleDataIsRequired(list2220[0]?.nmResp);

      form.setFieldsValue({
        nomeResposavel: list2220[0].nmResp,
        cpfResponsavel: list2220[0].cpfResp,
        crmResponsavel: list2220[0].nrCRMResp,
        ufCRMResp: list2220[0].ufCRMResp,
      });
    }
  };

  // LIMPA O CAMPO "OBS" DO EXAME ALTERADO
  const clearAdditionalExamsField = (index) => {
    let aditionalEx = form.getFieldValue("additionalExams");

    form.setFieldsValue({
      additionalExams: aditionalEx.map((exam, indexExam) => {
        if (index === indexExam) {
          return {
            ...exam,
            obsProc: "",
          };
        } else {
          setInputObservation(
            inputObservation.filter((input) => input !== index)
          );
          return {
            ...exam,
          };
        }
      }),
    });
  };

  // VERIFICA EXAME NO CARREGAMENTO DE EDIÇÃO
  const verifyExamEdition = (arrayExams) => {
    setInputObservation([]);
    let newIndexes = [];

    if (arrayExams) {
      arrayExams.forEach((exam, thisIndex) => {
        if (examesObservacao.includes(exam.procRealizado)) {
          newIndexes.push(thisIndex);
        } else {
          return;
        }
      });

      setInputObservation(newIndexes);
    }
  };

  // VERIFICA EXAME SELECIONADO VIA "SELECT"
  const verifyExam = (value, index) => {
    setInputObservation([]);

    for (let i = 0; i < examesObservacao.length; i++) {
      if (examesObservacao[i] === value) {
        setInputObservation([...inputObservation, index]);
        return;
      }
    }

    clearAdditionalExamsField(index);
  };

  // ATUALIZA OS A LISTA APÓS DELETAR EXAME
  const handleUpdateExamInputObservation = (index) => {
    let examsArray = form.getFieldValue("additionalExams");

    let examsArrayFiltered = [];
    let newIndexes = [];

    if (!examsArray) {
      return;
    }

    // FILTRANDO EXAMES POR INDEX
    examsArray.forEach((exam, thisIndex) => {
      if (thisIndex !== index) {
        return examsArrayFiltered.push(exam);
      } else {
        return;
      }
    });

    // EXAMES FILTRADOS SEND VERIFICADOS
    examsArrayFiltered.forEach((exam, thisIndex) => {
      if (examesObservacao.includes(exam?.procRealizado)) {
        return newIndexes.push(thisIndex);
      } else {
        return;
      }
    });

    setInputObservation(newIndexes);
  };

  const getDigitalSignatureAssistantTalks = async () => {
    const talks = await getAssistantTalks('s2220', 'Gerar 2220')
    setAssistantTalk(talks.descricao)
  }

  return (
    <React.Fragment>
      <Col lg={24} className="esocial-dashboard">
        <HeaderWithModal
          pageTitle="S-2220 - Monitoramento da Saúde do Trabalhador"
          helpText="Clique aqui e saiba mais!"
          assistant={"vivi"}
          openModal={() =>
            openAssistantModal(assistantTalk, "vivi")
          }
          prevButton={() => {
            form.resetFields();
            props.setActiveTab("2");
            props.setFormMode("new");
          }}
        />

        <div className="content">
          <Form form={form} onFinish={handleSubmit}>
            <div className="esocial-form-card">
              <span className="esocial-card-title">
                Identificação do trabalhador
              </span>
              {props.selectedCompany.autodeclaracao === true &&
              !props.funcionariosEsocial[0] ? (
                <React.Fragment>
                  <Form.Item
                    label="Trabalhador:"
                    name="nomeTrab"
                    rules={[
                      {
                        min: 2,
                        message: "O nome deve ter no mínimo dois caracteres.",
                      },
                    ]}
                  >
                    <Input
                      disabled={
                        props.formMode === "rectification" || eventWasSent
                      }
                      maxLength={70}
                    />
                  </Form.Item>
                  <Form.Item
                    label="CPF:"
                    name="cpfTrabalhador"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.resolve();
                          }

                          if (!validateCpf(value)) {
                            return Promise.reject(new Error("CPF inválido!"));
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <MaskedInput
                      mask="111.111.111-11"
                      placeholder="000.000.000-00"
                      style={{ width: "11rem" }}
                      disabled={
                        props.formMode === "rectification" || eventWasSent
                      }
                    />
                  </Form.Item>
                </React.Fragment>
              ) : (
                <Form.Item
                  label="Trabalhador:"
                  name="cpfTrabalhador"
                  rules={[
                    {
                      required: true,
                      message: "Selecione um trabalhador!",
                    },
                  ]}
                >
                  <Select
                    disabled={
                      props.formMode === "rectification" || eventWasSent
                    }
                    onChange={(value) => validateChangeSelectedUser(value)}
                  >
                    {props.funcionariosEsocial.map((funcionario) => {
                      return (
                        <Option key={funcionario.id} value={funcionario.cpf}>
                          {funcionario.nome}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}
              <div className="matricula-row">
                <Form.Item
                  label="Número de matrícula::"
                  name="matriculaTrabalhador"
                  tooltip="A matrícula, é a chave de acesso do trabalhador no eSocial. Certifique-se de inserir a mesma matrícula informada pelo departamento pessoal no evento S-2200, referente ao vínculo empregatício do trabalhador com a empresa."
                  rules={[
                    {
                      required: true,
                      message: "Informe a matrícula!",
                    },
                  ]}
                >
                  <Input
                    style={{ width: "90%", marginRight: "1rem" }}
                    disabled={
                      props.formMode === "rectification" ||
                      eventWasSent ||
                      !hasMatricula
                    }
                  />
                </Form.Item>
                <Checkbox
                  disabled={props.formMode === "rectification" || eventWasSent}
                  onChange={() => onCheck()}
                >
                  Não tem matrícula{" "}
                  <Tooltip title="Somente utilizar “Não tem matrícula”, se o trabalhador estiver enquadrado na lista abaixo “categoria do trabalhador”. Caso contrário, consulte o número da matrícula informada no evento S-2200 relacionado aos dados do departamento pessoal, enviado anteriormente a este evento de SST">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Checkbox>
              </div>
              {hasMatricula === false ? (
                <Form.Item
                  label="Categoria do Trabalhador::"
                  name="categoriaTrabalhador"
                >
                  <Select
                    disabled={
                      props.formMode === "rectification" || eventWasSent
                    }
                  >
                    {props.categoriasTrabalhadores.map((categoria) => {
                      return (
                        <Option value={categoria.id}>
                          {categoria.descricao}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              ) : (
                ""
              )}
            </div>

            <div className="esocial-exams-div">
              <div className="esocial-form-card">
                <span className="esocial-card-title">
                  Exame Médico Ocupacional
                </span>
                <Form.Item
                  label="Tipo de ASO::"
                  name="tipoExame"
                  tooltip="Verifique o tipo de Atestado de Saúde Ocupacional (ASO), a partir da lista oferecida. Esta informação contém no ASO."
                  rules={[
                    {
                      required: true,
                      message: "Selecione o tipo de ASO.",
                    },
                  ]}
                >
                  <Select disabled={selectedUser === ""}>
                    <Option value={0}>Exame admissional</Option>
                    <Option value={1}>Exame periódico</Option>
                    <Option value={2}>Exame retorno ao trabalho</Option>
                    <Option value={3}>
                      Exame mudança de risco ocupacional
                    </Option>
                    <Option value={4}>Exame monitoração pontual</Option>
                    <Option value={9}>Exame demissional</Option>
                  </Select>
                </Form.Item>
                <Row>
                  <Form.Item
                    label="Data de emissão do ASO::"
                    style={{ marginRight: "1rem" }}
                    name="dataEmissaoASO"
                    rules={[
                      {
                        required: true,
                        message: "Selecione a data de emissão do ASO!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.resolve();
                          }

                          let currentDate = new Date();
                          let eventDate = new Date(value._d);

                          if (eventDate <= currentDate) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error("Informe uma data válida!")
                          );
                        },
                      }),
                    ]}
                  >
                    <DatePicker
                      disabled={
                        selectedUser === "" ||
                        props.formMode === "rectification" ||
                        eventWasSent
                      }
                      format={"DD/MM/YYYY"}
                      disabledDate={disabledDate}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Resultado do ASO::"
                    name="resultadoASO"
                    tooltip="Indique, a partir da informação apresentada no atestado, se o trabalhador está apto ou inapto."
                    rules={[
                      {
                        required: true,
                        message: "Selecione o resultado do ASO!",
                      },
                    ]}
                  >
                    <Radio.Group disabled={selectedUser === ""}>
                      <Radio value="APTO">Apto</Radio>
                      <Radio value="INAPTO">Inapto</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Row>
                <Form.List
                  name="additionalExams"
                  initialValue={[
                    {
                      fieldKey: 0,
                      isListField: true,
                      key: 0,
                      name: 0,
                    },
                  ]}
                  rules={[
                    {
                      validator: async (_, additionalExams) => {
                        if (additionalExams.length >= 99) {
                          setBlockAddTest(true);
                        }
                      },
                    },
                  ]}
                >
                  {(additionalExams, { add, remove }) => {
                    return (
                      <React.Fragment>
                        <div className="esocial-exams-button-div">
                          <Button
                            ghost
                            className="add"
                            disabled={selectedUser === "" || blockAddTest}
                            onClick={() => add()}
                          >
                            <IconSESI type="add-circle" theme="filled" />
                            Adicionar exame
                            <Tooltip title="Clique no + quantas vezes for necessária, até contemplar neste formulário todos os exames realizados pelo trabalhador e as suas respectivas datas, que estão indicados no ASO.">
                              <QuestionCircleOutlined />
                            </Tooltip>
                          </Button>
                        </div>
                        {additionalExams.map((additionalExam, index) => (
                          <div className="esocial-form-card" key={index}>
                            <span className="esocial-card-title">
                              Avaliações clínicas e exames complementares
                            </span>
                            <Row>
                              <Form.Item
                                label="Data do exame::"
                                style={{ marginRight: "1rem" }}
                                name={[index, "dtExm"]}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Selecione a data do exame realizado.",
                                  },
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!value) {
                                        return Promise.resolve();
                                      }

                                      let currentDate = new Date();
                                      let eventDate = new Date(value._d);
                                      let asoDate =
                                        form.getFieldValue("dataEmissaoASO");

                                      if (!asoDate) {
                                        return Promise.resolve();
                                      }

                                      let asoDateFormatted = new Date(
                                        asoDate._d
                                      );

                                      if (
                                        eventDate > asoDateFormatted &&
                                        eventDate.toLocaleDateString() !==
                                          asoDateFormatted.toLocaleDateString()
                                      ) {
                                        return Promise.reject(
                                          new Error(
                                            "A data do exame deve ser igual ou anterior à de emissão do ASO!"
                                          )
                                        );
                                      }

                                      if (eventDate <= currentDate) {
                                        return Promise.resolve();
                                      }

                                      return Promise.reject(
                                        new Error("Informe uma data válida!")
                                      );
                                    },
                                  }),
                                ]}
                              >
                                <DatePicker
                                  disabled={selectedUser === ""}
                                  format={"DD/MM/YYYY"}
                                  disabledDate={disabledDate}
                                />
                              </Form.Item>
                              <Form.Item
                                label="Exame::"
                                style={{ width: "50%" }}
                                name={[index, "procRealizado"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Selecione o exame realizado.",
                                  },
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!value) {
                                        return Promise.resolve();
                                      }

                                      if (additionalExams.length === 1) {
                                        return Promise.resolve();
                                      }

                                      let asoDate =
                                        form.getFieldValue("additionalExams");
                                      let examCount = 0;

                                      asoDate.forEach((item) => {
                                        if (item?.procRealizado === 9999) {
                                          examCount++;
                                        }
                                      });

                                      if (examCount >= 2) {
                                        return Promise.reject(
                                          new Error(
                                            "Este exame não pode ser duplicado!"
                                          )
                                        );
                                      }

                                      return Promise.resolve();
                                    },
                                  }),
                                ]}
                              >
                                <Select
                                  disabled={selectedUser === ""}
                                  showSearch
                                  showArrow={false}
                                  allowClear
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0 ||
                                    option.props.value
                                      .toString()
                                      .indexOf(input) >= 0
                                  }
                                  onChange={(value) => {
                                    setSelectedTest([...selectedTest, value]);
                                    verifyExam(value, index);
                                  }}
                                  onClear={() => handleClearTest(index)}
                                >
                                  {props.tiposExames.map((exame) => (
                                    <Option key={exame.id} value={exame.id}>
                                      {[exame.id, exame.nome].join(" - ")}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Row>
                            {audiometria.includes(selectedTest[index]) ? (
                              <Form.Item
                                label="Ordem do exame::"
                                name={[index, "ordExame"]}
                                rules={[
                                  {
                                    required: audiometria.includes(
                                      selectedTest[index]
                                    )
                                      ? true
                                      : false,
                                    message: "Selecione qual a ordem do exame.",
                                  },
                                ]}
                              >
                                <Radio.Group disabled={selectedUser === ""}>
                                  <Radio value="INICIAL">Inicial</Radio>
                                  <Radio value="SEQUENCIAL">Sequencial</Radio>
                                </Radio.Group>
                              </Form.Item>
                            ) : null}

                            {inputObservation.includes(index) && (
                              <Form.Item
                                label="Observações do exame realizado::"
                                name={[index, "obsProc"]}
                                tooltip="Informação obrigatória para este tipo de exame. Insira aqui observação sobre o procedimento diagnóstico realizado no exame. Caso a informação não conste no ASO solicite para a clínica médica responsável pelo ASO."
                                rules={[
                                  {
                                    required: examesObservacao.includes(
                                      selectedTest[index]
                                    ),
                                    message:
                                      "Escreva as observações do exame realizado.",
                                  },
                                ]}
                              >
                                <Input.TextArea
                                  disabled={selectedUser === ""}
                                  maxLength="999"
                                  placeholder="Informação obrigatória para este tipo de exame. Insira aqui observação sobre o procedimento diagnóstico realizado no exame. Caso a informação não conste no ASO solicite para a clínica médica responsável pelo ASO."
                                />
                              </Form.Item>
                            )}

                            <div className="additional-test">
                              <Button
                                ghost
                                className="remove"
                                onClick={() => {
                                  handleUpdateExamInputObservation(index);
                                  remove(index);
                                }}
                                disabled={selectedUser === ""}
                                // disabled={props.formMode === "new" ? false : true}
                              >
                                <IconSESI type="trash" /> Excluir
                              </Button>
                            </div>
                          </div>
                        ))}
                      </React.Fragment>
                    );
                  }}
                </Form.List>
              </div>
            </div>

            <div className="esocial-form-card">
              <span className="esocial-card-title">
                Identificação do médico emitente do ASO
              </span>
              <Form.Item
                label="Nome::"
                name="nomeMedico"
                rules={[
                  {
                    required: true,
                    message: "Escreva o nome do médico emitente do ASO.",
                  },
                  {
                    min: 2,
                    message: "O nome deve ter no mínimo dois caracteres.",
                  },
                ]}
              >
                <Input disabled={selectedUser === ""} maxLength={70} />
              </Form.Item>
              <Row className="esocial-doctor-info">
                <Form.Item
                  label="CRM::"
                  name="crmMedico"
                  rules={[
                    {
                      required: true,
                      message: "Escreva o CRM do médico emitente do ASO.",
                    },
                    {
                      len: 6,
                      message: "Campo de 6 caracteres!",
                    },
                  ]}
                >
                  <Input disabled={selectedUser === ""} />
                </Form.Item>
                <Form.Item
                  label="UF de expedição do CRM::"
                  name="ufCRM"
                  rules={[
                    {
                      required: true,
                      message:
                        "Selecione a UF do CRM do médico emitente do ASO.",
                    },
                  ]}
                >
                  <Select disabled={selectedUser === ""}>
                    <Option value="AC">Acre</Option>
                    <Option value="AL">Alagoas</Option>
                    <Option value="AP">Amapá</Option>
                    <Option value="AM">Amazonas</Option>
                    <Option value="BA">Bahia</Option>
                    <Option value="CE">Ceará</Option>
                    <Option value="DF">Distrito Federal</Option>
                    <Option value="ES">Espírito Santo</Option>
                    <Option value="GO">Goiás</Option>
                    <Option value="MA">Maranhão</Option>
                    <Option value="MT">Mato Grosso</Option>
                    <Option value="MS">Mato Grosso do Sul</Option>
                    <Option value="MG">Minas Gerais</Option>
                    <Option value="PA">Pará</Option>
                    <Option value="PB">Paraíba</Option>
                    <Option value="PR">Paraná</Option>
                    <Option value="PE">Pernambuco</Option>
                    <Option value="PI">Piauí</Option>
                    <Option value="RJ">Rio de Janeiro</Option>
                    <Option value="RN">Rio Grande do Norte</Option>
                    <Option value="RS">Rio Grande do Sul</Option>
                    <Option value="RO">Rondônia</Option>
                    <Option value="RR">Roraima</Option>
                    <Option value="SC">Santa Catarina</Option>
                    <Option value="SP">São Paulo</Option>
                    <Option value="SE">Sergipe</Option>
                    <Option value="TO">Tocantins</Option>
                  </Select>
                </Form.Item>
              </Row>
            </div>
            <div className="esocial-form-card">
              <span className="esocial-card-title">
                Identificação do médico responsável pelo PCMSO{" "}
                <Tooltip title="Preencha com os dados do médico responsável pelo PCMSO da empresa. Caso sua empresa seja dispensada de elaborar PCMSO não será necessário preencher as informações abaixo. Obs.: De acordo com a NR 01, estão dispensadas de elaborar PCMSO as empresas de grau de risco 1 ou 2 que não possuem exposição a agentes físicos, químicos, biológicos ou ergonômicos.">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
              <Form.Item
                label="Nome::"
                name="nomeResposavel"
                rule={[
                  {
                    min: 2,
                    message: "O nome deve ter no mínimo dois caracteres.",
                  },
                ]}
              >
                <Input
                  disabled={selectedUser === ""}
                  onChange={(value) => {
                    if (value.currentTarget.value === "") {
                      form.setFieldsValue({
                        ufCRMResp: undefined,
                        crmResponsavel: undefined,
                      });
                    }
                    setResponsibleDataIsRequired(
                      value.currentTarget.value !== ""
                    );
                  }}
                  maxLength={70}
                />
              </Form.Item>
              {responsibleDataIsRequired && (
                <Row className="esocial-doctor-info">
                  <Form.Item
                    label="CRM::"
                    name="crmResponsavel"
                    rules={[
                      {
                        len: 6,
                        message: "Campo de 6 caracteres!",
                      },
                      {
                        required: responsibleDataIsRequired,
                        message: "Informe o CRM do responsável!",
                      },
                    ]}
                  >
                    <Input disabled={selectedUser === ""} />
                  </Form.Item>
                  <Form.Item
                    label="UF de expedição do CRM::"
                    name="ufCRMResp"
                    rules={[
                      {
                        required: responsibleDataIsRequired,
                        message: "Informe a UF CRM de expedição do CRM!",
                      },
                    ]}
                  >
                    <Select disabled={selectedUser === ""}>
                      <Option value="AC">Acre</Option>
                      <Option value="AL">Alagoas</Option>
                      <Option value="AP">Amapá</Option>
                      <Option value="AM">Amazonas</Option>
                      <Option value="BA">Bahia</Option>
                      <Option value="CE">Ceará</Option>
                      <Option value="DF">Distrito Federal</Option>
                      <Option value="ES">Espírito Santo</Option>
                      <Option value="GO">Goiás</Option>
                      <Option value="MA">Maranhão</Option>
                      <Option value="MT">Mato Grosso</Option>
                      <Option value="MS">Mato Grosso do Sul</Option>
                      <Option value="MG">Minas Gerais</Option>
                      <Option value="PA">Pará</Option>
                      <Option value="PB">Paraíba</Option>
                      <Option value="PR">Paraná</Option>
                      <Option value="PE">Pernambuco</Option>
                      <Option value="PI">Piauí</Option>
                      <Option value="RJ">Rio de Janeiro</Option>
                      <Option value="RN">Rio Grande do Norte</Option>
                      <Option value="RS">Rio Grande do Sul</Option>
                      <Option value="RO">Rondônia</Option>
                      <Option value="RR">Roraima</Option>
                      <Option value="SC">Santa Catarina</Option>
                      <Option value="SP">São Paulo</Option>
                      <Option value="SE">Sergipe</Option>
                      <Option value="TO">Tocantins</Option>
                    </Select>
                  </Form.Item>
                </Row>
              )}

              <Form.Item
                label="CPF::"
                name="cpfResponsavel"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.resolve();
                      }

                      if (!validateCpf(value)) {
                        return Promise.reject(new Error("CPF inválido!"));
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <MaskedInput
                  mask="111.111.111-11"
                  disabled={selectedUser === ""}
                />
              </Form.Item>
            </div>
            <Row className="esocial-submit-button-row">
              <Button htmlType="submit">Salvar</Button>
            </Row>
          </Form>
        </div>
      </Col>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    categoriasTrabalhadores: state.esocial.categoriasTrabalhadores
      ? state.esocial.categoriasTrabalhadores
      : [],
    // tiposExames: state.esocial.tiposExames ? state.esocial.tiposExames : [],
    funcionariosEsocial: state.esocial.funcionariosEsocial
      ? state.esocial.funcionariosEsocial
      : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        listarCategorias,
        // listarExames,
        listarFuncionariosEsocial,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ESocialForm);
