const uniqBy = (a, key) => {
  return [...new Map(a.map(x => [key(x), x])).values()];
};

function uniq_fast(a) {
  var seen = {};
  var out = [];
  var len = a.length;
  var j = 0;
  for (var i = 0; i < len; i++) {
    var item = a[i];
    if (seen[item] !== 1) {
      seen[item] = 1;
      out[j++] = item;
    }
  }
  return out;
}

function uniqueArray(array, key) {
  let uniqueList = [];
  if(array !== undefined){

    array.map(item => {
      if (!uniqueList.includes(item[key])) {
        uniqueList.push(item[key]);
      }
      return [];
    });
    return uniqueList;
  }else{
    return [];
  }

}

const filterObject = (o, keys) => {
  if (o !== undefined) {
    return o.filter(
      (s => o => (k => !s.has(k) && s.add(k))(keys.map(k => o[k]).join("|")))(
        new Set()
      )
    );
  }
};

const isEmpty = obj => {
  return Object.keys(obj).length === 0;
};

function objectsAreSame(x, y) {
  var objectsAreSame = true;
  for (var propertyName in x) {
    if (x[propertyName] !== y[propertyName]) {
      objectsAreSame = false;
      break;
    }
  }
  return objectsAreSame;
}

export {
  uniqBy,
  uniq_fast,
  filterObject,
  isEmpty,
  uniqueArray,
  objectsAreSame
};
