import { CloseCircleOutlined } from "@ant-design/icons";
import { Collapse, Row, Col } from "antd";
import React from "react";
import TagsList from "./TagsList";

const { Panel } = Collapse;

const CNAECard = (props) => {
  const changeTags = (id, tags) => {
    props.changeTags(props.dispatch, id, tags);
  };

  const CNAEMask = (cnae) => {
    if (cnae.length >= 6) {
      return `${cnae.substring(0, 4)}-${cnae.substring(4, 5)}/${cnae.substring(
        5
      )}`;
    }
    return cnae;
  };

  const removeCNAE = (id) => {
    props.deleteMissingCNAE(props.dispatch, id);
  };

  return (
    <Row gutter={16}>
      {props.cnaes.map((cnae) => (
        <Col span={12}>
          <Collapse
            defaultActiveKey={["1"]}
            className="card"
            collapsible={props.open}
          >
            <Panel
              header={
                <div>
                  {cnae.cnae ? (
                    <div className="cnae-faltante-header">
                      <h3>{`${CNAEMask(cnae.cnae)} - ${cnae.descricao}`}</h3>
                      <CloseCircleOutlined
                        onClick={() => removeCNAE(cnae.id)}
                      />
                    </div>
                  ) : (
                    <h3>{`${CNAEMask(cnae.id)} - ${cnae.descricao}`}</h3>
                  )}

                  <TagsList type="lote" tags={cnae.lote} />
                  <TagsList
                    type="tags"
                    tags={cnae.tags || []}
                    id={cnae.id}
                    changeTags={changeTags}
                  />
                </div>
              }
            >
              {props.showProcess && (
                <>
                  <div style={{ marginBottom: "16px" }}>
                    <h4>Processos do Catálago</h4>
                    <TagsList
                      type="processos"
                      tags={cnae.processosProdutivos.map(
                        (processo) => processo.nome
                      )}
                    />
                  </div>

                  {cnae.processosProdutivosDoUsuario.length > 0 && (
                    <div>
                      <h4>Processos do Cliente</h4>
                      <TagsList
                        type="processos"
                        tags={cnae.processosProdutivosDoUsuario.map(
                          (processo) => processo.nome
                        )}
                      />
                    </div>
                  )}
                </>
              )}
            </Panel>
          </Collapse>
        </Col>
      ))}
    </Row>
  );
};

export default CNAECard;
