import React from "react";
import { Form, Row, Col, Select, Input } from "antd";

const { Option } = Select;

const SearchBar = (props) => {
  const { filterList, handleOnCargos, name, selectCargoName, cargos } = props;

  return (
    <Form>
      <Row gutter={24}>
        <Col lg={6} md={12}>
          <Form.Item>
            <Input placeholder={`Pesquisar ${name}`} onChange={filterList} />
          </Form.Item>
        </Col>

        <Col lg={6} md={12}>
          <Form.Item>
            <Select
              showSearch
              allowClear
              placeholder={`Filtrar por ${selectCargoName}`}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleOnCargos}
            >
              {cargos.map((cargo, i) => (
                <Option key={cargo} value={cargo}>
                  {cargo}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchBar;
