import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import RelatorioDiarioCard from "../components/Cards/RelatorioDiarioCard";
import RelatorioMensalCard from "../components/Cards/RelatorioMensalCard";
import ResumoDeAnalisesCard from "../components/Cards/ResumoDeAnalisesCard";
// import {
//   getRelatorioDiarioCard,
//   getRelatorioMensalCard,
//   getRelatoriosEmpresasEfetivadasCard,
// } from "../actions";

//import { fetchRelatorio } from "../../../QuantidadeVidas/_/actions";
import "./style.scss";
import { bindActionCreators } from "redux";
import ExportacaoDeDadosCard from "../components/Cards/ExportacaoDeDadosCard";
import EmitirSMDCard from "../components/Cards/EmitirSMDCard";

const RelatoriosHome = (props) => {
  const [nomeDepartamento, setNomeDepartamento] = useState("");
  const [dashboard, setDashboard] = useState({});
  const [relatorioStatus, setRelatorioStatus] = useState([]);

  useEffect(() => {
    if (props.user.departamentos) {
      setNomeDepartamento(props.user.departamentos[0].nome);
    }
  }, JSON.stringify(props.user));

  useEffect(() => {
    setDashboard(props.dashboard);
  }, [JSON.stringify(props.dashboard)]);

  useEffect(() => {
    setRelatorioStatus(props.relatorioStatus);
  }, [JSON.stringify(props.relatorioStatus)]);

  const getDepartamentoId = () => {
    const { user } = props;
    if (user?.departamento) {
      if (getUserPerfil() === "ADMINISTRADOR") {
        return null;
      }
      return user.departamento.id;
    }
  };

  const getUserPerfil = () => {
    const { user } = props;
    if (user.perfil) {
      return user.perfil;
    }
  };

  return (
    <div className="relatorios">
      <h1>Relatórios</h1>
      <h4 className="dr">{nomeDepartamento}</h4>

      <div className="relatorios-wrapper">
        <ResumoDeAnalisesCard
          data={props.relatorioQuantidadeDeVidas}
          perfil={props.user.perfil}
          dashboard={dashboard}
        />
        <RelatorioDiarioCard data={relatorioStatus} />
        <div>
          <ExportacaoDeDadosCard />
          <EmitirSMDCard />
        </div>
      </div>

      <RelatorioMensalCard data={dashboard} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userInfo,
    relatorioDiarioCard: state.gestorDR.relatoriosHome.relatorioDiarioCard,
    relatorioMensalCard: state.gestorDR.relatoriosHome.relatorioMensalCard,
    relatorioEmpresasEfetivadasCard:
      state.gestorDR.relatoriosHome.relatorioEmpresasEfetivadasCard,
    dashboard: state.gestorDR.relatoriosHome.dashboard,
  };
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     dispatch,
//     ...bindActionCreators(
//       {
//         getRelatorioDiarioCard,
//         getRelatorioMensalCard,
//         getRelatoriosEmpresasEfetivadasCard,
//         fetchRelatorio,
//       },
//       dispatch
//     ),
//   };
// };

export default connect(mapStateToProps, {})(RelatoriosHome);
