import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { List, Row, Col } from "antd";
import Button from "../../../../../../../components/Buttons/_/components/button";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { showRemoveSuggestedProcessModal } from "../../../../../../../components/modals";
import { rejectProductiveProcess } from "../actions";

const ProcessoProdutivoCNAEList = (props) => {
  const [productiveProcessesCNAEList, setProductiveProcessesCNAEList] =
    useState([]);

  const showModal = (id) => {
    showRemoveSuggestedProcessModal(() => removeSuggestedProcess(id));
  };

  const removeSuggestedProcess = (id, reject = true) => {
    setProductiveProcessesCNAEList(
      productiveProcessesCNAEList.filter((process) => process.id !== id)
    );
    if (reject) rejectProductiveProcess(props.dispatch, id);
  };

  useEffect(() => {
    let processFiltered = [];
    props.processosProdutivosDisponiveis.forEach((process) => {
      let processAlreadyExists = props.processos.find(
        (item) => item.nome === process.nome
      );
      if (!processAlreadyExists) {
        processFiltered.push(process);
      }
    });
    setProductiveProcessesCNAEList(processFiltered);
  }, [
    JSON.stringify(props.processosProdutivosDisponiveis),
    JSON.stringify(props.processos),
  ]);

  return (
    <List
      dataSource={productiveProcessesCNAEList}
      className="productive-processes-CNAE-list"
      renderItem={(item, index) => (
        <List.Item key={index}>
          <List.Item.Meta
            //onClick={() => this.props.edit(item.id, true)}
            title={item.nome}
          />
          <Row gutter={12}>
            <Col span={12}>
              <Button
                text={
                  <>
                    <CloseOutlined />
                    Recusar
                  </>
                }
                block
                type="danger"
                onClick={() => showModal(item.id)}
                //style={theme[0] === "roxoClaro" ? { 'color': '#eee' } : {}}
              />
            </Col>
            <Col span={12}>
              <Button
                text={
                  <>
                    <CheckOutlined />
                    Aceitar
                  </>
                }
                block
                type="accept"
                onClick={() => {
                  // props.acceptProductiveProcess(props.dispatch, item.id);
                  removeSuggestedProcess(item.id, false);
                  props.handleAcceptProcessByCNAE(item.id, item.nome);
                }}
                //style={theme[0] === "roxoClaro" ? { 'color': '#eee' } : {}}
              />
            </Col>
          </Row>
        </List.Item>
      )}
    />
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({}, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcessoProdutivoCNAEList);
