import axios from "axios";
import openNotification from "../../../../components/notifications";
import {
  deletarFatorDeRisco,
  epcepimaoUrl,
  fatorDeRiscoEditURL,
  inventarioDeRiscoPorProcesso,
  inventarioUrl,
  fatorDeRiscoPorTipo,
  inventariosFatoresDeRiscoProcessosUrl,
  processosProdutivos,
} from "../../../../constants";
import {
  LISTAR_INVENTARIO_DE_RISCO,
  LISTAR_INVENTARIO_DE_RISCO_DO_PROCESSO,
  REMOVER_INVENTARIO_DE_RISCO,
  LISTAR_FATORES_DE_RISCO_DISPONIVEIS,
} from "./types";

const risksDictionary = {
  crítico: "Crítico",
  alto: "Alto",
  médio: "Médio",
  baixo: "Baixo",
  irrelevante: "Irrelevante",
};

export const editRiskInventory = async (dispatch, formData, id, fatorDeRiscoNome, processoProdutivoId) => {
  await axios
    .patch(fatorDeRiscoEditURL(id), formData)
    .then(async(response) => {
      await getInventarioDeRisco(dispatch);
      openNotification(
        "Salvo com sucesso!",
        `Nível de risco para ${fatorDeRiscoNome}: ${
          risksDictionary[response.data.nivelDeRisco]
        }`,
        "success"
      );
      await getInventarioDeRiscoByProcess(dispatch, processoProdutivoId)
    })
    .catch();
};

export const getInventarioDeRisco = async (dispatch) => {
  await axios
    .get(inventarioUrl)
    .then((response) => {
      dispatch({
        type: LISTAR_INVENTARIO_DE_RISCO,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getInventarioDeRiscoByProcess = async (dispatch, id) => {
  await axios
    .get(inventarioDeRiscoPorProcesso(id))
    .then((response) => {
      dispatch({
        type: LISTAR_INVENTARIO_DE_RISCO_DO_PROCESSO,
        payload: response.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getProcessWithoutInventarioDeRisco = (dispatch, id) => {
  axios
    .get(`${processosProdutivos}/${id}`)
    .then((response) => {
      dispatch({
        type: LISTAR_INVENTARIO_DE_RISCO_DO_PROCESSO,
        payload: response.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getInventarioDeRiscoByProcessIds = (ids, callback) => {
  let idsInventarioDeRiscoDoProcesso = {};
  Promise.all(
    ids.map((id) => axios.get(inventarioDeRiscoPorProcesso(id)))
  ).then((responses) => {
    responses.map((response) => {
      idsInventarioDeRiscoDoProcesso[response.data.processoProdutivoId] =
        response.data.inventarios.map((risco) => risco.id);
      return response;
    });
    callback(idsInventarioDeRiscoDoProcesso);
  });
};

export const getEPCEPIMAO = async () => {
  let response = await axios
    .get(epcepimaoUrl)
    .then((response) => {
      if (response.error) {
        throw response.error;
      }
      return response.data;
    })
    .catch((erro) => {
      return [];
    });
  return response;
};

export const excluirFatorDeRisco = (dispatch, id) => {
  axios
    .delete(deletarFatorDeRisco(id))
    .then(() => {
      dispatch({
        type: REMOVER_INVENTARIO_DE_RISCO,
        payload: id,
      });
      openNotification("Perigo excluído com sucesso!", "", "success");
      getInventarioDeRisco(dispatch);
    })
    .catch();
};

export const getFatorDeRiscoPorTipo = (dispatch, tipo) => {
  axios
    .get(fatorDeRiscoPorTipo(tipo))
    .then((response) => {
      dispatch({
        type: LISTAR_FATORES_DE_RISCO_DISPONIVEIS,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const addFatorDeRisco = (
  dispatch,
  fatoresDeRiscoBody,
  resetFields,
  toggleShowNewRisk,
  newId
) => {
  if (newId) fatoresDeRiscoBody.processoProdutivoId = newId;
  axios
    .post(inventariosFatoresDeRiscoProcessosUrl, fatoresDeRiscoBody)
    .then(() => {
      openNotification("Perigo adicionado com sucesso!", "", "success");
      axios
        .get(
          inventarioDeRiscoPorProcesso(fatoresDeRiscoBody.processoProdutivoId)
        )
        .then((response) => {
          dispatch({
            type: LISTAR_INVENTARIO_DE_RISCO_DO_PROCESSO,
            payload: response.data,
          });
          getInventarioDeRisco(dispatch);
          if (resetFields) resetFields();
          if (toggleShowNewRisk) toggleShowNewRisk();
        })
        .catch((err) => {
          console.log(err);
        });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addAusenciaDeRisco = (dispatch, processoProdutivoId) => {
  axios
    .post(
      inventariosFatoresDeRiscoProcessosUrl,
      ausenciaDeRiscoBody(processoProdutivoId)
    )
    .then(() => {
      openNotification(
        "Ausência de risco adicionada com sucesso!",
        "",
        "success"
      );
      axios
        .get(inventarioUrl)
        .then((response) => {
          dispatch({
            type: LISTAR_INVENTARIO_DE_RISCO,
            payload: response.data,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    })
    .catch((err) => {
      console.log(err);
    });
};

const ausenciaDeRiscoBody = (processoProdutivoId) => {
  return {
    fatoresDeRisco: [
      {
        fonteGeradora: "-",
        id: 190,
      },
    ],
    processoProdutivoId: processoProdutivoId,
  };
};
