import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { emptyMAOSelecionado, getMAOFromList, getMAOs } from "../../actions";
import "../../../styles.scss";

const CadastroMAOList = (props) => {
  const [maos, setMaos] = useState([]);

  const onLoadScreen = async () => {
    let maoList = await getMAOs();

    setMaos(maoList);
  };

  useEffect(() => {
    onLoadScreen();
  }, []);

  const handleEdit = (id) => {
    getMAOFromList(id, props.dispatch);
  };

  return (
    <React.Fragment>
      <div>
        <Link to="/form-mao">
          <Button onClick={() => emptyMAOSelecionado(props.dispatch)}>
            Cadastrar MAO
          </Button>
        </Link>
      </div>
      <div className="dn-content">
        {maos.map((mao) => (
          <div className="mao-item" key={mao.id}>
            <p>Código: {mao.id} </p>
            <p>MAO: {mao.nome}</p>
            <p>Situação: {mao.ativo ? "Ativo" : "Inativo"}</p>
            {mao.padrao && <p>Padrão para químicos</p>}
            <Link to="/form-mao">
              <Button onClick={() => handleEdit(mao.id)}>Editar</Button>
            </Link>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    listaMAOs: state.gestorDN.listaMAOs ? state.gestorDN.listaMAOs : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ getMAOs }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CadastroMAOList);
