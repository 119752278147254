import {
  NEXT_PAGE_INTRO,
  BACK_PAGE_INTRO,
  NEXT_PAGE_STEPS_ATIVIDADES,
  NEXT_PAGE_STEPS_ESPACO,
  NEXT_PAGE_STEPS_EMPRESA,
  NEXT_PAGE_FATOR_RISCO,
  NEXT_PAGE_STEPS_TRABALHADORES,
  NEXT_PAGE_STEPS_MAQUINAS,
  NEXT_PAGE_STEPS_PLANO,
  NEXT_PAGE_STEPS_PROD_QUIM,
  NEXT_PAGE_STEPS_SETORES,
  NEXT_PAGE_COMPANY_SELECTION,
  INTRO_OVER,
  NEXT_PAGE_PRIMEIROS_PASSOS,
  BACK_PAGE_PRIMEIROS_PASSOS
} from "../actions/types";

export default function(
  state = {
    pageIntro: 1,
    pagePrimeirosPassos: 1,
    pageStepsActividades: 1,
    pageStepsEspaco: 1,
    pageStepsEmpresa: 1,
    pageStepsFatoresRisco: 1,
    pageStepsTrabalhadores: 1,
    pageStepsMaquinas: 1,
    pageStepsPlanoAcao: 1,
    pageStepsProdQuim: 1,
    pageStepsSetores: 1,
    pageCompanySelection: 1,
    isIntroOver: false
  },
  action
) {
  switch (action.type) {
    case NEXT_PAGE_INTRO:
      let { pageIntro } = state;
      return {
        ...state,
        pageIntro: pageIntro + 1
      };
    case BACK_PAGE_INTRO:
      return {
        ...state,
        pageIntro: 1
      };
    case INTRO_OVER:
      return {
        ...state,
        isIntroOver: true
      };
    case NEXT_PAGE_PRIMEIROS_PASSOS:
      let { pagePrimeirosPassos } = state;
      return {
        ...state,
        pagePrimeirosPassos: pagePrimeirosPassos + 1
      };
    case BACK_PAGE_PRIMEIROS_PASSOS:
      return {
        ...state,
        pagePrimeirosPassos: 1
      };
    case NEXT_PAGE_STEPS_ATIVIDADES:
      let { pageStepsActividades } = state;
      return {
        ...state,
        pageStepsActividades: pageStepsActividades + 1
      };
    case NEXT_PAGE_STEPS_ESPACO:
      let { pageStepsEspaco } = state;
      return {
        ...state,
        pageStepsEspaco: pageStepsEspaco + 1
      };
    case NEXT_PAGE_STEPS_EMPRESA:
      let { pageStepsEmpresa } = state;
      return {
        ...state,
        pageStepsEmpresa: pageStepsEmpresa + 1
      };
    case NEXT_PAGE_FATOR_RISCO:
      let { pageStepsFatoresRisco } = state;
      return {
        pageStepsFatoresRisco: pageStepsFatoresRisco + 1
      };
    case NEXT_PAGE_STEPS_TRABALHADORES:
      let { pageStepsTrabalhadores } = state;
      return {
        ...state,
        pageStepsTrabalhadores: pageStepsTrabalhadores + 1
      };
    case NEXT_PAGE_STEPS_MAQUINAS:
      let { pageStepsMaquinas } = state;
      return {
        ...state,
        pageStepsMaquinas: pageStepsMaquinas + 1
      };
    case NEXT_PAGE_STEPS_PLANO:
      let { pageStepsPlanoAcao } = state;
      return {
        ...state,
        pageStepsPlanoAcao: pageStepsPlanoAcao + 1
      };
    case NEXT_PAGE_STEPS_PROD_QUIM:
      let { pageStepsProdQuim } = state;
      return {
        ...state,
        pageStepsProdQuim: pageStepsProdQuim + 1
      };
    case NEXT_PAGE_STEPS_SETORES:
      let { pageStepsSetores } = state;
      return {
        ...state,
        pageStepsSetores: pageStepsSetores + 1
      };
    case NEXT_PAGE_COMPANY_SELECTION:
      let { pageCompanySelection } = state;
      return {
        ...state,
        pageCompanySelection: pageCompanySelection + 1
      };
    default:
      return state;
  }
}
