import React, { useEffect, useState } from "react";
import { Button, Form, Input, Col, Row, Spin } from "antd";
import { isEmpty } from "../../../../../../../util/arrayUtil";
import { EMPRESA } from "../../../../../../../constants/stepTypes";
import { isPerfilCliente } from "../../../../../../../constants/userTypes";
import FinishStep from "../../../../../../../components/FinishStep";
import LogoUpload from "./LogoUpload";

const { TextArea } = Input;

const CompanyForm = (props) => {
  const [form] = Form.useForm();
  const [companySelected, setCompanySelected] = useState(props.companySelected);
  const [logo, setLogo] = useState(null);
  const [deleteLogo, setDeleteLogo] = useState([]);
  const [anexos] = useState([]);
  const [deleteAnexos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [anexosLoading] = useState(false);

  useEffect(() => {
    setCompanySelected(props.companySelected);
  }, [JSON.stringify(props.companySelected)]);

  const getLogoFormData = (logo) => {
    if (logo.name) {
      let formData = new FormData();
      formData.append("logo", logo);
      setLogo(formData);
    } else {
      setLogo(null);
    }
  };

  const getDeletedLogoFormData = () => {
    let formData = new FormData();
    formData.append("removerLogo", 1);
    setDeleteLogo(formData);
  };

  const logoLoadingToggle = (bool) => {
    setLogoLoading(bool);
  };

  const anexosLoadingToggle = () => {
    setLogoLoading(anexosLoading ? false : true);
  };

  const toggleLoading = (bool) => {
    setLoading(bool);
  };

  const handleSubmit = (e) => {
    form
      .validateFields()
      .then((values) => {
        props.saveEmpresa(
          values,
          logo,
          deleteLogo,
          anexos,
          deleteAnexos,
          toggleLoading,
          logoLoadingToggle,
          anexosLoadingToggle
        );
      })
      .catch((err) => console.log(err));
  };

  if (isEmpty(companySelected)) {
    return (
      <div className="spin">
        <Spin tip="Carregando..." />
      </div>
    );
  }

  return (
    <Form
      onFinish={handleSubmit}
      form={form}
      layout="vertical"
      className="company-form ant-row"
    >
      <div className="section">
        <Form.Item
          label="Descreva a empresa"
          initialValue={companySelected.descricao}
          rules={[
            {
              required: true,
              message: "Escreva uma descrição",
            },
          ]}
          name="descricao"
        >
          <div className="description">
            <TextArea
              disabled={props.completed}
              defaultValue={companySelected.descricao}
            />
            {/* <span className="title">*Descreva a empresa: </span> */}
            <span className="text">
              Como é a empresa? Como são as instalações? Como é o local onde ela
              está instalada? Quais são as características do lugar? Qual a área
              em metros quadrados? Qual é a altura do piso até o teto dos
              ambientes (pé-direito)? Qual é o tipo de piso utilizado? De que
              material são feitas as paredes? A iluminação é artificial ou
              natural? Como é a ventilação dos ambientes?
            </span>
          </div>
        </Form.Item>

        <Row gutter={24} style={{ maxHeight: "max-content" }}>
          <Col span={24}>
            <LogoUpload
              getLogoFormData={getLogoFormData}
              viewOnly={props.completed}
              getDeletedLogoFormData={getDeletedLogoFormData}
              perfil={props.perfil}
              logoLoading={logoLoading}
              completed={props.completed}
            />
          </Col>
          {/* <Col span={12}>
            <AnexosUpload
              getAnexosFormData={getAnexosFormData}
              viewOnly={viewOnly}
              getDeletedAnexosFormData={getDeletedAnexosFormData}
              perfil={props.perfil}
              anexosLoading={anexosLoading}
              completed={props.completed}
            />
          </Col> */}
        </Row>
      </div>
      <div className="finishCompanyCharacterizationDiv">
        <Row gutter={24} type="flex" justify="space-between" align="stretch">
          {props.completed ? (
            <Col span={24}></Col>
          ) : (
            <Col span={24}>
              <Button htmlType="submit" type="primary" block loading={loading}>
                Salvar
              </Button>
            </Col>
          )}
        </Row>
      </div>
      <div className="stepDiv">
        <Row gutter={24} align="middle">
          {isPerfilCliente(props.perfil) ? (
            <Col span={24}>
              <FinishStep
                perfil={props.perfil}
                completed={props.completed}
                step={EMPRESA}
                url="empresas"
                statusFluxo={props.companySelected.statusFluxo}
              />
            </Col>
          ) : (
            <Col span={24}></Col>
          )}
        </Row>
      </div>
    </Form>
  );
};

export default CompanyForm;
