export const OPERADOR = "OPERADOR";
export const RESPONSAVEL = "RESPONSAVEL";
export const TECNICO = "TECNICO";
export const ADMINISTRADOR = "ADMINISTRADOR";
export const GESTOR_DR = "GESTOR_DR";
export const MEDICO = "MEDICO";
export const GESTOR_DN = "GESTOR_DN";
export const isPerfilCliente = (perfil) => {
  return perfil === OPERADOR || perfil === RESPONSAVEL;
};
export const isPerfilGestor = (perfil) => {
  return perfil === ADMINISTRADOR || perfil === GESTOR_DR;
};
