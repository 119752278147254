import { Button, Form, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/";
import { openAssistantModal } from "../../../../components/AssistantModalHeader";
import { selectCompany } from "../../../../components/CompanyList/_/containers/Companies/_/actions";
import { HeaderWithModal } from "../../../../components/HeaderWithModal/index";
import { getAssistantTalks } from "../actions/index";


const ESocialCompanySelector = (props) => {
  const history = useHistory();

  const { destroyAll } = Modal;

  const { Option } = Select;
  const premiumCompanies = props.companies.filter((company) => company.premium);
  const [disableButtons, setDisableButtons] = useState(true);
  const [assistantTalk, setAssistantTalk] = useState([])

  const selectingCompany = (chosenCompanyCNPJ) => {
    let newSelectedCompany = premiumCompanies.find(
      (company) => company.cnpj === chosenCompanyCNPJ
    );
    selectCompany(props.dispatch, newSelectedCompany);
  };

  const handleRedirect = () => {
    history.push("/gerenciar-assinaturas");
    destroyAll();
  };

  const notEditablePhrase = (index) => {
    // o index "1" é de acordo com o index dos items nao editáveis vindos do banco
    if (index === 1) {
      return (
        <React.Fragment>
          <p>
            Para começar, você deve acessar o link Gerenciar Assinaturas no menu lateral
            <a onClick={handleRedirect}>(ou clique aqui)</a> e cadastrar sua assinatura digital.
            Depois disso, escolha a empresa que deseja enviar os eventos na lista ao lado e
            clique no botão "Gerar Eventos".
          </p>
        </React.Fragment>
      );
    }
  };

  const getSelectCompanyAssistantTalk = async () => {
    const talks = await getAssistantTalks('selecionar-evento', 'Início')

    if (talks.naoEditavel) {
      const formattedTalks = talks.descricao.map((phrase, index) => {
        if (talks.naoEditavel.includes(String(index))) {
          return notEditablePhrase(index);
        } else {
          return phrase;
        }
      });

      setAssistantTalk(formattedTalks)
    }
  }

  useEffect(() => {
    getSelectCompanyAssistantTalk()
  }, [])


  return (
    <React.Fragment>
      <div className="container">
        <HeaderWithModal
          pageTitle="Selecione a empresa para gerenciar o eSocial"
          helpText={"Clique aqui e saiba mais!"}
          assistant={"vivi"}
          openModal={() => openAssistantModal(assistantTalk ?? [], "vivi")}
        />
        <div className="esocial-dashboard">
          <Form>
            <div className="section">
              <Form.Item label="Empresa::">
                <Select
                  showArrow={false}
                  allowClear
                  onSelect={(value) => {
                    selectingCompany(value);
                    setDisableButtons(false);
                  }}
                  onClear={() => setDisableButtons(true)}
                >
                  {premiumCompanies.map((company) => (
                    <Option key={company.cnpj} value={company.cnpj}>
                      {company.razaoSocial}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Form>

          <div className="buttons-wrapper">
            <Button
              disabled={disableButtons}
              onClick={() => props.setActiveTab("2")}
            >
              <h3>Gerar Eventos</h3>
            </Button>

            <Button
              disabled={disableButtons}
              onClick={() => props.setActiveTab("5")}
            >
              <h3>Acompanhar Eventos</h3>
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ESocialCompanySelector;
