import { Collapse, Col, Row, Progress, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import IconSESI from "../../../../../components/IconsSESI";

const { Panel } = Collapse;
const EmpresaAtivaCard = (props) => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    setCompanies(props.companies);
  }, [JSON.stringify(props.companies)]);

  const CNPJMask = (cnpj) => {
    return cnpj.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  };

  const getDateAndMonthOnly = (date) => {
    let copiedDate = date.split("/");
    copiedDate.pop();
    return copiedDate.join("/");
  };

  const getAmountOfTimePassed = (date) => {
    const now = moment(new Date());
    const timePassed = moment(moment.duration(now.diff(date))).format("DD");
    if (timePassed < 7) {
      return "- de 7 dias";
    } else if (timePassed < 15) {
      return "- de 15 dias";
    } else {
      return "+ de 15 dias";
    }
  };

  const capitalizeEveryName = (name) => {
    if (name !== "") {
      let parsedName = "";
      let names = name.toLowerCase().split(" ");

      names.forEach(item => {
        if(item !== ""){
          if(parsedName.length === 0){
            parsedName += `${item[0].toUpperCase() + item.substring(1)}`;
          } else {
            parsedName += ` ${item[0].toUpperCase() + item.substring(1)}`;
          }
        }
      })

      return parsedName
    }
    return "Ainda não informado";
  };

  // const capitalizeAddress = (address) => {
  //   let addressArr = address.toLowerCase().split(" ");
  //   let firstWord = Array.from(addressArr.shift());
  //   firstWord[0] = firstWord[0].toUpperCase();
  //   return firstWord.join("") + " " + addressArr.join(" ");
  // };

  const telephoneMask = (telephone) => {
    if (telephone.length > 11) {
      telephone = telephone.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (telephone.length > 7) {
      telephone = telephone.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
    } else if (telephone.length > 2) {
      telephone = telephone.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    }
    return telephone;
  };

  return (
    <Row gutter={16}>
      {companies.length > 0 ? (
        companies.map((company) => (
          <Col span={12}>
            <Collapse expandIconPosition="end">
              <Panel
                header={
                  <div>
                    {company.razaoSocial} - {CNPJMask(company.cnpj)}
                    <p>{company.nomeFantasia}</p>
                  </div>
                }
              >
                <Row>
                  <Col span={14}>
                    <div>
                      <p>{`${company.numeroCnae} - ${company.cnae}`}</p>
                      <p>{`Grau de risco: ${company.grauRisco.toLowerCase()}`}</p>
                      <p>{`Porte: ${company.porte}`}</p>
                    </div>
                  </Col>
                  <Col span={10}>
                    <div className="circles-wrapper">
                      <Tooltip title="Data da compra">
                        <div className="circle-info">
                          <span className="circle">
                            {getDateAndMonthOnly(company.dataCompra)}
                          </span>

                          <span className="info">
                            {company.dataCompra.split("/").pop()}
                          </span>
                        </div>
                      </Tooltip>
                      <Tooltip title="Tempo restante do contrato">
                        <div className="circle-info">
                          <span className="circle">
                            {company.tempoRestanteContrato}
                          </span>

                          <span className="info">dias</span>
                        </div>
                      </Tooltip>
                      <Tooltip title="Tempo e porcentagem nesse status">
                        <div className="circle-info percentage">
                          <Progress
                            type="circle"
                            width={65}
                            percent={company.porcentagemStatus}
                          />
                          <span className="circle days">
                            {getAmountOfTimePassed(
                              company.diasPercorridosDoUltimoStatus
                            )}
                          </span>

                          <span className="info percentage">{`${company.porcentagemStatus}%`}</span>
                        </div>
                      </Tooltip>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="contact-card">
                      <h4>Contato</h4>
                      <div className="contact-line">
                        <IconSESI type="user"></IconSESI>
                        <span>{capitalizeEveryName(company.contato)}</span>
                      </div>
                      <div className="contact-line">
                        <IconSESI type="email"></IconSESI>
                        <span>
                          {company.email
                            ? company.email
                            : "Ainda não informado"}
                        </span>
                      </div>
                      <div className="contact-line">
                        <IconSESI type="telephone"></IconSESI>
                        <span>
                          {company.telefone
                            ? telephoneMask(company.telefone)
                            : "Ainda não informado"}
                        </span>
                      </div>
                      <div className="contact-line">
                        <IconSESI type="map"></IconSESI>
                        <span>{`${capitalizeEveryName(
                          company.endereco.endereco
                        )} - ${capitalizeEveryName(
                          company.endereco.cidade
                        )} - ${company.endereco.uf.toUpperCase()}`}</span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Col>
        ))
      ) : (
        <h2>Não há empresas nesse status.</h2>
      )}
    </Row>
  );
};

export default EmpresaAtivaCard;
