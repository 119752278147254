import { GET_ANSWERS, LIST_QUESTIONS } from "./types";
import {
  lead,
  perguntasAutodeclaracaoUrl,
  respostasAutodeclaracao,
  generateAutodeclaracaoUrl,
  finishQuestionarioUrl,
  finishAutodeclaracaoUrl,
  empresasUrl,
} from "../../../../constants";
import axios from "axios";
import openNotification from "../../../../components/notifications";

export const listQuestions = (dispatch) => {
  axios.get(perguntasAutodeclaracaoUrl).then((resp) => {
    dispatch({
      type: LIST_QUESTIONS,
      payload: resp.data,
    });
  });
};

export const answerQuestion = (dispatch, id, answer) => {
  axios
    .post(respostasAutodeclaracao, { idPergunta: id, resposta: answer })
    .then(() => {
      axios
        .get(respostasAutodeclaracao)
        .then((response) => {
          dispatch({
            type: GET_ANSWERS,
            payload: response.data,
          });
          openNotification("Resposta salva com sucesso!", "", "success");
        })
        .catch((err) => console.error(err));
    })
    .catch((err) => {
      console.log(err);
      openNotification("Erro ao enviar sua resposta!", "", "error");
    });
};

export const getCompanyAnswers = (dispatch) => {
  axios
    .get(respostasAutodeclaracao)
    .then((response) => {
      dispatch({
        type: GET_ANSWERS,
        payload: response.data,
      });
    })
    .catch((err) => console.error(err));
};

export const captureLead = async (tipo) => {
  await axios
    .post(lead, { tipo })
    .then(() => console.log("lead captured"))
    .catch((err) => console.error({err}));
};

export const generateAutodeclaracao = (
  callback,
  loading,
  logo,
  logoLoadingToggle,
  deleteLogo
) => {
  let calls = [];
  let logoUploaded = false;
  loading(true);
  if (logo instanceof FormData) {
    logoLoadingToggle(true);
    logoUploaded = true;
    calls.push(
      axios({
        method: "patch",
        headers: { "Content-Type": "multipart/form-data" },
        data: logo,
        url: `${empresasUrl}/logo`,
      })
    );
  }
  if (deleteLogo instanceof FormData) {
    calls.push(
      axios({
        url: `${empresasUrl}/logo`,
        method: "PATCH",
        headers: { "Content-Type": "multipart/form-data" },
        data: deleteLogo,
      })
    );
  }
  axios
    .all(calls)
    .then(() => {
      if (logoUploaded) logoLoadingToggle(false);
      axios
        .post(generateAutodeclaracaoUrl)
        .then((resposta) => {
          callback(resposta);
          console.log(resposta);
          loading(false);
        })
        .catch((e) => {
          loading(false);
          openNotification("Falha ao gerar autodeclaração!", "", "error");
        });
    })
    .catch((e) => {
      console.error(e);
    });
};

export const finishQuestionario = () => {
  axios
    .patch(finishQuestionarioUrl, { questionarioAutodeclaracao: true })
    .then((resposta) => {
      console.log(resposta);
    })
    .catch((e) => {
      console.error(e);
    });
};

export const finishAutodeclaracao = () => {
  axios
    .patch(finishAutodeclaracaoUrl, { autodeclaracao: true })
    .then((resposta) => {
      console.log(resposta);
    })
    .catch((e) => {
      console.error(e);
    });
};
