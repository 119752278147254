import CaracterizacaoEmpresa from "./_/components/CaracterizacaoEmpresa";
import CaracterizacaoFuncionarios from "./_/components/CaracterizacaoFuncionarios";
import CaracterizacaoSetores from "./_/components/CaracterizacaoSetores/_/containers/CaracterizacaoSetores";
import CaracterizacaoProcessosProdutivos from "./_/components/CaracterizacaoProcessosProdutivos/_/containers/CaracterizacaoProcessosProdutivos";
import CaracterizacaoRealizadorProcessos from "./_/components/CaracterizacaoRealizadorProcessos/_/containers/CaracterizacaoRealizadorProcessos";
import { connect } from "react-redux";
import React from "react";
import Step from "../../components/Step";
// import { fetchPageComments } from "../../components/TechnicianSuggestionsForm/_/actions";

import { getStepsCliente } from "../../components/FinishStep/_/actions";

class Caracterizacao extends React.Component {
  render() {
    // if (!this.props.customizacoes) {
    //   changeTheme("azul");
    // } else if (this.props.customizacoes.temaDeCoresDoUsuario) {
    //   changeTheme(this.props.customizacoes.temaDeCoresDoUsuario);
    // } else if (this.props.customizacoes.temasDeCoresDasEmpresas) {
    //   //mudar depois^
    //   //changeTheme(this.props.customizacoes.temasDeCoresDasEmpresas[0].temaDeCoresDaEmpresa);
    // }
    const {
      status,
      stepsEmpresa,
      statusAnaliseTecnica,
      perfil,
      // fetchPageComments,
      getStepsCliente,
    } = this.props;
    return (
      <Step
        status={status}
        stepsEmpresa={stepsEmpresa}
        statusAnaliseTecnica={statusAnaliseTecnica}
        perfil={perfil}
        // fetchPageComments={fetchPageComments}
        getStepsCliente={getStepsCliente}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    perfil: state.user.userInfo.perfil,
    status: state.caracterizacao,
    stepsEmpresa: state.caracterizacao.stepsEmpresa.stepsEmpresa,
    customizacoes: state.user.userInfo ? state.user.userInfo.customizacoes : {},
  };
};

export default connect(mapStateToProps, {
  // fetchPageComments,
  getStepsCliente,
})(Caracterizacao);

export {
  CaracterizacaoEmpresa,
  CaracterizacaoFuncionarios,
  CaracterizacaoSetores,
  CaracterizacaoProcessosProdutivos,
  CaracterizacaoRealizadorProcessos,
};
