import React, { useState, useEffect } from "react";
import "./styles.scss";
import Sidebar from "./Sidebar/index";
import Content from "./Content/index";

const Container = ({ menuItems, setMenuItems }) => {
  const [activeItem, setActiveItem] = useState();

  useEffect(() => {
    verifyActiveItem();
  }, [menuItems]);

  const verifyActiveItem = () => {
    const currentItem = menuItems.find((item) => item.active === true);

    setActiveItem(currentItem);
  };

  return (
    <>
      <div className="virtual-assistant-container">
        <Sidebar
          activeItem={activeItem ? activeItem : {}}
          setMenuItems={setMenuItems}
        />
        <Content activeItem={activeItem} />
      </div>
    </>
  );
};

export default Container;
