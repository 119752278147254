export const GET_BEGINNING_TALKS = "GET_BEGINNING_TALKS";
export const PATCH_BEGINNING_TALK = "PATCH_BEGINNING_TALK";
export const GET_CATEGORIZATION_TALKS = "GET_CATEGORIZATION_TALKS";
export const PATCH_CATEGORIZATION_TALK = "PATCH_CATEGORIZATION_TALK";
export const GET_RISKS_TALKS = "GET_RISKS_TALKS";
export const PATCH_RISKS_TALKS = "PATCH_RISKS_TALKS";
export const GET_ACTION_PLAN_TALKS = "GET_ACTION_PLAN_TALKS";
export const PATCH_ACTION_PLAN_TALKS = "PATCH_ACTION_PLAN_TALKS";
export const GET_MONITORING_TALKS = "GET_MONITORING_TALKS";
export const PATCH_MONITORING_TALK = "PATCH_MONITORING_TALK";
export const GET_AUTOSTATEMENT_TALKS = "GET_AUTOSTATEMENT_TALKS";
export const PATCH_AUTOSTATEMENT_TALKS = "PATCH_AUTOSTATEMENT_TALKS";
