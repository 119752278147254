import { Button, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getEPIs,
  getEPI,
  listarFatoresforGrupoDeRisco,
  patchEPI,
  emptyRelacaoEPISelecionanda,
  addEPI,
} from "../../actions";
import "../../../styles.scss";

const { Option } = Select;

const EPIForm = (props) => {
  const [form] = Form.useForm();
  const [mode, setMode] = useState("new");
  const [episOptions, setEpisOptions] = useState([]);

  const onLoadScreen = async () => {
    let epiList = await getEPIs();
    let epiListFormatted = [];

    epiList.forEach((epi) => {
      if (epi.ativo) {
        epiListFormatted.push(epi);
      } else {
        let epiAlreadyExists =
          props.relacaoEPIFatorDeRiscoSelecionada?.epis?.find(
            (item) => item.id == epi.id
          );
        if (epiAlreadyExists) {
          epiListFormatted.push(epi);
        }
      }
    });

    setEpisOptions(epiListFormatted);
  };

  useEffect(() => {
    if (props.relacaoEPIFatorDeRiscoSelecionada) {
      onLoadScreen();
      setMode("edit");
    }
    setEdit(props.relacaoEPIFatorDeRiscoSelecionada);
  }, [JSON.stringify(props.relacaoEPIFatorDeRiscoSelecionada)]);

  const setEdit = (relacaoEPIFatorDeRiscoSelecionada) => {
    if (mode === "edit") {
      form.setFieldsValue({
        riskName: relacaoEPIFatorDeRiscoSelecionada.descricao,
        riskEPIs: relacaoEPIFatorDeRiscoSelecionada.epis.map((epi) => epi.id),
      });
    }
  };

  const handleGroupSelection = () => {
    form
      .validateFields()
      .then((values) => {
        listarFatoresforGrupoDeRisco(values.riskGroup, props.dispatch);
      })
      .catch((err) => {});
  };

  const addNewEPI = () => {
    form.validateFields().then((values) => {
      let newRelacaoEPI = {
        epis: values.riskEPIs,
      };
      addEPI(values.riskName, newRelacaoEPI, props.dispatch);
    });
  };

  const patchRelacaoEPI = () => {
    form.validateFields().then((values) => {
      let edittedEPI = {
        epis: values.riskEPIs,
      };
      patchEPI(
        props.relacaoEPIFatorDeRiscoSelecionada.id,
        edittedEPI,
        props.dispatch
      );
      emptyRelacaoEPISelecionanda(props.dispatch);
    });
  };

  return (
    <React.Fragment>
      <Form
        form={form}
        onFinish={mode === "new" ? () => addNewEPI() : () => patchRelacaoEPI()}
      >
        <Form.Item name="riskGroup">
          <Select
            style={{
              width: 250,
            }}
            placeholder="Selecione um grupo de risco"
            onSelect={() => handleGroupSelection()}
          >
            <Option value="1">Físico</Option>
            <Option value="2">Químico</Option>
            <Option value="3">Biológico</Option>
            <Option value="4">Ergonômico</Option>
            <Option value="5">Acidente</Option>
            <Option value="6">Inespecífico</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Fator de risco" name="riskName">
          <Select>
            {props.fatoresDeRiscoPorGrupo
              ? props.fatoresDeRiscoPorGrupo.map((fator) => (
                  <Option value={fator.id}>{fator.descricao}</Option>
                ))
              : ""}
          </Select>
        </Form.Item>
        <Form.Item label="EPIs associados" name="riskEPIs">
          <Select
            mode="multiple"
            style={{
              width: "100%",
            }}
            filterOption={(input, option) => {
              let value = option.props.children.toLowerCase();
              console.log(value);
              value = value.replace(/[àáâã]/g, "a");
              value = value.replace(/[éêè]/g, "e");
              value = value.replace(/[íîì]/g, "i");
              value = value.replace(/[óòôõ]/g, "o");
              value = value.replace(/[úùû]/g, "u");
              value = value.replace(/[ç]/g, "c");

              return (
                value.indexOf(input.toLowerCase()) >= 0 ||
                value.indexOf(input) >= 0
              );
            }}
          >
            {episOptions.map((epi) => (
              <Option key={epi.id} value={epi.id}>
                {epi.nome}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Button htmlType="submit">Salvar</Button>
      </Form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    relacaoEPIFatorDeRiscoSelecionada:
      state.gestorDN.relacaoEPIFatorDeRiscoSelecionada,
    epis: state.gestorDN.listaEPIs,
    fatoresDeRiscoPorGrupo: state.gestorDN.fatoresDeRiscoPorGrupo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({
      getEPIs,
      getEPI,
      listarFatoresforGrupoDeRisco,
    }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EPIForm);
