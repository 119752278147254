export const LISTAR_FUNCIONARIOS = "LISTAR_FUNCIONARIOS";
export const LISTAR_FUNCIONARIOS_ERRO = "LISTAR_FUNCIONARIOS_ERRO";
export const CRIAR_FUNCIONARIO = "CRIAR_FUNCIONARIO";
export const CRIAR_FUNCIONARIO_ERRO = "CRIAR_FUNCIONARIO_ERRO";
export const ATIVAR_DESATIVAR_FUNCIONARIO = "ATIVAR_DESATIVAR_FUNCIONARIO";
export const EDITAR_FUNCIONARIO = "EDITAR_FUNCIONARIO";
export const OBTER_FUNCIONARIO = "OBTER_FUNCIONARIO";
export const OBTER_SETORES = "OBTER_SETORES";
export const LISTAR_CARGOS = "LISTAR_CARGOS";
export const LISTAR_FUNCIONARIOS_COM_EPI = "LISTAR_FUNCIONARIOS_COM_EPI";
