import {
  ALTERAR_ASSINATURA,
  DELETAR_ASSINATURA,
  LISTAR_ASSINATURAS,
  LISTAR_CATEGORIAS,
  LISTAR_EXAMES,
  LISTAR_FUNCIONARIOS_ESOCIAL,
  SALVAR_ASSINATURA,
  LISTAR_EVENTOS,
  GERAR_DOCUMENTO,
  ENVIAR_ESOCIAL,
  OBTER_EVENTO,
  GERAR_XML_S3000,
  SALVAR_S3000,
  ENVIAR_S3000,
  ALTERAR_S2220,
  SALVAR_S2240,
  LISTAR_S2240,
  OBTER_S2240,
  GERAR_XML_S2240,
  CONSULTAR_S2240,
  LISTAR_AGENTES_NOCIVOS,
  OBTER_AGENTE_NOCIVO,
} from "../actions/types";

export const esocialReducer = (
  state = {
    categoriasTrabalhadores: [],
    tiposExames: [],
    eventosEsocial: [],
    certificadosCadastrados: [],
    documentoGerado: [],
    respostaEnvio: [],
    s3000: [],
    s3000Salvo: {},
    s3000Xml: {},
    s3000Enviado: {},
    s2240: [],
  },
  action
) => {
  switch (action.type) {
    case LISTAR_CATEGORIAS:
      return {
        ...state,
        categoriasTrabalhadores: action.payload,
      };
    case LISTAR_EXAMES:
      return {
        ...state,
        tiposExames: action.payload,
      };
    case LISTAR_FUNCIONARIOS_ESOCIAL:
      return {
        ...state,
        funcionariosEsocial: action.payload,
      };
    case LISTAR_ASSINATURAS:
      return {
        ...state,
        certificadosCadastrados: action.payload,
      };
    case SALVAR_ASSINATURA:
      return {
        ...state,
        certificadosCadastrados: [action.payload],
      };
    case DELETAR_ASSINATURA:
      return {
        ...state,
        // certificadosCadastrados: action.payload,
      };
    case ALTERAR_ASSINATURA:
      return {
        ...state,
        certificadosCadastrados: [action.payload],
      };
    case LISTAR_EVENTOS:
      return {
        ...state,
        eventosEsocial: action.payload,
      };
    case GERAR_DOCUMENTO:
      return {
        ...state,
        documentoGerado: action.payload,
      };
    case ENVIAR_ESOCIAL:
      return {
        ...state,
        respostaEnvio: action.payload,
      };
    case OBTER_EVENTO:
      return {
        ...state,
        eventoSelecionado: action.payload,
      };
    case SALVAR_S3000:
      return {
        ...state,
        s3000Salvo: action.payload,
      };
    case GERAR_XML_S3000:
      return {
        ...state,
        s3000Xml: action.payload,
      };
    case ENVIAR_S3000:
      return {
        ...state,
        s3000Enviado: action.payload,
      };
    case ALTERAR_S2220:
      return {
        ...state,
      };
    case LISTAR_S2240:
      return {
        ...state,
        s2240: action.payload,
      };
    case OBTER_S2240:
      return {
        ...state,
        s2240Selecionado: action.payload,
      };
    case GERAR_XML_S2240:
      return {
        ...state,
        xmlS2240: action.payload,
      };
    case CONSULTAR_S2240:
      return {
        ...state,
        s2240Consultado: action.payload,
      };
    case LISTAR_AGENTES_NOCIVOS:
      return {
        ...state,
        agentesNocivos: action.payload,
      };
    case OBTER_AGENTE_NOCIVO:
      return {
        ...state,
        agenteNocivoSelecionado: action.payload,
      };
    default:
      return state;
  }
};
