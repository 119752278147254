import React, { useState } from "react";
import VirtualAssistantHeader from "./components/Header";
import { menuItemsData } from "./constants";
import Container from "./components/Container";

const VirtualAssistant = () => {
  const [menuItems, setMenuItems] = useState(menuItemsData);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <VirtualAssistantHeader
        menuItems={menuItems}
        setMenuItems={setMenuItems}
      />
      <Container menuItems={menuItems} setMenuItems={setMenuItems} />
    </div>
  );
};

export default VirtualAssistant;
