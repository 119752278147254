import {
  FETCH_PGR_PREVIEW,
  FETCH_TRABALHADORES_PGR_PREVIEW,
  START_PREENCHIMENTO,
  TOGGLE_STATUS,
} from "../actions/types";

export const dashboardReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PGR_PREVIEW:
      return {
        ...state,
        previewPGR: action.payload,
      };
    case FETCH_TRABALHADORES_PGR_PREVIEW:
      return {
        ...state,
        previewTrabalhadoresPGR: action.payload,
      };
    case START_PREENCHIMENTO:
      return {
        ...state,
        statusCaracterizacao: state.statusCaracterizacao,
      };
    case TOGGLE_STATUS:
      return {
        ...state,
        statusFluxo: state.companyselected.statusFluxo,
      };

    default:
      return state;
  }
};
