import { REQUERIR_MUDANCA_SENHA } from "../actions/types";

export const mudancaDeSenhaReducer = (
  state = {
    codigoValidador: "",
  },
  action
) => {
  switch (action.type) {
    case REQUERIR_MUDANCA_SENHA:
      return {
        ...state,
        codigoValidador: action.payload,
        userCpf: action.userCpf,
      };
    default:
      return state;
  }
};
