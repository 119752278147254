import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { List, Button, Tag, Menu, Dropdown, Col, Row } from "antd";
import SearchBar from "./SearchBar";
import "./style.scss";
import FinishStep from "../../../../../../../components/FinishStep";
import { FUNCIONARIOS } from "../../../../../../../constants/stepTypes";
import IconSESI from "../../../../../../../components/IconsSESI";
import { isPerfilCliente } from "../../../../../../../constants/userTypes";
import { ExclamationCircleOutlined, EllipsisOutlined } from "@ant-design/icons";

const FuncionarioList = (props) => {
  const [funcionarios, setFuncionarios] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [viewOnly, setViewOnly] = useState(false);

  useEffect(() => {
    setViewOnly(props.viewOnly);
  }, [props.viewOnly]);

  useEffect(() => {
    setViewOnly(props.viewOnly);
  }, [props.viewOnly]);

  useEffect(() => {
    if (props.funcionarios) {
      let funcionarios = Object.values(props.funcionarios).flat();
      setFuncionarios(funcionarios);
    }

    // const { funcionarios } = props;
    // if (funcionarios !== prevProps.funcionarios) {
    //   setFuncionarios(funcionarios);
    //   if (prevState !== funcionarios) {
    //     props.fetch();
    //   }
    // }
  }, [JSON.stringify(props.funcionarios)]);

  const getFuncionarios = () => {
    if (props.funcionarios) {
      let funcionarios = Object.values(props.funcionarios).flat();
      return funcionarios;
    }
  };

  const handleOnCargo = (value) => {
    const funcionarios = getFuncionarios();
    let filteredFuncionarios = funcionarios.filter((funcionario) => {
      if (funcionario.cargo === value) {
        return true;
      } else {
        return false;
      }
    });

    if (filteredFuncionarios.length > 0) {
      setFuncionarios(filteredFuncionarios);
      setIsFiltered(true);
    }
    if (value === undefined) {
      const funcionarios = getFuncionarios();
      setFuncionarios(funcionarios);
      setIsFiltered(false);
    }
  };

  const handleOnSetores = (value) => {
    const funcionarios = getFuncionarios();
    let filteredFuncionarios = funcionarios.filter((funcionario) => {
      if (funcionario.setor === value) {
        return true;
      } else {
        return false;
      }
    });

    if (filteredFuncionarios.length > 0) {
      setFuncionarios(filteredFuncionarios);
      setIsFiltered(true);
    }
    if (value === undefined) {
      const funcionarios = getFuncionarios();
      setFuncionarios(funcionarios);
      setIsFiltered(false);
    }
  };

  const handleOnTipo = (value) => {
    const funcionarios = getFuncionarios();
    if (value === undefined) {
      const funcionarios = getFuncionarios();
      setFuncionarios(funcionarios);
      setIsFiltered(false);
      return value;
    }
    let filteredFuncionarios = funcionarios.filter((funcionario) => {
      let tipo = funcionario.tipo;
      let valuesDicitionaty = {
        Estagiário: "ESTAGIARIO",
        Trabalhador: "TRABALHADOR",
        "Menor aprendiz": "MENOR_APRENDIZ",
        Outro: "OUTRO",
      };
      if (tipo === valuesDicitionaty[value]) {
        return true;
      } else {
        return false;
      }
    });
    if (filteredFuncionarios.length > 0) {
      setFuncionarios(filteredFuncionarios);
      setIsFiltered(true);
    } else {
      setFuncionarios([]);
      setIsFiltered(true);
    }
  };

  const filterList = (event) => {
    const value = event.target.value;
    if (value.length > 2) {
      let filteredFuncionarios = funcionarios.filter((funcionario) => {
        return (
          funcionario.nome.toLowerCase().indexOf(value.toLowerCase()) !== -1
        );
      });
      setFuncionarios(filteredFuncionarios);
      setIsFiltered(true);
    } else {
      setFuncionarios(Object.values(props.funcionarios).flat());
      setIsFiltered(false);
    }
  };

  const editClick = (id, viewOnly) => {
    props.selectFuncionario(id);
    props.tab();
    props.changeModo(viewOnly);
  };

  const showFilters = () => {
    const size = funcionarios.length;
    let show = false;
    if (isFiltered || size >= 5) {
      show = true;
    } else {
      show = false;
    }
    return show;
  };

  const emptyText = `Nenhum funcionário adicionado.`;

  let nomeCargos = [];
  if (funcionarios) {
    nomeCargos = funcionarios.map((funcionario) => funcionario.cargo);
    nomeCargos = [...new Set(nomeCargos)]; // remove duplicates
  }

  let nomesSetores = [];
  if (props.setores) {
    nomesSetores = props.setores
      .filter((setor) => {
        return setor.cargos.some((cargo) => {
          return nomeCargos.includes(cargo);
        });
      })
      .map((setor) => setor.nome);
  }

  const menu = (funcionario) =>
    funcionario.ativo === 1 ? (
      <Menu>
        <Menu.Item key="3" onClick={() => editClick(funcionario.id, false)}>
          {" "}
          <IconSESI type="editItem" /> Editar
        </Menu.Item>
        {/* <Menu.Item
          className="ant-dropdown-menu-item"
          key="4"
          onClick={() => handleClick(funcionario, 0, 2)}
        >
          {" "}
          {<IconSESI type="inactive" />} Tornar Inativo por Aposentadoria
        </Menu.Item>
        <Menu.Item
          className="ant-dropdown-menu-item"
          key="5"
          onClick={() => handleClick(funcionario, 0, 3)}
        >
          {" "}
          {<IconSESI type="inactive" />} Tornar Inativo por Afastamento
        </Menu.Item>
        <Menu.Item
          className="ant-dropdown-menu-item"
          key="6"
          onClick={() => {
            handleClick(funcionario, 0, 4);
            showModalTrabDesativado();
          }}
        >
          {" "}
          {<IconSESI type="inactive" />} Tornar Inativo por Desligamento
        </Menu.Item> */}
      </Menu>
    ) : (
      <Menu>
        <Menu.Item key="3" onClick={() => editClick(funcionario.id, false)}>
          {" "}
          <IconSESI type="editItem" /> Editar
        </Menu.Item>
        {/* <Menu.Item key="2" onClick={() => handleClick(funcionario, 1, 1)}>
          {" "}
          {<IconSESI type="active" />} Tornar ativo
        </Menu.Item> */}
      </Menu>
    );

  return (
    <>
      {showFilters() && (
        <SearchBar
          filterList={filterList}
          handleOnCargos={handleOnCargo}
          handleOnSetores={handleOnSetores}
          handleOnTipo={handleOnTipo}
          dataSelect={props.funcionarios}
          cargos={nomeCargos}
          setores={nomesSetores}
          tipo={["Trabalhador", "Menor aprendiz", "Estagiário", "Outro"]}
          name={"trabalhadores"}
          selectSetorName={"setor"}
          selectCargoName={"cargo"}
          selectTipoName={"tipo"}
        />
      )}
      <List
        itemLayout="horizontal"
        className="funcionarios-list"
        locale={{ emptyText: emptyText }}
        footer={
          <Row type="flex" justify="space-around" align="middle">
            <Col span={12}>
              <p>
                Total:{" "}
                <b>
                  {funcionarios.filter((f) => f.tipo === "TRABALHADOR").length}
                </b>{" "}
                {funcionarios.filter((f) => f.tipo === "TRABALHADOR").length ===
                1
                  ? "trabalhador"
                  : "trabalhadores"}{" "}
                ,{" "}
                <b>
                  {
                    funcionarios.filter((f) => f.tipo === "MENOR_APRENDIZ")
                      .length
                  }
                </b>{" "}
                {funcionarios.filter((f) => f.tipo === "MENOR_APRENDIZ")
                  .length === 1
                  ? "menor aprendiz"
                  : "menores aprendizes"}{" "}
                e{" "}
                <b>
                  {funcionarios.filter((f) => f.tipo === "ESTAGIARIO").length}
                </b>{" "}
                {funcionarios.filter((f) => f.tipo === "ESTAGIARIO").length ===
                1
                  ? "estagiário(a)"
                  : "estagiários"}
                .
              </p>
            </Col>
            {isPerfilCliente(props.perfil) ? (
              <Col span={12}>
                <FinishStep
                  completed={props.stepsEmpresa.FUNCIONARIOS}
                  step={FUNCIONARIOS}
                  perfil={props.perfil}
                  url="funcionarios"
                  disabled={
                    props.statusAnaliseTecnica === "PENDENTE" ||
                    props.statusAnaliseTecnica === "EM_ANALISE" ||
                    props.statusCaracterizacao === "PPRA_GERADO"
                  }
                />
              </Col>
            ) : (
              <Col span={12}></Col>
            )}
          </Row>
        }
      >
        {funcionarios.map((funcionario, k) => {
          let setorFuncionario = funcionario.setor;
          return (
            <List.Item key={k}>
              <List.Item.Meta
                onClick={() => editClick(funcionario.id, viewOnly)}
                className={funcionario.ativo === 1 ? "ativo" : "inativo"}
                title={funcionario.nome}
                description={
                  <div className="tag-list">
                    {funcionario.setor === 0 ? (
                      <div className="ant-tag pendente">
                        <ExclamationCircleOutlined />
                        <span> Sem setores vinculados</span>
                      </div>
                    ) : (
                      "Setor: "
                    )}
                    <Tag> {setorFuncionario}</Tag>
                    <br />
                    Tipo:{" "}
                    <Tag>
                      {funcionario.tipo !== "OUTRO"
                        ? funcionario.tipo.replace(/_/g, " ")
                        : funcionario.tipoEspecifico}
                    </Tag>
                    <br />
                    {funcionario.cargo === 0 ? (
                      <div className="ant-tag pendente">
                        <ExclamationCircleOutlined />
                        <span> Sem cargo vinculado</span>
                      </div>
                    ) : (
                      "Cargo: "
                    )}
                    <Tag> {funcionario.cargo}</Tag>
                  </div>
                }
              />
              {!viewOnly ? (
                <Dropdown overlay={menu(funcionario)} trigger={["click"]}>
                  <Button type="link" ghost className="action-button">
                    <EllipsisOutlined />
                  </Button>
                </Dropdown>
              ) : null}
            </List.Item>
          );
        })}
      </List>
    </>
  );
};

const mapStateProps = (state) => {
  return {
    stepsEmpresa: state.caracterizacao.stepsEmpresa.stepsEmpresa,
  };
};

export default connect(mapStateProps)(FuncionarioList);
