import { SELECT_COMPANY } from "./types";
import axios from "axios";
import { empresasUrl } from "../../../../../../../constants";
import { setupInterceptors } from "../../../../../../../auth/SetupInterceptors";
import { showModalEmpresaNaoEfetivada } from "../../../../../../../components/modals";

setupInterceptors();
export function selectCompany(dispatch, company) {
  axios
    .post(`${empresasUrl}/${company.cnpj}`)
    .then(() => {
      axios
        .get(empresasUrl)
        .then((response) => {
          dispatch({ type: SELECT_COMPANY, payload: response.data });
        })
        .catch((err) => {
          console.log(err);
        });
    })
    .catch((err) => {
      if (err.response.data.message) {
        showModalEmpresaNaoEfetivada();
      }
    });
}
