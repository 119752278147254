import React, { useState } from "react";
import { Input, Form, Row, Col, Tag } from "antd";

const { CheckableTag } = Tag;

const SearchBar = (props) => {
  const { filterList, name, lots, filterLots } = props;
  const [selectedTags, setSelectedTags] = useState([]);

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
    filterLots(nextSelectedTags);
  };

  return (
    <Form className="cnae-search-container">
      <Row gutter={24}>
        <Col lg={24} md={12}>
          <Form.Item>
            <Input
              placeholder={`Pesquisar por ${name}`}
              onChange={filterList}
            />
          </Form.Item>
        </Col>
        <Col lg={6} md={12}>
          <Form.Item>
            {lots ? (
              <div>
                <span>Filtrar por lote:</span>
                {lots.map((lot) => (
                  <CheckableTag
                    key={`${lot}`}
                    checked={selectedTags.indexOf(lot) > -1}
                    onChange={(checked) => handleChange(lot, checked)}
                  >
                    <span>{`Lote ${lot}`}</span>
                  </CheckableTag>
                ))}
              </div>
            ) : null}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchBar;
