import {
  LISTAR_FUNCIONARIOS,
  LISTAR_FUNCIONARIOS_ERRO,
  CRIAR_FUNCIONARIO,
  CRIAR_FUNCIONARIO_ERRO,
  ATIVAR_DESATIVAR_FUNCIONARIO,
  EDITAR_FUNCIONARIO,
  OBTER_FUNCIONARIO,
  LISTAR_CARGOS,
  LISTAR_FUNCIONARIOS_COM_EPI
} from "../actions/types";

export const caracterizacaoFuncionariosClienteReducer = (
  state = {
    funcionarios: [],
    erro: null,
    cargos: [],
    catalogoEpis: [],
    funcionariosComEpi: []
  },
  action
) => {
  switch (action.type) {
    case LISTAR_FUNCIONARIOS:
      return {
        ...state,
        funcionarios: action.payload
      };
    case LISTAR_FUNCIONARIOS_COM_EPI:
      return {
        ...state,
        funcionariosComEpi: action.payload
      };
    case LISTAR_FUNCIONARIOS_ERRO:
      return {
        ...state,
        erro: action.erro
      };
    case CRIAR_FUNCIONARIO:
      return {
        ...state,
        funcionarios: [...state.funcionarios, action.payload]
      };
    case CRIAR_FUNCIONARIO_ERRO:
      return {
        ...state,
        erro: action.erro
      };
    case ATIVAR_DESATIVAR_FUNCIONARIO:
      return {
        ...state,
        funcionarios: helper(state, action, false)
      };
    case EDITAR_FUNCIONARIO:
      return {
        ...state,
        funcionarios: helper(state, action, true)
      };
    case OBTER_FUNCIONARIO:
      return {
        ...state,
        funcionario: action.payload
      };
    case LISTAR_CARGOS:
      return {
        ...state,
        cargos: action.payload.cargosPorSetor,
        catalogoEpis: action.payload.catalogoEpis,
        atividadesPorSetor: action.payload.atividadesPorSetor
      };
    default:
      return state;
  }
};

function helper(state, action, mapCargo) {
  const itemNovo = action.payload;
  if (mapCargo) {
    state.cargos.forEach(setor => {
      const cargo = setor.cargos.find(cargo => cargo.id === itemNovo.idCargo);
      if (cargo) {
        itemNovo.cargo = cargo.descricao;
      }
    });
  }
  return state.funcionarios.map(function(item) {
    return item.id === action.payload.id ? itemNovo : item;
  });
}
