import { SELECT_COMPANY } from "../actions/types";

export const selectCompanyRedux = (state = {}, action) => {
  switch (action.type) {
    case SELECT_COMPANY:
      const company = action.payload;
      return company;
    default:
      return state;
  }
};
