import axios from "axios";
import {
  empresasAtivasComPGREmitidoUrl,
  gestorDRDashboard,
} from "../../../../../../constants";
import { DASHBOARD, RELATORIO_STATUS } from "./types";

export const getGestorDRDashboad = (dispatch) => {
  axios
    .get(gestorDRDashboard)
    .then((response) => {
      dispatch({
        type: DASHBOARD,
        payload: response.data,
      });
    })
    .catch((err) => console.error(err));
};

export const getGestorDRRelatorioStatus = (dispatch) => {
  axios
    .get(empresasAtivasComPGREmitidoUrl)
    .then((response) => {
      dispatch({
        type: RELATORIO_STATUS,
        payload: response.data,
      });
    })
    .catch((err) => console.error(err));
};
