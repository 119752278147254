import React, { useState, useEffect } from "react";
import { List, Tooltip } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DropdownActionMenu from "../../../../../../../components/DropdownActionMenu";
import { PROCESSOS_PRODUTIVOS } from "../../../../../../../constants/stepTypes";
import SearchBar from "./SearchBar";
import moment from "moment";
import "moment/locale/pt-br";

import { ExclamationCircleOutlined } from "@ant-design/icons";

const ProcessoProdutivoAcceptedList = (props) => {
  const [isFiltered, setIsFiltered] = useState(false);
  const [processos, setProcessos] = useState([]);

  useEffect(() => {
    setProcessos(props.processos);
  }, [JSON.stringify(props.processos)]);

  const showFilters = () => {
    const size = props.processos.length;
    let show = false;
    if (isFiltered || size >= 5) {
      show = true;
    } else {
      show = false;
    }
    return show;
  };

  const filterList = (event) => {
    const value = event.target.value;
    if (value.length > 2) {
      let filteredProcessos = processos.filter((processo) => {
        return processo.nome.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      });
      setProcessos(filteredProcessos);
      setIsFiltered(true);
    } else {
      setProcessos(Object.values(props.processos).flat());
      setIsFiltered(false);
    }
  };
  return (
    <React.Fragment>
      {showFilters() && (
        <SearchBar filterList={filterList} name={"processos"} />
      )}
      <List
        dataSource={processos}
        className="productive-processes-list"
        renderItem={(item) => (
          <List.Item
            key={item.idProcessoProdutivoDaEmpresa}
            className={
              item.dataFim
                ? moment(item.dataFim) <= new Date()
                  ? "disabled"
                  : "future-disabled"
                : ""
            }
          >
            {item.dataFim && moment(item.dataFim) >= new Date() ? (
              <Tooltip
                title={`Esse processo terminará em ${moment(
                  item.dataFim
                ).format("DD/MM/YYYY")}`}
              >
                <ExclamationCircleOutlined />
              </Tooltip>
            ) : null}
            <List.Item.Meta title={item.nome} />
            {!props.completed ? (
              <DropdownActionMenu
                dispatch={props.dispatch}
                entidade={PROCESSOS_PRODUTIVOS}
                item={item}
                edit={() =>
                  props.edit(item.id || item.idProcessoProdutivoDaEmpresa)
                }
                // remove={() => props.remove(item.id)}
              />
            ) : null}
          </List.Item>
        )}
      ></List>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({}, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcessoProdutivoAcceptedList);
