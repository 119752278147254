export const AllConstants = () => {
  return {
    agentesNocivosComDescricao,
    tableUnityMeasurement,
    chemicalMeasurements,
    ufsComplete,
    harmfulAgentsAbsence,
    courtDecisionObject,
    labelsAndTooltips2240,
  };
};

const agentesNocivosComDescricao = [
  "01.01.001",
  "01.02.001",
  "01.03.001",
  "01.04.001",
  "01.05.001",
  "01.06.001",
  "01.07.001",
  "01.08.001",
  "01.09.001",
  "01.10.001",
  "01.12.001",
  "01.13.001",
  "01.14.001",
  "01.15.001",
  "01.16.001",
  "01.17.001",
  "01.18.001",
  "05.01.001",
];

const tableUnityMeasurement = [
  {
    nociveCodes: ["05.01.001"],
    measurementOptions: [
      {
        id: 1,
        name: "1 - dose diária de ruído",
        value: "DOSE_DIARIA_RUIDO",
      },
      {
        id: 2,
        name: "2 - dB (decibel linear)",
        value: "DECIBEL_LINEAR",
      },
      {
        id: 3,
        name: "3 - dB(C) (decibel C)",
        value: "DECIBEL_C",
      },
      {
        id: 4,
        name: "4 - dB(A) (decibel A)",
        value: "DECIBEL_A",
      },
      {
        id: 5,
        name: "5 - m/s² (metro por segundo ao quadrado)",
        value: "M_S2",
      },
      {
        id: 6,
        name: "6 - m/s¹⋅⁷⁵ (metro por segundo elevado a 1,75)",
        value: "M_S175",
      },
      {
        id: 7,
        name: "7 - ppm (parte de vapor ou gás por milhão de partes de ar contaminado)",
        value: "PPM",
      },
      {
        id: 8,
        name: "8 - mg/m³ (miligrama por metro cúbico de ar)",
        value: "MG_M3",
      },
      {
        id: 9,
        name: "9 - f/cm³ (fibra por centímetro cúbico)",
        value: "F_CM3",
      },
      {
        id: 10,
        name: "10 - ºC (grau Celsius)",
        value: "GRAUS_C",
      },
      {
        id: 11,
        name: "14 - unidade formadora de colônias por metro cúbico (ufc/m3)",
        value: "UFC_M3",
      },
      {
        id: 12,
        name: "28 - mSv (milisievert)",
        value: "MILISIEVERT",
      },
      {
        id: 13,
        name: "29 - mppdc (milhão de partículas por decímetro cúbico)",
        value: "MPPDC",
      },
    ],
  },
  {
    nociveCodes: ["02.01.001"],
    measurementOptions: [
      {
        id: 1,
        name: "1 - dose diária de ruído",
        value: "DOSE_DIARIA_RUIDO",
      },
      {
        id: 2,
        name: "2 - dB (decibel linear)",
        value: "DECIBEL_LINEAR",
      },
      {
        id: 3,
        name: "3 - dB(C) (decibel C)",
        value: "DECIBEL_C",
      },
      {
        id: 4,
        name: "4 - dB(A) (decibel A)",
        value: "DECIBEL_A",
      },
    ],
  },
  {
    nociveCodes: ["02.01.002", "02.01.003"],
    measurementOptions: [
      {
        id: 1,
        name: "5 - m/s² (metro por segundo ao quadrado)",
        value: "M_S2",
      },
    ],
  },
  {
    nociveCodes: ["02.01.004"],
    measurementOptions: [
      {
        id: 1,
        name: "6 - m/s¹⋅⁷⁵ (metro por segundo elevado a 1,75)",
        value: "M_S175",
      },
    ],
  },
  {
    nociveCodes: ["02.01.014"],
    measurementOptions: [
      {
        id: 1,
        name: "10 - ºC (grau Celsius)",
        value: "GRAUS_C",
      },
    ],
  },
  {
    nociveCodes: [
      "03.01.001",
      "03.01.002",
      "03.01.003",
      "03.01.004",
      "03.01.005",
      "03.01.006",
      "03.01.007",
    ],
    measurementOptions: [
      {
        id: 1,
        name: "14 - unidade formadora de colônias por metro cúbico (ufc/m3)",
        value: "UFC_M3",
      },
    ],
  },
  {
    nociveCodes: ["02.01.006"],
    measurementOptions: [
      {
        id: 1,
        name: "28 - mSv (milisievert)",
        value: "MILISIEVERT",
      },
    ],
  },
];

const chemicalMeasurements = [
  {
    id: 1,
    name: "7 - ppm (parte de vapor ou gás por milhão de partes de ar contaminado)",
    value: "PPM",
  },
  {
    id: 2,
    name: "8 - mg/m³ (miligrama por metro cúbico de ar)",
    value: "MG_M3",
  },
  {
    id: 3,
    name: "9 - f/cm³ (fibra por centímetro cúbico)",
    value: "F_CM3",
  },
  {
    id: 4,
    name: "29 - mppdc (milhão de partículas por decímetro cúbico)",
    value: "MPPDC",
  },
];

const ufsComplete = [
  { id: 1, name: "Acre", value: "AC" },
  { id: 2, name: "Alagoas", value: "AL" },
  { id: 3, name: "Amapá", value: "AP" },
  { id: 4, name: "Amazonas", value: "AM" },
  { id: 5, name: "Bahia", value: "BA" },
  { id: 6, name: "Ceará", value: "CE" },
  { id: 7, name: "Distrito Federal", value: "DF" },
  { id: 8, name: "Espírito Santo", value: "ES" },
  { id: 9, name: "Goiás", value: "GO" },
  { id: 10, name: "Maranhão", value: "MA" },
  { id: 11, name: "Mato Grosso", value: "MT" },
  { id: 12, name: "Mato Grosso do Sul", value: "MS" },
  { id: 13, name: "Minas Gerais", value: "MG" },
  { id: 14, name: "Pará", value: "PA" },
  { id: 15, name: "Paraíba", value: "PB" },
  { id: 16, name: "Paraná", value: "PR" },
  { id: 17, name: "Pernambuco", value: "PE" },
  { id: 18, name: "Piauí", value: "PI" },
  { id: 19, name: "Rio de Janeiro", value: "RJ" },
  { id: 20, name: "Rio Grande do Norte", value: "RN" },
  { id: 21, name: "Rio Grande do Sul", value: "RS" },
  { id: 22, name: "Rondônia", value: "RO" },
  { id: 23, name: "Roraima", value: "RR" },
  { id: 24, name: "Santa Catarina", value: "SC" },
  { id: 25, name: "São Paulo", value: "SP" },
  { id: 26, name: "Sergipe", value: "SE" },
  { id: 27, name: "Tocantins", value: "TO" },
];

const harmfulAgentsAbsence = {
  codigoTabela24: "09.01.001",
  idFatorDeRisco: 0,
  descricao:
    "Ausência de agente nocivo ou de atividades previstas no Anexo IV do Decreto 3.048/1999",
};

const courtDecisionObject = {
  codigoTabela24: "05.01.001",
  idFatorDeRisco: 732,
  descricao:
    "Agentes nocivos não constantes no Anexo IV do Decreto 3.048/1999 e incluídos por força de decisão judicial ou administrativa",
};

const labelsAndTooltips2240 = {
  codAgNoc: {
    label: "Agente(s) nocivo(s) ao(s) qual(is) o trabalhador está exposto:",
    tooltip:
      "Se o PGR ou a DIR foram elaborados no Facilita, aqui serão listados os agentes nocivos relacionados à tabela 24 do eSocial ou ausência de exposição, se for o caso. Selecione um agente nocivo por vez e adicione as informações complementares para cada um. Caso não tenha PGR ou DIR, será exibida a lista completa de agentes nocivos da tabela 24",
  },
  tpAval: {
    label: "Tipo de avaliação do agente nocivo:",
    tooltip:
      "Caso não haja medição, informar qualitativo. Caso tenha realizado medição, quantitativo.",
  },
  nrProcJud: {
    label: "Número do Processo Judicial:",
    tooltip:
      "Este campo deve ser preenchido apenas com números e deve conter 17, 20 ou 21 dígitos.",
  },
  limTol: {
    label: "Limite de tolerância calculado para agentes específicos:",
    tooltip:
      "Dado automático advindo do inventário de riscos do PGR, ou lançado a partir do Laudo Técnico das Condições do Ambiente de Trabalho (LTCAT).",
  },
  dscAgNoc: {
    label: "Descrição do agente nocivo:",
    tooltip:
      "Para alguns agentes este campo é obrigatório. Descreva o agente para estes casos.",
  },
  intConc: {
    label: "Resultado ou valor da medição:",
    tooltip:
      "Insira aqui o resultado da medição do agente nocivo. Se o campo estiver preenchido, refere-se ao dado inserido previamente no PGR. Se estiver vazio, procure esta informação nos programas e laudos de SST da empresa.",
  },
  unMed: {
    label: "Unidade de medida da medição:",
    tooltip:
      "Selecione a unidade de medida referente ao resultado da medição do agente nocivo. Se o campo estiver preenchido, refere-se ao dado inserido previamente no PGR. Se estiver vazio, procure esta informação nos programas e laudos de SST da empresa.",
  },
  tecMedicao: {
    label: "Técnica ou metodologia utilizada na medição:",
    tooltip:
      "Digite a técnica de medição ou metodologia usada para obtenção do resultado da medição do agente nocivo. Procure esta informação nos programas e laudos de SST da empresa.",
  },
  utilizEPC: {
    label:
      "O empregador implementa medidas de proteção coletiva (EPC) para eliminar ou reduzir a exposição dos trabalhadores ao agente nocivo?",
  },
  eficEpc: {
    label: "Os EPCs são eficazes na neutralização do risco ao trabalhador?",
  },
  utilizEPI: {
    label: "Utilização de EPI:",
  },
  eficEpi: {
    label: "Os EPIs são eficazes na neutralização do risco ao trabalhador?",
  },
  docAval: {
    label: "Certificado de Aprovação - CA ou documento de avaliação do EPI:",
  },
  certificate: {
    tooltip:
      "Todo EPI deverá apresentar em caracteres indeléveis e bem visíveis o número do Certificado de Aprovação (CA). Além de que, o fornecedor deve indicar tal número no processo de aquisição.",
  },
  requisitos06: {
    header:
      "Requisitos da Norma Regulamentadora 06 - NR-06 e da Norma Regulamentadora 09 - NR-09 pelo(s) EPI(s) informado(s).",
  },
  medProtecao: {
    label:
      "Foi tentada a implementação de medidas de proteção coletiva, de caráter administrativo ou de organização, optando-se pelo EPI por inviabilidade técnica, insuficiência ou interinidade, ou ainda em caráter complementar ou emergencial?",
  },
  condFuncto: {
    label:
      "Foram observadas as condições de funcionamento do EPI ao longo do tempo, conforme especificação técnica do fabricante nacional ou importador, ajustadas às condições de campo?",
  },
  usoInint: {
    label:
      "Foi observado o uso ininterrupto do EPI ao longo do tempo, conforme especificação técnica do fabricante nacional ou importador, ajustadas às condições de campo?",
  },
  przValid: {
    label:
      "Foi observado o prazo de validade do CA no momento da compra do EPI?",
  },
  periodicTroca: {
    label:
      "É observada a periodicidade de troca definida pelo fabricante nacional ou importador e/ou programas ambientais, comprovada mediante recibo assinado pelo usuário em época própria?",
  },
  higienizacao: {
    label:
      "É observada a higienização conforme orientação do fabricante nacional ou importador?",
  },
  deleteButton: {
    tooltip: "Clique aqui, para excluir este agente nocivo cadastrado.",
  },
};

//Follow Events
export const formatEventType = (eventType) => {
  switch (eventType) {
    case "S-2210":
      return "s2210";
    case "S-2220":
      return "s2220";
    case "S-2240":
      return "s2240";
  }
};

export const disableEventAction = (name, status) => {
  let list;
  switch (name) {
    case "eSocialError":
      list = ["EXCLUIDO", "PROCESSANDO", "ENVIADO", "RETIFICADO", "RECEBIDO"];
      break;
    case "edit":
      list = ["ENVIADO", "EXCLUIDO", "RETIFICADO", "RECEBIDO"];
      break;
    case "rectification":
      list = ["EXCLUIDO"];
    default:
      list = ["EXCLUIDO", "PROCESSANDO", "INCONSISTENCIA", "ENVIADO"];
      break;
  }

  return list.includes(status);
};
