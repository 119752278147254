import Axios from "axios";
import {
  alterarTagsCNAESAutomatizados,
  exportExcelCnaes,
  listarCNAEsAutomatizados,
  listarCNAEsFaltantes,
  listarCNAEsNaoAutomatizados,
  salvarCNAEFaltante,
} from "../../../../../../../constants";
import {
  ADICIONAR_CNAE_FALTANTE,
  ALTERAR_TAGS,
  DELETAR_CNAE_FALTANTE,
  LISTAR_CNAES,
  LISTAR_CNAES_FALTANTES,
  LISTAR_CNAES_NAO_AUTOMATIZADOS,
} from "./types";
import openNotification from "../../../../../../../components/notifications";

export const getCurrentCNAEs = async (dispatch) => {
  let response = await Axios.get(listarCNAEsAutomatizados)
    .then((response) => {
      dispatch({
        type: LISTAR_CNAES,
        payload: response.data,
      });
      return response.data;
    })
    .catch(() => {
      openNotification(
        "Erro ao obter os CNAEs automatizados!",
        "Tente novamente",
        "error"
      );
      return [];
    });

  return response;
};

export const getNotAutomatedCNAEs = async (dispatch) => {
  let response = await Axios.get(listarCNAEsNaoAutomatizados)
    .then((response) => {
      dispatch({
        type: LISTAR_CNAES_NAO_AUTOMATIZADOS,
        payload: response.data,
      });
      return response.data;
    })
    .catch(() => {
      openNotification(
        "Erro ao obter os CNAEs não automatizados!",
        "Tente novamente",
        "error"
      );
      return [];
    });

  return response;
};

export const getMissingCNAEs = async (dispatch) => {
  let response = await Axios.get(listarCNAEsFaltantes)
    .then((response) => {
      dispatch({
        type: LISTAR_CNAES_FALTANTES,
        payload: response.data,
      });
      return response.data;
    })
    .catch(() => {
      openNotification(
        "Erro ao obter os CNAEs faltantes!",
        "Tente novamente",
        "error"
      );
      return [];
    });

  return response;
};

export const changeTags = (dispatch, cnae, tags) => {
  Axios.patch(alterarTagsCNAESAutomatizados(cnae), { tags: tags })
    .then((response) => {
      dispatch({
        type: ALTERAR_TAGS,
        payload: response.data,
      });
    })
    .catch(() => {
      openNotification("Erro ao modificar tags!", "Tente novamente", "error");
    });
};

export const saveMissingCNAE = (dispatch, cnae, callback) => {
  Axios.post(salvarCNAEFaltante, {
    cnae: cnae.codigo,
    descricao: cnae.nome,
    lote: cnae.lote,
    tags: [],
  })
    .then((response) => {
      dispatch({
        type: ADICIONAR_CNAE_FALTANTE,
        payload: response.data,
      });
      openNotification("CNAE faltante adicionado com sucesso!", "", "success");
      callback(false);
    })
    .catch(() => {
      openNotification(
        "Erro ao adicionar CNAE faltante!",
        "Tente novamente",
        "error"
      );
    });
};

export const deleteMissingCNAE = (dispatch, id) => {
  Axios.delete(`${salvarCNAEFaltante}/${id}`)
    .then(() => {
      dispatch({
        type: DELETAR_CNAE_FALTANTE,
        payload: id,
      });
      openNotification("CNAE faltante removido com sucesso!", "", "success");
    })
    .catch(() => {
      openNotification(
        "Erro ao deletar CNAE faltante!",
        "Tente novamente",
        "error"
      );
    });
};

export const downloadCnaesExcel = async () => {
  return await Axios.get(exportExcelCnaes, { responseType: "blob" })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Planilha_CNAEs_Automatizados.xls`);
      document.body.appendChild(link);
      link.click();

      return res;
    })
    .catch((err) => {
      return [];
    });
};
