export const INICIO = "Início";
export const CARACTERIZACAO = "Caracterização";
export const CARACTERIZACAOKEYS = {
  "0-0": 1,
  "0-1-0": 2,
  "0-1-1": 3,
  "0-2-0": 4,
  "0-2-1": 5,
  "0-3-0": 6,
  "0-3-1": 7,
  "0-4": 8,
};
export const ACOMPANHAMENTO = "Acompanhamento";
export const AUTODECLARACAO = "Autodeclaração";
