import React from "react";
import { Card, Col, Tag } from "antd";
import "./style.scss";
import IconSESI from "../../../../../../../IconsSESI";

const statusEnum = Object.freeze({
  1: "Em preenchimento",
  2: "Classificação de riscos",
  3: "Plano de ação",
  4: "Gerar PGR",
});

const CompanyCard = (props) => {
  const { company } = props;

  const getCompanyClass = (company) => {
    if (!props.selectedCompany) return "company";
    return company.cnpj === props.selectedCompany.cnpj
      ? "company selected"
      : "company";
  };
  return (
    <Col className="company-card" span={24}>
      <Card
        bordered={false}
        key={company.cnpj}
        className={getCompanyClass(company)}
        onClick={() => {
          props.selectCompanyAction(props.dispatch, company);
        }}
      >
        <span>Nome Fantasia</span>
        <h4>
          {company.premium && <IconSESI type="premium" fill="var(--alto)" />}
          {company.nomeFantasia}
        </h4>
        <Col>
          <div className="company-info">
            <span>CNPJ:</span>
            <h5>
              {company.cnpj &&
                company.cnpj.replace(
                  /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                  "$1.$2.$3/$4-$5"
                )}
            </h5>
          </div>
          <div className="company-info">
            <span>Razão Social:</span>
            <h5 className="responsivo-hidden">{company.razaoSocial} </h5>
          </div>
        </Col>
        <Tag>{statusEnum[company.statusFluxo]}</Tag>
      </Card>
    </Col>
  );
};

export default CompanyCard;
