import React from "react";
import { connect } from "react-redux";
import ESocialBanner from "../components/ESocialBanner";
import ESocialDashboard from "../components/ESocialDashboard";

const ESocialContainer = (props) => {
  const premiumCompany = props.selectedCompany.premium;
  const userHasPremiumCompany = props.companies.some(
    (company) => company.premium
  );
  return premiumCompany ? (
    <ESocialDashboard {...props} selectedCompany={props.selectedCompany} />
  ) : (
    <ESocialBanner />
  );
};

const mapStateToProps = (state) => {
  return {
    companies: state.user.companies,
    selectedCompany: state.caracterizacao.companySelected,
  };
};

export default connect(mapStateToProps, {})(ESocialContainer);
