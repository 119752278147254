import {
  Col,
  Input,
  Row,
  Form,
  Button,
  DatePicker,
  ConfigProvider,
} from "antd";
import React, { useEffect, useState } from "react";
import locale from "antd/lib/locale/pt_BR";
import moment from "moment";
// import Assistant from "../../../../components/VirtualAssistant";
// import { talksPlanoAcao } from "../../../../constants/aroldoTalks";

const ActionForm = (props) => {
  const [loading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.mode === "new") {
      form.resetFields();
    } else if (props.mode === "edit") {
      const { descricao, responsavel, previsaoInicio, previsaoFim } =
        props.selectedAction;
      form.setFieldsValue({
        acao: descricao,
        responsavel: responsavel,
        previsaoInicio: moment(previsaoInicio),
        previsaoFim: moment(previsaoFim),
      });
    }
  });

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (props.mode === "new") {
          console.log(values);
          const newPlannedAction = {
            descricao: values.acao,
            idFatorDeRisco: props.fatorDeRiscoId,
            responsavel: values.responsavel,
            previsaoInicio: moment(values.previsaoInicio).format("YYYY-MM-DD"),
            previsaoFim: moment(values.previsaoFim).format("YYYY-MM-DD"),
          };
          if (props.acceptParam.description) {
            newPlannedAction.acaoDoCatalogo = props.acceptParam.id;
          }
          props.addAction(
            props.dispatch,
            newPlannedAction,
            props.handleSwitchTab,
            props.loading
          );
          props.handleTalk(1);
        } else {
          const edittedAction = {
            descricao: values.acao,
            responsavel: values.responsavel,
            previsaoInicio: moment(values.previsaoInicio).format("YYYY-MM-DD"),
            previsaoFim: moment(values.previsaoFim).format("YYYY-MM-DD"),
          };
          props.editAction(
            props.dispatch,
            props.selectedAction.id,
            edittedAction,
            props.handleSwitchTab,
            props.loading
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const suggestedActionTooltip =
    "Esta foi uma ação sugerida por nós, após aceita-lá, você poderá editar somente o Responsável e o Prazo Previsto ";

  return (
    <Row gutter={32} justify="center">
      <Col span={24}>
        <div className="action-form">
          <ConfigProvider locale={locale}>
            <Form name="actionForm" form={form} onFinish={handleSubmit}>
              <div className="action-form-content">
                <div className="action-form-inputs">
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item
                        name="acao"
                        label="Ação"
                        tooltip={
                          props.acceptParam.description
                            ? suggestedActionTooltip
                            : null
                        }
                      >
                        <Input
                          defaultValue={
                            props.acceptParam.description
                              ? props.acceptParam.description
                              : ""
                          }
                          disabled={props.acceptParam.description}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        name="responsavel"
                        label="Responsável"
                        initialValue={props.responsavelEmpresa}
                      >
                        <Input defaultValue={props.responsavelEmpresa} />
                      </Form.Item>
                    </Col>
                    <Col span={12} className="dates-super-div">
                      <Form.Item name="prazoPrevisto" label="Prazo Previsto">
                        <div className="dates-div">
                          <span>Início:</span>
                          <Form.Item name="previsaoInicio">
                            <DatePicker
                              format="DD/MM/YYYY"
                              placeholder="___________________"
                              bordered={false}
                              suffixIcon={false}
                              locale={locale}
                            />
                          </Form.Item>
                          <span>Fim:</span>
                          <Form.Item name="previsaoFim">
                            <DatePicker
                              format="DD/MM/YYYY"
                              placeholder="___________________"
                              bordered={false}
                              suffixIcon={false}
                              locale={locale}
                            />
                          </Form.Item>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="action-form-save-button">
                  <Row gutter={24}>
                    <Col span={24}>
                      <Button loading={loading} htmlType="submit">
                        SALVAR
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Form>
          </ConfigProvider>
        </div>
      </Col>
    </Row>
  );
};

export default ActionForm;
