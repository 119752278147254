import { FETCH_LISTADEINFORMACOES } from "../actions/types";

export const listaDeInformacoesReducer = (
  state = {
    listaDeInformacoes: {
      listaDeInformacoesDisponiveis: [],
      itensMarcadosDaEmpresa: []
    }
  },
  action
) => {
  switch (action.type) {
    case FETCH_LISTADEINFORMACOES:
      return action.payload;
    default:
      return state;
  }
};
