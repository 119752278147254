import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { emptyEPISelecionado, getEPIFromList, getEPIs } from "../../actions";
import "../../../styles.scss";

const CadastroEPIList = (props) => {
  const [epis, setEpis] = useState([]);

  const onLoadScreen = async () => {
    let epiList = await getEPIs();

    setEpis(epiList);
  };

  useEffect(() => {
    onLoadScreen();
  }, []);

  const handleEdit = (id) => {
    getEPIFromList(id, props.dispatch);
  };

  return (
    <React.Fragment>
      <div>
        <Link to="/form-epi">
          <Button onClick={() => emptyEPISelecionado(props.dispatch)}>
            Cadastrar EPI
          </Button>
        </Link>
      </div>
      <div className="dn-content">
        {epis.map((epi) => (
          <div className="epi-item" key={epi.id}>
            <p>Código: {epi.id} </p>
            <p>EPI: {epi.nome}</p>
            <p>Situação: {epi.ativo ? "Ativo" : "Inativo"}</p>
            {epi.padrao && <p>Padrão para químicos</p>}
            <Link to="/form-epi">
              <Button onClick={() => handleEdit(epi.id)}>Editar</Button>
            </Link>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    listaEPIs: state.gestorDN.listaEPCs ? state.gestorDN.listaEPIs : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ getEPIs }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CadastroEPIList);
