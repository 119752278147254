import {
  FETCH_PGR_PREVIEW,
  FETCH_TRABALHADORES_PGR_PREVIEW,
  START_PREENCHIMENTO,
  TOGGLE_STATUS,
} from "./types";
import { setupInterceptors } from "../../../../auth/SetupInterceptors";
import {
  dashboardTrabalhadoresUrl,
  dashboardUrl,
  generatePGRUrl,
  preenchimentoUrl,
  switchStatusFluxo,
} from "../../../../constants";
import Axios from "axios";
import openNotification from "../../../../components/notifications";

setupInterceptors();

export const fetchPGRPreview = (dispatch) => {
  Axios.get(dashboardUrl).then((resposta) => {
    dispatch({
      type: FETCH_PGR_PREVIEW,
      payload: resposta.data,
    });
  });
};

export const fetchTrabalhadoresPGRPreview = (dispatch) => {
  Axios.get(dashboardTrabalhadoresUrl).then((resposta) => {
    dispatch({
      type: FETCH_TRABALHADORES_PGR_PREVIEW,
      payload: resposta.data,
    });
  });
};

export const generatePGR = (callback, loading) => {
  if (loading) loading(true);
  Axios.post(generatePGRUrl)
    .then((resposta) => {
      callback(resposta);
      if (loading) loading(false);
    })
    .catch((e) => {
      if (loading) loading(false);
      console.log(e);
      openNotification("Falha ao gerar PGR!", "", "error");
    });
};

export const iniciarPreenchimento = (enablePrimeirosPassos) => {
  return (dispatch) => {
    Axios.patch(
      preenchimentoUrl,
      { withCredentials: true },
      {
        params: {
          habilitarPrimeirosPassos: enablePrimeirosPassos ? 1 : 0,
        },
      }
    ).then(() => {
      dispatch({
        type: START_PREENCHIMENTO,
      });
      Axios.get(dashboardUrl).then((resposta) => {
        dispatch({
          type: FETCH_PGR_PREVIEW,
          payload: resposta.data,
        });
      });
    });
  };
};

export const toggleStatusFluxo = (statusFluxo) => {
  return (dispatch) => {
    Axios.patch(switchStatusFluxo, statusFluxo)
      .then((response) => {
        dispatch({
          type: TOGGLE_STATUS,
          payload: response.data,
        });
      })
      .catch((err) => console.log(err));
  };
};
