import React from "react";
import Button from "../../../../components/Buttons/_/components/button";

const ResetPasswordResponse = (props) => {
  return (
    <div>
      <div className="header-form transparent">
        <h2>Nova Senha</h2>
      </div>
      <div className="login-form-body">
        <h4>Senha alterada com sucesso!</h4>
      </div>
      <div className="login-form-footer">
        <Button text="Voltar" onClick={() => props.tab("login")} />
      </div>
    </div>
  );
};

export default ResetPasswordResponse;
