import React, { Component } from "react";
import { Menu as MenuAntd, Button } from "antd";
import IconSESI from "../../../../components/IconsSESI";
import { Link, withRouter } from "react-router-dom";
import MenuItems from "./../../../../constants/menuItems";
import { connect } from "react-redux";
import { isPerfilGestor } from "../../../../constants/userTypes";

const callCenterUrl = process.env.REACT_APP_CALL_CENTER_URL;

class MenuList extends Component {
  state = {
    collapsed: true,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  expand = () => {
    this.setState({
      collapsed: false,
    });
    this.props.toggleCollapse(false);
  };

  collapse = () => {
    this.setState({
      collapsed: true,
    });
    this.props.toggleCollapse(true);
  };

  render() {
    if (!this.props.user) {
      return <div></div>;
    }

    let menu;
    if (
      this.props.location.pathname === "/" &&
      !isPerfilGestor(this.props.user.perfil)
    ) {
      menu = MenuItems(this.props.company.nomeFantasia)[
        "RESPONSAVELSEMEMPRESA"
      ];
    } else if (
      !this.props.company.cnpj &&
      this.props.location.pathname === "/perfil" &&
      !isPerfilGestor(this.props.user.perfil)
    ) {
      menu = MenuItems(this.props.company.nomeFantasia)[
        "RESPONSAVELSEMEMPRESA"
      ];
    } else if (
      this.props.location.pathname === "/selecaoempresa" &&
      !isPerfilGestor(this.props.user.perfil)
    ) {
      menu = MenuItems(this.props.company.nomeFantasia)[
        "RESPONSAVELSEMEMPRESA"
      ];
    } else if (isPerfilGestor(this.props.user.perfil)) {
      menu = MenuItems(this.props.company.nomeFantasia)[this.props.user.perfil];
    } else if (this.props.company.cnpj) {
      menu = MenuItems(this.props.company.nomeFantasia)[this.props.user.perfil];
    }
    // let menuAdministrador = MenuItems("")["ADMINISTRADOR"];
    // if (this.props.user.perfil === "ADMINISTRADOR") {
    //   menu = menuAdministrador;
    // }

    // const menuItemClass = (currentItemPath, currentPath, icon) => {
    //   return currentItemPath === currentPath ? "ant-menu-item-selected" : icon;
    // };

    return (
      <MenuAntd
        mode="inline"
        inlineCollapsed={this.state.collapsed}
        //component={this.props.location.pathname}
        onMouseLeave={this.collapse}
        onMouseEnter={this.expand}
        multiple={false}
        selectedKeys={[]}
      >
        <MenuAntd.Item
          key="expand"
          className="button-expand"
          title={this.state.collapsed ? "Expandir Menu" : "Recolher menu"}
          disabled={true}
        >
          <Button type="link" onClick={this.toggle}>
            <IconSESI type="bars" />
            <span>Recolher menu</span>
          </Button>
        </MenuAntd.Item>
        <MenuAntd.Divider />
        {menu
          ? menu.map((item) => {
              if (item.hideFromMenu) return null;
              if (
                item.path.includes("/gerar-autodeclaracao") &&
                this.props?.company?.cnae?.grauDeRisco > 2
              )
                return null;
              return (
                <MenuAntd.Item
                  key={item.path}
                  title={item.title}
                  className={
                    item.path === window.location.pathname
                      ? "ant-menu-item-selected"
                      : item.icon
                  }
                >
                  {item.path.includes("central-de-atendimento") ? (
                    <div
                      className="menuAtendimento"
                      onClick={() => {
                        window.open(`${callCenterUrl}`, "_blank");
                      }}
                    >
                      <IconSESI type={item.icon} />
                      <span className="menu-item-title">
                        {item.title}
                        <span className="menu-item-description">
                          {item.description}
                        </span>
                      </span>
                    </div>
                  ) : (
                    <Link to={item.path}>
                      <IconSESI type={item.icon} />
                      <span className="menu-item-title">
                        {item.title}
                        <span className="menu-item-description">
                          {item.description}
                        </span>
                      </span>
                    </Link>
                  )}
                </MenuAntd.Item>
              );
            })
          : ""}
      </MenuAntd>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.userInfo,
    buttons: state.buttons,
    company: state.caracterizacao ? state.caracterizacao.companySelected : {},
    empresa: state.caracterizacao ? state.caracterizacao.empresa : {},
  };
};

export default connect(mapStateToProps, {})(withRouter(MenuList));
