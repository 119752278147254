import React, { useEffect, useState } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Row, Col, Form, List, Card, Button, Select, Tooltip } from "antd";
import {
  getSectors,
  getJobTitles,
  getEmployees,
  addRealizadorProcessos,
  getRealizadorProcesso,
} from "../actions/index";

const ProcessCard = ({ process, props }) => {
  const [form] = Form.useForm();
  const [sectors, setSectors] = useState();
  const [allJobs, setAllJobs] = useState();
  const [employees, setEmployees] = useState();
  const [sectorOptions, setSectorOptions] = useState([]);
  const [sectorFilterOptions, setSectorFilterOptions] = useState([]);
  const [jobOption, setJobOption] = useState([]);
  const [vinculatedWorkers, setVinculatedWorkers] = useState([]);
  const [jobTitleDisabled, setJobTitleDisabled] = useState(true);
  const [openExceptions, setOpenExceptions] = useState(false);
  const [selectedWorkers, setSelectedWorkers] = useState([]);
  const [availableWorkers, setAvailableWorkers] = useState([]);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  const onLoadScreen = async () => {
    let employeesList = [];
    let sectorsList = [];
    let jobsList = [];
    let processData;

    Promise.allSettled([
      (employeesList = await getEmployees()),
      (sectorsList = await getSectors()),
      (jobsList = await getJobTitles()),
      (processData = await getRealizadorProcesso(process.idProcesso)),
    ]);

    let activeEmployees = employeesList.filter(
      (employee) => employee.ativo === 1
    );

    let sectorOptionsFormatted = [];

    jobsList.cargosPorSetor.forEach((cargo) => {
      const nameFormated =
        cargo.nome[0].toUpperCase() + cargo.nome.toLowerCase().substring(1);
      sectorOptionsFormatted.push({
        label: nameFormated,
        options: cargo.cargos.map((item) => ({
          label: item.descricao,
          value: item.id,
        })),
      });
    });

    setAllJobs(jobsList);
    setEmployees(activeEmployees);
    setSectors(sectorsList);
    setSectorOptions(
      sectorsList.map((sector) => {
        return {
          label:
            sector.nome[0].toUpperCase() +
            sector.nome.toLowerCase().substring(1),
          value: sector.id,
        };
      })
    );
    setSectorFilterOptions(sectorOptionsFormatted);

    if (processData) {
      loadProcessData(processData, activeEmployees, sectorsList, jobsList);
    }
  };

  const loadProcessData = (data, employeesList, sectorsList, jobsList) => {
    let sectorsIds = [];
    let jobsIds = [];
    let workersIds = [];

    data.grupoExposto.funcionariosDoProcesso.forEach((funcionario) => {
      switch (funcionario.tipoDeAssociacao) {
        case "setores":
          if (!sectorsIds.includes(funcionario.setorId))
            sectorsIds.push(funcionario.setorId);
          break;

        case "trabalhador":
          if (!workersIds.includes(funcionario.id))
            workersIds.push(funcionario.funcionarioId);
          break;

        case "cargos":
          if (!jobsIds.includes(funcionario.cargoId))
            jobsIds.push(funcionario.cargoId);
          break;
      }
    });

    if (jobsIds.length !== 0) {
      jobsList.cargosPorSetor.forEach((job) => {
        job.cargos.forEach((cargo) => {
          if (jobsIds.includes(cargo.id)) {
            if (!sectorsIds.includes(job.id)) sectorsIds.push(job.id);
          }
        });
      });
    }

    form.setFieldsValue({
      setoresVinculados: sectorsIds,
      cargosVinculados: jobsIds,
    });

    let processWorkers = [];
    let availableWorkersList = [];

    employeesList.forEach((employee) => {
      if (workersIds.includes(employee.id)) {
        processWorkers.push(employee);
      } else {
        availableWorkersList.push(employee);
      }
    });

    setOpenExceptions(processWorkers.length !== 0);
    handleSelectedSector(sectorsList, jobsList, employeesList, processWorkers);
  };

  useEffect(() => {
    onLoadScreen();
  }, []);

  const handleSelectedSector = async (sectorsList, jobsList, employeesList, processWorkers) => {
    let selectedJobsOptions = [];
    let availableJobsOptions = [];

    let selectedSectors = form.getFieldValue("setoresVinculados");
    let selectedJobs = form.getFieldValue("cargosVinculados");

    setJobTitleDisabled(selectedSectors.length === 0);

    selectedSectors.forEach((sector) => {
      let currentSector;

      if (sectorsList) {
        currentSector = sectorsList.find((item) => item.id === sector);
      } else {
        currentSector = sectors.find((item) => item.id === sector);
      }

      if (!currentSector) {
        return;
      }

      let currentJobs;

      if (jobsList) {
        currentJobs = jobsList.cargosPorSetor.filter(
          (jobSector) => jobSector.id === sector
        );
      } else {
        currentJobs = allJobs.cargosPorSetor.filter(
          (jobSector) => jobSector.id === sector
        );
      }

      currentJobs.forEach((jobTitle) => {
        let currentJobAvailableOptions = jobTitle.cargos.map((cargo) => {
          return cargo.id;
        });
        availableJobsOptions.push(...currentJobAvailableOptions);

        const nameFormated =
          jobTitle.nome[0].toUpperCase() +
          jobTitle.nome.toLowerCase().substring(1);
        selectedJobsOptions.push({
          label: nameFormated,
          options: jobTitle.cargos.map((item) => ({
            label: item.descricao,
            value: item.id,
          })),
        });
      });
    });

    let employeesLinkedToJobs = [];
    let availableEmployees = [];

    let allEmployees;

    if (employeesList) {
      allEmployees = [...employeesList];
    } else {
      allEmployees = [...employees];
    }

    allEmployees.forEach((employee) => {
      if (availableJobsOptions.includes(employee.idCargo)) {
        employeesLinkedToJobs.push(employee);
      } else {
        availableEmployees.push(employee)
      }
    });

    setJobOption(selectedJobsOptions);

    if (!selectedJobs) {
      setVinculatedWorkers(employeesLinkedToJobs);
      return;
    }

    employeesLinkedToJobs = [];
    availableEmployees = [];

    form.setFieldsValue({
      cargosVinculados: selectedJobs.filter((selectedJob) =>
        availableJobsOptions.includes(selectedJob)
      ),
    });

    let jobsIdFiltered = selectedJobs.filter((selectedJob) =>
      availableJobsOptions.includes(selectedJob)
    );

    selectedJobsOptions.forEach((selectedJob) => {
      let isRestrictedSector = false;

      selectedJob.options.forEach((jobOption) => {
        if (jobsIdFiltered.includes(jobOption.value)) {
          isRestrictedSector = true;
        }
      });

      if (isRestrictedSector) {
        selectedJob.options.forEach((jobOption) => {
          if (jobsIdFiltered.includes(jobOption.value)) {
            let jobEmployees = allEmployees.filter(
              (employer) => employer.idCargo === jobOption.value
            );
            if (jobEmployees.length !== 0)
              employeesLinkedToJobs = [
                ...employeesLinkedToJobs,
                ...jobEmployees,
              ];
          }
        });
      } else {
        let allJobsIds = selectedJob.options.map((item) => {
          return item.value;
        });
        let jobEmployees = allEmployees.filter((employer) =>
          allJobsIds.includes(employer.idCargo)
        );

        if (jobEmployees.length !== 0)
          employeesLinkedToJobs = [...employeesLinkedToJobs, ...jobEmployees];
      }
    });

    allEmployees.forEach(employee => {
      if(!employeesLinkedToJobs.includes(employee)) {
        availableEmployees.push(employee)
      }
    })

    const newList = []

    if(processWorkers) {
      availableEmployees.forEach(employee => {
        if (!processWorkers.includes(employee)) {
          newList.push(employee)
        }
      })
    }
  
    setAvailableWorkers(processWorkers ? newList : availableEmployees)
    setSelectedWorkers(processWorkers ?? []);
    setVinculatedWorkers(employeesLinkedToJobs);
  };

  const handleSubmit = async () => {
    form.validateFields().then(async (values) => {
      setButtonIsLoading(true);
      const funcionarios = [];

      vinculatedWorkers.forEach((worker) => {
        if (values.cargosVinculados?.includes(worker.idCargo)) {
          funcionarios.push({ id: worker.id, vinculo: "cargos" });
        } else {
          funcionarios.push({ id: worker.id, vinculo: "setores" });
        }
      });

      selectedWorkers.forEach((worker) => {
        funcionarios.push({ id: worker.id, vinculo: "trabalhador" });
      });

      let processo = {
        funcionarios,
        processoProdutivoId: process.idProcessoProdutivoDaEmpresa,
      };

      await addRealizadorProcessos(processo, props.dispatch).finally(() => {
        setButtonIsLoading(false);
      });
    });
  };

  //adicionar e remover funcionario da lista de exceções
  const handleAddWorker = (worker) => {
    setSelectedWorkers((oldValue) => {
      return [...oldValue, worker];
    });

    setAvailableWorkers((oldValue) => {
      return oldValue.filter(
        (availableWorker) => availableWorker.id !== worker.id
      );
    });
  };

  const handleRemoveWorker = (worker) => {
    setSelectedWorkers((oldValue) => {
      return oldValue.filter(
        (selectedWorker) => selectedWorker.id !== worker.id
      );
    });

    setAvailableWorkers((oldValue) => {
      return [...oldValue, worker];
    });
  };

  const handleClearWorkersFilter = () => {
    let filteredEmployees = employees
      .filter((employee) => !vinculatedWorkers.includes(employee))
      .filter((employee) => !selectedWorkers.includes(employee))

    setAvailableWorkers(filteredEmployees);
  };

  const handleFilterWorkers = async (value) => {
    if (!value) {
      return;
    }

    let allSelectedWorkers = selectedWorkers.map((worker) => {
      return worker.id;
    });

    let filteredWorkers = employees
      .filter((employee) => employee.idCargo === value)
      .filter((employee) => !allSelectedWorkers.includes(employee.id))
      .filter((employee) => !vinculatedWorkers.includes(employee));

    setAvailableWorkers(filteredWorkers);
  };

  return (
    <div className="processCard">
      <Form form={form} onFinish={handleSubmit}>
        <div className="cardContent">
          <Col>
            <Row className="headerContent">
              <h4>
                Selecione os trabalhadores responsáveis por executar este
                processo produtivo.
              </h4>
            </Row>
            <Row gutter={32}>
              <Col span={12}>
                <div className="selects">
                  <Form.Item
                    label={"Setores vinculados: "}
                    name={"setoresVinculados"}
                    tooltip={
                      "Selecione os setores aos quais os trabalhadores estão vinculados para este processo."
                    }
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder="Setor"
                      options={sectorOptions}
                      onChange={() => handleSelectedSector()}
                    />
                  </Form.Item>
                  <Form.Item
                    label={"Cargos vinculados: "}
                    name="cargosVinculados"
                    tooltip={
                      "Selecione os cargos específicos dentro dos setores vinculados que estão envolvidos neste processo."
                    }
                  >
                    <Select
                      disabled={jobTitleDisabled}
                      mode="multiple"
                      placeholder="Cargo"
                      options={jobOption}
                      onChange={() => handleSelectedSector()}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <h4>Trabalhadores vinculados:</h4>
                <Card>
                  {vinculatedWorkers.map((worker, key) => (
                    <p key={`${worker.id}${key}`}>{worker.nome}</p>
                  ))}
                </Card>
              </Col>
            </Row>
            {!openExceptions ? (
              <Row>
                <div className="addExceptions">
                  <Button
                    onClick={() => {
                      setOpenExceptions(!openExceptions);
                    }}
                  >
                    Adicionar exceções
                  </Button>
                </div>
              </Row>
            ) : (
              <div className="exceptionCards">
                <Row className="headerContent">
                  <h4>Vincular trabalhador individualmente:</h4>
                  <Tooltip
                    title={
                      "Adicione trabalhadores específicos que também realizam este processo, independentemente do setor ou cargo selecionado anteriormente."
                    }
                  >
                    <QuestionCircleOutlined style={{ color: "#fff" }} />
                  </Tooltip>
                </Row>
                <Row gutter={32}>
                  <Col span={12}>
                    <Form.Item name="workerFilter">
                      <Select
                        allowClear
                        placeholder="Setor"
                        options={sectorFilterOptions}
                        onChange={handleFilterWorkers}
                        onClear={handleClearWorkersFilter}
                      />
                    </Form.Item>
                    <Card>
                      <List
                        dataSource={availableWorkers}
                        renderItem={(worker) => (
                          <List.Item className="worker">
                            {worker.nome}
                            <Button
                              className="button"
                              onClick={() => handleAddWorker(worker)}
                            >
                              Adicionar
                            </Button>
                          </List.Item>
                        )}
                      />
                    </Card>
                  </Col>
                  <Col span={12}>
                    <h4 style={{ marginTop: "14px" }}>
                      Trabalhadores vinculados:
                    </h4>
                    <Card>
                      <List
                        dataSource={selectedWorkers}
                        renderItem={(worker) => (
                          <List.Item className="worker">
                            {worker.nome}
                            <Button
                              className="button"
                              onClick={() => handleRemoveWorker(worker)}
                            >
                              Remover
                            </Button>
                          </List.Item>
                        )}
                      />
                    </Card>
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </div>
        <div className="processMakerSaveButton">
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  loading={buttonIsLoading}
                  disabled={buttonIsLoading}
                >
                  SALVAR
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default ProcessCard;
