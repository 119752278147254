import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import IconSESI from "../../../../../components/IconsSESI";
import { confirmInputChange } from "../../../../../components/modals";
import { AllConstants } from "../../constants/index";
import { removePeriods } from "../../utils/index";

const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;

const HarmulAgentCard = ({
  form,
  harmfulAgentIndex,
  harmfulAgentInfo,
  selectedUser,
  remove,
  harmfulAgentsArray,
  usedHarmfulAgents,
  setUsedHarmfulAgents,
  validateUsedHarmfulAgents,
  agentesNocivos,
  handleSelectedHarmfulAgents,
  validateHarmfulAgentsArray,
  utilizaEpcEpi,
  setUtilizaEpcEpi,
  formMode,
  blockAddAgenteNocivo,
}) => {
  const {
    agentesNocivosComDescricao,
    tableUnityMeasurement,
    chemicalMeasurements,
    courtDecisionObject,
    labelsAndTooltips2240,
  } = AllConstants();

  const [unityMeasurementList, setUnityMeasurementList] = useState([]);
  const [blockAddDoc, setBlockAddDoc] = useState(false);
  const [epc_epi_error, setEpc_epi_error] = useState(false);
  const [hasCourtDecision, setHasCourtDecision] = useState(null);
  const [unityMeasurementNociveAgents, setUnityMeasurementNociveAgents] =
    useState(tableUnityMeasurement);

  const onLoadScreen = () => {
    insertChemicalsToMeasurementList();
    insertCourtDecisionHarmfulAgent();
  };

  const insertCourtDecisionHarmfulAgent = () => {
    let alreadyHaveCourtDecision = false;
    harmfulAgentsArray.forEach((agent) => {
      agent.codigoTabela24 === courtDecisionObject.codigoTabela24 &&
        (alreadyHaveCourtDecision = true);
    });

    !alreadyHaveCourtDecision && harmfulAgentsArray.push(courtDecisionObject);
  };

  const insertChemicalsToMeasurementList = async () => {
    let min = "0101001".padStart(7, "0");
    let max = "0119041".padStart(7, "0");

    let chemicalAgentCodes = [];

    let currentMeasurementTable = [];

    harmfulAgentsArray.forEach((agent) => {
      let code = agent.codigoTabela24.padStart(7, "0").replace(/\./g, "");
      if (code >= min && code <= max) {
        chemicalAgentCodes.push(agent.codigoTabela24);
      }
    });

    if (chemicalAgentCodes.length === 0) {
      return;
    }

    if (unityMeasurementNociveAgents.length === 7) {
      currentMeasurementTable = unityMeasurementNociveAgents.map(
        (unity, thisIndex) => {
          if (thisIndex === 6) {
            return {
              nociveCodes: chemicalAgentCodes,
              measurementOptions: chemicalMeasurements,
            };
          } else {
            return unity;
          }
        }
      );
    } else {
      currentMeasurementTable = [
        ...unityMeasurementNociveAgents,
        {
          nociveCodes: chemicalAgentCodes,
          measurementOptions: chemicalMeasurements,
        },
      ];
    }

    setUnityMeasurementNociveAgents(currentMeasurementTable);
    populateUnityMeasurementSelectList(currentMeasurementTable);
  };

  const populateUnityMeasurementSelectList = (currentMeasurementTable) => {
    let newList = [];

    const nociveAgentsList = form.getFieldValue("agentesNocivos");

    nociveAgentsList.forEach((unity, unityIndex) => {
      currentMeasurementTable.forEach((agent) => {
        if (agent.nociveCodes.includes(unity?.codigoTabela24)) {
          newList.push({
            id: unityIndex,
            options: [agent.measurementOptions],
          });
        }
      });
    });

    setUnityMeasurementList(newList);
  };

  const defineUnityMeasurementsByHarmfulAgent = (harmfulAgentId) => {
    let currentMeasurementOptions = [];
    let nociveCode;

    const harmfulAgentData = form.getFieldValue("agentesNocivos");

    harmfulAgentsArray.forEach((item) => {
      if (harmfulAgentId === item.idFatorDeRisco) {
        nociveCode = item.codigoTabela24;
      }
    });

    // limpa o campo `unidade de medida` selecionada, caso altere o `agente nocivo`
    form.setFieldsValue({
      agentesNocivos: harmfulAgentData.map((item, thisIndex) => {
        if (thisIndex === harmfulAgentIndex) {
          return {
            ...item,
            unMed: "",
          };
        } else {
          return item;
        }
      }),
    });

    unityMeasurementNociveAgents.forEach((unity) => {
      if (unity.nociveCodes.includes(nociveCode)) {
        currentMeasurementOptions.push(unity.measurementOptions);
        if (unity.measurementOptions.length === 1) {
          form.setFieldsValue({
            agentesNocivos: harmfulAgentData.map((item, thisIndex) => {
              if (thisIndex === harmfulAgentIndex) {
                return {
                  ...item,
                  unMed: unity.measurementOptions[0].value,
                };
              } else {
                return item;
              }
            }),
          });
        }
      }
    });

    let measurementArrayUpdated = [...unityMeasurementList];

    let unityAlreadyExist = measurementArrayUpdated.find(
      (unity) => unity.id === harmfulAgentIndex
    );

    if (unityAlreadyExist) {
      measurementArrayUpdated = unityMeasurementList.map((unity) => {
        if (unity.id === harmfulAgentIndex) {
          return {
            ...unity,
            options: currentMeasurementOptions,
          };
        } else {
          return unity;
        }
      });
    } else {
      measurementArrayUpdated.push({
        id: harmfulAgentIndex,
        options: currentMeasurementOptions,
      });
    }

    setUnityMeasurementList(measurementArrayUpdated);
  };

  const handleUpdateUnityMeasurements = () => {
    let arrayMeasurement = [];

    unityMeasurementList.map((old) => {
      if (old.id !== harmfulAgentIndex) {
        arrayMeasurement.push(old);
      }
    });

    let arrayMeasurementFiltered = arrayMeasurement.map((item, thisIndex) => {
      return {
        ...item,
        id: thisIndex,
      };
    });

    setUnityMeasurementList(arrayMeasurementFiltered);
    validateUsedHarmfulAgents();
  };

  const validateHasCourtNumber = (agentCode, index) => {
    let currentCode;

    harmfulAgentsArray.forEach((agent) => {
      if (agent.codigoTabela24 === courtDecisionObject.codigoTabela24) {
        currentCode = agent.idFatorDeRisco;
      }
    });

    if (agentCode === currentCode) {
      setHasCourtDecision(index);
    } else {
      handleUpdateCourtDecision();
    }
  };

  const handleUpdateCourtDecision = () => {
    let agentsArray = form.getFieldValue("agentesNocivos");

    let indexHasCourtDecision = null;

    agentsArray &&
      agentsArray.forEach((item, thisIndex) => {
        if (item?.codigoTabela24 === courtDecisionObject.codigoTabela24) {
          indexHasCourtDecision = thisIndex;
        }
      });

    setHasCourtDecision(indexHasCourtDecision);
  };

  const openInputChangeModal = (type, event, agentIndex) => {
    let agentesNocivos = form.getFieldValue("agentesNocivos");

    switch (type) {
      case "utilizEPC":
        form.setFieldsValue({
          agentesNocivos: agentesNocivos.map((agente, index) => {
            let oldValue = [...utilizaEpcEpi];
            let epcSelected =
              utilizaEpcEpi[agentIndex].existEpcInPgr !== "" &&
              formMode === "new"
                ? utilizaEpcEpi[agentIndex].utilizaEpc
                : event.target.value;

            oldValue.forEach((item) => {
              if (item.id === agentIndex + 1) {
                item.utilizaEpc = epcSelected;
              }
            });
            setUtilizaEpcEpi(oldValue);

            if (index === agentIndex) {
              return {
                ...agente,
                utilizEPC: epcSelected,
              };
            }
            return agente;
          }),
        });

        let messageEpc = {
          IMPLEMENTA: "",
          NAO_IMPLEMENTA: "que Não Implementa",
          NAO_SE_APLICA: "que Não é necessário ou Não aplicável",
        };

        utilizaEpcEpi[agentIndex].existEpcInPgr !== "" &&
          formMode === "new" &&
          confirmInputChange(
            `Atenção! No PGR foi informado ${
              messageEpc[utilizaEpcEpi[agentIndex].existEpcInPgr]
            } EPC para este agente Nocivo, deseja mesmo alterar?`,
            () => {
              form.setFieldsValue({
                agentesNocivos: agentesNocivos.map((agente, index) => {
                  if (index === agentIndex) {
                    return {
                      ...agente,
                      utilizEPC: event.target.value,
                    };
                  }
                  return agente;
                }),
              });

              let oldValue = [...utilizaEpcEpi];
              oldValue.forEach((item) => {
                if (item.id === agentIndex + 1) {
                  item.utilizaEpc = event.target.value;
                }
              });
              setUtilizaEpcEpi(oldValue);
            }
          );
        break;

      case "utilizEPI":
        form.setFieldsValue({
          agentesNocivos: agentesNocivos.map((agente, index) => {
            let oldValue = [...utilizaEpcEpi];
            let epiSelected =
              utilizaEpcEpi[agentIndex].existEpiInPgr !== "" &&
              formMode === "new"
                ? utilizaEpcEpi[agentIndex].utilizaEpi
                : event.target.value;

            oldValue.forEach((item) => {
              if (item.id === agentIndex + 1) {
                item.utilizaEpi = epiSelected;
              }
            });
            setUtilizaEpcEpi(oldValue);

            if (index === agentIndex) {
              return {
                ...agente,
                utilizEPI: epiSelected,
              };
            }
            return agente;
          }),
        });

        let messageEpi = {
          IMPLEMENTA: "",
          NAO_IMPLEMENTA: "que Não Utiliza",
          NAO_SE_APLICA: "que Não é necessário ou Não aplicável",
        };

        utilizaEpcEpi[agentIndex].existEpiInPgr !== "" &&
          formMode === "new" &&
          confirmInputChange(
            `Atenção! No PGR foi informado ${
              messageEpi[utilizaEpcEpi[agentIndex].existEpiInPgr]
            } EPI para este agente Nocivo, deseja mesmo alterar?`,
            () => {
              form.setFieldsValue({
                agentesNocivos: agentesNocivos.map((agente, index) => {
                  if (index === agentIndex) {
                    return {
                      ...agente,
                      utilizEPI: event.target.value,
                    };
                  }
                  return agente;
                }),
              });

              let oldValue = [...utilizaEpcEpi];
              oldValue.forEach((item) => {
                if (item.id === agentIndex + 1) {
                  item.utilizaEpi = event.target.value;
                }
              });
              setUtilizaEpcEpi(oldValue);
            }
          );
        break;

      case "medProtecao":
        form.setFieldsValue({
          agentesNocivos: agentesNocivos.map((agente, index) => {
            let oldValue = [...utilizaEpcEpi];
            let pmSelected =
              utilizaEpcEpi[agentIndex].existEpcInPgr !== "" &&
              formMode === "new"
                ? utilizaEpcEpi[agentIndex].medProtecao
                : event.target.value;

            oldValue.forEach((item) => {
              if (item.id === agentIndex + 1) {
                item.medProtecao = pmSelected;
              }
            });
            setUtilizaEpcEpi(oldValue);

            if (index === agentIndex) {
              return {
                ...agente,
                medProtecao: pmSelected,
              };
            }
            return agente;
          }),
        });

        let useProtectionMeasureMessage = {
          IMPLEMENTA: "IMPLEMENTA",
          NAO_IMPLEMENTA: "NÃO IMPLEMENTA",
          NAO_SE_APLICA: "NÃO SE APLICA",
        };

        utilizaEpcEpi[agentIndex].existEpcInPgr !== "" &&
          formMode === "new" &&
          confirmInputChange(
            `Atenção! No campo implementa EPC para eliminar ou reduzir a exposição dos trabalhadores ao agente nocivo? Foi marcada a opção ${
              useProtectionMeasureMessage[
                utilizaEpcEpi[agentIndex].existEpcInPgr
              ]
            }, deseja mesmo alterar?`,
            () => {
              form.setFieldsValue({
                agentesNocivos: agentesNocivos.map((agente, index) => {
                  if (index === agentIndex) {
                    return {
                      ...agente,
                      medProtecao: event.target.value,
                    };
                  }
                  return agente;
                }),
              });

              let oldValue = [...utilizaEpcEpi];
              oldValue.forEach((item) => {
                if (item.id === agentIndex + 1) {
                  item.medProtecao = event.target.value;
                }
              });
              setUtilizaEpcEpi(oldValue);
            }
          );
        break;
    }
  };

  const handleRemoveHarmulAgent = () => {
    remove(harmfulAgentIndex);

    if (agentesNocivos.length === 2) {
      validateHarmfulAgentsArray(true);
    }

    let currentUsedHarmfulAgents = [...usedHarmfulAgents];
    currentUsedHarmfulAgents.splice(harmfulAgentIndex, 1);
    setUsedHarmfulAgents(currentUsedHarmfulAgents);

    let id = utilizaEpcEpi[harmfulAgentIndex]?.id;
    let arrayFiltered = utilizaEpcEpi
      .filter((item) => item.id !== id)
      .map((item, thisIndex) => ({
        ...item,
        id: thisIndex + 1,
      }));

    setUtilizaEpcEpi(arrayFiltered);
    handleUpdateUnityMeasurements();
    handleUpdateCourtDecision();
  };

  const validatorEPC_EPI = () => {
    const agent = form.getFieldValue("agentesNocivos")[harmfulAgentIndex];
    const {
      utilizEPC,
      utilizEPI,
      eficEpc,
      eficEpi,
      docAvals,
      condFuncto,
      higienizacao,
      usoInint,
      przValid,
      periodicTroca,
      medProtecao,
    } = agent;

    let epcEpiHasError = false;

    if (utilizEPC === "" || utilizEPI === "") {
      epcEpiHasError = true;
    } else if (utilizEPC !== "IMPLEMENTA" && utilizEPI !== "IMPLEMENTA") {
      epcEpiHasError = false;
    } else if (utilizEPC === "IMPLEMENTA" && eficEpc === undefined) {
      epcEpiHasError = true;
    } else if (utilizEPI === "IMPLEMENTA") {
      let docHasError = false;
      if (docAvals) {
        docAvals.forEach((doc) => {
          if (!doc || !doc.docAval || doc.docAval === "") {
            docHasError = true;
          }
        });
      }
      const otherUndefinedFields =
        eficEpi === undefined ||
        condFuncto === undefined ||
        higienizacao === undefined ||
        usoInint === undefined ||
        przValid === undefined ||
        periodicTroca === undefined ||
        medProtecao === undefined;

      epcEpiHasError = docHasError || otherUndefinedFields;
    }
    setEpc_epi_error(epcEpiHasError);
    return Promise.resolve();
  };

  const removeAccents = (str) => {
    return str
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  };

  useEffect(() => {
    handleUpdateCourtDecision();
  }, [usedHarmfulAgents]);

  useEffect(() => {
    onLoadScreen();
  }, [selectedUser, harmfulAgentsArray]);

  return (
    <React.Fragment>
      <div className="esocial-form-card esocial-2240-form">
        <span className="esocial-card-title">
          Informações sobre a exposição a agentes nocivos{" "}
        </span>
        <Form.Item
          name={[harmfulAgentInfo.name, "codAgNoc"]}
          fieldKey={[harmfulAgentInfo.fieldKey, "codAgNoc"]}
          label={labelsAndTooltips2240.codAgNoc.label}
          tooltip={labelsAndTooltips2240.codAgNoc.tooltip}
          rules={[
            {
              required: true,
              message: "Insira um agente nocivo.",
            },
          ]}
        >
          <Select
            disabled={selectedUser === ""}
            showSearch
            showArrow={false}
            filterOption={(input, option) => {
              let value = removeAccents(option.props.children);
              let normalizedInput = removeAccents(input);

              return value.indexOf(normalizedInput) >= 0;
            }}
            onSelect={(value) => {
              handleSelectedHarmfulAgents(value, harmfulAgentIndex);
              defineUnityMeasurementsByHarmfulAgent(value);
              validateHasCourtNumber(value, harmfulAgentIndex);
            }}
          >
            {harmfulAgentsArray?.map((agente) => {
              let currentHarmfulAgent =
                form.getFieldValue("agentesNocivos")[harmfulAgentIndex];
              if (
                !usedHarmfulAgents.includes(agente?.idFatorDeRisco) ||
                currentHarmfulAgent?.codAgNoc === agente.idFatorDeRisco
              ) {
                return (
                  <Option
                    key={agente.idFatorDeRisco}
                    value={agente.idFatorDeRisco}
                    disabled={usedHarmfulAgents.includes(agente.idFatorDeRisco)}
                  >
                    {`${agente.codigoTabela24} - ${agente.descricao}`}
                  </Option>
                );
              }
            })}
          </Select>
        </Form.Item>
        {form.getFieldValue("agentesNocivos")[harmfulAgentIndex]
          ?.codigoTabela24 !== "09.01.001" && (
          <>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name={[harmfulAgentInfo.name, "tpAval"]}
                  label={labelsAndTooltips2240.tpAval.label}
                  className="side-label-form-item"
                  tooltip={labelsAndTooltips2240.tpAval.tooltip}
                  rules={[
                    {
                      required: true,
                      message:
                        "Selecione o tipo de avaliação do agente nocivo.",
                    },
                  ]}
                >
                  <Select
                    disabled={selectedUser === ""}
                    onChange={(value) => {
                      let oldValue = [...utilizaEpcEpi];
                      oldValue.forEach((item) => {
                        if (item.id === harmfulAgentIndex + 1) {
                          item.tpAval = value;
                        }
                      });
                      setUtilizaEpcEpi(oldValue);
                    }}
                  >
                    <Option key={1} value={"QUANTITATIVO"}>
                      Possui medição (Quantitativa)
                    </Option>
                    <Option key={2} value={"QUALITATIVO"}>
                      Não possui medição (Qualitativa)
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              {hasCourtDecision !== null &&
                hasCourtDecision === harmfulAgentIndex && (
                  <Col span={12}>
                    <Form.Item
                      name={[harmfulAgentInfo.name, "nrProcJud"]}
                      label={labelsAndTooltips2240.nrProcJud.label}
                      tooltip={labelsAndTooltips2240.nrProcJud.tooltip}
                      className="side-label-form-item"
                      rules={[
                        {
                          required: hasCourtDecision === harmfulAgentIndex,
                          message: "Este campo é obrigatório.",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value) {
                              return Promise.resolve();
                            }

                            if (
                              removePeriods(value).length !== 17 &&
                              removePeriods(value).length !== 20 &&
                              removePeriods(value).length !== 21
                            ) {
                              return Promise.reject(
                                new Error(
                                  "Este campo deve conter 17, 20 ou 21 Números!"
                                )
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      <Input
                        disabled={selectedUser === ""}
                        placeholder="0000000-00.0000.0.00.0000"
                      />
                    </Form.Item>
                  </Col>
                )}
              {(form.getFieldValue("agentesNocivos")[harmfulAgentIndex]
                ?.codigoTabela24 === "01.18.001" ||
                form.getFieldValue("agentesNocivos")[harmfulAgentIndex]
                  ?.codigoTabela24 === "02.01.014") && (
                <Col span={12}>
                  <Form.Item
                    name={[harmfulAgentInfo.name, "limTol"]}
                    label={labelsAndTooltips2240.limTol.label}
                    tooltip={labelsAndTooltips2240.limTol.tooltip}
                    className="side-label-form-item"
                    rules={[
                      {
                        required: true,
                        message:
                          "Insira o limite de tolerância ou informe o valor 0.",
                      },
                    ]}
                  >
                    <Input disabled={selectedUser === ""} maxLength={10} />
                  </Form.Item>
                </Col>
              )}
            </Row>
            {agentesNocivosComDescricao.includes(
              form.getFieldValue("agentesNocivos")[harmfulAgentIndex]
                ?.codigoTabela24
            ) && (
              <Form.Item
                name={[harmfulAgentInfo.name, "dscAgNoc"]}
                fieldKey={[harmfulAgentInfo.fieldKey, "dscAgNoc"]}
                tooltip={labelsAndTooltips2240.dscAgNoc.tooltip}
                label={labelsAndTooltips2240.dscAgNoc.label}
                rules={[
                  {
                    required: true,
                    message: "Descreva o agente nocivo.",
                  },
                ]}
              >
                <TextArea
                  disabled={selectedUser === ""}
                  showCount
                  autoSize={{ minRows: 1, maxRows: 1 }}
                  maxLength={100}
                />
              </Form.Item>
            )}
            {utilizaEpcEpi[harmfulAgentIndex]?.tpAval == "QUANTITATIVO" && (
              <>
                <Form.Item
                  className="side-label-form-item"
                  name={[harmfulAgentInfo.name, "intConc"]}
                  label={labelsAndTooltips2240.intConc.label}
                  tooltip={labelsAndTooltips2240.intConc.tooltip}
                  rules={[
                    {
                      required:
                        !utilizaEpcEpi[harmfulAgentIndex]?.naoPossuiMedicao,
                      message:
                        "Insira a intensidade, concentração ou dose do agente nocivo a qual o trabalhador está exposto ou informe o valor 0.",
                    },
                  ]}
                >
                  <Input
                    maxLength={10}
                    disabled={
                      selectedUser === "" ||
                      utilizaEpcEpi[harmfulAgentIndex]?.naoPossuiMedicao
                    }
                  />
                </Form.Item>
                <Form.Item
                  name={[harmfulAgentInfo.name, "unMed"]}
                  label={labelsAndTooltips2240.unMed.label}
                  tooltip={labelsAndTooltips2240.unMed.tooltip}
                  rules={[
                    {
                      required: true,
                      message:
                        "Selecione a opção que se aplica ao agente nocivo.",
                    },
                  ]}
                >
                  <Select allowClear disabled={selectedUser === ""}>
                    {unityMeasurementList &&
                      unityMeasurementList.length !== 0 &&
                      unityMeasurementList.map((unity) => {
                        if (unity?.id === harmfulAgentIndex) {
                          return unity.options[0]?.map((option, thisIndex) => {
                            return (
                              <Option key={thisIndex} value={option.value}>
                                {option.name}
                              </Option>
                            );
                          });
                        }
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={[harmfulAgentInfo.name, "tecMedicao"]}
                  label={labelsAndTooltips2240.tecMedicao.label}
                  tooltip={labelsAndTooltips2240.tecMedicao.tooltip}
                  rules={[
                    {
                      required: true,
                      message: "Informe a técnica de medição.",
                    },
                  ]}
                >
                  <Input maxLength={40} disabled={selectedUser === ""} />
                </Form.Item>
              </>
            )}
            <Collapse>
              <Panel
                expandIconPosition="start"
                header="Informações relativas a Equipamentos de Proteção Coletiva - EPC e
                              Equipamentos de Proteção Individual - EPI"
                forceRender
              >
                <Form.Item
                  name={[harmfulAgentInfo.name, "utilizEPC"]}
                  fieldKey={[harmfulAgentInfo.fieldKey, "utilizEPC"]}
                  label={labelsAndTooltips2240.utilizEPC.label}
                  rules={[
                    {
                      required: true,
                      message: "Selecione uma opção.",
                    },
                    {
                      validator: validatorEPC_EPI,
                    },
                  ]}
                >
                  <Radio.Group
                    disabled={selectedUser === ""}
                    onChange={(event) => {
                      openInputChangeModal(
                        "utilizEPC",
                        event,
                        harmfulAgentIndex
                      );
                      validatorEPC_EPI();
                    }}
                  >
                    <Space direction="vertical">
                      <Radio
                        key={harmfulAgentInfo.fieldKey}
                        value={"NAO_SE_APLICA"}
                      >
                        Não se aplica
                      </Radio>
                      <Radio
                        key={harmfulAgentInfo.fieldKey}
                        value={"NAO_IMPLEMENTA"}
                      >
                        Não implementa
                      </Radio>
                      <Radio
                        key={harmfulAgentInfo.fieldKey}
                        value={"IMPLEMENTA"}
                      >
                        Implementa
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                {utilizaEpcEpi[harmfulAgentIndex]?.utilizaEpc ==
                  "IMPLEMENTA" && (
                  <>
                    <Form.Item
                      name={[harmfulAgentInfo.name, "eficEpc"]}
                      label={labelsAndTooltips2240.eficEpc.label}
                      rules={[
                        {
                          required: true,
                          message: "Selecione uma opção.",
                        },
                      ]}
                    >
                      <Radio.Group disabled={selectedUser === ""}>
                        <Space direction="vertical">
                          <Radio value={true}>Sim</Radio>
                          <Radio value={false}>Não</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </>
                )}
                <Form.Item
                  name={[harmfulAgentInfo.name, "utilizEPI"]}
                  label="Utilização de EPI:"
                  rules={[
                    {
                      required: true,
                      message: "Selecione uma opção.",
                    },
                    {
                      validator: validatorEPC_EPI,
                    },
                  ]}
                >
                  <Radio.Group
                    disabled={selectedUser === ""}
                    onChange={(event) => {
                      openInputChangeModal(
                        "utilizEPI",
                        event,
                        harmfulAgentIndex
                      );
                      validatorEPC_EPI();
                    }}
                  >
                    <Space direction="vertical">
                      <Radio value={"NAO_SE_APLICA"}>Não se aplica</Radio>
                      <Radio value={"NAO_IMPLEMENTA"}>Não utilizado</Radio>
                      <Radio value={"IMPLEMENTA"}>Utilizado</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                {utilizaEpcEpi[harmfulAgentIndex]?.utilizaEpi ===
                  "IMPLEMENTA" && (
                  <>
                    <Form.Item
                      name={[harmfulAgentInfo.name, "eficEpi"]}
                      label={labelsAndTooltips2240.eficEpi.label}
                      rules={[
                        {
                          required: true,
                          message: "Selecione uma opção.",
                        },
                        {
                          validator: validatorEPC_EPI,
                        },
                      ]}
                    >
                      <Radio.Group
                        onChange={() => validatorEPC_EPI()}
                        disabled={selectedUser === ""}
                      >
                        <Space direction="vertical">
                          <Radio value={true}>Sim</Radio>
                          <Radio value={false}>Não</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>

                    <Form.List
                      name={[harmfulAgentInfo.name, "docAvals"]}
                      forceRender
                      initialValue={[
                        {
                          fieldKey: 0,
                          isListField: true,
                          key: 0,
                          name: 0,
                        },
                      ]}
                      rules={[
                        {
                          validator: async (_, docAvals) => {
                            if (docAvals.length >= 50) {
                              setBlockAddDoc(true);
                            }
                          },
                        },
                      ]}
                    >
                      {(docAvals, { add, remove }, { errors }) => {
                        return (
                          <React.Fragment>
                            {docAvals.map((docAval) => (
                              <Form.Item
                                name={[docAval.name, "docAval"]}
                                label={labelsAndTooltips2240.docAval.label}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Descreva o documento ou informe a não aplicabilidade.",
                                  },
                                  {
                                    validator: validatorEPC_EPI,
                                  },
                                ]}
                              >
                                <TextArea
                                  onChange={() => {
                                    validatorEPC_EPI();
                                  }}
                                  disabled={selectedUser === ""}
                                  showCount
                                  autoSize={{
                                    minRows: 4,
                                    maxRows: 6,
                                  }}
                                  maxLength={255}
                                />
                              </Form.Item>
                            ))}
                            <Row className="add-doc-button-row">
                              <Tooltip
                                title={
                                  blockAddDoc
                                    ? "O número máximo de 50 documentos foi atingido."
                                    : null
                                }
                              >
                                <Button
                                  onClick={() => {
                                    add();
                                  }}
                                  disabled={selectedUser === "" || blockAddDoc}
                                >
                                  <IconSESI type="adicionar" /> ADICIONAR
                                  CERTIFICADO{" "}
                                  <Tooltip
                                    title={
                                      labelsAndTooltips2240.certificate.tooltip
                                    }
                                  >
                                    <QuestionCircleOutlined />
                                  </Tooltip>
                                </Button>
                              </Tooltip>
                            </Row>
                            <Form.ErrorList errors={errors} />
                          </React.Fragment>
                        );
                      }}
                    </Form.List>
                    <Collapse>
                      <Panel
                        header={labelsAndTooltips2240.requisitos06.header}
                        forceRender
                      >
                        <Form.Item
                          name={[harmfulAgentInfo.name, "medProtecao"]}
                          label={labelsAndTooltips2240.medProtecao.label}
                          rules={[
                            {
                              required: true,
                              message: "Selecione uma opção.",
                            },
                            {
                              validator: validatorEPC_EPI,
                            },
                          ]}
                        >
                          <Radio.Group
                            disabled={selectedUser === ""}
                            onChange={(event) => {
                              openInputChangeModal(
                                "medProtecao",
                                event,
                                harmfulAgentIndex
                              );
                              validatorEPC_EPI();
                            }}
                          >
                            <Space direction="vertical">
                              <Radio value={true}>Sim</Radio>
                              <Radio value={false}>Não</Radio>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          name={[harmfulAgentInfo.name, "condFuncto"]}
                          label={labelsAndTooltips2240.condFuncto.label}
                          rules={[
                            {
                              required: true,
                              message: "Selecione uma opção.",
                            },
                            {
                              validator: validatorEPC_EPI,
                            },
                          ]}
                        >
                          <Radio.Group
                            onChange={() => {
                              validatorEPC_EPI();
                            }}
                            disabled={selectedUser === ""}
                          >
                            <Space direction="vertical">
                              <Radio value={true}>Sim</Radio>
                              <Radio value={false}>Não</Radio>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          name={[harmfulAgentInfo.name, "usoInint"]}
                          label={labelsAndTooltips2240.usoInint.label}
                          rules={[
                            {
                              required: true,
                              message: "Selecione uma opção.",
                            },
                            {
                              validator: validatorEPC_EPI,
                            },
                          ]}
                        >
                          <Radio.Group
                            onChange={() => {
                              validatorEPC_EPI();
                            }}
                            disabled={selectedUser === ""}
                          >
                            <Space direction="vertical">
                              <Radio value={true}>Sim</Radio>
                              <Radio value={false}>Não</Radio>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          name={[harmfulAgentInfo.name, "przValid"]}
                          label={labelsAndTooltips2240.przValid.label}
                          rules={[
                            {
                              required: true,
                              message: "Selecione uma opção.",
                            },
                            {
                              validator: validatorEPC_EPI,
                            },
                          ]}
                        >
                          <Radio.Group
                            onChange={() => {
                              validatorEPC_EPI();
                            }}
                            disabled={selectedUser === ""}
                          >
                            <Space direction="vertical">
                              <Radio value={true}>Sim</Radio>
                              <Radio value={false}>Não</Radio>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          name={[harmfulAgentInfo.name, "periodicTroca"]}
                          label={labelsAndTooltips2240.periodicTroca.label}
                          rules={[
                            {
                              required: true,
                              message: "Selecione uma opção.",
                            },
                            {
                              validator: validatorEPC_EPI,
                            },
                          ]}
                        >
                          <Radio.Group
                            onChange={() => {
                              validatorEPC_EPI();
                            }}
                            disabled={selectedUser === ""}
                          >
                            <Space direction="vertical">
                              <Radio value={true}>Sim</Radio>
                              <Radio value={false}>Não</Radio>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          name={[harmfulAgentInfo.name, "higienizacao"]}
                          label={labelsAndTooltips2240.higienizacao.label}
                          rules={[
                            {
                              required: true,
                              message: "Selecione uma opção.",
                            },
                            {
                              validator: validatorEPC_EPI,
                            },
                          ]}
                        >
                          <Radio.Group
                            onChange={() => {
                              validatorEPC_EPI();
                            }}
                            disabled={selectedUser === ""}
                          >
                            <Space direction="vertical">
                              <Radio value={true}>Sim</Radio>
                              <Radio value={false}>Não</Radio>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                      </Panel>
                    </Collapse>
                  </>
                )}
              </Panel>
            </Collapse>
            {epc_epi_error && (
              <p className="error_epc_epi_collapse">
                Faltam opções obrigatórias no item acima
              </p>
            )}
          </>
        )}

        {agentesNocivos.length > 1 && (
          <div className="esocial-exams-button-div">
            <Button
              ghost
              className="add"
              onClick={handleRemoveHarmulAgent}
              disabled={selectedUser === "" || blockAddAgenteNocivo}
            >
              <IconSESI fill="white" type="trash-white" theme="filled" />
              Excluir Agente Nocivo
              <Tooltip title={labelsAndTooltips2240.deleteButton.label}>
                <QuestionCircleOutlined />
              </Tooltip>
            </Button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default HarmulAgentCard;
