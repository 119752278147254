import React, { useEffect, useState } from "react";
import Button from "../../../../components/Buttons/_/components/button";
import IconSESI from "../../../../components/IconsSESI";
import { Collapse, Form, Button as ButtonAntd, Select, Input } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { showRemoveRiskModal } from "../../../../components/modals";
import { excluirFatorDeRisco, getEPCEPIMAO } from "../actions";
import RiskCard from "./RiskCard";

const { Panel } = Collapse;
const { Option } = Select;

const RiskList = (props) => {
  const [form] = Form.useForm();
  const [inventarioDeRiscoDoProcesso, setInventarioDeRiscosDoProcesso] =
    useState({ inventarios: [], nome: "", processoProdutivoId: 0 });
  const [fatorDeRiscoSelected, setFatorDeRiscoSelected] = useState({});
  const [showSelectNewRisk, setShowSelectNewRisk] = useState(false);
  const [fatoresDeRiscoDisponiveis, setFatoresDeRiscoDisponiveis] = useState(
    []
  );
  const [fatoresDeRiscoTypes] = useState([
    "Físico",
    "Químico",
    "Biológico",
    "Ergonômico",
    "Acidente/Mecânico",
    "Inespecífico",
  ]);

  const [epiList, setEpiList] = useState([]);
  const [epcList, setEpcList] = useState([]);
  const [maoList, setMaoList] = useState([]);
  const [activeKey, setActiveKey] = useState(0);

  const onLoadScreen = async () => {
    setActiveKey(0);
    let epcEpiMaoList = await getEPCEPIMAO();

    epcEpiMaoList.epcs.length > 0 && setEpcList(epcEpiMaoList.epcs);
    epcEpiMaoList.epis.length > 0 && setEpiList(epcEpiMaoList.epis);
    epcEpiMaoList.maos.length > 0 && setMaoList(epcEpiMaoList.maos);
  };

  useEffect(() => {
    if (props.currentTab == "listOfRisks") {
      onLoadScreen();
    }
  }, [props.currentTab]);

  useEffect(() => {
    if (props.inventarioDeRiscoDoProcesso) {
      setInventarioDeRiscosDoProcesso(props.inventarioDeRiscoDoProcesso);
    }
  }, [JSON.stringify(props.inventarioDeRiscoDoProcesso)]);

  const showModal = (id) => {
    showRemoveRiskModal(() => excluirFatorDeRisco(props.dispatch, id));
  };

  useEffect(() => {
    if (props.fatoresDeRiscoDisponiveis) {
      setFatoresDeRiscoDisponiveis(props.fatoresDeRiscoDisponiveis);
    }
  }, [JSON.stringify(props.fatoresDeRiscoDisponiveis)]);

  const getButtonsHeader = (classification, type, id) => {
    return (
      <React.Fragment>
        <h5 className="header-fake-button">
          CLASSIFICAÇÃO: {classification === 1 ? "Quantitativo" : "Qualitativo"}
        </h5>
        <h5 className="header-fake-button">TIPO: {type}</h5>
        <ButtonAntd
          icon={<CloseOutlined />}
          danger
          onClick={() => showModal(id)}
        >
          EXCLUIR RISCO
        </ButtonAntd>
      </React.Fragment>
    );
  };

  const getRiskType = (typeId) => {
    switch (typeId) {
      case 1:
        return "Físico";
      case 2:
        return "Químico";
      case 3:
        return "Biológico";
      case 4:
        return "Ergonômico";
      case 5:
        return "Acidente";
      case 6:
        return "Inespecífico";
      default:
        break;
    }
  };

  const onSelectTipoFatorDeRisco = (id) => {
    props.getFatorDeRiscoPorTipo(props.dispatch, id);
  };

  const onSelectFatorDeRisco = (id) => {
    setFatorDeRiscoSelected(id);
  };

  const addFatorDeRisco = () => {
    form
      .validateFields()
      .then((values) => {
        const fatoresDeRiscoBody = {
          processoProdutivoId:
            inventarioDeRiscoDoProcesso.processoProdutivoDoCatalogoId
              ? inventarioDeRiscoDoProcesso.processoProdutivoDoCatalogoId
              : inventarioDeRiscoDoProcesso.processoProdutivoId,
          fatoresDeRisco: [
            {
              id: values.risk,
              fonteGeradora: values.generator,
            },
          ],
        };
        props.addFatorDeRisco(
          props.dispatch,
          fatoresDeRiscoBody,
          form.resetFields,
          setShowSelectNewRisk
        );
      })
      .catch((err) => console.log(err));
  };

  return (
    <React.Fragment>
      <div className="header-form ">
        <Button
          shape="circle"
          ghost
          text={<IconSESI type="arrow-left" />}
          onClick={() => {
            props.toggleTab();
            //props.getInventarioDeRisco(props.dispatch);
          }}
        />
        <h2>{`Processo: ${inventarioDeRiscoDoProcesso.nome}`}</h2>
      </div>
      <div className="content">
        <div className="add-risk-container">
          <ButtonAntd
            ghost
            className="add"
            onClick={() => setShowSelectNewRisk(true)}
          >
            <IconSESI type="add-circle" theme="filled" />
            Adicionar perigo
          </ButtonAntd>
          {showSelectNewRisk && (
            <div className="adicionar-risco-wrapper">
              <Form onFinish={addFatorDeRisco} layout="inline" form={form}>
                <Form.Item
                  label="Tipo de perigo"
                  name="riskType"
                  rules={[
                    {
                      required: true,
                      message: "Selecione um tipo!",
                    },
                  ]}
                >
                  <Select onSelect={onSelectTipoFatorDeRisco}>
                    {fatoresDeRiscoTypes.map((fatorDeRisco, index) => (
                      <Option key={index + 1} value={index + 1}>
                        {fatorDeRisco}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Perigo"
                  name="risk"
                  rules={[
                    {
                      required: true,
                      message: "Selecione um perigo!",
                    },
                  ]}
                  className="risk-name"
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onSelect={onSelectFatorDeRisco}
                    value={fatorDeRiscoSelected.id}
                  >
                    {fatoresDeRiscoDisponiveis
                      .filter((fatorDeRisco) => fatorDeRisco.ativo)
                      .map((fatorDeRisco) => (
                        <Option key={fatorDeRisco.id} value={fatorDeRisco.id}>
                          {fatorDeRisco.nome}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Fonte geradora do perigo"
                  name="generator"
                  rules={[
                    {
                      required: true,
                      message: "Selecione uma fonte geradora!",
                    },
                  ]}
                >
                  <Input type="text"></Input>
                </Form.Item>
                <Form.Item>
                  <Button
                    ghost
                    text="Adicionar"
                    style={{ padding: "6px 15px", width: "auto" }}
                    htmlType="submit"
                  />
                </Form.Item>
              </Form>
            </div>
          )}
        </div>
        {inventarioDeRiscoDoProcesso.inventarios ? (
          <div className="risks-container">
            <Collapse
              activeKey={activeKey}
              onChange={(key) => {
                setActiveKey(key[key.length - 1]);
              }}
            >
              {inventarioDeRiscoDoProcesso.inventarios.map((risco) =>
                risco.fatorDeRiscoNome.toLowerCase().includes("ausência") ? (
                  <Panel
                    header={<h4>{`PERIGO: ${risco.fatorDeRiscoNome}`}</h4>}
                    key={risco.id}
                    extra={getButtonsHeader(
                      risco.fatorDeRiscoClassificacao,
                      getRiskType(risco.fatorDeRiscoTipo),
                      risco.id
                    )}
                    collapsible={"disabled"}
                    className="not-collapsible"
                  ></Panel>
                ) : (
                  <Panel
                    header={<h4>{`PERIGO: ${risco.fatorDeRiscoNome}`}</h4>}
                    key={risco.id}
                    extra={getButtonsHeader(
                      risco.fatorDeRiscoClassificacao,
                      getRiskType(risco.fatorDeRiscoTipo),
                      risco.id
                    )}
                  >
                    <RiskCard
                      riskInventory={inventarioDeRiscoDoProcesso}
                      risk={risco}
                      dispatch={props.dispatch}
                      epcList={epcList}
                      epiList={epiList}
                      maoList={maoList}
                      activeKey={activeKey}
                    />
                  </Panel>
                )
              )}
            </Collapse>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default RiskList;
