import { Modal, Select } from "antd";
import React from "react";
import { CertificateModal } from "../certificate/certificateModal/index";

const { confirm } = Modal;
const { Option } = Select;

export const openCertificate_A3_Modal = (
  eventType,
  eventId,
  handleRefreshTable
) => {
  confirm({
    content: (
      <React.Fragment>
        <CertificateModal
          eventType={eventType}
          eventId={eventId}
          handleRefreshTable={handleRefreshTable}
          destroyModal={Modal.destroyAll}
        />
      </React.Fragment>
    ),
    closable: true,
    style: { marginTop: "-10rem" },
    // footer: null,
    // okButtonProps: { style: { display: "none" } },
    cancelButtonProps: { style: { display: "none" } },
    okText: "Fechar",
    className: `hideAssistent`,
  });
};
