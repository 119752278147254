import {
  RELATORIO_DIARIO_CARD,
  RELATORIO_MENSAL_CARD,
  RELATORIO_EMPRESAS_EFETIVADAS_CARD,
  DASHBOARD,
  RELATORIO_STATUS,
} from "../actions/types";

export default function (
  state = {
    relatorioDiarioCard: {},
    relatorioMensalCard: {},
    relatorioEmpresasEfetivadasCard: {},
    relatorioStatus: {},
    dashboard: {},
  },
  action
) {
  switch (action.type) {
    case RELATORIO_DIARIO_CARD:
      return {
        ...state,
        relatorioDiarioCard: action.payload,
      };
    case RELATORIO_MENSAL_CARD:
      return {
        ...state,
        relatorioMensalCard: action.payload,
      };
    case RELATORIO_EMPRESAS_EFETIVADAS_CARD:
      return {
        ...state,
        relatorioEmpresasEfetivadasCard: action.payload,
      };
    case DASHBOARD:
      return {
        ...state,
        dashboard: action.payload,
      };
    case RELATORIO_STATUS:
      return {
        ...state,
        relatorioStatus: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
