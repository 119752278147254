import React from "react";
import { NEXT_PAGE_INTRO } from "../components/Buttons/_/actions/types";

import viviRepousandoEsquerda from "./images/vivi-repousando-esquerda.svg";
import viviComemorandoDireita from "./images/vivi-comemorando-direita.svg";
import viviComemorandoEsquerda from "./images/vivi-comemorando-esquerda.svg";
import viviExplicandoDireita from "./images/vivi-explicando-direita.svg";
import viviExplicandoEsquerda from "./images/vivi-explicando-esquerda.svg";
import viviMostrandobaixoDireita from "./images/vivi-mostrandoabaixo-direita.svg";
import viviMostrandobaixoEsquerda from "./images/vivi-mostrandoabaixo-esquerda.svg";
import viviMostrandocimaEsquerda from "./images/vivi-mostrandoacima-esquerda.svg";
import viviPensandoDireita from "./images/vivi-pensando-direita.svg";
import viviPensandoEsquerda from "./images/vivi-pensando-esquerda.svg";
import viviRepousandoDireita from "./images/vivi-repousando-direita.svg";
import viviSorrindoDireita from "./images/vivi-sorrindo-direita.svg";
import viviSorrindoEsquerda from "./images/vivi-sorrindo-esquerda.svg";
import viviEmPe from "./images/vivi-em-pe.svg";
import viviEpi from "./images/vivi-epi.svg";
import viviAndando1 from "./images/vivi-andando-1.svg";
import viviAndando2 from "./images/vivi-andando-2.svg";
import viviAndando3 from "./images/vivi-andando-3.svg";

import aroldoRepousandoDireita from "./images/aroldo-repousando-direita.svg";
import aroldoComemorandoDireita from "./images/aroldo-comemorando-direita.svg";
import aroldoComemorandoEsquerda from "./images/aroldo-comemorando-esquerda.svg";
import aroldoExplicandoDireita from "./images/aroldo-explicando-direita.svg";
import aroldoExplicandoEsquerda from "./images/aroldo-explicando-esquerda.svg";
import aroldoMostrandobaixoDireita from "./images/aroldo-mostrandoabaixo-direita.svg";
import aroldoMostrandobaixoEsquerda from "./images/aroldo-mostrandoabaixo-esquerda.svg";
import aroldoMostrandocimaEsquerda from "./images/aroldo-mostrandoacima-esquerda.svg";
import aroldoPensandoDireita from "./images/aroldo-pensando-direita.svg";
import aroldoPensandoEsquerda from "./images/aroldo-pensando-esquerda.svg";
import aroldoRepousandoEsquerda from "./images/aroldo-repousando-esquerda.svg";
import aroldoSorrindoDireita from "./images/aroldo-sorrindo-direita.svg";
import aroldoSorrindoEsquerda from "./images/aroldo-sorrindo-esquerda.svg";
import { Checkbox, Tooltip } from "antd";
import { showRisksDefinitions } from "../components/modals";
import { Link } from "react-router-dom";

export const viewModes = {
  card: "card",
  default: "default",
  horizontal: "horizontal",
};

export const assistant = {
  vivi: {
    default: viviRepousandoEsquerda,
    comemorando_direta: viviComemorandoDireita,
    comemorando_esquerda: viviComemorandoEsquerda,
    explicando_direita: viviExplicandoDireita,
    explicando_esquerda: viviExplicandoEsquerda,
    mostrandoabaixo_direita: viviMostrandobaixoDireita,
    mostrandoabaixo_esquerda: viviMostrandobaixoEsquerda,
    mostrandoacima_esquerda: viviMostrandocimaEsquerda,
    pensando_direita: viviPensandoDireita,
    pensando_esquerda: viviPensandoEsquerda,
    repousando_direita: viviRepousandoDireita,
    repousando_esquerda: viviRepousandoEsquerda,
    sorrindo_direita: viviSorrindoDireita,
    sorrindo_esquerda: viviSorrindoEsquerda,
    em_pe: viviEmPe,
    epi: viviEpi,
    andando1: viviAndando1,
    andando2: viviAndando2,
    andando3: viviAndando3,
  },
  aroldo: {
    default: aroldoRepousandoDireita,
    comemorando_direta: aroldoComemorandoDireita,
    comemorando_esquerda: aroldoComemorandoEsquerda,
    explicando_direita: aroldoExplicandoDireita,
    explicando_esquerda: aroldoExplicandoEsquerda,
    mostrandoabaixo_direita: aroldoMostrandobaixoDireita,
    mostrandoabaixo_esquerda: aroldoMostrandobaixoEsquerda,
    mostrandoacima_esquerda: aroldoMostrandocimaEsquerda,
    pensando_direita: aroldoPensandoDireita,
    pensando_esquerda: aroldoPensandoEsquerda,
    repousando_direita: aroldoRepousandoDireita,
    repousando_esquerda: aroldoRepousandoEsquerda,
    sorrindo_direita: aroldoSorrindoDireita,
    sorrindo_esquerda: aroldoSorrindoEsquerda,
  },
};

export const introTalks = {
  1: {
    viewMode: viewModes.card,
    assistant: assistant.aroldo.sorrindo_direita,
    assistantHelp: assistant.vivi.sorrindo_direita,
    buttons: [
      {
        text: "Vamos começar ",
        type: "primary",
        action_type: NEXT_PAGE_INTRO,
      },
    ],
    text: [
      "Olá!",
      "Seja bem-vindo ao SESI Facilita.",
      "Somos a Vivi e o Aroldo.",
      "Vamos te auxiliar no preenchimento de algumas informações para elaboração do PPRA (Programa de Prevenção de Riscos Ambientais), para, em seguida, gerarmos o PCMSO (Programa de Controle Médico de Saúde Ocupacional) da sua empresa.",
    ],
  },
  // 2: {
  //   viewMode: viewModes.card,
  //   assistant: assistant.aroldo.explicando_esquerda,
  //   // links: [
  //   //   {
  //   //     text: "LISTA DE ITENS NECESSÁRIOS",
  //   //     href: "/itens",
  //   //     download: true
  //   //   }
  //   // ],
  //   buttons: [
  //     {
  //       text: "Vamos começar",
  //       type: "primary",
  //       action_type: NEXT_PAGE_INTRO
  //     }
  //   ],
  //   text: [
  //     "Para começar o preenchimento tenha em mãos os documentos listados abaixo.",
  //     "Com eles em mãos, clique no botão “VAMOS COMEÇAR”."
  //   ]
  // }
};

// const LINK = () => {
//   return (
//     <span>
//       Se precisar corrigir algum dado, acesse o{" "}
//       <a
//         href={`${process.env.REACT_APP_LIFERAY_URL}web/loja/acessos-ao-sistema`}
//         style={{ textDecoration: "underline" }}
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         SESI Facilita
//       </a>
//       : “minha conta” → “acessos ao sistema” → “dados adicionais”.
//     </span>
//   );
// };

const LINKSIGNATURE = () => {
  return (
    <p>
      Para começar, você deve acessar o link Gerenciar Assinaturas no menu
      lateral (ou <Link to={"/gerenciar-assinaturas"}>clicando aqui </Link>) e
      cadastrar sua assinatura digital. Depois disso, escolha a empresa que
      deseja enviar os eventos na lista ao lado e clique no botão “Gerar
      Eventos”.,
    </p>
  );
};

const HELPDANGERS = () => {
  return (
    <React.Fragment>
      <p>
        1ª Clique em “Adicionar perigo”, depois escolha o tipo, o perigo, a
        fonte geradora deste perigo, e depois o adicione. Tem dúvida em qual
        tipo de perigo?{" "}
        <span
          style={{ textDecoration: "underline" }}
          onClick={() => showRisksDefinitions()}
        >
          Clique aqui{" "}
        </span>
      </p>
    </React.Fragment>
  );
};

export const RISKSDESCRIPTION = (firstTalk) => {
  return (
    <React.Fragment>
      <p>
        {firstTalk
          ? "De acordo com a NR1 (Norma Regulamentadora) as empresas que possuem Grau de Risco 1 e 2 não precisam gerar o PGR, exceto se, sua empresa tiver fator de risco"
          : "A partir das suas respostas, tudo indica que sua empresa não precisa de um PGR, pois, de acordo com suas respostas, não identificamos exposição dos trabalhadores a fatores de riscos"}
        <Tooltip
          title={
            <p>
              Substâncias ou misturas, que em seu estado natural, ou geradas no
              processo de trabalho, possam penetrar no organismo do trabalhador
              pelas vias respiratórias, ou possam ter contato ou ser absorvido
              pelo organismo através da pele ou por ingestão (Ex.: trabalho com
              tintas e solventes, lubrificação de máquinas com óleo mineral).
            </p>
          }
        >
          <span style={{ textDecoration: "underline" }}> químico</span>
        </Tooltip>{" "}
        <Tooltip
          title={
            <p>
              Forma de energia, que, a depender da sua natureza, intensidade e
              tempo de exposição do trabalhador, é capaz de causar lesões ou
              agravos à saúde do trabalhador. Temos como principais exemplos:
              ruído, vibrações, temperaturas extremas (frio e calor), pressões
              anormais e as radiações ionizantes (Ex.: RX, Alfa, Gama) e não
              ionizantes (Ex.: radiação UV, IV, Laser).
            </p>
          }
        >
          <span style={{ textDecoration: "underline" }}>, físico</span>
        </Tooltip>{" "}
        <Tooltip
          title={
            <p>
              São os diversos tipos de bactérias, vírus, fungos, parasitas,
              entre outros associados à determinadas atividades que podem
              acarretar lesão ou agravos à saúde do trabalhador (Ex.: trabalho
              em hospitais, trabalho em redes de esgoto).
            </p>
          }
        >
          <span style={{ textDecoration: "underline" }}>ou biológico.</span>
        </Tooltip>{" "}
      </p>
    </React.Fragment>
  );
};

export const AUTODECLARAOFINALRISKS = () => (
  <React.Fragment>
    <p>
      Se ainda assim, você quiser elaborar o PGR da sua empresa, basta clicar em
      “Ir para o PGR”, para fazer o levantamento para identificação e avaliação
      dos fatores de riscos
      <Tooltip
        title={
          <p>
            São as condições de trabalho em que os trabalhadores estão expostos,
            que podem desencadear lesões ou agravos à sua saúde, caso não
            estejam adaptadas às características psicofisiológica dos
            trabalhadores. Estão relacionados ao levantamento, transporte e
            descarga de materiais; ao mobiliário dos postos de trabalho; ao
            trabalho com máquinas, equipamentos e ferramentas manuais; às
            condições de conforto no ambiente de trabalho e à própria
            organização do trabalho.
          </p>
        }
      >
        <span style={{ textDecoration: "underline" }}> ergonômicos</span>
      </Tooltip>{" "}
      <Tooltip
        title={
          <p>
            São as condições do ambiente de trabalho, relacionadas à estrutura
            física, máquinas ou equipamentos, que podem acarretar acidentes de
            trabalho. Como exemplos, temos: choque elétrico, picada de animais
            peçonhentos, agarramento/enroscamento em máquinas ou equipamentos,
            queimadura, incêndio, explosão etc.
          </p>
        }
      >
        <span style={{ textDecoration: "underline" }}> e mecânicos.</span>
      </Tooltip>{" "}
    </p>
  </React.Fragment>
);

export const CAPTURALEAD = () => {
  return (
    <Checkbox defaultChecked id="lead-checkbox">
      Ao concluir meu PGR, desejo que o SESI entre em contato comigo para me
      apresentar os próximos passos que precisam ser dados para manter minha
      empresa atualizada e atender a outros requisitos legais de segurança e
      saúde no trabalho.
    </Checkbox>
  );
};

export const itensTalk = {
  viewMode: viewModes.card,
  assistant: assistant.aroldo.mostrandoabaixo_esquerda,
  text: ["Marque na lista os itens que já possui e imprima."],
};

export const talksEmpresa = (text) => {
  return {
    1: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.sorrindo_esquerda,
      buttons: null,
      text: text,
    },
  };
};

export const talksSetores = (text) => {
  return {
    2: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.mostrandoacima_esquerda,
      buttons: null,
      text: text,
    },
    3: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.explicando_esquerda,
      buttons: null,
      text: text,
    },
  };
};

export const talksTrabalhadores = (text) => {
  return {
    4: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.mostrandoacima_esquerda,
      buttons: null,
      text: text,
    },
    5: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.epi,
      buttons: null,
      text: text,
    },
  };
};

export const talksProcessos = (text) => {
  return {
    6: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.mostrandoacima_esquerda,
      buttons: null,
      text: text,
    },
    7: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.explicando_esquerda,
      buttons: null,
      text: text,
    },
  };
};

export const talksRealizadorProcesso = (text) => {
  return {
    8: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.mostrandoacima_esquerda,
      buttons: null,
      text: text,
    },
  };
};

export const stepsTalks = {
  talksMaquinas: {
    1: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.mostrandoacima_esquerda,
      buttons: null,
      text: [
        "Também precisamos conhecer as máquinas da empresa.",
        "Se tiver dúvidas sobre as máquinas que devem ser cadastradas, clique no saiba mais. ",
        "Em seguida, clique no botão “Adicionar Máquina“.",
      ],
      saibaMais: [
        "O conceito inclui somente máquina e equipamento de uso não doméstico, e movido por força não humana.",
        "Relacione as máquinas que geram algum tipo de perigo, tais como ruído, calor, vibração, poeira, etc.",
      ],
    },
    2: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.explicando_esquerda,
      buttons: null,
      text: [
        "Insira as máquinas da empresa. Se não encontrar na lista, clique em “OUTRO“ e digite o nome dela.",
        "Selecione a(s) atividade(s) ou o(s) setor(es) relacionadas a cada máquina.",
        "Lembre-se: é importante anexar fotos das máquinas.",
        "Confira se todas as máquinas foram adicionadas e salvas, para poder continuar ao próximo passo.",
      ],
    },
  },
  talksProdQuim: {
    1: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.mostrandoacima_esquerda,
      buttons: null,
      text: [
        "Informe quais produtos químicos são usados na produção.",
        "Os produtos químicos podem representar riscos aos trabalhadores. Alguns deles podem penetrar no organismo por via respiratória, em forma de poeira, fumo, névoa, neblina, gás ou vapor. Também podem ser absorvidos pela pele ou por ingestão.",
        "Se tiver dúvidas sobre produtos químicos, clique no botão: “saiba mais”.",
        "Em seguida, clique no botão “Adicionar Produto Químico”, para me apresentar quais são utilizados na empresa.",
      ],
      saibaMais: [
        "Produto químico é uma substância formada por um ou mais compostos químicos que lhe permitem cumprir com uma determina função.",
        "Muitas empresas utilizam produtos químicos em diversas atividades como, pintura, soldagem, fundição, tinturaria, etc. Inclusive na higienização de instalações sanitárias (Por exemplo: água sanitária, soda cáustica).",
        "Nas empresas, geralmente os produtos químicos ficam acondicionados em prateleiras, armários ou em áreas especificas de armazenamento.",
      ],
    },
    2: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.explicando_esquerda,
      buttons: null,
      text: [
        "Informe o nome do produto, sua forma física e a substância química presente. Algumas dessas informações podem ser retiradas dos rótulos dos produtos.",
        "Selecione o setor ou a atividade que usa o produto químico.",
        "Atenção! É necessário cadastrar todos os produtos químicos usados em todas as atividades.",
        "Não conseguiu identificar o nome das substâncias químicas? Pode inserir uma foto do rótulo do produto.",
        "Confira se todos os produtos químicos foram listados, salve e continue com o próximo passo!",
      ],
    },
  },
  talksFatoresRisco: {
    1: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.mostrandoacima_esquerda,
      buttons: null,
      text: [
        "Já estamos quase finalizando o cadastro.",
        "Nessa etapa, vamos relacionar os fatores de riscos que podem causar danos à saúde do trabalhador. Veja em nosso glossário o conceito de dano.",
        "Os fatores de riscos podem ser agentes físicos, químicos, biológicos, mas também os ergonômicos e os geradores de acidentes de trabalho. Os riscos dependem da função, natureza, concentração, intensidade e/ou do tempo de exposição a esses fatores.",
        "Explicação sobre fatores de risco no “saiba mais”.",
        "Em seguida, clique no botão “Adicionar Fator de Risco”.",
      ],
      saibaMais: [
        "Consideram-se fatores de riscos os agentes físicos, químicos, biológicos, ergonômicos e de acidentes existentes nos ambientes de trabalho que, em função de sua natureza, concentração ou intensidade e tempo de exposição, são capazes de causar danos à saúde do trabalhador.",
        "Os agentes físicos são as diversas formas de energia a que possam estar expostos os trabalhadores, tais como: ruído, vibrações, pressões anormais, temperaturas extremas, radiações ionizantes, radiações não ionizantes, bem como o infra-som e o ultra-som.",
        "Com relação aos agentes químicos, são substâncias, compostos, misturas ou produtos que podem penetrar no organismo do trabalhador, pela via respiratória, nas formas de poeiras, fumos gases, neblinas, nevoas ou vapores, ou que seja, pela natureza da atividade, de exposição, possam ter contato ou ser absorvido pelo organismo através da pele ou por ingestão.",
        "Consideram-se agentes biológicos as bactérias, fungos, bacilos, parasitas, protozoários, vírus, entre outros",
        "São considerados fatores de riscos ergonômicos: esforço físico, levantamento de peso, postura inadequada, controle rígido de produtividade, situação de estresse, trabalhos em período noturno, jornada de trabalho prolongada, monotonia e repetitividade, imposição de rotina intensa.",
        "São exemplos de fatores de risco de acidente: as máquinas e equipamentos sem proteção, probabilidade de incêndio e explosão, arranjo físico inadequado, armazenamento inadequado, trabalho em altura, dentre outros.",
      ],
    },
    2: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.explicando_esquerda,
      buttons: null,
      text: [
        "Selecione o fator de risco.",
        "Identifique em quais atividades podem estar presentes. Logo após, clique em “salvar“.",
        "Atenção! É necessário cadastrar todos os fatores de risco de todas as atividades.",
        "Não se preocupe, caso tenha colocado algum fator de risco que não esteja presente, você pode excluí-lo a qualquer momento.",
        "Antes de finalizar e seguir adiante, verifique se todos os fatores de risco foram listados e relacionados.",
      ],
    },
  },
  talksEspacoFisico: {
    1: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.mostrandoacima_esquerda,
      buttons: null,
      text: [
        "É importante conhecer o espaço físico da empresa para realizar a caracterização do local, e assim, gerar o seu PPRA e seu PCMSO.",
        "Siga o passo-a-passo:",
        "1º- Selecione um setor (já cadastrado anteriormente) pra caracterizar seu espaço físico.",
        "2º- Na região lateral direita da tela selecione o botão “modelo de planta”. Em seguida aparecerá uma lista de formas geométricas, escolha a que mais se assemelha ao espaço físico desse setor. Ao selecioná-la, ela aparecerá automaticamente no espaço de edição. Caso queira mudar, é só clicar em outra forma geométrica.",
        "3º- Após escolher a forma geométrica mais semelhante ao seu espaço, clique no botão “voltar” localizado no topo da lista de formas (condicionado à ideia abaixo).",
        "4º- Coloque as atividades dentro desse espaço clicando em “selecionar atividade”.",
        "5º- IMPORTANTE: A lista de atividades que aparece na tela são as atividades já cadastradas apenas para esse setor. Clique nas atividades e em seguida elas aparecerão no espaço de edição.",
        "6º- Disponha as atividades dentro do setor de acordo com o que acontece no dia a dia da empresa. Para isso, clique na atividade e arraste até o local correto com o mouse ainda clicado.",
        "7º- Após fazer isso com todas as atividades, para sair da listagem de atividades basta clicar no botão voltar no topo da listagem.",
        "8º- Agora é só voltar para a listagem de setores, no botão que fica ao lado do nome do setor e repetir esse processo para todos os setores cadastrados.",
      ],
    },
  },
  talksPlanoAcao: {
    1: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.sorrindo_direita,
      buttons: null,
      text: [
        "Bem-vindo ao Plano de Ação!",
        "Aqui nós iremos juntos fazer o Plano de Ação da sua empresa. Ele será feito por fator de risco.",
        " É bem fácil, eu te ajudo!",
        "Clique em cada perigo e você perceberá que o sistema oferece algumas sugestões de acordo com o perigo encontrado, você pode aceitá-las ou removê-las. Ao remover uma sugestão ela não volta mais e ao aceitar você poderá editar somente o nome do responsável e o prazo.",
        "Observe que para cada perigo são apresentados os processos em que este perigo está presente e, também é apresentada a prioridade de ação por processo, de acordo com o nível de risco encontrado.",
        "Terminou o Plano de Ação? Então, siga para a geração do seu PGR!",
      ],
    },
    2: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.sorrindo_direita,
      buttons: null,
      text: [
        "Você clicou em “Adicionar Ação”.",
        " Agora você só precisa nos dizer uma ação para o fator de risco, o nome do responsável e o prazo previsto. Ao finalizar clique em salvar.",
      ],
    },
    3: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.sorrindo_direita,
      buttons: null,
      text: [
        "Parabéns!",
        "A ação que acabou de criar foi adicionada com sucesso.",
      ],
    },
    4: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.sorrindo_direita,
      buttons: null,
      text: [
        "Olhe! Sua ação criada já foi para a lista.",
        "Você pode criar mais ou usar das nossas sugestões.",
      ],
    },
    5: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.sorrindo_direita,
      buttons: null,
      text: [
        "Vi que aceitou uma de nossas sugestões.",
        "Você não poderá alterar a ação, já que foi uma ação sugerida. É necessário que nos informe o Responsável e o Prazo previsto para a Ação.",
      ],
    },
  },
  talksFinishedStep: {
    1: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.default,
      buttons: null,
      text: [
        "Você concluiu essa etapa!",
        "Caso precise fazer alguma alteração, desmarque o campo de 'Etapa concluída'.",
      ],
    },
  },
};

export const companySelectionTalks = (text) => {
  return {
    1: {
      viewMode: viewModes.card,
      assistant: assistant.aroldo.mostrandoabaixo_esquerda,
      buttons: null,
      text: text,
    },
    2: {
      viewMode: viewModes.horizontal,
      assistant: assistant.aroldo.explicando_direita,
      buttons: null,
      text: text,
    },
    3: {
      viewMode: viewModes.card,
      assistant: assistant.aroldo.explicando_esquerda,
      buttons: null,
      text: ["Selecione a empresa."],
    },
  };
};

export const talksAcompanhamento = (text, naoEditavel) => {
  const arrangeTalk = (text, naoEditavel) => {
    let textWithoutUnchangeableParagraph = text.filter(
      (item) => item.indexOf("Ao concluir meu PGR") === -1
    );
    textWithoutUnchangeableParagraph.splice(
      parseInt(naoEditavel[0]),
      0,
      CAPTURALEAD()
    );
    return textWithoutUnchangeableParagraph;
  };
  return {
    1: {
      viewMode: viewModes.card,
      assistant: assistant.vivi.explicando_esquerda,
      buttons: [],
      text: arrangeTalk(text, naoEditavel),
    },
  };
};

export const talksInventariodeRiscos = (text, naoEditavel) => {
  const arrangeTalk = (text, naoEditavel) => {
    let textWithoutUnchangeableParagraph = text.filter(
      (item) => item.indexOf("1ª Clique em") === -1
    );
    textWithoutUnchangeableParagraph.splice(
      parseInt(naoEditavel[0]),
      0,
      HELPDANGERS()
    );
    return textWithoutUnchangeableParagraph;
  };
  return {
    1: {
      viewMode: viewModes.card,
      assistant: assistant.vivi.explicando_esquerda,
      buttons: [],
      text: text,
    },
    2: {
      viewMode: viewModes.card,
      assistant: assistant.vivi.explicando_esquerda,
      buttons: [],
      text: arrangeTalk(text, naoEditavel),
    },
  };
};

export const talksPlanoDeAcao = (text) => {
  return {
    1: {
      viewMode: viewModes.card,
      assistant: assistant.vivi.sorrindo_esquerda,
      buttons: [],
      text: text,
    },
  };
};

export const acompanhamentoTalks = {
  0: {
    viewMode: viewModes.card,
    assistant: assistant.vivi.explicando_esquerda,
    buttons: [],
    text: [
      "PGR - Programa de Gerenciamento de Riscos",
      "O PGR tem o intuito de gerenciar os riscos existentes no local de suas atividades. Ele é formado pelo inventário de riscos e plano de ação.",
      "No inventário de riscos, a partir de cada processo produtivo criado na jornada anterior, você irá realizar uma série de ações que servirão para nos explicar os métodos de trabalho de sua empresa, processos, riscos e grupo exposto aos riscos.",
      "Já o plano de ação é onde trazemos para você algumas sugestões* apropriadas para os perigos caracterizados no inventário, além de você mesmo poder criar uma ação personalizada, direcionar uma pessoa de sua empresa como responsável, além de especificar datas de início e fim para realizar esta ação.",
      "*Para as empresas com CNAES que sugerimos os processos produtivos na jornada anterior.",
      CAPTURALEAD(),
    ],
  },
  1: {
    viewMode: viewModes.card,
    assistant: assistant.vivi.explicando_esquerda,
    buttons: [],
    text: [
      "Se o processo produtivo foi sugerido de acordo com o seu CNAE na etapa de caracterização da empresa, aqui já serão apresentados os perigos e as características destes perigos de modo a representar o que ocorre de forma mais frequente neste tipo de processo x CNAE.",
      "Para visualizar as características destes perigos, ou adicionar novos, clique em “Ver perigos”. ",
      "Para cada perigo sugerido do CNAE é necessário clicar em salvar ao menos um vez, para confirmar os dados. Ao confirmar todos os perigos de um processo, o processo está concluído. Os perigos já confirmados apresentam um check ao lado do nome.",
      "Terminou de caracterizar os perigos do inventário e avaliar os riscos? Selecione no menu lateral o botão “Elaboração PGR”, e siga para elaboração do Plano de Ação!",
    ],
  },
  2: {
    viewMode: viewModes.card,
    assistant: assistant.vivi.explicando_esquerda,
    buttons: [],
    text: [
      "Caso realmente não exista algum perigo dos sugeridos, você pode clicar em “Excluir Risco”, ou até mesmo adicionar outros perigos não contemplados, assim como ocorrerá para os processos produtivos novos criados por você.",
      "Para isso, siga os seguintes passos:",
      HELPDANGERS(),
      "2º Para cada perigo adicionado/sugerido, analisando o ambiente e ouvindo os trabalhadores, você deve definir a frequência de exposição, o tempo de exposição, a percepção de intensidade, se houve alguma medição (Avaliação ambiental) deste perigo, incluir o resultado, informar se há registros de doenças/acidentes relacionados a esse perigo, e o que a empresa implementa de controles, como medidas de proteção coletiva (MPC), de proteção individual (EPI), ou medidas administrativas.  Para isso, basta clicar em cada um deles, escolher as opções disponibilizadas, e então clicar em salvar!",
      "3º Inclua o próximo perigo, se for o caso. Ao concluir este processo, clique na setinha à esquerda ao lado do nome do processo para voltar, e realize o mesmo procedimento para o próximo processo produtivo.",
    ],
  },
};

export const talksAutodeclaracao = (text, naoEditavel) => {
  const arrangeTalk1 = (text, naoEditavel) => {
    let textWithoutUnchangeableParagraph = text.filter(
      (item) =>
        item.indexOf(
          "De acordo com a NR1 (Norma Regulamentadora) as empresas que possuem Grau de Risco 1 e 2 não precisam gerar o PGR"
        ) === -1
    );
    textWithoutUnchangeableParagraph.splice(
      parseInt(naoEditavel[0]),
      0,
      RISKSDESCRIPTION(true)
    );
    return textWithoutUnchangeableParagraph;
  };
  const arrangeTalk2 = (text, naoEditavel) => {
    let textWithoutUnchangeableParagraph = text.filter(
      (item) =>
        item.indexOf(
          "A partir das suas respostas, tudo indica que sua empresa não precisa de um PGR"
        ) === -1
    );
    textWithoutUnchangeableParagraph = textWithoutUnchangeableParagraph.filter(
      (item) =>
        item.indexOf(
          "Se ainda assim, você quiser elaborar o PGR da sua empresa"
        ) === -1
    );
    textWithoutUnchangeableParagraph = textWithoutUnchangeableParagraph.filter(
      (item) =>
        item.indexOf(
          "Ao concluir meu PGR, desejo que o SESI entre em contato comigo para me apresentar"
        ) === -1
    );
    textWithoutUnchangeableParagraph.splice(
      parseInt(naoEditavel[0]),
      0,
      RISKSDESCRIPTION(false)
    );
    textWithoutUnchangeableParagraph.splice(
      parseInt(naoEditavel[1]),
      0,
      AUTODECLARAOFINALRISKS()
    );
    textWithoutUnchangeableParagraph.splice(
      parseInt(naoEditavel[2]),
      0,
      CAPTURALEAD()
    );
    return textWithoutUnchangeableParagraph;
  };
  return {
    1: {
      viewMode: viewModes.horizontal,
      assistant: assistant.vivi.sorrindo_direita,
      buttons: null,
      text: arrangeTalk1(text, naoEditavel),
    },
    2: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.sorrindo_esquerda,
      buttons: null,
      text: text,
    },
    3: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.sorrindo_esquerda,
      buttons: null,
      text: text,
    },
    4: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.sorrindo_esquerda,
      buttons: null,
      text: text,
    },
    5: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.sorrindo_esquerda,
      buttons: null,
      text: text,
    },
    6: {
      viewMode: viewModes.default,
      assistant: assistant.vivi.sorrindo_esquerda,
      buttons: null,
      text: text,
    },
    7: {
      viewMode: viewModes.horizontal,
      assistant: assistant.vivi.sorrindo_direita,
      buttons: null,
      text: arrangeTalk2(text, naoEditavel),
    },
  };
};

export const talkResumo = {
  1: {
    viewMode: viewModes.card,
    assistant: assistant.aroldo.repousando_esquerda,
    buttons: null,
    text: [
      "Esqueceu de algo? Ou tem algum erro? Confira todas as telas antes de concluir.",
      "Antes de enviar para análise você pode editar ou excluir quaisquer informações. Se precisar, use nossos recursos disponíveis, com os filtros ou a busca, nas abas, para localizar alguma informação.",
      "Não esqueça que para concluir seu trabalho e efetuar o envio do documento ao técnico do SESI para análise, você tem de marcar “etapa concluída” em todas as abas e clicar abaixo em “Concluir todo o PPRA”.",
    ],
  },
};

export const talkPerfil = {
  1: {
    viewMode: viewModes.card,
    assistant: assistant.aroldo.mostrandoabaixo_esquerda,
    buttons: null,
    text: [
      "Nessa página você pode customizar o sistema como preferir. ",
      "Fique à vontade para colocar um nome e uma imagem representando sua empresa ou perfil.",
      "Além disso, pode alterar a cor do sistema ou até mesmo colocar uma cor para cada empresa, se no seu cadastro tiver mais de uma empresa.",
    ],
  },
};

export const talksPlanoAcao = {
  1: {
    viewMode: viewModes.card,
    assistant: assistant.vivi.sorrindo_esquerda,
    buttons: null,
    text: [
      "Bem-vindo ao Plano de Ação!",
      "Aqui nós iremos juntos fazer o Plano de Ação da sua empresa. Ele será feito por fator de risco.",
      " É bem fácil, eu te ajudo!",
      "Clique em cada perigo e você perceberá que o sistema oferece* algumas sugestões de acordo com o perigo encontrado, você pode aceitá-las ou removê-las. Ao remover uma sugestão ela não volta mais e ao aceitar você poderá editar somente o nome do responsável e o prazo.",
      "Ao concluir todas as ações previstas para este perigo, clique no botão “CONCLUIR AÇÕES DO FATOR DE RISCO”, e parta para as ações do próximo.",
      "Observe que para cada perigo são apresentados os processos em que este perigo está presente e, também é apresentada a prioridade de ação por processo, de acordo com o nível de risco encontrado.",
      "Terminou o Plano de Ação? Então, siga para a geração do seu PGR! Basta clicar na seta de voltar e em seguida no botão “PRÓXIMO PASSO” e seguir para a última etapa, gerar o PGR!",
      "*Lembre-se que os novos processos produtivos adicionados, não temos as sugestões de ações, você precisa adicioná-los neste momento.",
    ],
  },
  2: {
    viewMode: viewModes.card,
    assistant: assistant.vivi.sorrindo_esquerda,
    buttons: null,
    text: [
      "Você clicou em “Adicionar Ação”.",
      " Agora você só precisa nos dizer uma ação para o fator de risco, o nome do responsável e o prazo previsto. Ao finalizar clique em salvar.",
    ],
  },
  3: {
    viewMode: viewModes.card,
    assistant: assistant.vivi.sorrindo_esquerda,
    buttons: null,
    text: [
      "Parabéns!",
      "A ação que acabou de criar foi adicionada com sucesso.",
    ],
  },
  4: {
    viewMode: viewModes.card,
    assistant: assistant.vivi.sorrindo_esquerda,
    buttons: null,
    text: [
      "Olhe! Sua ação criada já foi para a lista.",
      "Você pode criar mais ou usar das nossas sugestões.",
    ],
  },
  5: {
    viewMode: viewModes.card,
    assistant: assistant.vivi.sorrindo_esquerda,
    buttons: null,
    text: [
      "Vi que aceitou uma de nossas sugestões.",
      "Você não poderá alterar a ação, já que foi uma ação sugerida. É necessário que nos informe o Responsável e o Prazo previsto para a Ação.",
    ],
  },
};

export const autodeclaracaoTalks = {
  0: {
    viewMode: viewModes.horizontal,
    assistant: assistant.vivi.sorrindo_direita,
    buttons: null,
    text: [
      "Olá, eu sou a Vivi, colega virtual do Aroldo! Estou também aqui para te ajudar nos próximos passos!",
      "Como a sua empresa é de grau de risco 1 ou 2, neste momento vamos juntos fazer um levantamento para identificar se sua empresa precisa emitir uma “Declaração de Dispensa do programa de Gerenciamento de riscos – PGR” junto ao Governo Federal ou se precisa gerar o seu PGR.",
      "Para isso, basta que você nos responda algumas perguntas importantes e bem rápidas, para que possamos identificar se existem fatores de riscos na sua empresa.",
      RISKSDESCRIPTION(true),
      "Clique em AVANÇAR no botão abaixo e vamos lá!",
    ],
  },
  1: {
    viewMode: viewModes.default,
    assistant: assistant.vivi.sorrindo_esquerda,
    buttons: null,
    text: [
      "O conceito inclui somente máquina e equipamento de uso não doméstico, e movido por força não humana.",
    ],
  },
  2: {
    viewMode: viewModes.default,
    assistant: assistant.vivi.sorrindo_esquerda,
    buttons: null,
    text: [
      "Produto químico é formado por uma substância, composto ou mistura, que lhe permitem cumprir com uma determina função, como por exemplo, colar, pintar, limpar, diluir, entre outros.",
      "Muitas empresas utilizam produtos químicos, como matérias-primas ou insumos em diversas atividades como, pintura, soldagem, fundição, tinturaria, atividades de limpeza, lubrificação etc.",
      "Os trabalhadores podem estar expostos a perigos químicos quando manuseiam ou estão em ambientes onde eles são manipulados. A exposição pode ocorrer por meio das vias respiratórias (gases, vapores, neblinas, névoas, fumaças e fumos) e pelo contato com a pele e olhos.",
    ],
  },
  3: {
    viewMode: viewModes.default,
    assistant: assistant.vivi.sorrindo_esquerda,
    buttons: null,
    text: [
      "Produto químico é formado por uma substância, composto ou mistura, que lhe permitem cumprir com uma determina função, como por exemplo, colar, pintar, limpar, diluir, entre outros.",
      "Muitas empresas utilizam produtos químicos, como matérias-primas ou insumos em diversas atividades como, pintura, soldagem, fundição, tinturaria, atividades de limpeza, lubrificação etc.",
      "Os trabalhadores podem estar expostos a perigos químicos quando manuseiam ou estão em ambientes onde eles são manipulados. A exposição pode ocorrer por meio das vias respiratórias (gases, vapores, neblinas, névoas, fumaças e fumos) e pelo contato com a pele e olhos.",
    ],
  },
  4: {
    viewMode: viewModes.default,
    assistant: assistant.vivi.sorrindo_esquerda,
    buttons: null,
    text: [
      "Além das máquinas, existem outras situações ou atividades em que o trabalhador está exposto a fatores de riscos físicos. Verifique a partir desta pergunta se na sua empresa contempla algum desses fatores",
    ],
  },
  5: {
    viewMode: viewModes.default,
    assistant: assistant.vivi.sorrindo_esquerda,
    buttons: null,
    text: [
      "Consideram-se agentes biológicos as bactérias, fungos, bacilos, parasitas, protozoários, vírus, entre outros.",
    ],
  },
  6: {
    viewMode: viewModes.horizontal,
    assistant: assistant.vivi.sorrindo_direita,
    buttons: null,
    text: [
      "Chegamos até aqui!",
      RISKSDESCRIPTION(false),
      "Sendo assim, você pode agora gerar um relatório de autodeclaração de ausência de riscos, e assim receber todas as orientações necessárias para fazer seu registro no portal do Governo Federal!",
      "Se desejar, selecione agora a imagem com a logomarca da empresa, e depois é só clicar no botão “Gerar Autodeclaração” e em seguida na caixinha do download. Agora é só abrir este documento, salvar em seu computador e depois seguir todas as instruções apresentadas para gerar o recibo com a “Declaração de Dispensa do programa de Gerenciamento de riscos – PGR” junto ao Governo Federal.",
      "Guarde este documento juntamente com a declaração emitida pelo site do governo, para apresentar em caso de fiscalizações do trabalho.",
      AUTODECLARAOFINALRISKS(),
      CAPTURALEAD(),
    ],
  },
};

export const downloadDocumentosTalk = {
  0: {
    viewMode: viewModes.default,
    assistant: assistant.vivi.sorrindo_esquerda,
    buttons: null,
    text: [
      "Olá, aqui você pode ver e realizar o download de documentos já emitidos em nosso sistema!",
      "Selecione a empresa, escolha qual a versão do documento desejada e em seguida clique no respectivo ícone de download, para baixar o documento para seu computador.",
    ],
  },
};

export const ESocialTalks = {
  0: {
    viewMode: viewModes.horizontal,
    assistant: assistant.vivi.sorrindo_direita,
    buttons: null,
    text: [
      "Bem-vindo! Gerencie os eventos do eSocial com facilidade.",
      "Este recurso exclusivo está disponível para nossos usuários Premium.",
      "Clique no botão abaixo para descobrir mais e saiba como manter sua empresa em conformidade com todas as obrigações legais.",
    ],
  },
  1: {
    viewMode: viewModes.default,
    assistant: assistant.vivi.mostrandoacima_esquerda,
    buttons: null,
    text: [
      "Olá! Se você é dono de uma micro ou pequena empresa, é importante saber que o governo federal exige o envio de informações de Segurança e Saúde do Trabalhador (SST) para o eSocial. Mas não se preocupe, estamos aqui para ajudá-lo a enviar esses eventos.",
      LINKSIGNATURE(),
      "Para realizar os envios para o governo dos eventos já preenchidos e/ou retificações e/ou exclusões, clique no botão “Acompanhar Eventos”.",
    ],
  },
  2: {
    viewMode: viewModes.default,
    assistant: assistant.vivi.mostrandoacima_esquerda,
    buttons: null,
    text: [
      "Escolha agora qual evento deseja gerar e clique no botão “PRÓXIMO PASSO” ",
      "No SESI Facilita estão disponíveis os seguintes leiautes:",
      "S-2210: para comunicar acidentes de trabalho. Em caso de acidente, a empresa precisa informar o governo dentro de 24 horas. Em casos de óbitos, imediatamente. ",
      "S-2220: para enviar os Atestados de Saúde Ocupacional (ASO) dos trabalhadores. É necessário enviar este evento até o dia 15 do mês seguinte da emissão do ASO. ",
      "S-2240: para enviar informações sobre os perigos aos quais os trabalhadores estão expostos. É necessário fazer o primeiro envio e, posteriormente, enviar as mudanças de perigos identificados no ambiente de trabalho. Se não houver riscos, a empresa precisa comunicar isso por meio deste evento até o dia 15 do mês subsequente.",
      "É importante lembrar que, para que o governo receba corretamente os dados necessários, as informações referentes às fases anteriores do eSocial, que envolvem o cadastro da empresa e dos trabalhadores, precisam ter sido enviadas. Se você não tem certeza se essas informações foram enviadas corretamente, converse com o seu contador para esclarecer quaisquer dúvidas que você possa ter.",
      "Se precisar de ajuda, estamos aqui para ajudá-lo. Você pode entrar em contato com nosso suporte ou acessar a central de ajuda no menu lateral.",
    ],
  },
  3: {
    viewMode: viewModes.default,
    assistant: assistant.vivi.mostrandoacima_esquerda,
    buttons: null,
    text: [
      "Preencha os dados do formulário ao lado. O símbolo “*”, significa que são dados obrigatórios.",
      "Os campos que possuem (?) possuem maiores esclarecimentos.",
      "Observação importante: ",
      "O primeiro evento do eSocial em que a empresa informa a matrícula do trabalhador é o evento S-2200 - Cadastramento Inicial do Vínculo e Admissão/Ingresso de Trabalhador. Neste evento, a empresa informa os dados cadastrais e os dados do contrato de trabalho do empregado, incluindo a matrícula, o CPF, a data de admissão, o cargo, o salário e outras informações relevantes.",
      "O S-2200 é um dos eventos iniciais do eSocial e deve ser enviado pelas empresas antes do início das atividades do trabalhador. É importante que as informações prestadas sejam precisas e completas para evitar erros ou inconsistências nos demais eventos do eSocial.",
    ],
  },
  4: {
    viewMode: viewModes.default,
    assistant: assistant.vivi.mostrandoacima_esquerda,
    buttons: null,
    text: [
      "Na tela de acompanhamento de eventos, você pode verificar todos os eventos que foram salvos anteriormente, clicando no botão “Gerar Eventos”. Nela você encontrará o tipo de evento, o nome do funcionário para quem o evento foi gerado, a data de envio, o status do evento e as ações a serem tomadas.",
      "Depois de salvar um evento, você pode gerá-lo clicando no botão “GERAR”. Após receber a mensagem de geração com sucesso, você pode baixar o leiaute do evento para o seu computador e enviar o evento clicando no botão “ENVIAR”. Em breve, o status do evento será alterado para “CONSULTAR”.",
      "Clicando neste novo botão, será realizado o andamento de todo o processo até se tornar “RECEBIDO”.",
      "Se ocorrer algum erro, o status permanecerá como “CONSULTAR” na coluna “Status do Evento”.",
      "Nesse caso, verifique qual foi o erro e corrija o evento.",
      "Se o registro estiver com o status “RECEBIDO” ou “RETIFICADO”, você pode clicar no botão “+” para retificar o evento. Neste mesmo botão, você pode gerar um novo evento ou enviar um evento de exclusão, para os casos de erros que não podem ser retificados. ",
      "Clicando no botão “RETIFICAR”, você será redirecionado para o formulário correspondente, para realizar os ajustes necessários. Depois de salvar, o status do evento mudará para “ENVIAR RETIFICAÇÃO”. Basta clicar neste botão e aguardar até que o status do evento mude para “CONSULTAR”. Então, basta clicar no novo status para confirmar que o evento foi retificado com sucesso, quando ele se tornar “RECEBIDO”.",
    ],
  },
  5: {
    viewMode: viewModes.default,
    assistant: assistant.vivi.mostrandoacima_esquerda,
    buttons: null,
    text: [
      "Certificado digital é um documento eletrônico que funciona como uma identidade virtual. Com ele, as empresas podem fazer várias coisas na internet, como transações bancárias, acessar sistemas do governo e, no caso das empresas, assinar digitalmente documentos, incluindo os eventos do eSocial. Para assinar os eventos do eSocial, as empresas precisam cadastrar o certificado digital delas. Isso garante que as informações prestadas sejam autênticas e íntegras.",
      "No SESI Facilita, o cadastro é feito com o certificado do tipo A1, que pode ser enviado facilmente. Primeiro, você escolhe a empresa que deseja associar ao certificado. Depois, clica no botão “Enviar certificado”, procura o arquivo do certificado no seu computador, insere a senha e escolhe se quer definir como certificado padrão.",
      "Pronto! Todos os eventos do eSocial relacionados à SST da empresa escolhida receberão automaticamente a assinatura digital cadastrada. Se você tiver outorga para vincular este certificado como da empresa responsável pelos eventos de SST, poderá associar esta mesma assinatura a outras empresas.",
      "Para vincular o certificado aos eventos de SST, é preciso fazer isso pelo site do eSocial. Se o seu contador já emite os outros eventos, procure-o para que ele realize a outorga. Se precisar de ajuda, estamos aqui para ajudá-lo. Você pode entrar em contato com nosso suporte ou acessar a central de ajuda no menu lateral.",
    ],
  },
};
