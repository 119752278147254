import React, { useEffect, useState } from "react";
import { Card, Tooltip } from "antd";
import IconSESI from "../../../../../../../components/IconsSESI";
import moment from "moment";
import { QuestionCircleOutlined } from "@ant-design/icons";
import "./styles.scss";

const ResumoDeAnalisesCard = (props) => {
  const [qtdPGR, setQtdPGR] = useState(0);
  const [qtdAutodeclaracao, setQtdAutodeclaracao] = useState(0);
  const [PGRPercentage, setPGRPercentage] = useState(0);
  const [AutodeclaracaoPercentage, setAutodeclaracaoPercentage] = useState(0);

  const currentMonthName = moment().format("MMMM");
  const lastMonthName = moment().subtract(1, "month").format("MMMM");
  const currentMonthDate = moment().format("MM/YYYY");
  const lastMonthDate = moment().subtract(1, "month").format("MM/YYYY");

  useEffect(() => {
    const { pgrsDoUltimoAno, autoDeclaracoesUltimoAno } = props.dashboard;
    if (pgrsDoUltimoAno) setQtdPGR(pgrsDoUltimoAno[currentMonthDate]);
    if (autoDeclaracoesUltimoAno)
      setQtdAutodeclaracao(autoDeclaracoesUltimoAno[currentMonthDate]);
    if (pgrsDoUltimoAno && autoDeclaracoesUltimoAno) {
      getPercentages();
    }
  }, [JSON.stringify(props.dashboard)]);

  // if (Object.keys(props.dashboard).length > 0) {
  //   const { pgrsDoUltimoAno, autoDeclaracoesUltimoAno } = props.dashboard;
  //   const currentMonth = moment().format("MM/YYYY");
  //   setQtdPGR(pgrsDoUltimoAno[currentMonth]);
  // }

  const getPercentages = () => {
    const { pgrsDoUltimoAno, autoDeclaracoesUltimoAno } = props.dashboard;
    if (pgrsDoUltimoAno[currentMonthDate] !== pgrsDoUltimoAno[lastMonthDate]) {
      const percentagePGR =
        Math.round(
          (100 * pgrsDoUltimoAno[currentMonthDate]) /
            (pgrsDoUltimoAno[lastMonthDate] === 0
              ? 1
              : pgrsDoUltimoAno[lastMonthDate])
        ) - 100;
      setPGRPercentage(percentagePGR);
    } else {
      setPGRPercentage(0);
    }

    if (
      autoDeclaracoesUltimoAno[currentMonthDate] !==
      autoDeclaracoesUltimoAno[lastMonthDate]
    ) {
      const percentageAutodeclaracao =
        Math.round(
          (100 * autoDeclaracoesUltimoAno[currentMonthDate]) /
            (autoDeclaracoesUltimoAno[lastMonthDate] === 0
              ? 1
              : autoDeclaracoesUltimoAno[lastMonthDate])
        ) - 100;
      setAutodeclaracaoPercentage(percentageAutodeclaracao);
    } else {
      setAutodeclaracaoPercentage(0);
    }
  };

  return (
    <Card bordered={false} hoverable={true}>
      <div className="header">
        <div className="title resumo-analises">
          <div>
            <h3>Resumo de documentos emitidos</h3>{" "}
            <Tooltip title="Uma empresa pode emitir mais de um relatório.">
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
          <h4>em {currentMonthName}</h4>
        </div>
      </div>
      <div className="content">
        <div className="content-line">
          <div className="content-lines">
            <p className="number-data">{qtdPGR}</p>
            <span> PGR</span>
          </div>
          <div className="content-lines">
            <div className="d-flex align-center percentage">
              <span>{`${PGRPercentage}%`}</span>
              {PGRPercentage > 100 && PGRPercentage !== 0 ? (
                <IconSESI type="arrow-circle-up" />
              ) : PGRPercentage !== 0 ? (
                <IconSESI type="arrow-circle-down" />
              ) : null}
            </div>
            <h5> em relação a {lastMonthName}</h5>
          </div>
        </div>
        <div className="content-line">
          <div className="content-lines">
            <p className="number-data">{qtdAutodeclaracao}</p>
            <span> AUTODECLARAÇÃO</span>
          </div>
          <div className="content-lines">
            <div className="d-flex align-center percentage">
              <span>{`${AutodeclaracaoPercentage}%`}</span>
              {AutodeclaracaoPercentage > 100 &&
              AutodeclaracaoPercentage !== 0 ? (
                <IconSESI type="arrow-circle-up" />
              ) : AutodeclaracaoPercentage !== 0 ? (
                <IconSESI type="arrow-circle-down" />
              ) : null}
            </div>
            <h5> em relação a {lastMonthName}</h5>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ResumoDeAnalisesCard;
