import React, { Component } from "react";
import { Row, Col } from "antd";
import Button from "../../../../components/Buttons/_/components/button";
import IconSESI from "../../../../components/IconsSESI";
import { Link } from "react-router-dom";
import ItensListaForm from "../components/ItensListaForm";

const ItensLista = (props) => {
  return (
    <React.Fragment>
      <Row gutter={32} type="flex" justify="space-around">
        <Col span={24}>
          <div className="header-checklist">
            <Link to="/selecaoempresa">
              <Button
                shape="circle"
                ghost
                text={<IconSESI type="arrow-left" />}
              />
            </Link>
            <h2>Lista de Informações</h2>
          </div>
        </Col>
      </Row>
      <Row type="flex" justify="space-around" align="middle">
        <ItensListaForm
          dispatch={props.dispatch}
          gerarPDF={props.gerarPDF}
          listaDeInformacoes={props.listaDeInformacoes}
        />
      </Row>
    </React.Fragment>
  );
};

export default ItensLista;
