import React, { useEffect, useState } from "react";
import { Row, Col, Select, Form, Button, Tabs } from "antd";

import Assistant from "../../../../components/VirtualAssistant";
import { ESocialTalks } from "../../../../constants/aroldoTalks";
import { Link } from "react-router-dom";
import ESocialCompanySelector from "./ESocialCompanySelector";
import ESocialEventSelector from "./ESocialEventSelector";
import ESocialForm from "./ESocialForm";
import Esocial2210Form from "./Esocial2210Form";
import ESocialSignatures from "./ESocialSignatures";
import IconSESI from "../../../../components/IconsSESI";
import {
  listarAgtsNocivos,
  listarCertificados,
  listarEventos,
  listarExames,
  listarFuncionariosEsocial,
  obterEvento,
  listar2210,
  listarEsocialS2240,
  obterEsocialS2240,
  obterEvento2210,
} from "../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ESocial2240Form from "./ESocialForm2240/Form";
import axios from "axios";
import {
  generativeSituation,
  publicPlace,
  countries,
  affeectedParts,
  causativeAgents,
  natureInjures,
} from "../../../../constants";
import FollowEvents from "./FollowEvents";

const { TabPane } = Tabs;
const { Option } = Select;

const ESocialDashboard = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [formMode, setFormMode] = useState("new");
  const [selectedEvent, setSelectedEvent] = useState({});
  const [situacoesGeradoras, setSicuacoesGeradoras] = useState([]);
  const [logradouros, setLogradouros] = useState([]);
  const [paises, setPaises] = useState([]);
  const [partesAtingidas, setPartesAtingidas] = useState([]);
  const [agentesCausadores, setAgentesCausadores] = useState([]);
  const [naturezaLesoes, setNaturezaLesoes] = useState([]);
  const [eventoDuplicado, setEventoDuplicado] = useState({});

  const premiumCompanies = props.companies.filter((company) => company.premium);

  const buscarSituacoesGeradoras = async () => {
    await axios
      .get(generativeSituation)
      .then((response) => {
        setSicuacoesGeradoras(response.data);
      })
      .catch((error) => {
        setSicuacoesGeradoras();
      });
  };

  const buscarPaises = async () => {
    await axios
      .get(countries)
      .then((response) => {
        setPaises(response.data);
      })
      .catch((error) => {
        setPaises([]);
      });
  };

  const buscarLogradouros = async () => {
    await axios
      .get(publicPlace)
      .then((response) => {
        setLogradouros(response.data);
      })
      .catch((error) => {
        setLogradouros([]);
      });
  };

  const buscarPargesAtingidas = async () => {
    await axios
      .get(affeectedParts)
      .then((response) => {
        setPartesAtingidas(response.data);
      })
      .catch((error) => {
        setPartesAtingidas([]);
      });
  };

  const buscarAgentesCausadores = async () => {
    await axios
      .get(causativeAgents)
      .then((response) => {
        setAgentesCausadores(response.data);
      })
      .catch((error) => {
        setAgentesCausadores([]);
      });
  };

  const buscarNaturezaLesoes = async () => {
    await axios
      .get(natureInjures)
      .then((response) => {
        setNaturezaLesoes(response.data);
      })
      .catch((error) => {
        setNaturezaLesoes([]);
      });
  };

  useEffect(() => {
    listarEventos(props.dispatch);
    listarCertificados(props.dispatch);
    listarAgtsNocivos(props.dispatch);
    listarExames(props.dispatch);
    listarEsocialS2240(props.dispatch);
    listarFuncionariosEsocial(props.dispatch);
    buscarSituacoesGeradoras();
    buscarPaises();
    buscarLogradouros();
    buscarPargesAtingidas();
    buscarAgentesCausadores();
    buscarNaturezaLesoes();
  }, [activeTab]);

  const selecionarEvento = async (id) => {
    await obterEvento(props.dispatch, id);
    setSelectedEvent(props.eventoSelecionado);
  };

  const selecionarEvento2240 = (id) => {
    obterEsocialS2240(props.dispatch, id);
    setSelectedEvent(props.s2240Selecionado);
  };
  const duplicarEvento = (evento) => {
    setEventoDuplicado(evento);
  };

  return (
    // <Row gutter={32}>
    //   <Assistant
    //     perfil={props.perfil}
    //     talk={ESocialTalks[1]}
    //     path="caracterizacao"
    //   />
    //   <Col lg={16} md={20} className="esocial-dashboard">
    <Tabs className="esocialTabs" activeKey={activeTab}>
      <TabPane key="1">
        <ESocialCompanySelector {...props} setActiveTab={setActiveTab} />
      </TabPane>

      <TabPane key="2">
        <ESocialEventSelector {...props} setActiveTab={setActiveTab} />
      </TabPane>

      <TabPane key="3">
        <ESocialForm
          {...props}
          setActiveTab={setActiveTab}
          setFormMode={setFormMode}
          formMode={formMode}
          selectedCompany={props.selectedCompany}
          listarEventos={listarEventos}
          eventoSelecionado={props.eventoSelecionado}
          selectedEvent={props.selectedEvent}
          tiposExames={props.tiposExames}
          eventoDuplicado={eventoDuplicado}
          activeTab={activeTab}
        />
      </TabPane>

      <TabPane key="4">
        <ESocialSignatures
          {...props}
          setActiveTab={setActiveTab}
          // listarCertificados={listarCertificados}
        />
      </TabPane>

      <TabPane key="5">
        <FollowEvents
          {...props}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          formMode={formMode}
          setFormMode={setFormMode}
          selecionarEvento={selecionarEvento}
          duplicarEvento={duplicarEvento}
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          eventoSelecionado={props.eventoSelecionado}
          listarEventos={listarEventos}
          listar2210={listar2210}
          listarCertificados={listarCertificados}
          listarFuncionariosEsocial={listarFuncionariosEsocial}
          funcionariosEsocial={props.funcionariosEsocial}
          selecionarEvento2240={selecionarEvento2240}
          certificadosCadastrados={props.certificadosCadastrados}
        />
      </TabPane>

      {/* Acidente de trabalho */}
      <TabPane key="6">
        <Esocial2210Form
          {...props}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          setFormMode={setFormMode}
          eventoDuplicado={eventoDuplicado}
          formMode={formMode}
          selecionarEvento={selecionarEvento}
          selectedCompany={props.selectedCompany}
          listarEventos={listarEventos}
          situacoesGeradoras={situacoesGeradoras}
          logradouros={logradouros}
          paises={paises}
          partesAtingidas={partesAtingidas}
          agentesCausadores={agentesCausadores}
          naturezaLesoes={naturezaLesoes}
          funcionariosEsocial={props.funcionariosEsocial}
        />
      </TabPane>

      <TabPane key="7">
        <ESocial2240Form
          {...props}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          setFormMode={setFormMode}
          formMode={formMode}
          selectedCompany={props.selectedCompany}
          listarEventos={listarEventos}
          eventoSelecionado={props.eventoSelecionado}
          selectedEvent={props.selectedEvent}
          agentesNocivos={props.agentesNocivos}
          eventosEsocials2240={props.eventosEsocials2240}
          selecionarEvento2240={selecionarEvento2240}
          setSelectedEvent={setSelectedEvent}
          eventoDuplicado={eventoDuplicado}
          funcionariosEsocial={props.funcionariosEsocial}
        />
      </TabPane>
    </Tabs>
    //   </Col>
    // </Row>
  );
};

const mapStateProps = (state) => {
  return {
    eventosEsocial: state.esocial.eventosEsocial
      ? state.esocial.eventosEsocial
      : [],
    eventoSelecionado: state.esocial.eventoSelecionado
      ? state.esocial.eventoSelecionado
      : {},
    certificadosCadastrados: state.esocial.certificadosCadastrados
      ? state.esocial.certificadosCadastrados
      : [],
    funcionariosEsocial: state.esocial.funcionariosEsocial
      ? state.esocial.funcionariosEsocial
      : [],
    agentesNocivos: state.esocial.agentesNocivos
      ? state.esocial.agentesNocivos
      : [],
    tiposExames: state.esocial.tiposExames ? state.esocial.tiposExames : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        listarEventos,
        obterEvento,
        listarCertificados,
        listarFuncionariosEsocial,
        listarAgtsNocivos,
        listarExames,
        listarEsocialS2240,
      },
      dispatch
    ),
  };
};

export default connect(mapStateProps, mapDispatchToProps)(ESocialDashboard);
