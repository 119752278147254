import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import { Button } from "antd";

const NotFound = () => (
  <div className="error-page">
    <center>
      <span className="error-title">404</span>
      <h1>Desculpe...</h1>
      <h1>Página não encontrada</h1>
      <Button className="back-btn">
        <Link to="/">VOLTAR PARA O SESI FACILITA</Link>
      </Button>
    </center>
  </div>
);
export default NotFound;
