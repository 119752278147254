export const LISTAR_FATORES_DE_RISCO = "LISTAR_FATORES_DE_RISCO";
export const OBTER_FATOR_DE_RISCO = "OBTER_FATOR_DE_RISCO";
export const ALTERAR_FATOR_DE_RISCO = "ALTERAR_FATOR_DE_RISCO";
export const SALVAR_FATOR_DE_RISCO = "SALVAR_FATOR_DE_RISCO";
export const LIMPAR_FATOR_DE_RISCO_SELECIONADO =
  "LIMPAR_FATOR_DE_RISCO_SELECIONADO";
export const LISTAR_MAOS = "LISTAR_MAOS";
export const LISTAR_EPCS = "LISTAR_EPCS";
export const LISTAR_MAOS_POR_GRUPO = "LISTAR_MAOS_POR_GRUPO";
export const LISTAR_EPCS_POR_GRUPO = "LISTAR_EPCS_POR_GRUPO";
export const OBTER_MAO = "OBTER_MAO";
export const OBTER_EPC = "OBTER_EPC";
export const SALVAR_MAO_POR_GRUPO = "SALVAR_MAO_POR_GRUPO";
export const SALVAR_EPC_POR_GRUPO = "SALVAR_EPC_POR_GRUPO";
export const ALTERAR_MAO_POR_GRUPO = "ALTERAR_MAO_POR_GRUPO";
export const LIMPAR_MAO_SELECIONADO = "LIMPAR_MAO_SELECIONADO";
export const LISTAR_EPIS = "LISTAR_EPIS";
export const LISTAR_EPIS_POR_GRUPO = "LISTAR_EPIS_POR_GRUPO";
export const OBTER_EPI = "OBTER_EPI";
export const SALVAR_EPI_POR_GRUPO = "SALVAR_EPI_POR_GRUPO";
export const ALTERAR_EPI_POR_GRUPO = "ALTERAR_EPI_POR_GRUPO";
export const LIMPAR_EPI_SELECIONADO = "LIMPAR_EPI_SELECIONADO";
export const CRIAR_EPC = "CRIAR_EPC";
export const EDITAR_EPC = "EDITAR_EPC";
export const OBTER_EPC_DA_LISTA = "OBTER_EPC_DA_LISTA";
export const LIMPAR_EPC_SELECIONADO = "LIMPAR_EPC_SELECIONADO";
export const DELETAR_EPC = "DELETAR_EPC";
export const CRIAR_EPI = "CRIAR_EPI";
export const EDITAR_EPI = "EDITAR_EPI";
export const OBTER_EPI_DA_LISTA = "OBTER_EPI_DA_LISTA";
export const LIMPAR_SELECAO_EPI = "LIMPAR_SELECAO_EPI";
export const DELETAR_EPI = "DELETAR_EPC";
export const CRIAR_MAO = "CRIAR_MAO";
export const EDITAR_MAO = "EDITAR_MAO";
export const OBTER_MAO_DA_LISTA = "OBTER_MAO_DA_LISTA";
export const LIMPAR_SELECAO_MAO = "LIMPAR_SELECAO_MAO";
export const DELETAR_MAO = "DELETAR_MAO";
