import React, { useState } from "react";
import { Form } from "antd";
import MaskedInput from "antd-mask-input";
import Button from "../../../../components/Buttons/_/components/button";
import IconSESI from "../../../../components/IconsSESI";
import ReCAPTCHA from "react-google-recaptcha";
import { requerirMudancaDeSenha } from "../actions";

const PasswordForm = (props) => {
  const [form] = Form.useForm();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const resetState = () => {
    form.resetFields();
  };

  const captchaResult = (value) => {
    if (value) {
      setButtonDisabled(false);
    }
  };

  const toggleLoading = () => {
    loading ? setLoading(false) : setLoading(true);
  };

  const handleSubmit = (values) => {
    form
      .validateFields()
      .then(async (values) => {
        const cpfMaskOff = values.cpf.replace(/[.-]/gi, "");
        const credentials = {
          username: cpfMaskOff,
        };
        await requerirMudancaDeSenha(props.dispatch, cpfMaskOff);
        props.signIn(props.dispatch, credentials, toggleLoading);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Form onFinish={handleSubmit} form={form}>
      <div className="header-form transparent">
        <Button
          shape="circle"
          ghost
          text={<IconSESI type="arrow-left" />}
          onClick={() => {
            resetState();
            props.tab("login");
          }}
        />
        <h2>Recuperar Senha</h2>
      </div>
      <div className="login-form-body">
        <Form.Item
          name="cpf"
          rules={[{ required: true, message: "Este campo é obrigatório." }]}
          label={
            <React.Fragment>
              <h4>Insira seu CPF e enviaremos para o e-mail </h4>
              <h4> cadastrado o link para acessar a sua conta. </h4>
            </React.Fragment>
          }
        >
          <MaskedInput name="cpf" placeholder="CPF" mask="111.111.111-11" />
        </Form.Item>
        <Form.Item>
          <ReCAPTCHA
            sitekey="6LeveQ4dAAAAAKf_fJUKbO0zBj87tXbfVssjEGWe"
            onChange={captchaResult}
          />
        </Form.Item>
      </div>
      <div className="login-form-footer">
        <Button
          htmlType="submit"
          text="Enviar"
          disabled={buttonDisabled}
          onClick={() => {
            props.tab("code");
          }}
        />
      </div>
    </Form>
  );
};

export default PasswordForm;
